// get user list actions
export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR";

// get agency list actions
export const GET_AGENCY_LIST_REQUEST = "GET_AGENCY_LIST_REQUEST";
export const GET_AGENCY_LIST_SUCCESS = "GET_AGENCY_LIST_SUCCESS";
export const GET_AGENCY_LIST_ERROR = "GET_AGENCY_LIST_ERROR";

//create new user
export const CREATE_NEW_USER_REQUEST = "CREATE_NEW_USER_REQUEST";
export const CREATE_NEW_USER_SUCCESS = "CREATE_NEW_USER_SUCCESS";
export const CREATE_NEW_USER_ERROR = "CREATE_NEW_USER_ERROR";
export const CLEAR_CREATE_NEW_USER_RESPONSE = "CLEAR_CREATE_NEW_USER_RESPONSE";

// get client list actions
export const GET_CLIENT_LIST_REQUEST = "GET_CLIENT_LIST_REQUEST";
export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS";
export const GET_CLIENT_LIST_ERROR = "GET_CLIENT_LIST_ERROR";

// Common error actions for all list
export const CLEAR_LIST_ERROR = "CLEAR_LIST_ERROR";

export const doGetUserList = (payload) => {
  return ({
    type: GET_USER_LIST_REQUEST,
    payload
  });
}

export const doGetAgencyList = (payload) => {
  return ({
    type: GET_AGENCY_LIST_REQUEST,
    payload
  });
}

export const doGetClientList = (payload) => {
  return ({
    type: GET_CLIENT_LIST_REQUEST,
    payload
  });
}

export const clearListError = () => {
  return ({
    type: CLEAR_LIST_ERROR
  });
}

export const doCreateUser = (payload) => {
  return ({
    type: CREATE_NEW_USER_REQUEST,
    payload
  });
}

export const clearCreateUserResponse = () => {
  return ({
    type: CLEAR_CREATE_NEW_USER_RESPONSE
  });
}

// User management > Client
export const CREATE_NEW_AGENCY_REQUEST = "CREATE_NEW_AGENCY_REQUEST";
export const CREATE_NEW_AGENCY_SUCCESS = "CREATE_NEW_AGENCY_SUCCESS";
export const CREATE_NEW_AGENCY_ERROR = "CREATE_NEW_AGENCY_ERROR";
export const CLEAR_CREATE_NEW_AGENCY_RESPONSE = "CLEAR_CREATE_NEW_AGENCY_RESPONSE";

export const doCreateAgency = (payload) => {
  return ({
    type: CREATE_NEW_AGENCY_REQUEST,
    payload
  });
}

export const clearCreateAgencyResponse = () => {
  return ({
    type: CLEAR_CREATE_NEW_AGENCY_RESPONSE
  });
}

// User management > Client
export const CREATE_NEW_CLIENT_REQUEST = "CREATE_NEW_CLIENT_REQUEST";
export const CREATE_NEW_CLIENT_SUCCESS = "CREATE_NEW_CLIENT_SUCCESS";
export const CREATE_NEW_CLIENT_ERROR = "CREATE_NEW_CLIENT_ERROR";
export const CLEAR_CREATE_NEW_CLIENT_RESPONSE = "CLEAR_CREATE_NEW_CLIENT_RESPONSE";

export const doCreateClient = (payload) => {
  return ({
    type: CREATE_NEW_CLIENT_REQUEST,
    payload
  });
}

export const clearCreateClientResponse = () => {
  return ({
    type: CLEAR_CREATE_NEW_CLIENT_RESPONSE
  });
}

