import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual, reject as _reject, every as _every } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { showAlertMessage, ErrorMessage, CustomModal } from "../common/formElements";
import { downloadExcelFile, isEmailValid } from "../../helpers/utils";

import { exportUploadTemplateData, sendExportUploadTemplateMail } from "../../redux/services/campaign";
import { updateLoadingState } from "../../redux/actions/application";

class ExportUploadTemplate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      emailTo: "",
      note: "",
      addMeToCC: false,
      showModal: false,
      isFormSubmitted: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevState.emailTo, this.state.emailTo) && (_get(this.state, "isFormSubmitted", false) === true)) {
      this._handleValidation();
    }
  }

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { emailTo } = this.state;

    let formErrors = {};

    if (_isEmpty(emailTo)) {
      formErrors["emailTo"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_brief.export_upload_template.email_to_label", defaultMessage: "email to" })
      });
    }

    if (!_isEmpty(emailTo)) {
      let emailArray = _reject((emailTo || "").replaceAll(" ", "").split(","), _isEmpty);
      const isValidEmail = _every(emailArray, (e) => isEmailValid(e));

      if (!isValidEmail) {
        formErrors["emailTo"] = intl.formatMessage({ id: "error.valid", defaultMessage: "Please enter valid {field}." }, {
          field: intl.formatMessage({ id: "campaign_brief.export_upload_template.email_to_label", defaultMessage: "email to" })
        });
      }
    }

    this.setState({ formErrors });

    if (returnFlag) {
      return (!_isEmpty(formErrors)) ? false : true;
    }
  }

  _handleSendEmail = async () => {
    const { campaignId, selectedBriefIds, updateLoadingState } = this.props;
    const { formErrors, emailTo, note, addMeToCC } = this.state;

    if (!_isEmpty(formErrors)) { return false; }

    this.setState({ isFormSubmitted: true });

    const isValidForm = this._handleValidation(true);

    if (!isValidForm) { return false; }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const payload = {
        brief_ids: selectedBriefIds,
        campaign_id: campaignId,
        is_download: false,
        include_me_in_cc: (addMeToCC || false),
        emails: _reject((emailTo || "").split(","), _isEmpty),
        note: (note || "")
      };

      const response = await sendExportUploadTemplateMail(payload);

      if (_get(response, "flag", false) === true) {

        showAlertMessage(_get(response, "message", "Email sent successfully."), "success");

        this._closeModal();
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while sending email."));
      }

    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while sending email.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleDownload = async () => {
    const { campaignId, selectedBriefIds, updateLoadingState, intl } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const payload = {
        brief_ids: selectedBriefIds,
        campaign_id: campaignId,
        is_download: true,
        include_me_in_cc: false,
        emails: [],
        note: ""
      };

      const response = await exportUploadTemplateData(payload);

      if (_get(response, "flag", false) === true) {

        const downloadFileDetails = _get(response, "data", []);

        const contentType = _get(downloadFileDetails, "contentType", "");
        const contents = _get(downloadFileDetails, "Contents", "");
        const filename = _get(downloadFileDetails, "filename", "");

        if ((contentType !== "") && (contents !== "") && (filename !== "")) {
          downloadExcelFile(contentType, contents, filename);

          showAlertMessage(intl.formatMessage({ id: "message.file_generated_successfully", defaultMessage: "File generated successfully." }), "success");

          this._closeModal();
        }
      } else {
        showAlertMessage(response.message || "Something went wrong while exporting upload template.");
      }
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while exporting upload template.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _closeModal = () => {
    this.setState({ formErrors: {}, emailTo: "", note: "", addMeToCC: false, showModal: false, isFormSubmitted: false });
  }

  _renderView = () => {
    const { intl } = this.props;
    const { showModal, formErrors, emailTo, note, addMeToCC } = this.state;

    return (
      <CustomModal
        isOpen={showModal}
        size="md"
        modalTitle={intl.formatMessage({ id: "campaign_brief.export_upload_template.title", defaultMessage: "export upload template" })}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
      >
        <>
          <div className="row">
            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label text-capitalize-first">
                  <FormattedMessage id="campaign_brief.export_upload_template.email_to" defaultMessage="email to: (add email addresses separated by comma)" />
                  <span className="text-danger">*</span>
                </label>
                <input
                  name="email_to"
                  type="text"
                  className="form-control"
                  placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                    field: intl.formatMessage({ id: "campaign_brief.export_upload_template.email_to", defaultMessage: "email to: (add email addresses separated by comma)" })
                  })}
                  value={(emailTo || "")}
                  onChange={(event) => this.setState({ emailTo: _get(event, "target.value", "") })}
                />
                {_get(formErrors, "emailTo", "") && <ErrorMessage message={_get(formErrors, "emailTo", "")} />}
              </div>
            </div>

            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label text-capitalize-first">
                  <FormattedMessage id="campaign_brief.export_upload_template.note" defaultMessage="note" />
                </label>
                <textarea
                  name="note"
                  className="form-control"
                  value={(note || "")}
                  placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                    field: intl.formatMessage({ id: "campaign_brief.export_upload_template.note", defaultMessage: "note" })
                  })}
                  onChange={(event) => this.setState({ note: _get(event, "target.value", "") })}
                />
                {_get(formErrors, "note", "") && <ErrorMessage message={_get(formErrors, "note", "")} />}
              </div>
            </div>
            <div className="col-log-12">
              <div className="mb-6">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="addMeToCC"
                    className="form-check-input"
                    checked={(addMeToCC || false)}
                    onChange={(e) => this.setState((prevState) => ({ addMeToCC: !_get(prevState, "addMeToCC", false) }))}
                  />
                  <label className="form-check-label fw-medium ms-1 text-capitalize" htmlFor="addMeToCC">
                    <FormattedMessage id="campaign_brief.export_upload_template.add_me_to_cc" defaultMessage="add me to CC" />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button type="button" className="btn btn-secondary" onClick={() => this._closeModal()}>
              <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
            </button>

            <button className="btn btn-primary" onClick={() => this._handleDownload()}>
              <FormattedMessage id="btn.download" defaultMessage="download" />
            </button>
            <button className="btn btn-primary" onClick={() => this._handleSendEmail()}>
              <FormattedMessage id="btn.send_email" defaultMessage="send email" />
            </button>
          </div>
        </>
      </CustomModal>
    );
  }

  render() {

    return (
      <>
        <button className="btn btn-outline-primary waves-effect" onClick={() => this.setState({ showModal: true })}>
          <FormattedMessage id="campaign_brief.btn.export_upload_template" defaultMessage="export upload template" />
        </button>

        {this._renderView()}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(null, mapDispatchToProps)(injectIntl(ExportUploadTemplate));
