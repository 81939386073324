import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, cloneDeep as _cloneDeep, map as _map } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";

import { CustomModal, showAlertMessage, SingleDropzone } from "../common/formElements";

import { getClientBriefSuppressions, assignBriefCriteria } from "../../redux/services/campaignSetup";

import { updateLoadingState } from "../../redux/actions/application";

class AssignSuppressionsToBrief extends Component {

  constructor(props) {
    super(props);

    this.defaultState = {
      searchText: "",
      selectIds: [],
      listData: [],
      initialListData: [],
      recheckDataValidation: false
    };

    this.state = {
      ...this.defaultState,
      checkAllIds: false,
      showModal: false,
      fileUploaded: false
    };
  }

  componentDidUpdate(prevProps, prevStates) {

    if (!_isEqual(prevStates.showModal, this.state.showModal) && _get(this.state, "showModal", false) === true) {

      this._fetchListData();
    }

    if (!_isEqual(prevStates.searchText, this.state.searchText)) {
      const { initialListData, searchText } = this.state;

      let tmpListData = _cloneDeep(initialListData);

      tmpListData = (tmpListData || []).filter(({ name }) => (name || "").toLowerCase().includes((searchText || "").toLowerCase()));

      this.setState({ listData: tmpListData });
    }

    if (!_isEqual(prevStates.fileUploaded, _get(this.state, "fileUploaded", false)) && (_get(this.state, "fileUploaded", false) === true)) {
      showAlertMessage("Suppressions assigned to briefs successfully.", "success");

      this._closeModal();
    }
  }

  // Fetch data list
  _fetchListData = async () => {
    const { selectedBriefIds, clientId, updateLoadingState, intl } = this.props;
    const { selectIds } = this.state;
    let tmpSelectIds = _cloneDeep(selectIds || []);

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getClientBriefSuppressions(clientId, { brief_ids: (selectedBriefIds || []) });

      if ((response.flag || false) === true) {


        _get(response, "data", []).forEach(account => {
          if (_get(account, "is_assigned_to_brief", 0) === 1) {
            tmpSelectIds.push(_get(account, "id", 0));
          }
        });

        this.setState({
          listData: _get(response, "data", []),
          initialListData: _get(response, "data", []),
          selectIds: (tmpSelectIds || [])
        });
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while fetching suppressions."));
      }

    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while fetching suppressions."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _showModal = () => {
    const { selectedBriefIds, intl } = this.props;

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    this.setState({ ...this.defaultState, showModal: true });
  }

  _closeModal = () => {

    this.setState({ ...this.defaultState, showModal: false, fileUploaded: false });
  }

  _selectIds = (id = null, source = "single") => {
    const { selectIds, checkAllIds, listData } = this.state;
    let updatedState = null;
    let tmpSelectIds = _cloneDeep(selectIds || []);

    switch (source) {
      case "single":
        let tmpCheckAllIds = _cloneDeep(checkAllIds);

        if (tmpSelectIds.includes(id)) {
          tmpSelectIds = (tmpSelectIds).filter(c => (c !== id));
          tmpCheckAllIds = false;
        } else {
          tmpSelectIds.push(id);
        }

        updatedState = { selectIds: tmpSelectIds, checkAllIds: tmpCheckAllIds };

        break;

      case "all":
        updatedState = {
          selectIds: (checkAllIds === true) ? [] : _map(listData, "id"),
          checkAllIds: !checkAllIds
        };
        break;

      default:
        break;
    }


    this.setState(updatedState);
  }

  async _assignIdsToBriefs() {
    const { selectedBriefIds, updateLoadingState, intl } = this.props;
    const { selectIds } = this.state;

    if ((selectIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.suppression", defaultMessage: "suppression" }) }
      ));
      return false;
    }

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const payload = {
        "brief_ids": selectedBriefIds,
        "criteria_values": selectIds,
        "criteria_name": "suppression"
      };

      const response = await assignBriefCriteria(payload);

      if ((response.flag || false) === true) {

        showAlertMessage(_get(response, "data.message", "Suppression assigned to brief successfully."), "success");

        this._closeModal();
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while assigning briefs."));
      }

    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while assigning briefs."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderModalContent = () => {
    const { source = "campaign_setup", selectedBriefIds, allowedUploadFiles, allowedUploadFilesLabel, campaignId, clientId, intl } = this.props;
    const { showModal, listData, selectIds, checkAllIds } = this.state;

    return (
      <CustomModal
        isOpen={showModal}
        className="text-capitalize"
        modalTitle={(intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.modal.title", defaultMessage: "assign {dataType} to briefs" },
          { dataType: intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.suppression", defaultMessage: "suppression" }) }))}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
      >

        <form className="modalForm assign-brief-modal">
          {(source === "campaign_edit") && (
            <div className="row mb-5">
              <div className="col-md-12">
                <div>
                  <SingleDropzone
                    source="suppression_list"
                    pageSource="campaign_edit"
                    briefIds={(selectedBriefIds || [])}
                    existingSelectedIds={(selectIds || [])}
                    campaignId={(campaignId || null)}
                    clientId={(clientId || null)}
                    acceptedFormat={allowedUploadFiles}
                    allowedExtensions={allowedUploadFilesLabel}
                    setState={(args) => this.setState(args)}
                  />
                </div>
              </div>
              <span className="border-bottom border-2" />
            </div>
          )}

          <div className="searchOverview mb-3">
            <input
              className="form-control fuzzy-search"
              type="text"
              name="search"
              placeholder={intl.formatMessage({ id: "search", defaultMessage: "search" })}
              onChange={(e) => {
                const { target: { value } } = e;

                this.setState({ searchText: value });
              }}
            />
          </div>
          <div>
            <div className="form-check" onClick={(e) => this._selectIds(null, "all")}>
              <input
                type="checkbox"
                className="form-check-input"
                name="outcome"
                key="check-all-briefs"
                checked={checkAllIds}
                onChange={() => { }}
              />
              <label className="form-check-label text-capitalize" htmlFor="check-all-briefs">
                <FormattedMessage id="placeholder.select_all" defaultMessage="select all" />
              </label>
            </div>
          </div>

          <div className="assign-brief-container">
            <>
              {(listData).map((l) => {
                const id = (l.id || null);

                return (
                  <div key={`main-dev-${id}`} onClick={(e) => this._selectIds(id)}>
                    <div className="form-check">
                      <input
                        key={`checkbox-${id}`}
                        type="checkbox"
                        className="form-check-input"
                        name="outcome"
                        checked={((selectIds || []).includes(id))}
                        onChange={() => { }}
                        value={id}
                      />
                      <label className="form-check-label" htmlFor={`checkbox-${id}`}>{(l.name || "")}</label>
                    </div>
                  </div>
                );
              })}
            </>
          </div>


        </form>

        <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
          <button className="btn btn-secondary" onClick={() => this._closeModal()} >
            <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
          </button>
          <button className="btn btn-primary text-capitalize" onClick={() => this._assignIdsToBriefs()}>
            <FormattedMessage id="campaign_setup.campaign_brief.assign.data_type" defaultMessage="assign {dataType}" values={{ dataType: "suppressions" }} />
          </button>
        </div>
      </CustomModal>
    );
  }

  render() {

    return (
      <>

        {this._renderModalContent()}

        <Dropdown.Item className="dropdown-item d-block waves-effect text-capitalize" onClick={() => this._showModal()} >
          <FormattedMessage id="campaign_setup.campaign_brief.assign.data_type" defaultMessage="assign {dataType}" values={{ dataType: "suppressions" }} />
        </Dropdown.Item>

      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  allowedUploadFiles: _get(state, "application.constants.allowedUploadFiles", ""),
  allowedUploadFilesLabel: _get(state, "application.constants.allowedUploadFilesLabel", ""),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignSuppressionsToBrief));
