import React from "react";
import { createRoot } from "react-dom/client";

import Main from "./Main";
import "./styles/css/main.css";

const mainElement = document.getElementById("main");

const mainRoot = createRoot(mainElement);
mainRoot.render(<Main />);

// hot reloading.
if (module.hot) {
  module.hot.accept("./Main", () => {
    const NextApp = require("./Main").default;

    mainRoot.render(<NextApp />);
  });
}
