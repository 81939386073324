import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, cloneDeep as _cloneDeep } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { CustomModal, Select, showAlertMessage } from "../../../resusableComponents/common/formElements";

import { fetchPipingLeadCounts, submitLeadsToCRM } from "../../../redux/services/campaign";
import { updateLoadingState } from "../../../redux/actions/application";

const defaultCrm = { id: null, client_id: null, shortcode: "Select CRM", pipe_to: "Select CRM" };

class PipeToCRM extends Component {

  constructor(props) {
    super(props);

    this.defaultState = {
      noLeads: 0,
      showModal: false,
      selCrmId: { ...defaultCrm },
    }

    this.state = {
      ...this.defaultState,
      crmPartnerList: [],
    };

  }

  componentDidUpdate(prevProps, prevState) {

    if ((!_isEqual(prevProps.crmList, this.props.crmList))) {

      const tmpCrmList = _get(this.props, "crmList", []);
      (tmpCrmList || []).unshift(_cloneDeep(defaultCrm));

      this.setState({ crmPartnerList: tmpCrmList });
    }

    if ((!_isEqual(prevState.selCrmId, this.state.selCrmId))) {

      if (_get(this.state, "selCrmId.id", null) !== null) {

        this._getLeadCount();
      } else {

        this.setState({ noLeads: 0 });
      }
    }
  }

  _getLeadCount = async () => {
    const { campaignId, selectAllLeads, updateLoadingState, selectedLeads = [] } = this.props;
    const { selCrmId } = this.state;

    try {

      if (typeof updateLoadingState === "function") {
        updateLoadingState(true);
      }

      const _leadsFilter = this._prepareLeadFilter();

      const payload = {
        campaign_id: (campaignId || null),
        match_key: (_leadsFilter.matchKey || ""),
        approval_ids: (_leadsFilter.approval || []),
        column_id: (_leadsFilter.columnId || null),
        column_value: (_leadsFilter.columnValues || []),
        asset_ids: (_leadsFilter.assetIds || []),
        upload_ids: (_leadsFilter.uploadIds || []),
        lead_ids: ((selectAllLeads || false) === true) ? null : selectedLeads,
        piped: (_leadsFilter.piped !== null) ? _leadsFilter.piped : null,
        pipe_to_crm_id: _get(selCrmId, "id", null),
      };

      const response = await fetchPipingLeadCounts(payload);

      if ((response.flag || false) === false) {
        showAlertMessage(_get(response, "data.message", "Something went wrong while fetching lead counts."));
      }
      this.setState({ noLeads: _get(response, "data.lead_count", 0) });

    } catch (err) {
      showAlertMessage((err.message || "Something went wrong while fetching lead counts."));
    } finally {
      if (typeof updateLoadingState === "function") {
        updateLoadingState(false);
      }
    }
  }

  _prepareLeadFilter = () => {
    const { leadsFilter } = this.props;
    const updatedStates = { ...leadsFilter };
    // Update column value
    if (updatedStates.columnValues) {
      updatedStates.columnValues = updatedStates.columnValues.split("\n");
    }

    updatedStates.piped = (_get(updatedStates, "piped", null) === null) ? null : _get(updatedStates, "piped", null);

    return updatedStates;
  }

  _closeModal = () => {

    this.setState({ showModal: false, selCrmId: { ...defaultCrm } });
  }

  _pushToCRM = async () => {
    const { updateLoadingState, campaignId, selectedLeads = [], refreshLeadTable = () => { }, intl } = this.props;
    const { selCrmId, selectAllLeads } = this.state;

    try {
      if (_get(selCrmId, "id", null) === null) {
        showAlertMessage(intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
          field: intl.formatMessage({ id: "view_leads.crm", defaultMessage: "CRM" })
        }));
        return false;
      }

      if (typeof updateLoadingState === "function") {
        updateLoadingState(true);
      }

      const _leadsFilter = this._prepareLeadFilter();

      const payload = {
        campaign_id: (campaignId || null),
        match_key: (_leadsFilter.matchKey || ""),
        approval_ids: (_leadsFilter.approval || []),
        column_id: (_leadsFilter.columnId || null),
        column_value: (_leadsFilter.columnValues || []),
        asset_ids: (_leadsFilter.assetIds || []),
        upload_ids: (_leadsFilter.uploadIds || []),
        pipe_to_crm_id: _get(selCrmId, "id", []),
        lead_ids: ((selectAllLeads || false) === true) ? null : selectedLeads,
        piped: (_leadsFilter.piped === null) ? null : (_leadsFilter.piped || 0),
      };

      const response = await submitLeadsToCRM(payload);

      if ((response.flag || false) === true) {

        showAlertMessage(_get(response, "message", "Leads successfully piped to partner's CRM."), "success");

        if (typeof refreshLeadTable === "function") { refreshLeadTable(true); }
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while push to CRM."));
      }
      this.setState({ ...this.defaultState });

    } catch (err) {
      showAlertMessage((err.message || "Something went wrong while push to CRM."));
    } finally {
      if (typeof updateLoadingState === "function") {
        updateLoadingState(false);
      }
    }
  }

  _renderModalContent = () => {
    const { intl } = this.props;
    const { showModal, selCrmId, crmPartnerList, noLeads } = this.state;

    if (showModal === false) { return null; }

    return (
      <CustomModal
        isOpen={showModal}
        modalTitle={intl.formatMessage({ id: "view_leads.pipe_to_crm", defaultMessage: "Pipe to CRM" })}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
      >
        <form className="modalForm">
          <div className="mb-3">
            <label className="form-label text-capitalize">
              <FormattedMessage
                id="label.select_field"
                defaultMessage="select {field}"
                values={{ field: intl.formatMessage({ id: "view_leads.crm", defaultMessage: "CRM" }) }}
              />
            </label>
            <div className="modalSelect">
              <Select
                className="form-custom-select"
                getOptionLabel={(option) => (option.shortcode || "")}
                getOptionValue={(option) => (option.id || null)}
                isMulti={false}
                value={(selCrmId || null)}
                options={(crmPartnerList || [])}
                onChange={(opt) => this.setState({ selCrmId: opt })}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label text-capitalize">
              <FormattedMessage id="view_leads.pipe_to_crm.no_of_leads_selected" defaultMessage="No. of leads selected: " />
              {(noLeads || 0)}
            </label>
          </div>
        </form>

        <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
          <button className="btn btn-secondary" onClick={() => this._closeModal()} >
            <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
          </button>
          <button
            className="btn btn-primary text-capitalize"
            onClick={() => this._pushToCRM()}
            disabled={((noLeads || 0) === 0) ? true : false}
          >
            <FormattedMessage id="btn.submit" defaultMessage="submit" />
          </button>
        </div>

      </CustomModal>
    );
  }

  render() {
    const { selectedLeads, selectAllLeads, intl } = this.props;
    const { crmPartnerList } = this.state;

    const haveCRMs = ((crmPartnerList || []).length > 0) ? true : false;

    return (
      <>
        <div className="col-lg-2">
          <label className="form-label text-capitalize d-block">
            <FormattedMessage id="view_leads.pipe_to_crm" defaultMessage="Pipe to CRM" />
          </label>
          <button
            className="btn btn-outline-primary waves-effect w-100"
            title={(haveCRMs === false) ? intl.formatMessage({ id: "view_leads.pipe_to_crm.pipe_to_crm_is_not_configured_yet_for_this_campaign", defaultMessage: "Pipe-to-CRM is not configured yet for this campaign." }) : ""}
            onClick={() => {

              if (((selectedLeads || []).length > 0) || ((selectAllLeads || false) === true)) {
                this.setState({ showModal: true });
              } else {
                showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
                  field: intl.formatMessage({ id: "lead", defaultMessage: "lead" })
                }));
              }
            }}
            disabled={(haveCRMs === true) ? false : true}
          >
            <FormattedMessage id="view_leads.pipe_to_crm" defaultMessage="Pipe to CRM" />
          </button>
        </div>
        {this._renderModalContent()}
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (payload) => dispatch(updateLoadingState(payload)),
});


export default connect(null, mapDispatchToProps)(injectIntl(PipeToCRM));
