import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./redux/configureStore";
import Waves from "node-waves";

import App from "./App";

// Initialize Waves
Waves.init();
Waves.attach(".waves-effect", ["waves-button"], ".sidebar-menu-link");
Waves.attach(".btn");
Waves.attach(".dropdown-menu .dropdown-item");

Waves.attach(".custom-waves-effect", { duration: 500, delay: 200 });

export default function () {
  return (
    <Provider store={store}>
      <PersistGate loading={false} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
}
