import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { get as _get, map as _map } from "lodash";
import { connect } from "react-redux";

import { EmptyLabel } from "../../../resusableComponents/common/formElements";

import { updateCampaignSetupDetails } from "../../../redux/actions/campaignSetup";

class CampaignSetupProgress extends Component {

  _handleEditButton = (goToStep) => {
    let { summary: { common }, updateCampaignSetupDetails } = this.props;

    if (typeof updateCampaignSetupDetails === "function") {
      updateCampaignSetupDetails({ "common": { ...common, currentStep: goToStep } });
    }
  }

  _editButton = (goToStep = 2) => {
    return (
      <span className="cursor-pointer" onClick={() => this._handleEditButton(goToStep)}>
        <img src={require("../../../assets/icons/icon-edit.svg").default} alt="edit_icon" className="img-fluid" />
      </span>
    );
  }

  _renderStep1 = () => {
    const { summary, totalStep } = this.props;

    const campaignData = _get(summary, "[1]", {});

    return (
      <>
        <div className="campaign-left-header">
          <p className="text-primary">
            <FormattedMessage id="campaign_setup.summary.step1" defaultMessage="Campaign Details Step 1 of {totalStep}" values={{ totalStep: totalStep }} /></p>
        </div>
        <div className="campaign-left-body">
          <ul className="list-unstyled">
            <li>
              <p className="text-capitalize">
                <FormattedMessage id="campaign_setup.campaign_detail.agency_label" defaultMessage="agency name" />
              </p>
              <p>{_get(campaignData, "agencyId.agency_name", <EmptyLabel />)}</p>
            </li>
            <li>
              <p className="text-capitalize">
                <FormattedMessage id="campaign_setup.campaign_detail.client_label" defaultMessage="client name" />
              </p>
              <p>{_get(campaignData, "clientName.client_name", <EmptyLabel />)}</p>
            </li>
            <li>
              <p className="text-capitalize">
                <FormattedMessage id="campaign_setup.campaign_setup_progress.campaign_name" defaultMessage="campaign name" />
              </p>
              <p>{_get(campaignData, "campaignName", <EmptyLabel />)}</p>
            </li>
            <li>
              {/* <p>Dates:</p> */}
              <p className="text-capitalize">
                <FormattedMessage id="campaign_setup.campaign_setup_progress.dates" defaultMessage="dates" />
              </p>
              <p>{_get(campaignData, "dates", <EmptyLabel />)}</p>
            </li>
            <li>
              <p>
                <FormattedMessage id="campaign_setup.campaign_detail.po_number_label" defaultMessage="PO number" />
              </p>
              <p>{_get(campaignData, "poNumber", <EmptyLabel />)}</p>
            </li>
            <li>
              <p className="text-capitalize">
                <FormattedMessage id="campaign_setup.campaign_detail.rate_type" defaultMessage="Rate type" />
              </p>
              <p>{_get(campaignData, "rateType.rate_type", <EmptyLabel />)}</p>
            </li>
            <li>
              <p className="text-capitalize">
                <FormattedMessage id="campaign_setup.campaign_detail.rate" defaultMessage="Rate" />
              </p>
              <p>{_get(campaignData, "currencySymbol.symbol", <EmptyLabel />)} {_get(campaignData, "rateValue", <EmptyLabel />)}</p>
            </li>
            <li>
              <p className="text-capitalize">
                <FormattedMessage id="campaign_setup.campaign_detail.upload_day" defaultMessage="upload day" />
              </p>
              <p>{_get(campaignData, "uploadDay.value", <EmptyLabel />)}</p>
            </li>
          </ul>
        </div>
      </>
    );
  }

  _renderStep2 = () => {
    const { summary, totalStep } = this.props;

    const assetData = _get(summary, "[2]", {});
    const selectedOption = _get(assetData, "selectedOption", "none");

    return (
      <>
        <div className="campaign-left-header">
          <p className="text-primary">
            <FormattedMessage id="campaign_setup.summary.step2" defaultMessage="Assets Step 2 of {totalStep}" values={{ totalStep: totalStep }} />
          </p>
          {this._editButton(2)}
        </div >

        <div className="campaign-left-body">
          {(selectedOption === "none") && (
            <ul className="list-unstyled">
              <li>
                <p className="text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.none" defaultMessage="none" />
                </p>
                <p>-</p>
              </li>
            </ul>
          )}

          {(selectedOption === "useExisting") && (
            <ul className="list-unstyled">
              <li>
                <p className="text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.use_existing" defaultMessage="use existing" />
                </p>
                <p>{_map(_get(assetData, "formData.searchAssets", []), "asset_name").join(", ")}</p>
              </li>
            </ul>
          )}

          {(selectedOption === "physicalEvent") && (
            <ul className="list-unstyled">
              <li>
                <p className="text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.name" defaultMessage="name" />
                </p>
                <p>{_get(assetData, "formData.physicalName", <EmptyLabel />)}</p>
              </li>
              <li>
                <p className="text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.asset_code" defaultMessage="asset code" />
                </p>
                <p>{_get(assetData, "formData.assetCode", <EmptyLabel />)}</p>
              </li>
              <li>
                <p className="text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.topic" defaultMessage="topic" />
                </p>
                <p>{_get(assetData, "formData.physicalTopic.id", <EmptyLabel />)}</p>
              </li>
              <li>
                <p className="text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.sub_topic" defaultMessage="sub topic" />
                </p>
                <p>{_get(assetData, "formData.physicalSubTopic.id", <EmptyLabel />)}</p>
              </li>

            </ul>
          )}

        </div>
      </>
    )
  }

  _renderStep3 = () => {
    const { summary, totalStep } = this.props;

    const accountListData = _get(summary, "[3]", {});
    const selectedOption = _get(accountListData, "selectedOption", "none");

    return (
      <>
        <div className="campaign-left-header">
          <p className="text-primary">
            <FormattedMessage id="campaign_setup.summary.step3" defaultMessage="Account List Step 3 of {totalStep}" values={{ totalStep: totalStep }} />
          </p>
          {this._editButton(3)}
        </div>
        <div className="campaign-left-body">
          {(selectedOption === "none") && (
            <ul className="list-unstyled">
              <li>
                <p className="text-capitalize">
                  <FormattedMessage id="campaign_setup.account_list.none" defaultMessage="none" />
                </p>
                <p>-</p>
              </li>
            </ul>
          )}

          {(selectedOption === "useExisting") && (
            <ul className="list-unstyled">
              <li>
                <p className="text-capitalize">
                  <FormattedMessage id="campaign_setup.account_list.use_existing" defaultMessage="use existing" />
                </p>
                <p>{_map((accountListData.existingAccountIds || []), "name").join(", ")}</p>
              </li>
            </ul>
          )}

          {(selectedOption === "uploadNew") && (
            <ul className="list-unstyled">
              <li>
                <p className="text-capitalize">
                  <FormattedMessage id="campaign_setup.account_list.upload_new" defaultMessage="upload new" />
                </p>
                <p>{_get(accountListData, "uploadedFileName", <EmptyLabel />)}</p>
              </li>
            </ul>
          )}

        </div>
      </>
    )
  }

  _renderStep4 = () => {
    const { summary, totalStep } = this.props;

    const suppressionListData = _get(summary, "[4]", {});
    const selectedOption = _get(suppressionListData, "selectedOption", "none");

    return (
      <>
        <div className="campaign-left-header">
          <p className="text-primary">
            <FormattedMessage id="campaign_setup.summary.step4" defaultMessage="Suppression List Step 4 of {totalStep}" values={{ totalStep: totalStep }} />
          </p>
          {this._editButton(4)}
        </div>
        <div className="campaign-left-body">
          {(selectedOption === "none") && (
            <ul className="list-unstyled">
              <li>
                <p className="text-capitalize">
                  <FormattedMessage id="campaign_setup.suppression_list.none" defaultMessage="none" />
                </p>
                <p>-</p>
              </li>
            </ul>
          )}

          {(selectedOption === "useExisting") && (
            <ul className="list-unstyled">
              <li>
                <p className="text-capitalize">
                  <FormattedMessage id="campaign_setup.suppression_list.use_existing" defaultMessage="use existing" />
                </p>
                <p>{_map((suppressionListData.existingSuppressionIds || []), "name").join(", ")}</p>
              </li>
            </ul>
          )}

          {(selectedOption === "uploadNew") && (
            <ul className="list-unstyled">
              <li>
                <p className="text-capitalize">
                  <FormattedMessage id="campaign_setup.suppression_list.upload_new" defaultMessage="upload new" />
                </p>
                <p>{_get(suppressionListData, "uploadedFileName", <EmptyLabel />)}</p>
              </li>
            </ul>
          )}

        </div>
      </>
    )
  }

  _renderStep5 = () => {
    const { summary, totalStep } = this.props;

    return (
      <>
        <div className="campaign-left-header">
          <p className="text-primary">
            <FormattedMessage id="campaign_setup.summary.step5" defaultMessage="Media Plan Upload Step 5 of {totalStep}" values={{ totalStep: totalStep }} />
          </p>
          {this._editButton(5)}
        </div>
        <div className="campaign-left-body">
          <ul className="list-unstyled">
            <li>
              <p className="text-capitalize">
                <FormattedMessage id="campaign_setup.media_plan.upload_new" defaultMessage="upload new" />
              </p>
              <p>{_get(summary, "[5].uploadedFileName", <EmptyLabel />)}</p>
            </li>
          </ul>
        </div>
      </>
    )
  }

  _renderStep6 = () => {
    const { summary, totalStep } = this.props;

    const requiredFieldsData = _get(summary, "[6]", {});

    return (
      <>
        <div className="campaign-left-header">
          <p className="text-primary">
            <FormattedMessage id="campaign_setup.summary.step6" defaultMessage="Required Fields Step 6 of {totalStep}" values={{ totalStep: totalStep }} />
          </p>
          {this._editButton(6)}
        </div>
        <div className="campaign-left-body">
          <ul className="list-unstyled">
            <li>
              <p className="text-capitalize">
                <FormattedMessage id="campaign_setup.required_fields.title" defaultMessage="required fields" />
              </p>
              <p>{_map((requiredFieldsData.selectedFieldColumnValues || []), "name").join(", ")}</p>
            </li>
          </ul>
        </div>
      </>
    );
  }

  render() {
    const { lastFilledStep } = this.props;

    return (
      <div className="card mt-lg-0 mt-5 h-100">
        <div className="card-body vh-165">
          <h6 className="heading-05 fw-semibold text-center mb-3 text-capitalize ">
            <FormattedMessage id="campaign_setup.campaign_setup_progress.campaign_setup_progress" defaultMessage=" campaign setup progress" />
          </h6>
          <div className="campaign-left">
            {(lastFilledStep > 1) && this._renderStep1()}
            {(lastFilledStep > 2) && this._renderStep2()}
            {(lastFilledStep > 3) && this._renderStep3()}
            {(lastFilledStep > 4) && this._renderStep4()}
            {(lastFilledStep > 5) && this._renderStep5()}
            {(lastFilledStep > 6) && this._renderStep6()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  summary: _get(state, "campaignSetup.summary", {}),
  totalStep: _get(state, "campaignSetup.summary.common.totalStep", 8),
  currentStep: _get(state, "campaignSetup.summary.common.currentStep", 1),
  lastFilledStep: _get(state, "campaignSetup.summary.common.lastFilledStep", 1),
});

const mapDispatchToProps = (dispatch) => ({
  updateCampaignSetupDetails: (data) => dispatch(updateCampaignSetupDetails(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaignSetupProgress));
