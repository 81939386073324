import { all } from "redux-saga/effects";

import authentication from "./authentication";
import userMangement from "./userMangement";
import campaignSetup from "./campaignSetup";
import application from "./application";
import campaign from "./campaign";
import user from "./user";

export default function* () {
  yield all([
    ...authentication,
    ...userMangement,
    ...campaignSetup,
    ...application,
    ...campaign,
    ...user
  ]);
}
