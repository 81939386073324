import { get as _get } from "lodash";
import api from "../../helpers/api";

export const doGetUserList = (variables) => {
  return api().options("/user/user_list", { data: (variables || {}) }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "User list fetched successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "data.responseMessage", "Something went wrong while fetching user list."),
      data: {},
    };
  });
};

export const editUser = (variables, userId) => {

  return api().put(`/user/${(userId || 0)}`, variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "User details updated successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong white updating user data."),
      data: {}
    };
  });
};

export const deleteUser = (userId) => {

  return api().delete(`/user/${(userId || 0)}`).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "User deleted successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "data.responseMessage", "Something went wrong while deleting user."),
      data: {},
    };
  });
};

export const doGetAgencyList = (variables) => {
  return api().options("/user/agency_list", { data: (variables || {}) }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Agency list fetched successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "data.responseMessage", "Something went wrong while fetching agency list."),
      data: {},
    };
  });
};

export const getUserDetailsById = (userId) => {

  return api().get(`/user/${(userId || 0)}`).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "User detail fetched successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "data.responseMessage", "Something went wrong while fetching user details."),
      data: {},
    };
  });
};

export const editAgency = (variables, agencyId) => {

  return api().put(`/user/agency/${(agencyId || 0)}`, variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Agency details updated successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong white updating agency data."),
      data: {}
    };
  });
};

export const getAgencyDetailsById = (agencyId) => {

  return api().get(`/user/agency/${(agencyId || 0)}`).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Agency detail fetched successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "data.responseMessage", "Something went wrong while fetching agency details."),
      data: {},
    };
  });
};

export const deleteAgency = (agencyId) => {

  return api().delete(`/user/agency/${(agencyId || 0)}`).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Agency deleted successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "data.responseMessage", "Something went wrong while deleting agency."),
      data: {},
    };
  });
};

export const doGetClientList = (variables) => {
  return api().options("/user/client_list", { data: (variables || {}) }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Client list fetched successfully."),
      data: _get(res, "data", {}),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "data.responseMessage", "Something went wrong while fetching client list."),
      data: {},
    };
  });
};

export const getClientDetailsById = (clientId) => {

  return api().get(`/user/client/${(clientId || 0)}`).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Client detail fetched successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "data.responseMessage", "Something went wrong while fetching client details."),
      data: {},
    };
  });
};

export const editClient = (variables, clientId) => {

  return api().put(`/user/client/${(clientId || 0)}`, variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Client details updated successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.message", "Something went wrong white updating client details."),
      data: {}
    };
  });
};

export const deleteClient = (clientId) => {

  return api().delete(`/user/client/${(clientId || 0)}`).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Client deleted successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "data.responseMessage", "Something went wrong while deleting client."),
      data: {},
    };
  });
};

export const doGetRoleList = () => {
  return api().get("/user/role_list").then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Role list fetched successfully."),
      data: _get(res, "data", {}),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "data.responseMessage", "Something went wrong while fetching role list."),
      data: {},
    };
  });
};

export const doCreateUser = (variables) => {
  return api().post("/user", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "User created successfully."),
      data: _get(res, "data", null),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while creating user."),
      data: null,
    };
  });
}

export const doCreateAgency = (variables) => {
  return api().post("/user/agency", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Agency created successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while creating agency."),
      data: {},
    };
  });
}

export const doCreateClient = (variables) => {
  return api().post("/user/client", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Client created successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while creating client."),
      data: {},
    };
  });
}
