import React, { Component } from "react"
import { cloneDeep as _cloneDeep, get as _get, isEmpty as _isEmpty, isEqual as _isEqual, map as _map, includes as _includes, forEach as _forEach } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl"
import { connect } from "react-redux";

import { showAlertMessage } from "../../../resusableComponents/common/formElements";

import CampaignSetupProgress from "./CampaignSetupProgress";

import { updateCampaignSetupDetails, saveRequiredFields, resetCampaignSetupError } from "../../../redux/actions/campaignSetup";
import { updateLoadingState } from "../../../redux/actions/application";

import { fetchCampaignRequiredFields } from "../../../redux/services/campaignSetup";

const defaultCampaignFields = {
  13: 13, //country
  18: 18, //"email",
  20: 20, //"employees",
  1: 1, //"first_name",
  22: 22, //"industry",
  23: 23, //"asset_code",
  26: 26, //"brief_name",
  //25: 25, //"tactic_code"
}

class RequiredFields extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedRequiredFields: [],
      selectedFieldIds: [],
      selectedFieldColumnValues: [],
    }
  }

  componentDidMount() {
    this._fetchCampaignRequiredFields();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.selectedRequiredFields, this.state.selectedRequiredFields) || !_isEqual(prevState.defaultSelectedFields, this.state.defaultSelectedFields)) {
      this._setDefaultRequiredFields();
    }

    if (!_isEqual(prevProps.setupErrors, this.props.setupErrors) && !_isEmpty(this.props.setupErrors)) {
      showAlertMessage(_get(this.props, "setupErrors.message", ""));

      if (typeof this.props.resetCampaignSetupError === "function") { this.props.resetCampaignSetupError(); }
    }
  }

  _fetchCampaignRequiredFields = async () => {
    const { common: { campaignId }, updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const reqRes = await fetchCampaignRequiredFields(campaignId);

      if ((reqRes.flag || false) === true) {

        this.setState({ selectedRequiredFields: _get(reqRes, "data.required_fields", []), defaultSelectedFields: _get(reqRes, "data.campaign_required_fields", []) });
      } else {
        showAlertMessage(_get(reqRes, "message", "Something went wrong while fetching default selected fields."));
      }
    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while fetching required field details."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _setDefaultRequiredFields = () => {
    const { selectedRequiredFields, selectedFieldIds, selectedFieldColumnValues, defaultSelectedFields } = this.state;

    let tmpSelectedFieldIds = _cloneDeep(selectedFieldIds);
    let tmpSelectedFieldColumnValues = _cloneDeep(selectedFieldColumnValues);

    _forEach((selectedRequiredFields || []), (mf) => {
      const id = _get(mf, "id", "");

      if (_get(defaultCampaignFields, `${id}`, "") !== "") {

        tmpSelectedFieldIds = { ...tmpSelectedFieldIds, [id]: id };
        tmpSelectedFieldColumnValues = { ...tmpSelectedFieldColumnValues, [id]: { "id": id, "name": _get(mf, "friendly_name", ""), "value": "" } };
      }
    });

    _forEach((defaultSelectedFields || []), (mf) => {
      const id = _get(mf, "id", 0);
      const colmName = _get(mf, "friendly_name", "");
      let requiredValues = _get(mf, "required_values", "");

      /*let finalRequiredValues = [];
      if (["state", "country"].includes(colmName)) {
        let tmpRequiredValues = requiredValues.split("||");
        tmpRequiredValues.forEach(element => {
          finalRequiredValues.push(element);
        });
        requiredValues = finalRequiredValues;
      }

      if (!["state", "country", "gdpr_consent_date"].includes(colmName)) {
        requiredValues = replaceAllWithNewLine(requiredValues, "||");
      }*/

      tmpSelectedFieldIds = { ...tmpSelectedFieldIds, [id]: id };
      tmpSelectedFieldColumnValues = { ...tmpSelectedFieldColumnValues, [id]: { "id": id, "name": colmName, "value": (requiredValues || "") } };
    });

    this.setState({ selectedFieldIds: tmpSelectedFieldIds, selectedFieldColumnValues: tmpSelectedFieldColumnValues });
  }

  handleNext = () => {
    const { common, common: { currentStep, campaignId, lastFilledStep }, saveRequiredFields, updateCampaignSetupDetails } = this.props;
    const { selectedFieldIds, selectedFieldColumnValues } = this.state;

    const colmnValue = _map(selectedFieldColumnValues, (v, k) => ({ "name": (v.name || ""), "value": (v.value || ""), "id": (v.id || "") }));

    const selectedColumnCount = Object.keys(colmnValue || {}).length;

    const variable = {
      "common": {
        ...common,
        currentStep: (currentStep + 1),
        lastFilledStep: (lastFilledStep > currentStep) ? lastFilledStep : (currentStep + 1),
      },
      [currentStep]: {
        summaryText: (selectedColumnCount > 0) ? `${selectedColumnCount} fields are selected.` : "no fields selected",
        selectedFieldIds,
        selectedFieldColumnValues,
      }
    };

    if (selectedColumnCount > 0 && typeof saveRequiredFields === "function") {

      const payload = Object.assign({}, variable, {
        "payload": {
          campaignId,
          data: {
            fields: colmnValue,
          },
        }
      });

      saveRequiredFields(payload);
    }

    if (selectedColumnCount <= 0 && typeof updateCampaignSetupDetails === "function") {
      updateCampaignSetupDetails(variable);
    }
  };

  _renderRadioButton = ({ id, name, value, onChange, checked }) => {
    return (
      <input className="form-check-input form-check-input-radio"
        type="checkbox"
        name={(name || "")}
        id={(id || "")}
        value={(value || "")}
        onChange={onChange}
        checked={(checked || false)}
      />
    );
  }

  handleChange = (keyName, keyValue) => {
    this.setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [keyName]: keyValue,
      }
    }));
  }

  _renderLeftPanel = () => {
    const { currentStep, totalSteps, onPreviousStep } = this.props;
    const { selectedRequiredFields, selectedFieldIds, selectedFieldColumnValues } = this.state;

    return (

      <div className="col-lg-8">
        <div className="card">
          <div className="card-body py-5">
            <h6 className="heading-04 fw-semibold mb-6">{`Step ${currentStep} of ${totalSteps} - Required Fields`}</h6>
            <div className="row">

              {_map(selectedRequiredFields, (field, i) => {

                return (
                  <div key={i} className="col-lg-6">
                    <div className="mb-5">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          className="form-check-input form-check-input-radio"
                          name="required_fields"
                          id={(field.id || "")}
                          value={(field.id || "")}
                          checked={_includes((selectedFieldIds || []), (field.id || "")) ? true : false}
                          onChange={() => {

                            let tmpSelectedFieldIds = _cloneDeep(selectedFieldIds);
                            let tmpSelectedFieldColumnValues = _cloneDeep(selectedFieldColumnValues);
                            const key = (field.id || null);

                            if (key === null) { return false; }
                            const isExist = (typeof tmpSelectedFieldIds[key] !== "undefined") ? true : false;

                            if (isExist === true) {
                              delete tmpSelectedFieldIds[key];
                              delete tmpSelectedFieldColumnValues[key];

                            } else {
                              tmpSelectedFieldIds = { ...tmpSelectedFieldIds, [key]: key };
                              tmpSelectedFieldColumnValues = { ...tmpSelectedFieldColumnValues, [key]: { "id": key, "name": (field.friendly_name || null), "value": "" } };

                            }

                            this.setState({ selectedFieldIds: tmpSelectedFieldIds, selectedFieldColumnValues: tmpSelectedFieldColumnValues });
                          }}
                        />
                        <label className="form-check-label text-capitalize" htmlFor={(field.id || "")}>
                          {(field.friendly_name || "")}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="col-lg-12">
                <div className="d-flex align-items-center justify-content-between gap-4 mt-4">
                  <button className="btn btn-primary text-capitalize" onClick={() => { if (typeof onPreviousStep === "function") { onPreviousStep() } }}>
                    <FormattedMessage id="btn.back" defaultMessage="back" />
                  </button>
                  <button className="btn btn-primary text-capitalize" onClick={() => this.handleNext()}>
                    <FormattedMessage id="btn.next" defaultMessage="next" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderRightPanel = () => {
    const { currentStep, totalSteps, onEdit } = this.props;
    return (
      <div className="col-lg-4">
        <CampaignSetupProgress currentStep={currentStep} totalSteps={totalSteps} onEdit={onEdit} />
      </div>
    );
  }

  render() {

    return (

      <div className="row">
        {this._renderLeftPanel()}
        {this._renderRightPanel()}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  common: _get(state, "campaignSetup.summary.common", {}),
  setupErrors: _get(state, "campaignSetup.setupErrors", {}),
  requiredFieldsData: _get(state, "campaignSetup.requiredFields", {}),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  updateCampaignSetupDetails: (data) => dispatch(updateCampaignSetupDetails(data)),
  saveRequiredFields: (data) => dispatch(saveRequiredFields(data)),
  resetCampaignSetupError: () => dispatch(resetCampaignSetupError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RequiredFields));
