import { takeLatest, call, fork, put } from "redux-saga/effects";
import { get as _get } from "lodash";

import {
  saveCampaignDetails, assignExistingAccountIds, removeUploadedFile, saveMappingData, assignAssetCampaign, initiateAssetCampaign, removeAssignedAssets,
  removeAssignedAccountList, assignExistingSuppressionIds, removeAssignedSuppressionList, saveRequiredFields, fetchCampaignBriefs, initiateCampaign
} from "../services/campaignSetup";

import {
  SAVE_CAMPAIGN_DETAILS_REQUEST, SAVE_CAMPAIGN_DETAILS_SUCCESS, SAVE_CAMPAIGN_DETAILS_ERROR,
  ASSIGN_ASSET_CAMPAIGN_REQUEST, ASSIGN_ASSET_CAMPAIGN_SUCCESS, ASSIGN_ASSET_CAMPAIGN_ERROR,
  INITIATE_ASSET_CAMPAIGN_REQUEST, INITIATE_ASSET_CAMPAIGN_SUCCESS, INITIATE_ASSET_CAMPAIGN_ERROR,
  ASSIGN_EXISTING_ACCOUNTS_REQUEST, ASSIGN_EXISTING_ACCOUNTS_SUCCESS, ASSIGN_EXISTING_ACCOUNTS_ERROR,
  REMOVE_ASSIGNED_ASSETS_REQUEST, REMOVE_ASSIGNED_ASSETS_SUCCESS, REMOVE_ASSIGNED_ASSETS_ERROR,
  REMOVE_UPLOADED_FILE_REQUEST, REMOVE_UPLOADED_FILE_SUCCESS, REMOVE_UPLOADED_FILE_ERROR,
  SAVE_UPLOAD_MAPPING_DATA_REQUEST, SAVE_UPLOAD_MAPPING_DATA_SUCCESS, SAVE_UPLOAD_MAPPING_DATA_ERROR,
  REMOVE_ASSIGNED_ACCOUNT_LIST_REQUEST, REMOVE_ASSIGNED_ACCOUNT_LIST_SUCCESS, REMOVE_ASSIGNED_ACCOUNT_LIST_ERROR,
  ASSIGN_EXISTING_SUPPRESSION_REQUEST, ASSIGN_EXISTING_SUPPRESSION_SUCCESS, ASSIGN_EXISTING_SUPPRESSION_ERROR,
  REMOVE_ASSIGNED_SUPPRESSION_LIST_REQUEST, REMOVE_ASSIGNED_SUPPRESSION_LIST_SUCCESS, REMOVE_ASSIGNED_SUPPRESSION_LIST_ERROR,
  SAVE_REQUIRED_FIELDS_REQUEST, SAVE_REQUIRED_FIELDS_SUCCESS, SAVE_REQUIRED_FIELDS_ERROR,
  FETCH_CAMPAIGN_BRIEFS_REQUEST, FETCH_CAMPAIGN_BRIEFS_SUCCESS, FETCH_CAMPAIGN_BRIEFS_ERROR,
  INITIATE_CAMPAIGN_REQUEST, INITIATE_CAMPAIGN_SUCCESS, INITIATE_CAMPAIGN_ERROR,
} from "../actions/campaignSetup";

import { UPDATE_APP_LOADING } from "../actions/application";

function* workerSaveCampaignDetails(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const variable = _get(action, "payload.payload", {});

    const response = yield call(saveCampaignDetails, variable);
    const campaign_id = _get(response, "data.campaign_id", null);
    const tmpPayload = (action.payload || {});
    delete tmpPayload["payload"];

    const responsePayload = Object.assign({}, tmpPayload, { "common": { ...tmpPayload.common, campaignId: campaign_id } });

    if (_get(response, "flag", false) === true) {
      yield put({
        type: SAVE_CAMPAIGN_DETAILS_SUCCESS,
        payload: (responsePayload || {})
      });
    } else {
      yield put({
        type: SAVE_CAMPAIGN_DETAILS_ERROR,
        error: {
          errors: (response.error || {}),
          message: _get(response, "message", "Something went wrong while saving campaign details.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: SAVE_CAMPAIGN_DETAILS_ERROR,
      error: {
        errors: (error || {}),
        message: _get(error, "message", "Something went wrong while saving campaign details.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerAssignExistingAccounts(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const variable = _get(action, "payload.payload", {});

    const response = yield call(assignExistingAccountIds, variable);

    const tmpPayload = (action.payload || {});
    delete tmpPayload["payload"];

    if (_get(response, "flag", false) === true) {
      yield put({
        type: ASSIGN_EXISTING_ACCOUNTS_SUCCESS,
        payload: Object.assign({}, tmpPayload)
      });
    } else {
      yield put({
        type: ASSIGN_EXISTING_ACCOUNTS_ERROR,
        error: {
          errors: (response.error || {}),
          message: _get(response, "message", "Something went wrong while assigning account list.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: ASSIGN_EXISTING_ACCOUNTS_ERROR,
      error: {
        errors: (error || {}),
        message: _get(error, "message", "Something went wrong while assigning account list.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerSaveUploadMappingData({ payload }) {

  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(saveMappingData, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: SAVE_UPLOAD_MAPPING_DATA_SUCCESS,
        payload: {
          data: _get(response, "data", {}),
          message: (response.message || ""),
          payload: payload
        }
      });
    } else {
      yield put({
        type: SAVE_UPLOAD_MAPPING_DATA_ERROR,
        error: {
          message: _get(response, "message", "Something went wrong while storing uploaded data."),
          errors: _get(response, "error", {})
        }
      });
    }

  } catch (error) {

    yield put({
      type: SAVE_UPLOAD_MAPPING_DATA_ERROR,
      payload: {
        message: _get(error, "message", "Something went wrong while storing uploaded data.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerRemoveUploadedFile({ payload = {} }) {

  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(removeUploadedFile, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: REMOVE_UPLOADED_FILE_SUCCESS,
        payload: {
          data: _get(response, "data", {}),
          message: (response.message || ""),
          uploadType: (payload.uploadType || "")
        }
      });
    } else {
      yield put({
        type: REMOVE_UPLOADED_FILE_ERROR,
        error: {
          message: _get(response, "message", "Something went wrong while removing uploaded file."),
          errors: (response.error || {})
        }
      });
    }

  } catch (error) {
    yield put({
      type: REMOVE_UPLOADED_FILE_ERROR,
      error: {
        message: _get(error, "message", "Something went wrong while removing uploaded file.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerAssignAssetCampaign(action) {
  try {
    const variable = _get(action, "payload.payload", {});

    const response = yield call(assignAssetCampaign, variable);
    const tmpPayload = (action.payload || {});
    delete tmpPayload["payload"];

    const responsePayload = Object.assign({}, tmpPayload, { "common": { ...tmpPayload.common } });

    if (_get(response, "flag", false) === true) {
      yield put({
        type: ASSIGN_ASSET_CAMPAIGN_SUCCESS,
        payload: (responsePayload || {})
      });
    } else {
      yield put({
        type: ASSIGN_ASSET_CAMPAIGN_ERROR,
        error: {
          errors: (response.error || {}),
          message: _get(response, "message", "Something went wrong while assigning asset to campaign.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: ASSIGN_ASSET_CAMPAIGN_ERROR,
      error: {
        errors: (error || {}),
        message: _get(error, "message", "Something went wrong while assigning asset to campaign.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerInitiateAssetCampaign(action) {
  try {
    const variable = _get(action, "payload.payload", {});

    const response = yield call(initiateAssetCampaign, variable);
    const tmpPayload = (action.payload || {});
    delete tmpPayload["payload"];

    const responsePayload = Object.assign({}, tmpPayload, { "common": { ...tmpPayload.common } });

    if (_get(response, "flag", false) === true) {
      yield put({
        type: INITIATE_ASSET_CAMPAIGN_SUCCESS,
        payload: (responsePayload || {})
      });
    } else {
      yield put({
        type: INITIATE_ASSET_CAMPAIGN_ERROR,
        error: {
          errors: (response.error || {}),
          message: _get(response, "message", "Something went wrong while assigning physical asset to campaign.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: INITIATE_ASSET_CAMPAIGN_ERROR,
      error: {
        errors: (error || {}),
        message: _get(error, "message", "Something went wrong while assigning physical asset to campaign.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerRemoveAssignedAssets(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const variable = _get(action, "payload.payload", {});

    const response = yield call(removeAssignedAssets, variable);

    const tmpPayload = (action.payload || {})
    delete tmpPayload["payload"];

    if ((response.flag || false) === true) {

      yield put({
        type: REMOVE_ASSIGNED_ASSETS_SUCCESS,
        payload: Object.assign({}, tmpPayload)
      });
    } else {
      yield put({
        type: REMOVE_ASSIGNED_ASSETS_ERROR,
        error: {
          message: _get(response, "message", "Something went wrong while removing assigned assets."),
          errors: (response.error || {})
        }
      });
    }

  } catch (error) {
    yield put({
      type: REMOVE_ASSIGNED_ASSETS_ERROR,
      error: {
        message: _get(error, "message", "Something went wrong while removing assigned assets.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerRemoveAssignedAccountList(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const variable = _get(action, "payload.payload", {});

    const response = yield call(removeAssignedAccountList, variable);

    const tmpPayload = (action.payload || {})
    delete tmpPayload["payload"];

    if ((response.flag || false) === true) {

      yield put({
        type: REMOVE_ASSIGNED_ACCOUNT_LIST_SUCCESS,
        payload: Object.assign({}, tmpPayload)
      });
    } else {
      yield put({
        type: REMOVE_ASSIGNED_ACCOUNT_LIST_ERROR,
        error: {
          message: _get(response, "message", "Something went wrong while removing assigned account list."),
          errors: (response.error || {})
        }
      });
    }

  } catch (error) {
    yield put({
      type: REMOVE_ASSIGNED_ACCOUNT_LIST_ERROR,
      error: {
        message: _get(error, "message", "Something went wrong while removing assigned account list.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerAssignExistingSuppression(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const variable = _get(action, "payload.payload", {});

    const response = yield call(assignExistingSuppressionIds, variable);

    const tmpPayload = (action.payload || {});
    delete tmpPayload["payload"];

    if (_get(response, "flag", false) === true) {
      yield put({
        type: ASSIGN_EXISTING_SUPPRESSION_SUCCESS,
        payload: Object.assign({}, tmpPayload)
      });
    } else {
      yield put({
        type: ASSIGN_EXISTING_SUPPRESSION_ERROR,
        error: {
          errors: (response.error || {}),
          message: _get(response, "message", "Something went wrong while assigning suppression list.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: ASSIGN_EXISTING_SUPPRESSION_ERROR,
      error: {
        errors: (error || {}),
        message: _get(error, "message", "Something went wrong while assigning suppression list.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerRemoveAssignedSuppressionList(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const variable = _get(action, "payload.payload", {});

    const response = yield call(removeAssignedSuppressionList, variable);

    const tmpPayload = (action.payload || {})
    delete tmpPayload["payload"];

    if ((response.flag || false) === true) {

      yield put({
        type: REMOVE_ASSIGNED_SUPPRESSION_LIST_SUCCESS,
        payload: Object.assign({}, tmpPayload)
      });
    } else {
      yield put({
        type: REMOVE_ASSIGNED_SUPPRESSION_LIST_ERROR,
        error: {
          message: _get(response, "message", "Something went wrong while removing assigned suppression list."),
          errors: (response.error || {})
        }
      });
    }

  } catch (error) {
    yield put({
      type: REMOVE_ASSIGNED_SUPPRESSION_LIST_ERROR,
      error: {
        message: _get(error, "message", "Something went wrong while removing assigned suppression list.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerSaveRequiredFields(action) {

  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const variable = _get(action, "payload.payload", {});

    const response = yield call(saveRequiredFields, variable);
    const tmpPayload = (action.payload || {});
    delete tmpPayload["payload"];

    if (_get(response, "flag", false) === true) {
      yield put({
        type: SAVE_REQUIRED_FIELDS_SUCCESS,
        payload: Object.assign({}, tmpPayload)
      });
    } else {
      yield put({
        type: SAVE_REQUIRED_FIELDS_ERROR,
        error: {
          errors: (response.error || {}),
          message: _get(response, "message", "Something went wrong while saving required fields.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: SAVE_REQUIRED_FIELDS_ERROR,
      error: {
        errors: (error || {}),
        message: _get(error, "message", "Something went wrong while saving required fields.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerFetchCampaignBriefs(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(fetchCampaignBriefs, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_CAMPAIGN_BRIEFS_SUCCESS,
        data: (response.data || []),
        message: (response.message || ""),
      });
    } else {
      yield put({
        type: FETCH_CAMPAIGN_BRIEFS_ERROR,
        error: {
          data: {},
          message: _get(response, "message", "Something went wrong while fetching campaign briefs."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_CAMPAIGN_BRIEFS_ERROR,
      error: {
        data: {},
        message: _get(error, "response.message", "Something went wrong while fetching campaign briefs."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerInitiateCampaign(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const variable = _get(action, "payload.payload", {});

    const response = yield call(initiateCampaign, variable);

    const tmpPayload = (action.payload || {});
    delete tmpPayload["payload"];

    if (_get(response, "flag", false) === true) {
      yield put({
        type: INITIATE_CAMPAIGN_SUCCESS,
        payload: Object.assign({}, tmpPayload)
      });
    } else {
      yield put({
        type: INITIATE_CAMPAIGN_ERROR,
        error: {
          errors: (response.error || {}),
          message: _get(response, "message", "Something went wrong while initiating campaign.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: INITIATE_CAMPAIGN_ERROR,
      error: {
        errors: (error || {}),
        message: _get(error, "message", "Something went wrong while initiating campaign.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* watcherSaveCampaignDetails() {
  yield takeLatest(SAVE_CAMPAIGN_DETAILS_REQUEST, workerSaveCampaignDetails);
}

function* watcherAssignAssetCampaign() {
  yield takeLatest(ASSIGN_ASSET_CAMPAIGN_REQUEST, workerAssignAssetCampaign);
}

function* watcherInitiateAssetCampaign() {
  yield takeLatest(INITIATE_ASSET_CAMPAIGN_REQUEST, workerInitiateAssetCampaign);
}

function* watcherRemoveAssignedAssets() {
  yield takeLatest(REMOVE_ASSIGNED_ASSETS_REQUEST, workerRemoveAssignedAssets);
}

function* watcherAssignExistingAccounts() {
  yield takeLatest(ASSIGN_EXISTING_ACCOUNTS_REQUEST, workerAssignExistingAccounts);
}

function* watcherSaveUploadMappingData() {
  yield takeLatest(SAVE_UPLOAD_MAPPING_DATA_REQUEST, workerSaveUploadMappingData);
}

function* watcherRemoveUploadedFile() {
  yield takeLatest(REMOVE_UPLOADED_FILE_REQUEST, workerRemoveUploadedFile);
}

function* watcherRemoveAssignedAccountList() {
  yield takeLatest(REMOVE_ASSIGNED_ACCOUNT_LIST_REQUEST, workerRemoveAssignedAccountList);
}

function* watcherAssignExistingSuppression() {
  yield takeLatest(ASSIGN_EXISTING_SUPPRESSION_REQUEST, workerAssignExistingSuppression);
}

function* watcherRemoveAssignedSuppressionList() {
  yield takeLatest(REMOVE_ASSIGNED_SUPPRESSION_LIST_REQUEST, workerRemoveAssignedSuppressionList);
}

function* watcherSaveRequiredFields() {
  yield takeLatest(SAVE_REQUIRED_FIELDS_REQUEST, workerSaveRequiredFields);
}

function* watcherFetchCampaignBriefs() {
  yield takeLatest(FETCH_CAMPAIGN_BRIEFS_REQUEST, workerFetchCampaignBriefs);
}

function* watcherInitiateCampaign() {
  yield takeLatest(INITIATE_CAMPAIGN_REQUEST, workerInitiateCampaign);
}

const campaignSetupSaga = [
  fork(watcherSaveCampaignDetails),
  fork(watcherAssignAssetCampaign),
  fork(watcherInitiateAssetCampaign),
  fork(watcherRemoveAssignedAssets),
  fork(watcherAssignExistingAccounts),
  fork(watcherRemoveUploadedFile),
  fork(watcherSaveUploadMappingData),
  fork(watcherRemoveAssignedAccountList),
  fork(watcherAssignExistingSuppression),
  fork(watcherRemoveAssignedSuppressionList),
  fork(watcherSaveRequiredFields),
  fork(watcherFetchCampaignBriefs),
  fork(watcherInitiateCampaign),
]

export default campaignSetupSaga;
