import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { showAlertMessage, Select, ErrorMessage, CustomModal } from "../../../../../resusableComponents/common/formElements";
import { hasAccess } from "../../../../../resusableComponents/hoc/AccessManagement";
import { USER_ROLES } from "../../../../../helpers/constants";
import { isEmailValid } from "../../../../../helpers/utils"

import { doGetAgencyList, doGetClientList, editUser, getUserDetailsById } from "../../../../../redux/services/userMangement";
import { updateLoadingState } from "../../../../../redux/actions/application";

class EditUser extends Component {

  constructor(props) {
    super(props);

    this.defaultState = {
      confirmPassword: "",
      agencyList: null,
      clientList: null,
      firstName: "",
      lastName: "",
      userType: null,
      email: "",
    }

    this.state = {
      formData: { ...this.defaultState },
      agencyListDropDownData: [],
      formErrors: {},
      clientListDropDownData: [],
      isFormSubmitted: false
    };
  }

  componentDidMount() {
    const { showEditUserModal } = this.props;

    if (showEditUserModal) {
      this._fetchUserDetails();
      this._fetchAgencyList();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevProps.showEditUserModal, this.props.showEditUserModal) && (_get(this.props, "showEditUserModal", false) === true)) {
      this._fetchUserDetails();
      this._fetchAgencyList();
    }

    if (
      !_isEqual(_get(prevState, "formData.userType", null), _get(this.state, "formData.userType", null)) &&
      ([_get(USER_ROLES, "AGENCY.id", 0), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", 0)].includes(_get(this.state, "formData.userType.id", null)))
    ) {
      this._fetchAgencyList();
    }

    if (!_isEqual(_get(prevState, "formData.agencyList", null), _get(this.state, "formData.agencyList", null))) {
      this._fetchClientList();
    }

    if (!_isEqual(prevState.formData, this.state.formData) && (_get(this.state, "isFormSubmitted", false) === true)) {
      this._handleValidation();
    }
  }

  _fetchAgencyList = async () => {
    const { updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetAgencyList({ dropdown_flag: 1 });

      if ((response.flag || false) === true) {
        this.setState({ agencyListDropDownData: _get(response, "data.responseData.agency_list", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching agency list.")));
        this.setState({ agencyListDropDownData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching agency list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _fetchClientList = async () => {
    const { updateLoadingState } = this.props;
    const { formData: { agencyList } } = this.state;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetClientList({ agency_id: _get(agencyList, "id", null), dropdown_flag: 1 });

      if ((response.flag || false) === true) {
        this.setState({ clientListDropDownData: _get(response, "data.responseData.client_list", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching client list.")));
        this.setState({ clientListDropDownData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching client list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _fetchUserDetails = async () => {
    const { selectedUserObj, updateLoadingState, intl } = this.props;

    if (_isEmpty(selectedUserObj) || (_get(selectedUserObj, "user_id", 0) === 0)) {
      showAlertMessage(intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "user", defaultMessage: "user" })
      }));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getUserDetailsById(_get(selectedUserObj, "user_id", 0));

      const resData = _get(response, "data.responseData", {});

      if ((response.flag || false) === true) {

        this.setState({
          formData: {
            clientList: _get(resData, "clients", null),
            agencyList: { id: _get(resData, "agency_id", ""), agency_name: _get(resData, "agency_name", "") },
            userType: { id: _get(resData, "role_id", ""), name: _get(resData, "role_name", "") },
            firstName: _get(resData, "first_name", ""),
            lastName: _get(resData, "last_name", ""),
            email: _get(resData, "email", ""),
          }
        });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching user details.")));
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching user details.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { formData: { firstName, email, lastName, agencyList, clientList, userType } } = this.state;
    let formErrors = {};
    const selectedUserType = _get(userType, "id", null);

    if (([_get(USER_ROLES, "AGENCY.id", 0), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", 0)].includes(selectedUserType)) && _isEmpty(agencyList)) {
      formErrors["agencyList"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.agency_placeholder", defaultMessage: "agency list" })
      });
    }

    if (([_get(USER_ROLES, "AGENCY.id", 0), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", 0)].includes(selectedUserType)) && _isEmpty(clientList)) {
      formErrors["clientList"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.client_placeholder", defaultMessage: "client list" })
      });
    }

    if (_isEmpty(userType)) {
      formErrors["userType"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.user.user_type", defaultMessage: "userType" })
      });
    }

    if (_isEmpty(firstName)) {
      formErrors["firstName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.firstname", defaultMessage: "first name" })
      });
    }

    if (_isEmpty(lastName)) {
      formErrors["lastName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.lastname", defaultMessage: "last name" })
      });
    }

    if (!isEmailValid(email)) {
      formErrors["email"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}." }, {
        field: intl.formatMessage({ id: "email", defaultMessage: "email" }),
      });
    }

    this.setState({ formErrors });

    if (returnFlag) {
      return (!_isEmpty(formErrors)) ? false : true;
    }
  }

  handleChange = (keyName, keyValue) => {
    this.setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [keyName]: keyValue,
      }
    }));
  }

  _handleUserSubmit = async () => {
    const { selectedUserObj, updateLoadingState, setState, intl } = this.props;
    const { formErrors, formData: { firstName, email, lastName, agencyList, clientList, userType } } = this.state;

    if (_isEmpty(selectedUserObj) || (_get(selectedUserObj, "user_id", 0) === 0)) {
      showAlertMessage(intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "user", defaultMessage: "user" })
      }));
      return false;
    }

    if (!_isEmpty(formErrors)) { return false; }

    this.setState({ isFormSubmitted: true });

    const isValidForm = this._handleValidation(true);

    if (!isValidForm) { return false; }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const clientIds = (!_isEmpty(clientList)) ? (clientList || []).map(obj => obj.id) : null;

      let payload = {
        first_name: (firstName || ""),
        last_name: (lastName || ""),
        email: (email || ""),
        role_id: _get(userType, "id", null),
        agency_id: _get(agencyList, "id", null),
        client_ids: clientIds
      };

      const response = await editUser(payload, _get(selectedUserObj, "user_id", 0));

      if (_get(response, "data.responseStatus", null) === true) {

        showAlertMessage(_get(response, "data.responseMessage", "User details updated successfully."), "success");

        this.setState({ formErrors: {}, isFormSubmitted: false })

        if (typeof setState === "function") {
          setState({ selectedUser: {}, showEditUserModal: false, reloadUserList: true });
        }
      } else {
        showAlertMessage(_get(response, "data.responseMessage", "Something went wrong while updating user details."));
      }

    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while updating user details.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _renderTextFiled = ({ label, placeholder, onChange, name, defaultValue, isRequired = false, ...restProps }) => {
    const { formErrors } = this.state;

    return (
      <div className="mb-6">
        <label className="form-label text-capitalize">
          {label}
          {(isRequired) && (<span className="text-danger">*</span>)}
        </label>
        <input
          name={name}
          type="text"
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
          value={defaultValue}
          {...restProps}
        />
        {_get(formErrors, `${name}`, "") && <ErrorMessage message={_get(formErrors, `${name}`, "")} />}
      </div >
    );
  }

  _cloneModal = () => {
    const { setState } = this.props;

    if (typeof setState === "function") { setState({ showEditUserModal: false, selectedClient: {} }); }
  }

  _renderView = () => {
    const { userRolesDropDownData, showEditUserModal, intl } = this.props;
    const { formErrors, formData: { clientList, agencyList, firstName, userType, lastName, email }, agencyListDropDownData, clientListDropDownData } = this.state;
    const selectedUserType = _get(userType, "id", null);

    return (
      <CustomModal
        isOpen={showEditUserModal}
        modalTitle={intl.formatMessage({ id: "user_management.user.edit.modal.title", defaultMessage: "edit user" })}
        onHide={() => this._cloneModal()}
        onClose={() => this._cloneModal()}
      >
        <div className="row">

          <div className="col-lg-6">
            <div className="mb-6">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "user_management.firstname_label", defaultMessage: "First Name*" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "user_management.firstname", defaultMessage: "first name" }),
                }),
                onChange: (event) => this.handleChange("firstName", _get(event, "target.value", "")),
                name: "firstName",
                isRequired: true,
                defaultValue: (firstName || "")
              })}
              {_get(formErrors, "firstName", "") && <ErrorMessage message={_get(formErrors, "firstName", "")} />}
            </div>
          </div>

          <div className="col-lg-6">
            <div className="mb-6">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "user_management.lastname_label", defaultMessage: "Last Name*" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "user_management.lastname", defaultMessage: "last name" }),
                }),
                onChange: (event) => this.handleChange("lastName", _get(event, "target.value", "")),
                name: "lastName",
                isRequired: true,
                defaultValue: (lastName || "")
              })}
              {_get(formErrors, "lastName", "") && <ErrorMessage message={_get(formErrors, "lastName", "")} />}
            </div>
          </div>

          <div className="col-lg-6">
            <div className="mb-6">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "user_management.email_label", defaultMessage: "Email*" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "email", defaultMessage: "email" }),
                }),
                onChange: (event) => this.handleChange("email", _get(event, "target.value", "")),
                name: "email",
                isRequired: true,
                defaultValue: (email || ""),
                disabled: true
              })}
              {_get(formErrors, "email", "") && <ErrorMessage message={_get(formErrors, "email", "")} />}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mb-6">
              <label className="form-label text-capitalize">
                <FormattedMessage id="user_management.usertype_label" defaultMessage="User Type" />
                <span className="text-danger">*</span>
              </label>
              <Select
                className="form-custom-select"
                placeholder={intl.formatMessage({ id: "user_management.usertype_label", defaultMessage: "User Type" })}
                options={(userRolesDropDownData || [])}
                value={(userType || null)}
                getOptionLabel={(option) => (option.name || "User Type")}
                getOptionValue={(option) => (option.id || null)}
                onChange={(selected) => this.handleChange("userType", selected)}
                isMulti={false}
              />
              {_get(formErrors, "userType", "") && <ErrorMessage message={_get(formErrors, "userType", "")} />}
            </div>
          </div>

          {([_get(USER_ROLES, "AGENCY.id", 0), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", 0)].includes(selectedUserType)) && (
            <div className="col-lg-6">
              <div className="mb-6">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="user_management.agency_placeholder" defaultMessage="Agency list" />
                  <span className="text-danger">*</span>
                </label>
                <Select
                  className="form-custom-select"
                  placeholder={intl.formatMessage({ id: "user_management.agency_placeholder", defaultMessage: "Agency list" })}
                  options={(agencyListDropDownData || [])}
                  value={(agencyList || null)}
                  getOptionLabel={(option) => (option.agency_name || "Agency list")}
                  getOptionValue={(option) => (option.id || null)}
                  onChange={(selected) => this.handleChange("agencyList", selected)}
                  isMulti={false}
                />
                {_get(formErrors, "agencyList", "") && <ErrorMessage message={_get(formErrors, "agencyList", "")} />}
              </div>
            </div>
          )}

          {([_get(USER_ROLES, "AGENCY.id", 0), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", 0)].includes(selectedUserType)) && (
            <div className="col-lg-6">
              <div className="mb-6">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="user_management.client_placeholder" defaultMessage="Client list" />
                </label>
                <Select
                  className="form-custom-select"
                  placeholder={intl.formatMessage({ id: "user_management.client_placeholder", defaultMessage: "Client list" })}
                  options={(clientListDropDownData || [])}
                  value={(clientList || null)}
                  getOptionLabel={(option) => (option.client_name || "Client list")}
                  getOptionValue={(option) => (option.id || null)}
                  onChange={(selected) => this.handleChange("clientList", selected)}
                  isMulti={true}
                />
                {_get(formErrors, "clientList", "") && <ErrorMessage message={_get(formErrors, "clientList", "")} />}
              </div>
            </div>
          )}
        </div>
        <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
          <div className="col-md-12">
            <button type="button" className="btn btn-secondary text-capitalize" onClick={() => this._cloneModal()}>
              <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
            </button>
            <button className="btn btn-primary text-capitalize" onClick={() => this._handleUserSubmit()}>
              <FormattedMessage id="btn.submit" defaultMessage="Submit" />
            </button>
          </div>
        </div>
      </CustomModal>
    );
  }

  render() {

    return (
      <>
        {hasAccess("admin", "user-management", ["update-user"]) && this._renderView()}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(null, mapDispatchToProps)(injectIntl(EditUser));
