import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Helmet from "react-helmet";

class UnderConstruction extends Component {

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "hermes.generic_page.coming_soon", defaultMessage: "Coming soon: Hermes" })} </title>
        </Helmet>

        <div className="container-fluid flex-grow-1 px-lg-6 px-4 py-4">
          <div className="row mt-20">
            <div className="col-lg-12 text-center">
              <h1 className="heading-01 fw-medium mb-4 text-capitalize">
                <FormattedMessage id="generic_page.coming_soon.title" defaultMessage="coming soon" />
              </h1>
              <h2 className="heading-02"><FormattedMessage id="generic_page.coming_soon.sub_title" defaultMessage="This page is under construction." /></h2>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default injectIntl(UnderConstruction);
