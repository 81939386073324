import { combineReducers } from "redux";
import { get as _get } from "lodash";
import actionTypes from "../actions";

//Store URL to redux store
export const uri = (state = null, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_URL:
      return action.uri;

    default:
      return state;
  }
};

// Store user's token after login in redux store
export const userToken = (state = null, action) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATION_LOGIN_SUCCESS:
    case actionTypes.AUTHENTICATION_SET_TOKEN:
      return action.userToken;

    case actionTypes.DELETE_AUTHENTICATION_TOKEN_REQUEST:
      // localStorage.removeItem("__token");
      return null;

    default:
      return state;
  }
};

/*
 * Reducer to store logged in user 's data
 */
const user = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_CONFIGURATION_SUCCESS:
      return _get(action, "payload.user", {});

    case actionTypes.UPDATE_USER_AUTHENTICATION_DATA:
      return { ...state, ...(action.data || {}) };

    case actionTypes.DELETE_AUTHENTICATION_TOKEN_REQUEST:
      return {};

    default:
      return state;
  }
}

const success = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_SUCCESS:
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return (action.message || {});

    case actionTypes.CLEAR_AUTHENTICATION_SUCCESS:
    case actionTypes.CLEAR_FORGOT_PASSWORD_RESPONSE:
    case actionTypes.CLEAR_RESET_PASSWORD_RESPONSE:
      return {};

    default:
      return state;
  }
}

/*
 * Reducer to store all authentication errors
 */
const error = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_ERROR:
    case actionTypes.RESET_PASSWORD_ERROR:
    case actionTypes.AUTHENTICATION_LOGIN_ERROR:
    case actionTypes.RESET_PASSWORD_INVALID:
      return (action.error || {});

    case actionTypes.CLEAR_AUTHENTICATION_ERROR:
    case actionTypes.CLEAR_FORGOT_PASSWORD_ERROR:
    case actionTypes.CLEAR_CHECK_RESET_PASSWORD_RESPONSE:
      return {};

    default:
      return state;
  }
}

// Reducer to store forgot password success response
const forgotResponse = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return action.data;

    case actionTypes.CLEAR_FORGOT_PASSWORD_RESPONSE:
    case actionTypes.FORGOT_PASSWORD_ERROR:
      return false;

    default:
      return state;
  }
}

const resetAuthenticationToken = (state = null, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_VALID:
      return action.resetAuthenticationToken;

    case actionTypes.RESET_PASSWORD_INVALID:
      return null;

    case actionTypes.CLEAR_RESET_PASSWORD_RESPONSE:
    case actionTypes.CLEAR_CHECK_RESET_PASSWORD_RESPONSE:
      return null;

    default:
      return state;
  }
}

export default {
  authentication: combineReducers({
    uri,
    userToken,
    success,
    error,
    user,
    forgotResponse,
    resetAuthenticationToken
  })
};
