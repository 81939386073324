import { get as _get, omit as _omit } from "lodash";
import api from "../../helpers/api";

export const saveCampaignDetails = (variables) => {
  return api().post("/campaign", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "message", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while saving campaign details."),
      data: {}
    };
  });
}

export const doGetClientNameList = (variables) => {
  return api().options("/user/client_list", { data: (variables || {}) }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Client list fetched successfully."),
      data: _get(res, "data", {}),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching client list."),
      data: {},
    };
  });
}

export const getCurrencyList = () => {
  return api().get("/campaign/currency_list").then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Currencies fetched successfully."),
      data: _get(res, "data", {}),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching currencies."),
      data: {},
    };
  });
}

export const getRateTypes = () => {
  return api().get("/campaign/rate_type").then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Rate types fetched successfully."),
      data: _get(res, "data", {}),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching rate types."),
      data: {},
    };
  });
}

export const getAssetsDetails = (clientId, variables) => {

  return api().options(`/campaign/asset_details/${(clientId || 0)}`, { data: (variables || {}) }).then((res) => {
    return {
      flag: _get(res, "data.responseStatus", false),
      message: _get(res, "data.responseMessage", "Asset list fetched successfully."),
      data: _get(res, "data.responseData", []),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching asset list."),
      data: {},
    };
  });
}

export const createNewAsset = (variables) => {
  return api().post("/Campaign/create_asset", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while creating new asset."),
      error: _get(err, "response.data.responseData.errors", {}),
      data: {}
    };
  });
}

export const assignAssetCampaign = (variable) => {
  return api().put("/campaign/assign_asset_campaign", variable).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Asset assigned successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while assigning asset."),
      data: {},
    };
  });
}

export const initiateAssetCampaign = (variable) => {
  return api().post("/campaign/assets_initiate", variable).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Physical asset assigned successfully."),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while assigning physical asset."),
      data: {},
    };
  });
}

export const doGetAssetTypeList = () => {
  return api().get("/campaign/asset_type_list").then((res) => {
    return {
      flag: _get(res, "data.responseStatus", false),
      message: _get(res, "data.responseMessage", "Asset type list fetched successfully."),
      data: _get(res, "data.responseData", []),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching asset type list."),
      data: {},
    };
  });
}

export const doGetTopicList = () => {

  return api().get("/campaign/topic_list").then((res) => {
    return {
      flag: _get(res, "data.responseStatus", false),
      message: _get(res, "data.responseMessage", "Topic list fetched successfully."),
      data: _get(res, "data.responseData", []),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching topic list."),
      data: {},
    };
  });
}

export const doGetSubTopicList = (TopicId,) => {
  return api().get(`/campaign/sub_topic_list/${TopicId}`).then((res) => {
    return {
      flag: _get(res, "data.responseStatus", false),
      message: _get(res, "data.responseMessage", "Sub topic list fetched successfully."),
      data: _get(res, "data.responseData", []),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching sub topic list."),
      data: {},
    };
  });
}

export const uploadSingle = (fileUploadType, variables, setParentState = null) => {

  const config = {
    onUploadProgress: (data) => {
      if (typeof setParentState === "function") {
        setParentState({
          progress: Math.round((100 * (data.loaded || 0)) / (data.total || 0))
        });
      }
    }
  };

  return api().post(`/upload/single/${(fileUploadType || "")}`, variables, config).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {

    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while storing uploaded data."),
      error: _get(err, "response.data.responseData.errors", {}),
      data: {}
    };
  });
}

export const uploadSingleAsset = (variables, setParentState = null) => {

  const config = {
    onUploadProgress: (data) => {
      if (typeof setParentState === "function") {
        setParentState({
          progress: Math.round((100 * (data.loaded || 0)) / (data.total || 0))
        });
      }
    }
  };

  return api().post("/upload/assets", variables, config).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {
    const statusCode = _get(err, "response.status", 401);

    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while uploading assets."),
      error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
      data: {}
    };
  });
}

export const verifyExternalAsset = (variable) => {
  return api().post("/upload/web_asset_verify", variable).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Asset verified successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while verifying asset."),
      data: {},
    };
  });
}

export const removeAssignedAssets = (variables) => {
  return api().delete(`/campaign/delete_asset_campaign/${variables.campaign_id}`, { data: {} }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {
    const statusCode = _get(err, "response.status", 401);

    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while removing assigned assets."),
      error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
      data: {}
    };
  });
}

// Account List
export const getAccountList = (variables) => {
  return api().options("/campaign/account_list_detail", { data: (variables || {}) }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Account list fetched successfully."),
      data: _get(res, "data.responseData", []),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching account list."),
      data: {},
    };
  });
}

export const assignExistingAccountIds = async (variables) => {
  return api().patch("/campaign/account", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Account list assigned successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while assigning account list."),
      data: {},
    };
  });
}

// Remove assigned account list
export const removeAssignedAccountList = (variables) => {
  return api().patch("/campaign/account", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Account list removed successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    const statusCode = _get(err, "response.status", 401);
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while removing assigned account list."),
      error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
      data: {}
    };
  });
}

//Upload and read file and fetch data mapping columns
export const uploadReadFile = (variables, setParentState = null) => {

  const config = {
    onUploadProgress: (data) => {
      if (typeof setParentState === "function") {
        setParentState({
          progress: Math.round((100 * (data.loaded || 0)) / (data.total || 0))
        });
      }
    }
  };

  return api().post(`/upload/campaign/${(variables.campaign_id || 0)}/${(variables.source || "none")}`, (variables.data || {}), config).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {
    const statusCode = _get(err, "response.status", 401);

    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while uploading file."),
      error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
      data: {}
    };
  });
}

export const removeUploadedFile = (variables) => {
  const payload = _omit(variables, "source");

  return api().delete(`/upload/campaign/${variables.source}`, { data: payload }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {
    const statusCode = _get(err, "response.status", 401);

    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while removing uploaded file."),
      error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
      data: {}
    };
  });
}

//Pass mapping data and store in DB
export const saveMappingData = (variables) => {

  return api().post(`/upload/campaign/${variables.campaign_id}/${variables.source}/validate_save`, variables.payload).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while storing uploaded data."),
      error: _get(err, "response.data.responseData.errors", {}),
      data: {}
    };
  });
}

// Suppression List
export const getSuppressionList = (variables) => {
  return api().options("/campaign/suppression_list_detail", { data: (variables || {}) }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Suppression list fetched successfully."),
      data: _get(res, "data.responseData", []),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching suppression list."),
      data: {},
    };
  });
}

export const assignExistingSuppressionIds = async (variables) => {
  return api().patch("/campaign/suppression", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Suppression list assigned successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while assigning suppression list."),
      data: {},
    };
  });
}

export const removeAssignedSuppressionList = (variables) => {
  return api().patch("/campaign/suppression", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Suppression list removed successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    const statusCode = _get(err, "response.status", 401);

    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while removing assigned suppression list."),
      error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
      data: {}
    };
  });
}

export const fetchCampaignRequiredFields = (campaignId) => {

  return api().get(`/campaign/${(campaignId || 0)}/lead_required_field`).then((res) => {

    return {
      flag: _get(res, "data.responseStatus", false),
      message: _get(res, "data.responseMessage", "Campaign required fields fetched successfully."),
      data: _get(res, "data.responseData", []),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching required fields."),
      data: {},
    };
  });
}

export const saveRequiredFields = (variables) => {

  return api().post(`/campaign/${(variables.campaignId || 0)}/requiredfields`, variables.data).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while saving required fields."),
      error: _get(err, "response.data.responseData.errors", {}),
      data: {}
    };
  });
}

export const fetchCampaignBriefs = (campaignId) => {

  return api().get(`/campaign/${(campaignId || 0)}/brief_detail`).then((res) => {

    return {
      flag: _get(res, "data.responseStatus", false),
      message: _get(res, "data.responseMessage", "Campaign briefs fetched successfully."),
      data: _get(res, "data.responseData", []),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching campaign briefs."),
      data: {},
    };
  });
}

// Initiate campaign
export const initiateCampaign = (variables) => {

  return api().post(`/Campaign/initiate/${(variables.campaign_id || null)}`, (variables || {})).then((res) => {
    return {
      flag: _get(res, "data.responseStatus", false),
      message: _get(res, "data.responseMessage", "Campaign initiated successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    const statusCode = _get(err, "response.status", 401);

    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while initiating campaign."),
      error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
      data: {}
    };
  });
}

// Campaign Setup > Step 7 > Fetch Suppliers for Add/Edit Brief
export const fetchSuppliersList = (variables) => {

  return api().options("/campaign/supplier_list", { data: (variables || {}) }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Suppliers fetched successfully."),
      data: _get(res, "data", {}),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "data.responseMessage", "Something went wrong while fetching suppliers."),
      data: {},
    };
  });
}

export const createBrief = (campaignId, variables) => {
  return api().post(`/campaign/${(campaignId || 0)}/create_brief`, variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "message", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while creating brief."),
      data: {}
    };
  });
}

export const updateBrief = (campaignId, briefId, variables) => {
  return api().put(`/campaign/${(campaignId || 0)}/update_brief/${(briefId || 0)}`, variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while updating brief."),
      data: {}
    };
  });
}

export const fetchBriefCriteria = (briefId) => {

  return api().get(`/campaign/brief_criteria_detail/${(briefId || 0)}`).then((res) => {

    return {
      flag: _get(res, "data.responseStatus", false),
      message: _get(res, "data.responseMessage", "Brief criteria fetched successfully."),
      data: _get(res, "data.responseData", []),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching brief criteria."),
      data: {},
    };
  });
}

// Fetch brief master data: suppression, regions, job functions, industries, etc
export const getBriefMasterData = (dataType) => {

  return api().get(`/campaign/brief_master_data/${(dataType || "")}`).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Brief master data fetched successfully."),
      data: _get(res, "data.responseData", []),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching brief master data."),
      data: {}
    };
  });
}

// Assign brief master data: suppression, regions, job functions, industries, etc
export const assignBriefCriteria = (variables) => {
  return api().post("/campaign/assign_brief_criteria", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "message", ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while assigning brief."),
      data: {}
    };
  });
}

export const getClientBriefAccounts = (clientId, variables) => {
  return api().options(`campaign/${(clientId || 0)}/brief_account`, { data: (variables.brief_ids || {}) }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Accounts fetched successfully."),
      data: _get(res, "data.responseData", []),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching accounts."),
      data: {},
    };
  });
}

export const getClientBriefSuppressions = (clientId, variables) => {
  return api().options(`campaign/${(clientId || 0)}/brief_suppression`, { data: (variables.brief_ids || {}) }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Suppressions fetched successfully."),
      data: _get(res, "data.responseData", []),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching suppressions."),
      data: {},
    };
  });
}

// Assign Dates to brief
export const assignDatesToBrief = (variables) => {
  return api().post("/campaign/assign_brief_date", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Dates assigned successfully."),
      data: (res.data || {})
    };
  }).catch((err) => {

    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while assigning dates."),
      data: {}
    };
  });
}

// Assign Dates to brief
export const enableDisablePersonalityCard = (variables) => {
  return api().patch(`/campaign/${(variables.campaignId || null)}/humantics_processing/${(variables.isEnabled || null)}`).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Personality card enabled successfully for this campaign."),
      data: (res.data || {})
    };
  }).catch((err) => {

    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while enabling personality card for this campaign."),
      data: {}
    };
  });
}

export const deleteBriefs = (briefIds) => {

  return api().delete("/campaign/delete_brief", { data: (briefIds || []) }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Brief(s) deleted successfully."),
      data: _get(res, "data.responseData", []),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while deleting brief(s)."),
      data: {},
    };
  });
}

export const getAssetsData = (campaignId, variables) => {

  return api().options(`/campaign/${(campaignId || 0)}/asset_detail`, { data: (variables || {}) }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Assets fetched successfully."),
      data: _get(res, "data.responseData", []),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching brief assets."),
      data: {}
    };
  });
}

export const assignAssetToBriefs = (campaignId, variables) => {
  return api().options(`campaign/${(campaignId || 0)}/assign_assets`, { data: (variables || {}) }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Assets assigned successfully."),
      data: _get(res, "data.responseData", []),
    }
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while assigning assets."),
      data: {},
    };
  });
}
