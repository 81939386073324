import { isEmpty as _isEmpty } from "lodash";
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";

export const decryptUserData = (arr, isArray = true) => {
  if (_isEmpty(arr)) { return null; }

  if (isArray) {
    var bytes = AES.decrypt(arr, (process.env.REACT_APP_ENCRYPT_KEY || "UBxfx3dhZTibN8bnDBRbjoDHvU8Ck"));
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return AES.decrypt(arr, (process.env.REACT_APP_ENCRYPT_KEY || "UBxfx3dhZTibN8bnDBRbjoDHvU8Ck")).toString(CryptoJS.enc.Utf8);
  }
};

export const encryptUserData = (arr, isArray = true) => {
  if (_isEmpty(arr)) { return null; }

  if (isArray) {
    return AES.encrypt(JSON.stringify(arr), (process.env.REACT_APP_ENCRYPT_KEY || "UBxfx3dhZTibN8bnDBRbjoDHvU8Ck")).toString();
  } else {
    return AES.encrypt(arr, (process.env.REACT_APP_ENCRYPT_KEY || "UBxfx3dhZTibN8bnDBRbjoDHvU8Ck")).toString();
  }
};

export default {
  encryptUserData,
};
