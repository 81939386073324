import { takeLatest, call, fork, select, put } from "redux-saga/effects";
import { get as _get } from "lodash";

import { doLogin, forgotPassowrd, checkResetPassword, passwordReset } from "../services/authentication";

import {
  AUTHENTICATION_LOGIN_REQUEST, AUTHENTICATION_LOGIN_SUCCESS, AUTHENTICATION_LOGIN_ERROR,
  CHECK_RESET_PASSWORD_CRITERIA, RESET_PASSWORD_VALID, RESET_PASSWORD_INVALID,
  RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR,
  FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR,
} from "../actions/authentication";

import { UPDATE_APP_LOADING } from "../actions/application";

function* workerDoLogin(action) {
  try {

    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const url = yield select((state) => _get(state, "authentication.uri", ""));

    const response = yield call(doLogin, url, action.data);

    if ((response.flag || false) === true) {

      yield put({
        type: AUTHENTICATION_LOGIN_SUCCESS,
        userToken: (response.data || {}),
      });
    } else {

      yield put({
        type: AUTHENTICATION_LOGIN_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while login."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: RESET_PASSWORD_INVALID,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong while validating reset password link."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerCheckResetPassword(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(checkResetPassword, action.data);

    if ((response.flag || false) === true) {
      yield put({
        type: RESET_PASSWORD_VALID,
        resetAuthenticationToken: _get(response, "data.id", null),
        data: (response.flag || false),
      });
    } else {
      yield put({
        type: RESET_PASSWORD_INVALID,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while validating reset password link."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: RESET_PASSWORD_INVALID,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong while validating reset password link."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerResetPassword(action) {
  try {

    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(passwordReset, action.data);

    if ((response.flag || false) === true) {

      yield put({
        type: RESET_PASSWORD_SUCCESS,
        data: (response.flag || false),
        message: {
          title: _get(response, "title", "Success"),
          message: (response.message || "Password reset successfully.")
        },
      });
    } else {

      yield put({

        type: RESET_PASSWORD_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while resetting password."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: RESET_PASSWORD_ERROR,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong while validating reset password link."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerForgotPassword(action) {
  try {

    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(forgotPassowrd, action.data);

    if ((response.flag || false) === true) {

      yield put({
        type: FORGOT_PASSWORD_SUCCESS,
        data: (response.flag || {}),
        message: {
          title: _get(response, "title", "Success"),
          message: _get(response, "message", "Something went wrong while generating reset password link."),
        },
      });
    } else {

      yield put({
        type: FORGOT_PASSWORD_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while generating reset password link."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: FORGOT_PASSWORD_ERROR,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong while validating reset password link."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* watcherDoLogin() {
  yield takeLatest(AUTHENTICATION_LOGIN_REQUEST, workerDoLogin);
}

function* watcherCheckResetPassword() {
  yield takeLatest(CHECK_RESET_PASSWORD_CRITERIA, workerCheckResetPassword);
}

function* watcherResetPasswor() {
  yield takeLatest(RESET_PASSWORD_REQUEST, workerResetPassword);
}

function* watcherForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, workerForgotPassword);
}

const authenticationSaga = [
  fork(watcherDoLogin),
  fork(watcherCheckResetPassword),
  fork(watcherResetPasswor),
  fork(watcherForgotPassword),
]

export default authenticationSaga;
