import React, { Component, Fragment } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual, debounce as _debounce } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";

import { RenderTableCellValue, RenderTableHeaderValue, showAlertMessage, ConfirmBox, CustomModal, ErrorMessage } from "../../../../../resusableComponents/common/formElements";
import { isEmailValid, isValidURL } from "../../../../../helpers/utils";
import { hasAccess } from "../../../../../resusableComponents/hoc/AccessManagement";
import ReactDataTable from "../../../../../resusableComponents/common/ReactDataTable";

import { editAgency, deleteAgency, getAgencyDetailsById } from "../../../../../redux/services/userMangement";
import { doGetAgencyList, clearListError } from "../../../../../redux/actions/userMangement";
import { updateLoadingState } from "../../../../../redux/actions/application";


class AgencyList extends Component {

  constructor(props) {
    super(props);

    const { intl } = props;

    this.defaultAgencyListPayload = {
      page_no: 1,
      page_size: 10,
      search_text: "",
      dropdown_flag: 0
    }

    this.state = {
      agencyListPayload: { ...this.defaultAgencyListPayload },
      agencyTableData: [],
      selectedAgency: {},
      showEditAgencyModal: false,
      showDeleteAgencyConfirmBox: false,
      isEditButtonClicked: false,
      formErrors: {}
    };

    this.searchDebounce = _debounce(this._handleSearchAgency, 1000);

    this.agencyTableColumns = [
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.agency.list.table.column_name", defaultMessage: "name" })} />),
        selector: (row) => _get(row, "agency_name", ""),
        cell: (row) => {

          /*if (_get(row, "logo", null) !== null) {
            return (
              <Fragment>
                <img src={_get(row, "logo", "")} className="img-fluid" alt="" />
                <RenderTableCellValue value={_get(row, "agency_name", "")} tooltip={true} />
              </Fragment>
            );
          } else {
            return (<RenderTableCellValue value={_get(row, "agency_name", "")} tooltip={true} />);
          }*/
          return (
            <Fragment>
              <img className="img-fluid me-5" width="30" src={`https://logo.clearbit.com/${_get(row, "website", "")}`} alt="" />
              <RenderTableCellValue className="text-truncate" value={_get(row, "agency_name", "")} tooltip={true} />
            </Fragment>
          );
        },
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.agency.list.table.column_email", defaultMessage: "email" })} />),
        selector: (row) => (_get(row, "email", "")),
        cell: (row) => (<RenderTableCellValue className="text-blue-22 text-truncate" value={_get(row, "email", "")} tooltip={true} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.agency.list.table.column_website", defaultMessage: "website" })} />),
        selector: (row) => (_get(row, "website", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "website", "")} tooltip={true} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.agency.list.table.leads_booked", defaultMessage: "Leads Booked" })} />),
        selector: (row) => (_get(row, "credits", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "credits", "")} tooltip={true} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.agency.list.table.gross", defaultMessage: "gross" })} />),
        selector: (row) => (_get(row, "gross", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "gross", "")} tooltip={true} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.agency.list.table.net", defaultMessage: "net" })} />),
        selector: (row) => (_get(row, "net", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "net", "")} tooltip={true} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.agency.list.table.column_deleted", defaultMessage: "deleted" })} />),
        selector: (row) => (_get(row, "deleted", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "deleted", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.agency.list.table.column_action", defaultMessage: "action" })} />),
        selector: (row) => {
          return hasAccess("admin", "agency-management", ["update-agency", "delete-agency"], "OR") ? (
            <Dropdown>
              <Dropdown.Toggle as="a" variant="link" className="fw-normal dropdown-toggle-no cursor-pointer">
                <img src={require("../../../../../assets/icons/icon-three-dot.svg").default} className="img-fluid" alt="img" />
              </Dropdown.Toggle>

              <Dropdown.Menu as="ul" className="dropdown-menu">

                {hasAccess("admin", "agency-management", ["update-agency"]) && (
                  <Dropdown.Item className="dropdown-item d-block waves-effect text-capitalize" onClick={() => this.setState({ showEditAgencyModal: true, selectedAgency: row })}>
                    <FormattedMessage id="user_management.agency.list.action.edit" defaultMessage="edit" />
                  </Dropdown.Item>
                )}
                {hasAccess("admin", "agency-management", ["delete-agency"]) && (
                  <Dropdown.Item className="dropdown-item d-block waves-effect text-capitalize" onClick={() => this.setState({ showDeleteAgencyConfirmBox: true, selectedAgency: row })}>
                    <FormattedMessage id="user_management.agency.list.action.delete" defaultMessage="delete" />
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : (<></>)
        },
        sortable: false,
        width: "80px",
        center: "true",
      },
    ];
  }

  componentDidMount() {

    this.fetchAgencyList();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.errorList, this.props.errorList) && !_isEmpty(_get(this.props, "errorList.message", ""))) {
      const { errorList, clearListError } = this.props;

      showAlertMessage(_get(errorList, "message", "Something went wrong while fetching agency list"));

      if (typeof clearListError === "function") { clearListError(); }
    }

    if (!_isEqual(prevState.selectedAgency, _get(this.state, "selectedAgency", {}))) { this._handleValidation(); }

    if (!_isEqual(prevState.agencyListPayload, this.state.agencyListPayload)) {
      this.fetchAgencyList();
    }

    if (!_isEqual(prevState.showEditAgencyModal, this.state.showEditAgencyModal) && (_get(this.state, "showEditAgencyModal", false) === true)) {
      this._fetchAgencyDetails();
    }
  }

  fetchAgencyList = () => {
    const { doGetAgencyList } = this.props;
    const { agencyListPayload } = this.state;

    if (typeof doGetAgencyList === "function") { doGetAgencyList(agencyListPayload); }
  }

  _fetchAgencyDetails = async () => {
    const { updateLoadingState, intl } = this.props;
    const { selectedAgency } = this.state;

    if (_isEmpty(selectedAgency) || (_get(selectedAgency, "id", 0) === 0)) {
      showAlertMessage(intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "agency", defaultMessage: "agency" })
      }));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getAgencyDetailsById(_get(selectedAgency, "id", 0));

      const resData = _get(response, "data.responseData", {});

      if ((response.flag || false) === true) {
        this.setState({ selectedAgency: resData });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching agency details.")));
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching agency details.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  onDeleteAgency = async () => {
    const { updateLoadingState, intl } = this.props;
    const { selectedAgency } = this.state;

    if (_isEmpty(selectedAgency) || (_get(selectedAgency, "id", 0) === 0)) {

      showAlertMessage(intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "agency", defaultMessage: "agency" })
      }));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await deleteAgency(_get(selectedAgency, "id", 0));

      if ((response.flag || false) === true) {

        showAlertMessage(_get(response, "data.responseMessage", "Agency deleted successfully."), "success");

        this.fetchAgencyList();
      } else {

        showAlertMessage(_get(response, "data.responseMessage", "Something went wrong while deleting agency."));
      }

      this.setState({ selectedAgency: {}, showDeleteAgencyConfirmBox: false });
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while deleting agency.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleSearchAgency = (searchText) => {
    const { agencyListPayload } = this.state;

    this.setState({ agencyListPayload: { ...agencyListPayload, search_text: searchText } });
  }

  handlePaginationChange = (pageNo) => {
    const { agencyListPayload } = this.state;

    this.setState({ agencyListPayload: { ...agencyListPayload, page_no: pageNo } });
  }

  _renderTextFiled = ({ label, placeholder, onChange, name, defaultValue, isRequired = false, type = "text", ...restProps }) => {
    const { formErrors } = this.state;

    return (
      <div className="mb-6">
        <label className="form-label text-capitalize">
          {label}
          {(isRequired) && (<span className="text-danger">*</span>)}
        </label>
        <input
          name={name}
          type={(type || "text")}
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
          value={defaultValue}
          {...restProps}
        />
        {_get(formErrors, `${name}`, "") && <ErrorMessage message={_get(formErrors, `${name}`, "")} />}
      </div>
    );
  }

  handleFileUpload = async (e) => {
    const { intl } = this.props;
    let file = _get(e, "target.files[0]", null);

    if ((file || null) === null) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "image", defaultMessage: "image" }),
      }));
      return;
    }

    if (!["image/jpg", "image/jpeg", "image/png"].includes(_get(file, "type", ""))) {
      showAlertMessage(intl.formatMessage({ id: "error.select_valid", defaultMessage: "Please select valid {field}." }, {
        field: intl.formatMessage({ id: "image", defaultMessage: "image" }),
      }));
      return;
    }

    this.setState(prevState => ({
      selectedAgency: {
        ...prevState.selectedAgency,
        newLogo: file,
        isLogoUpdate: true
      }
    }));
  }

  handleChange = (keyName, keyValue) => {

    this.setState(prevState => ({
      selectedAgency: {
        ...prevState.selectedAgency,
        [keyName]: keyValue,
      }
    }));
  }

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { selectedAgency } = this.state;
    let formErrors = {};

    if (_isEmpty(_get(selectedAgency, "agency_name", ""))) {
      formErrors["agencyName"] = intl.formatMessage({ id: "error.enter", defaultMessage: "Please enter {field}." }, {
        field: intl.formatMessage({ id: "user_management.agency.list.table.column_name", defaultMessage: "name" }),
      });
    }

    if (!isEmailValid(_get(selectedAgency, "email", ""))) {
      formErrors["email"] = intl.formatMessage({ id: "error.valid", defaultMessage: "Please enter valid {field}." }, {
        field: intl.formatMessage({ id: "user_management.agency.list.table.column_email", defaultMessage: "email" }),
      });
    }

    if (_isEmpty(_get(selectedAgency, "website", ""))) {
      formErrors["websiteUrl"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.agency.create.website_url", defaultMessage: "website URL" })
      });
    }

    if ((!_isEmpty(_get(selectedAgency, "website", ""))) && (!isValidURL(_get(selectedAgency, "website", "")))) {
      formErrors["website"] = intl.formatMessage({ id: "error.valid", defaultMessage: "Please enter valid {field}." }, {
        field: intl.formatMessage({ id: "user_management.agency.list.table.column_website", defaultMessage: "website" }),
      });
    }

    /*if ((!_isEmpty(_get(selectedAgency, "newLogo", ""))) && (!["image/jpg", "image/jpeg", "image/png"].includes(_get(selectedAgency, "newLogo.type", "")))) {
      formErrors["logo"] = intl.formatMessage({ id: "error.select_valid", defaultMessage: "Please select valid {field}." }, {
        field: intl.formatMessage({ id: "image", defaultMessage: "image" }),
      });
    }*/

    this.setState({ formErrors, });

    if (returnFlag) {
      return (!_isEmpty(formErrors)) ? false : true;
    }
  }

  handleSubmitEditAgency = async () => {
    const { updateLoadingState } = this.props;
    const { selectedAgency, formErrors } = this.state;

    this.setState({ isEditButtonClicked: true });

    const isValidForm = this._handleValidation(true);

    if (!isValidForm) { return false; }

    if (!_isEmpty(formErrors)) { return false; }
    //const logo = (_get(selectedAgency, "isLogoUpdate", false) === true) ? _get(selectedAgency, "newLogo", {}) : _get(selectedAgency, "logo", "")

    let data = new FormData();
    data.append("agency_name", _get(selectedAgency, "agency_name", ""));
    data.append("email", _get(selectedAgency, "email", ""));
    data.append("website", _get(selectedAgency, "website", ""));
    data.append("credits", _get(selectedAgency, "credits", 0));
    data.append("gross", _get(selectedAgency, "gross", 0));
    data.append("net", _get(selectedAgency, "net", 0));
    //data.append("logo", logo);

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await editAgency(data, _get(selectedAgency, "id", 0));

      if ((response.flag || false) === true) {

        showAlertMessage(_get(response, "data.responseMessage", "Agency updated successfully."), "success");

        this.fetchAgencyList();
      } else {

        showAlertMessage(_get(response, "data.responseMessage", "Something went wrong while updating agency data."));
      }

      this.setState({ selectedAgency: {}, showEditAgencyModal: false, formErrors: {}, isEditButtonClicked: false });
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while updating agency data.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderEditAgencyModal = () => {
    const { intl } = this.props;
    const { selectedAgency, showEditAgencyModal } = this.state;

    return (
      <CustomModal
        isOpen={showEditAgencyModal}
        modalTitle={intl.formatMessage({ id: "user_management.agency.list.modal.edit.title", defaultMessage: "edit agency" })}
        onHide={() => this.setState({ showEditAgencyModal: false })}
        onClose={() => this.setState({ showEditAgencyModal: false })}
      >
        <>
          <div className="row">
            <div className="col-lg-6">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "user_management.agency_name", defaultMessage: "Agency Name" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "user_management.agency_name", defaultMessage: "agency name", }),
                }),
                onChange: (event) => this.handleChange("agency_name", _get(event, "target.value", "")),
                name: "agencyName",
                isRequired: true,
                defaultValue: _get(selectedAgency, "agency_name", "")
              })}
            </div>

            <div className="col-lg-6">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "user_management.email_address", defaultMessage: "Email Address" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "user_management.email_address", defaultMessage: "email address", }),
                }),
                onChange: (event) => this.handleChange("email", _get(event, "target.value", "")),
                name: "email",
                isRequired: true,
                disabled: true,
                defaultValue: _get(selectedAgency, "email", "")
              })}
            </div>

            <div className="col-lg-6">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "user_management.website_url", defaultMessage: "Website URL" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "user_management.website_url", defaultMessage: "website url", }),
                }),
                onChange: (event) => this.handleChange("website", _get(event, "target.value", "")),
                name: "website",
                isRequired: true,
                defaultValue: _get(selectedAgency, "website", "")
              })}
            </div>

            <div className="col-lg-6">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "user_management.leads_booked", defaultMessage: "Leads booked" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "user_management.leads_booked", defaultMessage: "Leads booked", }),
                }),
                onChange: (event) => this.handleChange("credits", _get(event, "target.value", "")),
                name: "credits",
                type: "number",
                isRequired: true,
                defaultValue: _get(selectedAgency, "credits", "")
              })}
            </div>

            <div className="col-lg-6">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "user_management.agency.create.gross", defaultMessage: "Gross" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "user_management.agency.create.gross", defaultMessage: "Gross", }),
                }),
                onChange: (event) => this.handleChange("gross", _get(event, "target.value", "")),
                name: "gross",
                type: "number",
                defaultValue: _get(selectedAgency, "gross", "")
              })}
            </div>

            <div className="col-lg-6">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "user_management.agency.create.net", defaultMessage: "Net" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "user_management.agency.create.net", defaultMessage: "Net", }),
                }),
                onChange: (event) => this.handleChange("net", _get(event, "target.value", "")),
                name: "net",
                type: "number",
                defaultValue: _get(selectedAgency, "net", "")
              })}
            </div>

            {/*<div className="col-lg-6">
              <div className="mb-6">
                <div className="mb-3">
                  <label htmlFor="agencyLogo" className="form-label">
                    <FormattedMessage id="user_management.upload_logo" defaultMessage="Upload Logo" />
                  </label>
                  <input className="form-control" type="file" id="agencyLogo" onChange={(event) => this.handleFileUpload(event)} />
                  {(_get(formErrors, "logo", "")) && ((isEditButtonClicked || false) === true) && (<ErrorMessage message={_get(formErrors, "logo", "")} />)}
                </div>
              </div>
            </div>*/}
          </div>

          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button type="button" className="btn btn-secondary" onClick={() => this.setState({ showEditAgencyModal: false, selectedAgency: {} })}>
              <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
            </button>
            <button className="btn btn-primary" onClick={() => this.handleSubmitEditAgency()}>
              <FormattedMessage id="btn.submit" defaultMessage="Submit" />
            </button>
          </div>
        </>
      </CustomModal>
    );
  }

  render() {
    const { agencyListData, intl } = this.props;
    const { selectedAgency, showDeleteAgencyConfirmBox } = this.state;

    return (
      <>
        {hasAccess("admin", "agency-management", ["agency-list"]) && (
          <>
            <table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-group w-auto">
                          <input type="text" className="form-control" placeholder="Search ..." onChange={(e) => this.searchDebounce(_get(e, "target.value", ""))} />
                          <span className="input-group-text bg-primary waves-effect py-1 d-flex align-items-center">
                            <img src={require("../../../../../assets/icons/icon-search-sm.svg").default} className="img-fluid" alt="" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>

            <ReactDataTable
              columns={this.agencyTableColumns}
              data={_get(agencyListData, "agency_list", [])}
              pagination={true}
              totalRecords={_get(agencyListData, "agency_list_info[0].total_records", 10)}
              onChangePage={(e) => this.handlePaginationChange(e)}
            />
          </>
        )}

        {hasAccess("admin", "agency-management", ["delete-agency"]) && (
          <ConfirmBox
            onConfirm={() => this.onDeleteAgency()}
            isOpen={(showDeleteAgencyConfirmBox || false)}
            onClose={() => this.setState({ showDeleteAgencyConfirmBox: false })}
            content={intl.formatMessage({ id: "confirm.are_you_sure_delete", defaultMessage: "Are you sure want to delete {field}?" }, { field: (_get(selectedAgency, "agency_name", "") || "Agency") })}
          />
        )}

        {hasAccess("admin", "agency-management", ["update-agency"]) && this._renderEditAgencyModal()}
      </>
    );
  };
};

const mapStateToProps = (state) => ({
  errorList: _get(state, "userManagement.errorList", {}),
  agencyListData: _get(state, "userManagement.agencyList.responseData", {}),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  doGetAgencyList: (data) => dispatch(doGetAgencyList(data)),
  clearListError: () => dispatch(clearListError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AgencyList));
