import React, { Component } from "react";
import { get as _get, isEqual as _isEqual } from "lodash";
import { injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { withRouter } from "../../resusableComponents/hoc/withRouter";
import CompaignProgressBar from "./components/CampaignProgressBar"
import InitiateCampaign from "./components/InitiateCampaign";
import CampaignDetails from "./components/CampaignDetails";
import SuppressionList from "./components/SuppressionList";
import UploadMediaPlan from "./components/UploadMediaPlan";
import RequiredFields from "./components/RequiredFields";
import CampaignBrief from "./components/CampaignBrief";
import AccountLists from "./components/AccountLists";
import Assets from "./components/Assets";

import { updateCampaignSetupDetails, resetCampaignSetup } from "../../redux/actions/campaignSetup";

const components = [
  CampaignDetails,
  Assets,
  AccountLists,
  SuppressionList,
  UploadMediaPlan,
  RequiredFields,
  CampaignBrief,
  InitiateCampaign,
];

class CampaignSetup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      totalSteps: (components || []).length,
    }
  }

  componentDidUpdate(prevProps, prevStates) {
    if ((!_isEqual(prevProps.setupInitiated, this.props.setupInitiated)) && (this.props.setupInitiated === true)) {
      const { common: { campaignId }, navigate, resetCampaignSetup } = this.props;

      // Reset campaign setup redux store
      if (typeof resetCampaignSetup === "function") { resetCampaignSetup(); }

      // Redirect to campaign dashboard
      if (typeof navigate === "function") {
        navigate({ "pathname": `/campaigns/dashboard/${campaignId}` });
      }
    }
  }

  _handleBtnAction = (isBackwardClick = true) => {
    let { common, common: { currentStep }, updateCampaignSetupDetails } = this.props;

    if (typeof updateCampaignSetupDetails === "function") {
      updateCampaignSetupDetails({ "common": { ...common, currentStep: (isBackwardClick) ? (currentStep - 1) : (currentStep + 1) } });
    }
  }

  _renderView() {
    const { common: { currentStep, totalStep } } = this.props;
    const CurrentComponent = components[currentStep - 1];

    return (
      <div className="content-wrapper">

        <div className="container-fluid flex-grow-1 px-6 py-6">
          <CompaignProgressBar />
          <CurrentComponent
            currentStep={(currentStep || 1)}
            totalSteps={(totalStep || 1)}
            onNextStep={() => this._handleBtnAction(false)}
            onPreviousStep={() => this._handleBtnAction()}
          />
        </div>

      </div>
    )
  };

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title>
            {intl.formatMessage({ id: "campaign_setup.title", defaultMessage: "Campaign Setup" })}
          </title>
        </Helmet>

        {this._renderView()}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  common: _get(state, "campaignSetup.summary.common", {}),
  setupInitiated: _get(state, "campaignSetup.setupInitiated", false),
});

const mapDispatchToProps = (dispatch) => ({
  resetCampaignSetup: () => dispatch(resetCampaignSetup()),
  updateCampaignSetupDetails: (data) => dispatch(updateCampaignSetupDetails(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaignSetup)));
