import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get as _get } from "lodash";

import { showAlertMessage, CustomModal } from "../../../resusableComponents/common/formElements";
import { updateLoadingState } from "../../../redux/actions/application";
import { doDeleteCampaign } from "../../../redux/services/campaign";

const radioButtonList = [
  { id: "deletepermanently", labelId: "campaign_list.model.radio_button.delete_permanently", defaultMessage: "delete permanently", defaultValue: 1 },
  { id: "markasdeleted", labelId: "campaign_list.model.radio_button.mark_as_deleted", defaultMessage: "mark as deleted", defaultValue: 0, defaultChecked: true },
];

class DeleteCampaign extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedDeleteOption: 0,
    };
  }

  _handleDeleteCampaign = async () => {
    const { updateLoadingState, selectedCampaignIds, setState, intl } = this.props;
    const { selectedDeleteOption } = this.state;

    if ((selectedCampaignIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "campaign_list.campaign", defaultMessage: "campaign" }) }
      ));
      return false;
    }

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doDeleteCampaign({ campaign_ids: selectedCampaignIds, permanent_deletion_flag: selectedDeleteOption });

      if ((response.flag || false) === true) {

        showAlertMessage(_get(response, "data.responseMessage", "Campaign deleted successfully."), "success");

        if (typeof setState === "function") {
          setState({ showDeleteCampaignConfirmBox: false, selectedCampaignIds: [], reloadCampaignList: true });
        }

      } else {

        showAlertMessage(_get(response, "data.responseMessage", "Something went wrong while deleting campaign."));
      }

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while deleting Campaign.");
    } finally {

      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _cloneModal = () => {
    const { setState } = this.props;

    this.setState({ selectedDeleteOption: 0 });
    if (typeof setState === "function") { setState({ showDeleteCampaignConfirmBox: false }); }
  }

  _renderRadioButton = ({ label, id, name, defaultValue, onChange }) => {
    const { selectedDeleteOption } = this.state;

    return (
      <div className="form-check form-check-inline">
        <input
          className="form-check-input show-data"
          type="radio"
          name={name}
          id={id}
          value={defaultValue}
          checked={selectedDeleteOption === defaultValue}
          onChange={onChange} />
        <label className="form-check-label text-capitalize" htmlFor={id}>{label}</label>
      </div>
    );
  }

  _renderView = () => {
    const { showDeleteCampaignConfirmBox, intl } = this.props;

    return (
      <CustomModal
        isOpen={showDeleteCampaignConfirmBox}
        modalTitle={intl.formatMessage({ id: "campaign_list.model_header_delete_campaign", defaultMessage: "delete campaign" })}
        onHide={() => this._cloneModal()}
        onClose={() => this._cloneModal()}
      >
        <>
          <div className="row">

            <div className="col-lg-12">
              <div className="mb-8">
                {(radioButtonList || []).map((button, index) => (
                  <React.Fragment key={index}>
                    {this._renderRadioButton({
                      key: index,
                      label: intl.formatMessage({ id: button.labelId, defaultMessage: button.defaultMessage }),
                      id: (button.id || ""),
                      name: (button.id || ""),
                      defaultValue: (button.defaultValue || 0),
                      onChange: (event) => { this.setState({ selectedDeleteOption: parseInt(_get(event, "target.value", 0)) }) },
                      defaultChecked: (button.defaultChecked || false)
                    })}
                  </React.Fragment>
                ))}
              </div>
            </div>

          </div>

          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button type="button" className="btn btn-secondary text-capitalize" onClick={() => this._cloneModal()}>
              <FormattedMessage id="btn.cancel" defaultMessage="cancel" />
            </button>
            <button className="btn btn-primary text-capitalize" onClick={() => this._handleDeleteCampaign()}>
              <FormattedMessage id="btn.delete" defaultMessage="delete" />
            </button>
          </div>
        </>
      </CustomModal>
    );
  }

  render() {

    return (
      <>
        {this._renderView()}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(null, mapDispatchToProps)(injectIntl(DeleteCampaign));
