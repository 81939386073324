import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { Popover, OverlayTrigger, Dropdown } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { RenderTableCellValue, RenderTableHeaderValue, showAlertMessage, ShowContentLoader } from "../../../../resusableComponents/common/formElements";
import ReactDataTable from "../../../../resusableComponents/common/ReactDataTable";

import { fetchBriefCriteria, deleteBriefs } from "../../../../redux/services/campaignSetup";

import { fetchCampaignBriefs, resetCampaignSetupError } from "../../../../redux/actions/campaignSetup";
import { updateLoadingState } from "../../../../redux/actions/application";

import AssignJobFunctionsToBrief from "../../../../resusableComponents/campaignBrief/AssignJobFunctionsToBrief";
import AssignSuppressionsToBrief from "../../../../resusableComponents/campaignBrief/AssignSuppressionsToBrief";
import AssignCompanySizesToBrief from "../../../../resusableComponents/campaignBrief/AssignCompanySizesToBrief";
import AssignIndustriesToBrief from "../../../../resusableComponents/campaignBrief/AssignIndustriesToBrief";
import AssignAccountsToBrief from "../../../../resusableComponents/campaignBrief/AssignAccountsToBrief";
import AssignRegionsToBrief from "../../../../resusableComponents/campaignBrief/AssignRegionsToBrief";
import AssignDatesToBrief from "../../../../resusableComponents/campaignBrief/AssignDatesToBrief";
import AddEditBrief from "../../../../resusableComponents/campaignBrief/AddEditBrief";

class CampaignBrief extends Component {

  constructor(props) {
    super(props);
    const { intl } = props;

    this._timeout = null;

    this.state = {
      selectedBriefs: [],
      selectedBriefIds: [],
      showBriefAddEditModal: false,
      reloadBriefList: false,
      isEditBrief: false,
      criteriaLoading: false,
      briefCriteria: [],
      criteriaBriefId: null,
    };

    this.briefTableColumns = [
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.id", defaultMessage: "ID" })} />),
        selector: (row) => _get(row, "id", ""),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "id", "")} tooltip={false} />),
        sortable: false,
        width: "50px"
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.name", defaultMessage: "name" })} />),
        selector: (row) => (_get(row, "name", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "name", "")} tooltip={true} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.tactic_code", defaultMessage: "tactic code" })} />),
        selector: (row) => (_get(row, "tactic_code", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "tactic_code", "")} tooltip={true} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.supplier", defaultMessage: "supplier" })} />),
        selector: (row) => (_get(row, "supplier", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "supplier", "")} tooltip={true} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.date", defaultMessage: "date" })} />),
        selector: (row) => (_get(row, "date", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "date", "")} tooltip={true} />),
        sortable: true,
        grow: 1.2
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.criteria", defaultMessage: "criteria" })} />),
        selector: (row) => (_get(row, "net", "")),
        cell: (row) => {
          const { criteriaBriefId } = this.state;
          const briefId = _get(row, "id", null);

          return (
            <div className="align-items-center cursor-pointer">
              <OverlayTrigger
                placement="right"
                show={(criteriaBriefId === briefId)}
                shouldUpdatePosition={true}
                overlay={this._showCriteria(briefId)}
                rootClose={true}
              >
                <span onMouseEnter={() => this._handleShowPopper(briefId)}>
                  <img src={require("../../../../assets/icons/icon-info.svg").default} alt="info_icon" className="img-fluid" />
                </span>
              </OverlayTrigger>
            </div>
          );
        },
        sortable: false,
        center: "true",
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.quantity", defaultMessage: "quantity" })} />),
        selector: (row) => (_get(row, "quantity", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "quantity", "")} />),
        sortable: true,
        width: "100px",
        center: "true",
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.rate_type", defaultMessage: "rate type" })} />),
        selector: (row) => (_get(row, "rate_type", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "rate_type", "")} />),
        sortable: true,
        width: "100px",
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.rate", defaultMessage: "rate" })} />),
        selector: (row) => (_get(row, "cpl", "")),
        cell: (row) => (<RenderTableCellValue value={`${_get(row, "currency", "")}${_get(row, "cpl", "")}`} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.site", defaultMessage: "site" })} />),
        selector: (row) => (_get(row, "site", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "site", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.notes", defaultMessage: "notes" })} />),
        selector: (row) => (_get(row, "notes", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "notes", "")} />),
        sortable: true,
      },
    ];
  }

  componentDidMount() {
    this._fetchCampaignBriefs();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.reloadBriefList, this.state.reloadBriefList) && (_get(this.state, "reloadBriefList", false) === true)) {
      this._fetchCampaignBriefs();
      this.setState({ reloadBriefList: false });
    }

    if (!_isEqual(prevState.criteriaBriefId, this.state.criteriaBriefId) && this.state.criteriaBriefId !== null) {
      this._fetchBriefCriteria();
    }

    document.addEventListener("click", () => this._handleClickOutside());
  }

  componentWillUnmount() {
    document.removeEventListener("click", () => this._handleClickOutside());
  }

  _handleClickOutside = () => {
    const { criteriaBriefId } = this.state;

    if (criteriaBriefId !== null) {
      this.setState({ criteriaBriefId: null, briefCriteria: [] });
    }
  }

  _handleShowPopper = (criteriaBriefId) => {
    if (!_isEmpty(this._timeout)) { clearTimeout(this._timeout); }

    this._timeout = setTimeout(() => {
      this.setState({ criteriaBriefId });
    }, 300);
  }

  _fetchCampaignBriefs = async () => {
    const { fetchCampaignBriefs, common: { campaignId } } = this.props;

    if (typeof fetchCampaignBriefs === "function") { fetchCampaignBriefs(campaignId); }
  }

  _fetchBriefCriteria = async () => {
    const { criteriaBriefId } = this.state;

    if (criteriaBriefId === null) { return false; }

    this.setState({ criteriaLoading: true });

    try {

      const response = await fetchBriefCriteria(criteriaBriefId);

      if ((response.flag || false) === true) {
        this.setState({ briefCriteria: _get(response, "data", []) });
      } else {
        this.setState({ briefCriteria: [] });
        showAlertMessage(_get(response, "message", "Something went wrong while fetching brief criteria."));
      }

    } catch (error) {
      this.setState({ briefCriteria: [] });
      showAlertMessage(_get(error, "message", "Something went wrong while fetching brief criteria."));
    } finally {
      this.setState({ criteriaLoading: false });
    }
  }

  _showCriteria = (briefId) => {
    const { briefCriteria, criteriaBriefId, criteriaLoading } = this.state;

    if (criteriaBriefId !== briefId) { return <></>; }

    return (
      <Popover
        id="popover-basic"
        placement="right"
        className="criteria_overlay"
        show={(criteriaBriefId === briefId)}
      >
        <h5 className="popover-header text-capitalize fw-bold">
          <FormattedMessage id="campaign_brief.criteria.title" defaultMessage="brief criteria" />
        </h5>

        {(criteriaLoading === true) && (
          <div className="popover-height">
            <div className="popover-body">
              <ShowContentLoader />
            </div>
          </div>
        )}

        {(criteriaLoading === false) && (
          <>
            {((briefCriteria || []).length === 0) && (
              <div className="popover-height">
                <div className="popover-body">
                  <FormattedMessage id="message.no_found_data" defaultMessage="no data found" />
                </div>
              </div>
            )}

            <div className="popover-body d-flex flex-column gap-1">
              {(briefCriteria || []).map((e, key) => (
                <div key={key}>
                  <span className="text-capitalize fw-semibold">
                    <FormattedMessage id={`campaign_brief.criteria.${(e.criteria || "criteria")}`} defaultMessage="Brief criteria: " />
                  </span>
                  <span>
                    {(e.criteria_value || "")}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
      </Popover>
    );
  }

  _onHideCriteria = () => {
    this.setState({ criteriaBriefId: null, briefCriteria: [] });
  }

  showEditBriefModal = () => {
    const { intl } = this.props;
    const { selectedBriefs } = this.state;

    if ((selectedBriefs || []).length < 1) {

      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }),
      }));
      return;
    } else if ((selectedBriefs || []).length > 1) {

      showAlertMessage(intl.formatMessage({ id: "error.select_only_one", defaultMessage: "Please select only one {field}." }, {
        field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }),
      }));
      return;
    }

    this.setState({ showBriefAddEditModal: true, isEditBrief: true })
  }

  _deleteSelectedBriefs = async () => {
    const { intl } = this.props;
    const { selectedBriefIds } = this.state;

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    try {
      const response = await deleteBriefs(selectedBriefIds);

      if (_get(response, "flag", false) === true) {

        showAlertMessage(_get(response, "message", "brief(s) deleted successfully."), "success");

        setTimeout(() => {
          window.onbeforeunload = false;
          window.location.reload();
        }, 500);
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while deleting briefs."));
      }
    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while deleting briefs."));
    }
  }

  _renderTopActions = () => {
    const { common: { campaignId, clientId } } = this.props;
    const { selectedBriefIds } = this.state;

    return (
      <>
        <Dropdown>
          <Dropdown.Toggle as="a" variant="success" className="btn btn-outline-primary text-capitalize-first dropdown-toggle-no cursor-pointer">
            <FormattedMessage id="campaign_setup.campaign_brief.assign" defaultMessage="Assign" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <AssignAccountsToBrief selectedBriefIds={selectedBriefIds} source="campaign" campaignId={(campaignId || 0)} clientId={(clientId || 0)} />

            <AssignSuppressionsToBrief selectedBriefIds={selectedBriefIds} source="campaign" campaignId={(campaignId || 0)} clientId={(clientId || 0)} />

            <AssignJobFunctionsToBrief selectedBriefIds={selectedBriefIds} source="campaign" campaignId={(campaignId || 0)} />

            <AssignRegionsToBrief selectedBriefIds={selectedBriefIds} source="campaign" campaignId={(campaignId || 0)} />

            <AssignCompanySizesToBrief selectedBriefIds={selectedBriefIds} source="campaign" campaignId={(campaignId || 0)} />

            <AssignIndustriesToBrief selectedBriefIds={selectedBriefIds} source="campaign" campaignId={(campaignId || 0)} />

            <AssignDatesToBrief selectedBriefIds={selectedBriefIds} source="campaign" campaignId={(campaignId || 0)} setState={(args) => this.setState(args)} />
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }

  render() {
    const { common: { campaignId }, briefs, currentStep, onPreviousStep, onNextStep } = this.props;
    const { selectedBriefIds, selectedBriefs, showBriefAddEditModal, criteriaBriefId, briefCriteria, criteriaLoading, isEditBrief } = this.state;

    const uniqueKey = `${(criteriaBriefId || 1)}-${(briefCriteria || "criteria")}-${(criteriaLoading || "loader")}`;

    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body py-5 vh-165">
                <h6 className="heading-04 fw-semibold mb-6">{`Step ${currentStep} of 8 - Campaign Brief`}</h6>

                <div className="row gy-4">
                  <div className="col-lg-3">
                    {this._renderTopActions()}
                  </div>
                  <div className="col-lg-9">
                    <div className="d-flex flex-wrap gap-3 align-items-center justify-content-end">
                      <button className="btn btn-outline-primary waves-effect text-capitalize" onClick={() => this.setState({ showBriefAddEditModal: true })}>
                        <FormattedMessage id="campaign_setup.campaign_brief.add_new_brief" defaultMessage="add new brief" />
                      </button>
                      <button className="btn btn-outline-primary waves-effect text-capitalize" onClick={() => this.showEditBriefModal()}>
                        <FormattedMessage id="btn.update" defaultMessage="btn.update" />
                      </button>
                      <button className="btn btn-outline-primary waves-effect text-capitalize" onClick={() => this._deleteSelectedBriefs()}>
                        <FormattedMessage id="btn.delete" defaultMessage="delete" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row mt-8">
                  <div className="col-lg-12">
                    <div className="table-responsive custom-scroll">
                      <ReactDataTable
                        key={(uniqueKey || "brief-table")}
                        columns={this.briefTableColumns}
                        data={(briefs || [])}
                        pagination={false}
                        selectableRows
                        onSelectedRowsChange={(selectedRows) => {
                          const tmpSelectedBriefIds = _get(selectedRows, "selectedRows", []).map(row => { return row.id });

                          this.setState({ selectedBriefs: _get(selectedRows, "selectedRows", []), selectedBriefIds: tmpSelectedBriefIds });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-auto">
                  <div className="col-lg-12">
                    <div className="d-flex align-items-center justify-content-between gap-4 mt-4">
                      <button className="btn btn-primary text-capitalize" onClick={() => { if (typeof onPreviousStep === "function") { onPreviousStep() } }}>
                        <FormattedMessage id="btn.back" defaultMessage="back" />
                      </button>
                      <button className="btn btn-primary" onClick={() => { if (typeof onNextStep === "function") { onNextStep(); } }}>
                        <FormattedMessage id="btn.next" defaultMessage="next" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddEditBrief
          setState={(args) => this.setState(args)}
          selectedBriefIds={(selectedBriefIds || [])}
          selectedBrief={(selectedBriefs || [])}
          isEditBrief={(isEditBrief || false)}
          showBriefAddEditModal={(showBriefAddEditModal || false)}
          campaignId={(campaignId || 0)}
        />
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  common: _get(state, "campaignSetup.summary.common", {}),
  briefs: _get(state, "campaignSetup.briefs.briefs", []),
  setupErrors: _get(state, "campaignSetup.setupErrors", {}),
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "dd/MM/yyyy"),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  fetchCampaignBriefs: (data) => dispatch(fetchCampaignBriefs(data)),
  resetCampaignSetupError: () => dispatch(resetCampaignSetupError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaignBrief));
