import React, { Component, Fragment } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual, debounce as _debounce, isArray as _isArray } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";

import { doGetClientList, clearListError } from "../../../../../redux/actions/userMangement";

import { RenderTableCellValue, RenderTableHeaderValue, showAlertMessage, ConfirmBox, Select } from "../../../../../resusableComponents/common/formElements";
import { hasAccess } from "../../../../../resusableComponents/hoc/AccessManagement";
import ReactDataTable from "../../../../../resusableComponents/common/ReactDataTable";
import EditClient from "./EditClient";

import { doGetAgencyList } from "../../../../../redux/services/userMangement";
import { deleteClient } from "../../../../../redux/services/userMangement";

class ClientList extends Component {

  constructor(props) {
    super(props);

    const { intl } = props;

    this.defaultClientListPayload = {
      page_no: 1,
      page_size: 10,
      search_text: "",
      dropdown_flag: 0
    }

    this.state = {
      clientListPayload: this.defaultClientListPayload,
      clientTableData: [],
      selectedClient: {},
      showEditClientModal: false,
      showDeleteClientConfirmBox: false,
      reloadClientList: false,
      selectedAgency: null,
      agencyListDropDownData: [],
    };

    this.searchDebounce = _debounce(this._handleSearchClient, 1000);

    this.clientTableColumns = [
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.client.list.table.column_name", defaultMessage: "name" })} />),
        selector: (row) => _get(row, "client_name", ""),
        cell: (row) => {

          /*if (_get(row, "client_logo", null) !== null) {
            return (
              <Fragment>
                <img src={_get(row, "client_logo", "")} className="img-fluid me-5" width="50" alt="" />
                <RenderTableCellValue value={_get(row, "client_name", "")} tooltip={true} />
              </Fragment>
            );
          } else {
            return (<RenderTableCellValue value={_get(row, "client_name", "")} tooltip={true} />);
          }*/

          return (
            <Fragment>
              <img className="img-fluid me-5" width="30" src={`https://logo.clearbit.com/${_get(row, "website", "")}`} alt="" />
              <RenderTableCellValue className="text-truncate" value={_get(row, "client_name", "")} tooltip={true} />
            </Fragment>
          );
        },
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.client.list.table.column_agency", defaultMessage: "agency" })} />),
        selector: (row) => (_get(row, "agency_name", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "agency_name", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.client.list.table.column_website", defaultMessage: "website" })} />),
        selector: (row) => (_get(row, "website", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "website", "")} tooltip={true} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.client.list.table.column_deleted", defaultMessage: "deleted" })} />),
        selector: (row) => (_get(row, "deleted", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "deleted", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.client.list.table.column_action", defaultMessage: "action" })} />),
        selector: (row) => {

          return hasAccess("admin", "client-management", ["update-client", "delete-client"], "OR") ? (
            <Dropdown>
              <Dropdown.Toggle as="a" variant="link" className="fw-normal dropdown-toggle-no cursor-pointer">
                <img src={require("../../../../../assets/icons/icon-three-dot.svg").default} className="img-fluid" alt="img" />
              </Dropdown.Toggle>

              <Dropdown.Menu as="ul" className="dropdown-menu">
                {hasAccess("admin", "client-management", ["update-client"]) && (
                  <Dropdown.Item className="dropdown-item d-block waves-effect text-capitalize" onClick={() => this.setState({ showEditClientModal: true, selectedClient: row })}>
                    <FormattedMessage id="user_management.client.list.action.edit" defaultMessage="edit" />
                  </Dropdown.Item>
                )}
                {hasAccess("admin", "client-management", ["delete-client"]) && (
                  <Dropdown.Item className="dropdown-item d-block waves-effect text-capitalize" onClick={() => this.setState({ showDeleteClientConfirmBox: true, selectedClient: row })}>
                    <FormattedMessage id="user_management.client.list.action.delete" defaultMessage="delete" />
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : (<></>)
        },
        sortable: false,
        width: "80px",
        center: "true",
      },
    ];
  }

  componentDidMount() {
    this.fetchClientList();
    this._fetchAgencyList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevProps.errorList, this.props.errorList) && !_isEmpty(_get(this.props, "errorList.message", ""))) {
      const { errorList, clearListError } = this.props;

      showAlertMessage(_get(errorList, "message", "Something went wrong while fetching client list"));

      if (typeof clearListError === "function") { clearListError(); }
    }

    if (!_isEqual(prevState.reloadClientList, this.state.reloadClientList) && (_get(this.state, "reloadClientList", false) === true)) {
      this.fetchClientList();
      this.setState({ reloadClientList: false });
    }

    if (!_isEqual(prevState.clientListPayload, this.state.clientListPayload)) {
      this.fetchClientList();
    }
  }

  fetchClientList = () => {
    const { doGetClientList } = this.props;
    const { clientListPayload } = this.state;

    if (typeof doGetClientList === "function") { doGetClientList(clientListPayload); }
  }

  onDeleteClient = async () => {
    const { /*updateLoadingState,*/ intl } = this.props;
    const { selectedClient } = this.state;

    if (_isEmpty(selectedClient) || (_get(selectedClient, "id", 0) === 0)) {
      showAlertMessage(intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "client", defaultMessage: "client" })
      }));
      return false;
    }

    try {
      //if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await deleteClient(_get(selectedClient, "id", 0));

      if ((response.flag || false) === true) {

        showAlertMessage(_get(response, "data.responseMessage", "Client deleted successfully."), "success");

        this.fetchClientList();
      } else {

        showAlertMessage(_get(response, "data.responseMessage", "Something went wrong while deleting client."));
      }

      this.setState({ selectedClient: {}, showDeleteClientConfirmBox: false });
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while deleting client.");
    } finally {
      //if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _fetchAgencyList = async () => {
    const { updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetAgencyList({ agency_id: null, search_text: null, dropdown_flag: 1 });

      if ((response.flag || false) === true) {
        this.setState({ agencyListDropDownData: _get(response, "data.responseData.agency_list", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching agency list.")));
        this.setState({ agencyListDropDownData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching agency list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _handleSearchClient = (searchText) => {
    const { clientListPayload } = this.state;

    this.setState({ clientListPayload: { ...clientListPayload, search_text: searchText } });
  }

  handlePaginationChange = (pageNo) => {
    const { clientListPayload } = this.state;

    this.setState({ clientListPayload: { ...clientListPayload, page_no: pageNo } });
  }

  render() {
    const { clientListData, intl } = this.props;
    const { selectedClient, showDeleteClientConfirmBox, showEditClientModal, selectedAgency, agencyListDropDownData } = this.state;
    let agencyArray = [{ id: "", agency_name: "All" }]

    if (_isArray(agencyListDropDownData) && (agencyListDropDownData || []).length !== 0) {
      agencyListDropDownData.map((d) => agencyArray.push({ id: (d.id || null), agency_name: (d.agency_name || "") }))
    }

    return (
      <>
        {hasAccess("admin", "client-management", ["client-list"]) && (
          <>
            <table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-group w-auto">
                          <input type="text" className="form-control" placeholder="Search ..." onChange={(e) => this.searchDebounce(_get(e, "target.value", ""))} />
                          <span className="input-group-text bg-primary waves-effect py-1 d-flex align-items-center">
                            <img src={require("../../../../../assets/icons/icon-search-sm.svg").default} className="img-fluid" alt="" />
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <Select
                          className="form-custom-select"
                          placeholder={intl.formatMessage({ id: "user_management.client.agency", defaultMessage: "Agency" })}
                          options={(agencyArray || [])}
                          value={(selectedAgency || null)}
                          getOptionLabel={(option) => (option.agency_name || null)}
                          getOptionValue={(option) => (option.id || null)}
                          onChange={(selected) => this.setState(prevState => ({
                            clientListPayload: {
                              ...prevState.clientListPayload,
                              agency_id: _get(selected, "id", null)
                            },
                            selectedAgency: selected
                          }))}
                          isMulti={false}
                        />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>

            <ReactDataTable
              columns={this.clientTableColumns}
              data={_get(clientListData, "client_list", [])}
              pagination={true}
              totalRecords={_get(clientListData, "client_list_info[0].total_records", 10)}
              onChangePage={(e) => this.handlePaginationChange(e)}
            />
          </>
        )}

        {hasAccess("admin", "client-management", ["delete-client"]) && (
          <ConfirmBox
            onConfirm={() => this.onDeleteClient()}
            isOpen={(showDeleteClientConfirmBox || false)}
            onClose={() => this.setState({ showDeleteClientConfirmBox: false })}
            content={intl.formatMessage({ id: "confirm.are_you_sure_delete", defaultMessage: "Are you sure want to delete {field}?" }, { field: _get(selectedClient, "client_name", "Client") })}
          />
        )}

        {hasAccess("admin", "client-management", ["update-client"]) && (
          <EditClient
            setState={(args) => this.setState(args)}
            selectedClientObj={(selectedClient || {})}
            showEditClientModal={(showEditClientModal || false)}
          />
        )}
      </>
    );
  };
};

const mapStateToProps = (state) => ({
  errorList: _get(state, "userManagement.errorList", {}),
  clientListData: _get(state, "userManagement.clientList.responseData", []),
});

const mapDispatchToProps = (dispatch) => ({
  doGetClientList: (data) => dispatch(doGetClientList(data)),
  clearListError: () => dispatch(clearListError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ClientList));
