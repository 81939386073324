import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import moment from "moment";

import { showAlertMessage, HighChart, EmptyLabel } from "../../resusableComponents/common/formElements";
import { hasAccess } from "../../resusableComponents/hoc/AccessManagement";
import { withRouter } from "../../resusableComponents/hoc/withRouter";
import MarkAsButton from "../../resusableComponents/common/MarkAsButton";

import { fetchCampaignDashboardData, clearCampaignDashboardError } from "../../redux/actions/campaign";

class CampaignDashboard extends Component {

  constructor(props) {
    super(props);

    this.trackerChartOptions = { title: { text: null }, series: [], xAxis: { categories: [] }, yAxis: { categories: [] }, credits: { enabled: false } };

    this.state = {
      campaignDetails: {},
      tilesData: {},
      campaignId: _get(this.props, "params.id", 0),
      trackerChartOptions: { ...this.trackerChartOptions },
      reloadCampaignDashboard: false
    }
  }

  componentDidMount() {
    const { fetchCampaignDashboardData } = this.props;
    const { campaignId } = this.state;

    if (typeof fetchCampaignDashboardData === "function") {
      fetchCampaignDashboardData(campaignId || 0);
    }
    this._setDashboardData();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.dashboardData, this.props.dashboardData) && !_isEmpty(this.props.dashboardData)) {
      const { dashboardData, clearCampaignDashboardError } = this.props;
      const responseFlag = _get(dashboardData, "flag", null);

      if (responseFlag === null) { return false; }

      if (responseFlag === true) {
        showAlertMessage(_get(dashboardData, "message", "Campaign dashboard data fetched successfully."), "success");

        this._setDashboardData();
      } else if (responseFlag === false) {
        showAlertMessage(_get(dashboardData, "message", "Something went wrong while fetching campaign dashboard data."));
      }

      if (typeof clearCampaignDashboardError === "function") { clearCampaignDashboardError(); }
    }

    if (!_isEqual(prevState.reloadCampaignDashboard, this.state.reloadCampaignDashboard) && (_get(this.state, "reloadCampaignDashboard", false) === true)) {
      const { fetchCampaignDashboardData } = this.props;
      const { campaignId } = this.state;

      if (typeof fetchCampaignDashboardData === "function") {
        fetchCampaignDashboardData(campaignId || 0);
      }
      this.setState({ reloadCampaignDashboard: false });
    }

  }

  _setDashboardData = () => {
    const { dashboardData, campaignTrackerDisplayDateFormat } = this.props;
    const { trackerChartOptions } = this.state;

    const trackerOptions = {
      ...trackerChartOptions,
      series: [
        {
          name: "Target Leads",
          color: "#5b49b9",
          data: _get(dashboardData, "data.campaign_tracker.target_leads", [])
        },
        {
          name: "Approved Leads",
          color: "#61C4D9",
          data: _get(dashboardData, "data.campaign_tracker.approve_leads", [])
        }
      ],
      xAxis: {
        categories: _get(dashboardData, "data.campaign_tracker.xAxis.categories", []),
        labels: {
          align: "right",
          formatter: function () {
            return moment(this.value).format(campaignTrackerDisplayDateFormat);
          }
        }
      },
      yAxis: { min: 0, showEmpty: false, tickInterval: 50 },
    };

    this.setState({
      campaignDetails: _get(dashboardData, "data.campaign_details[0]", {}),
      tilesData: _get(dashboardData, "data.campaign_numbers[0]", {}),
      trackerChartOptions: trackerOptions
    });
  };

  _renderTiles = () => {
    const { tilesData, campaignId } = this.state;

    const hasApproveLeadLinkPermission = hasAccess("campaigns", "dashboard", ["approved-leads-link"]);
    const hasRejectedLeadLinkPermission = hasAccess("campaigns", "dashboard", ["rejected-leads-link"]);
    const hasPendingLeadLinkPermission = hasAccess("campaigns", "dashboard", ["pending-leads-link"]);

    return (
      <div className="row gy-4 mt-2">
        <div className="col-lg-3">
          <div className="card-static border-blue-55">
            <h6 className="text-blue-55">{_get(tilesData, "complete_percent", 0)}</h6>
            <p className="text-capitalize"><FormattedMessage id="campaign_dashboard.tiles.completed" defaultMessage="completed" /></p>
          </div>
        </div>
        <div className="col-lg-3">
          <Link to={(hasApproveLeadLinkPermission) ? `/campaigns/approved-leads/${(campaignId || null)}` : "#"}>
            <div className="card-static border-success">
              <h6 className="text-success">{_get(tilesData, "approved_leads", 0)}</h6>
              <p className="text-capitalize"><FormattedMessage id="campaign_dashboard.tiles.approved_leads" defaultMessage="approved leads" /></p>
            </div>
          </Link>
        </div>
        <div className="col-lg-3">
          <Link to={(hasRejectedLeadLinkPermission) ? `/campaigns/rejected-leads/${(campaignId || null)}` : "#"}>
            <div className="card-static border-danger">
              <h6 className="text-danger">{_get(tilesData, "rejected_leads", 0)}</h6>
              <p className="text-capitalize"><FormattedMessage id="campaign_dashboard.tiles.rejected_leads" defaultMessage="rejected leads" /></p>
            </div>
          </Link>
        </div>
        <div className="col-lg-3">
          <Link to={(hasPendingLeadLinkPermission) ? `/campaigns/pending-leads/${(campaignId || null)}` : "#"}>
            <div className="card-static border-warning">
              <h6 className="text-warning">{_get(tilesData, "pending_leads", 0)}</h6>
              <p className="text-capitalize"><FormattedMessage id="campaign_dashboard.tiles.pending_leads" defaultMessage="pending leads" /></p>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  _renderCampaignButtons = () => {
    const { campaignId } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="d-flex flex-wrap gap-3 align-items-center">
            {(hasAccess("campaigns", "dashboard", ["edit-campaign"])) && (
              <Link to={`/campaigns/edit/${campaignId}`} className="btn btn-outline-primary text-capitalize waves-effect">
                <FormattedMessage id="campaign_dashboard.btn.edit_campaign" defaultMessage="edit campaign" />
              </Link>
            )}

            {(hasAccess("campaigns", "dashboard", ["campaign-content"])) && (
              <Link to={`/campaigns/content/${campaignId}`} className="btn btn-outline-primary text-capitalize waves-effect">
                <FormattedMessage id="campaign_dashboard.btn.campaign_content" defaultMessage="campaign content" />
              </Link>
            )}

            {(hasAccess("campaigns", "dashboard", ["upload-leads"])) && (
              <Link to={`/campaigns/upload-lead/${campaignId}`} className="btn btn-outline-primary text-capitalize waves-effect">
                <FormattedMessage id="campaign_dashboard.btn.upload_leads" defaultMessage="upload leads" />
              </Link>
            )}

            {(hasAccess("campaigns", "dashboard", ["data-history"])) && (
              <Link to={`/campaigns/data-history/${campaignId}`} className="btn btn-outline-primary waves-effect">
                <FormattedMessage id="campaign_dashboard.btn.data_history" defaultMessage="data history" />
              </Link>
            )}

            {(hasAccess("campaigns", "dashboard", ["view-leads"])) && (
              <Link to={`/campaigns/view-leads/${campaignId}`} className="btn btn-outline-primary text-capitalize waves-effect">
                <FormattedMessage id="campaign_dashboard.btn.view_leads" defaultMessage="view leads" />
              </Link>
            )}

            {(hasAccess("campaigns", "dashboard", ["mark-as-complete"])) && (
              <MarkAsButton
                className="btn btn-outline-primary waves-effect"
                buttonId="campaign_list.button.mark_as_complete"
                buttonDefultMessage="mark as complete"
                operationStatus="complete"
                selectedCampaignIds={[campaignId]}
                source="campaign_dashboard"
                setState={(args) => this.setState(args)}
              />
            )}

            {(hasAccess("campaigns", "dashboard", ["mark-as-live"])) && (
              <MarkAsButton
                className="btn btn-outline-primary waves-effect"
                buttonId="campaign_list.button.mark_as_live"
                buttonDefultMessage="mark as live"
                operationStatus="live"
                selectedCampaignIds={[campaignId]}
                source="campaign_dashboard"
                setState={(args) => this.setState(args)}
              />
            )}

          </div>
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { campaignDetails, trackerChartOptions } = this.state;

    const logoURL = (_get(campaignDetails, "client_website", "")) ? `https://logo.clearbit.com/${_get(campaignDetails, "client_website", "")}` : require("../../assets/images/no-img-icon.png");

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "hermes.campaign_dashboard", defaultMessage: "Campaign Dashboard: Hermes" })} </title>
        </Helmet>

        <div className="container-fluid flex-grow-1 px-6 py-6">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center gap-5 py-3 px-4">
                    <img className="img-fluid me-5" width="30" src={(logoURL || "")} alt="" />
                    <p className="heading-04 text-uppercase">
                      {_get(campaignDetails, "campaign_name", <EmptyLabel />)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this._renderTiles()}

          <div className="row mt-5">
            <div className="col-lg-12">
              <h1 className="heading-04 fw-semibold text-dark-35 text-uppercase mb-5">
                <FormattedMessage id="campaign_dashboard.title" defaultMessage="dashboard" />
              </h1>
            </div>
          </div>

          {this._renderCampaignButtons()}

          {(hasAccess("campaigns", "dashboard", ["page-view"])) && (
            <div className="row mt-6">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h3 className="heading-04 mt-2 mb-4 fw-semibold text-capitalize">
                      <FormattedMessage id="campaign_dashboard.campaign_tracker" defaultMessage="campaign tracker" />
                    </h3>
                    <div id="highchartCampaignTracker">
                      <HighChart options={(trackerChartOptions || {})} />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  dashboardData: _get(state, "campaign.dashboard", {}),
  campaignTrackerDisplayDateFormat: _get(state, "application.constants.campaignTrackerDisplayDateFormat", "DD MMM YY"),
});

const mapDispatchToProps = (dispatch) => ({
  clearCampaignDashboardError: () => dispatch(clearCampaignDashboardError()),
  fetchCampaignDashboardData: (data) => dispatch(fetchCampaignDashboardData(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaignDashboard)));
