import React, { Component } from "react";
import { forEach as _forEach, get as _get, map as _map, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { matchPath, Link } from "react-router-dom";
import { connect } from "react-redux";

import { withRouter } from "../../../resusableComponents/hoc/withRouter";

import { applicationRoutes, CAMPAIGN_SETUP_ROUTE } from "../../../helpers/constants";
import { ucfirst } from "../../../helpers/utils";

import { resetCampaignSetup } from "../../../redux/actions/campaignSetup";

class Sidebar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentMenu: "dashboard",
      currentSubMenu: ""
    };
  }

  componentDidMount() {
    this._setCurrentActiveMenu();
  }

  componentDidUpdate(prevProps) {

    if (!_isEqual(_get(prevProps, "location.pathname", "/"), _get(this.props, "location.pathname", "/"))) {
      this._setCurrentActiveMenu();
    }
  }

  // Set the active menu based on current route of url || passed from props state
  _setCurrentActiveMenu = () => {
    const { location } = this.props;

    let currentMenu = "";
    let currentSubMenu = "";
    const route = _get(location, "pathname", "/");

    // Check if history has active menu state
    const menuState = _get(location, "state.currentMenu", {});

    if (Object.keys(menuState).length > 0) {
      currentMenu = (menuState.group || "");
      currentSubMenu = (menuState.name || "");
    } else {
      // Match with current url route
      _forEach(applicationRoutes, (r) => {
        const path = (r.path || "");
        const exact = (r.exact || null);
        const matched = matchPath({ path, exact, strict: false }, route);

        // Break the loop and get the menu
        if (matched) {
          currentMenu = _get(r, "currentMenu.group", "");
          currentSubMenu = _get(r, "currentMenu.name", "");
          return false;
        }
      });
    }

    this.setState({ currentMenu, currentSubMenu });
  }

  // Keep parent menu as open
  _handleActiveParentMenu = (e, mainMenu) => {
    e.preventDefault();
    const { currentMenu } = this.state;
    const setActiveMenu = ((mainMenu || "") === (currentMenu || "")) ? "" : (mainMenu || "");

    this.setState({ currentMenu: setActiveMenu });
  }

  _redirectToPage = (routePath = "/") => {
    const { location, resetCampaignSetup, navigate, currentCampaignSetupStep, intl } = this.props;
    const fromURL = _get(location, "pathname", "");

    const isFromCampaignSetup = matchPath({ path: `/${CAMPAIGN_SETUP_ROUTE}`, exact: true, strict: true }, fromURL);

    // Reset redux store if user click on same campaign setup menu
    if ((isFromCampaignSetup !== null) && (currentCampaignSetupStep > 1)) {
      const confirmed = window.confirm(intl.formatMessage({ id: "confirm.changes_you_made_may_not_be_saved", defaultMessage: "Changes you made may not be saved." }));

      if ((confirmed === true) && (typeof resetCampaignSetup === "function")) { resetCampaignSetup(); }
      if ((confirmed === true) && (typeof navigate === "function")) { navigate((routePath || "")); }
    }

    if ((typeof navigate === "function") && ((isFromCampaignSetup === null) || (isFromCampaignSetup !== null && currentCampaignSetupStep === 1))) {
      // Redirect to normal URL
      navigate({ "pathname": (routePath || "") });
    }
  };

  _renderLogo = () => {
    return (
      <div className="sidebar-brand">
        <Link to="/" className="sidebar-brand-link">
          <span className="sidebar-brand-logo">
            <img src={require("../../../assets/images/logo-white.svg").default} className="img-fluid" alt="" />
          </span>
        </Link>
      </div>
    );
  }

  render() {
    const { menuConfig, isNavbarVisible } = this.props;
    const { currentMenu, currentSubMenu } = this.state;

    const sidebarMenuConfiguration = { ...menuConfig };

    return (
      <aside id="sidebar-menu" className={`sidebar-menu ${(isNavbarVisible === true) ? "" : "active"}`}>
        {this._renderLogo()}

        <ul className="sidebar-menu-inner">
          {_map(sidebarMenuConfiguration, (conf, i) => {

            if (_isEmpty(conf)) { return null; }

            if ((conf.containSubMenu || false) === true) {
              const mainMenu = (
                <div
                  key={i}
                  className={`sidebar-menu-link sidebar-menu-toggle waves-effect cursor-pointer ${(i === currentMenu) ? "active" : ""}`}
                  onClick={(e) => this._handleActiveParentMenu(e, i)}
                >
                  <i className="sidebar-menu-icon">
                    <img src={require(`../../../assets/icons/${(conf.icon || "icon-setting.svg")}`)} className="img-fluid" alt="" />
                  </i>
                  <span className="sidebar-menu-text text-capitalize">
                    <FormattedMessage id={`menu.${(conf.menuLanguageKey || "")}`} defaultMessage={ucfirst(i)} />
                  </span>
                </div>
              );

              const childMenu = [];
              _map(conf.subMenus, (a, k) => {

                childMenu.push((
                  <li key={k} className="sidebar-menu-item cursor-pointer">
                    <div className={`sidebar-menu-link ${(a.name === currentSubMenu) ? "active" : ""}`} onClick={() => this._redirectToPage(`/${(a.route || "")}`)} >
                      <span className="sidebar-menu-text text-capitalize">
                        <FormattedMessage id={`menu.${(a.menuLanguageKey || "")}`} defaultMessage={ucfirst((a.menuLanguageKey || ""))} />
                      </span>
                    </div>
                  </li>
                ));
              });

              return (
                <li className="sidebar-menu-item sidebar-has-submenu" key={i}>
                  {mainMenu}
                  <ul className={`sidebar-menu-sub collapse ${(i === currentMenu) ? "show" : ""}`}>
                    {childMenu}
                  </ul>
                </li>
              );
            } else {

              let routeUrl = `/${(conf.route || "")}`;

              return (
                <li className="sidebar-menu-item" key={i}>
                  <div key={i} className={(`sidebar-menu-link cursor-pointer ${((conf.group || "") === currentMenu) ? "active" : ""}`)} onClick={() => this._redirectToPage(routeUrl)}>
                    <i className="sidebar-menu-icon">
                      <img src={require(`../../../assets/icons/${(conf.icon || "icon-setting.svg")}`)} className="img-fluid" alt="" />
                    </i>
                    <span className="sidebar-menu-text text-capitalize">
                      <FormattedMessage id={`menu.${(conf.menuLanguageKey || "")}`} defaultMessage={ucfirst(conf.menuLanguageKey || "")} />
                    </span>
                  </div>
                </li>
              );
            }
          })}

          <li className="sidebar-menu-item mt-auto">
            <Link className="sidebar-menu-link" to={{ pathname: "terms-and-conditions" }} target="_blank">
              <i className="sidebar-menu-icon">
                <img src={require("../../../assets/icons/icon-tnc.svg").default} className="img-fluid" alt="" />
              </i>
              <span className="sidebar-menu-text text-capitalize">
                <FormattedMessage id="menu.terms_and_conditions" defaultMessage="terms & conditions" />
              </span>
            </Link>
          </li>
        </ul>

      </aside>
    );
  }
}

const mapStateToProps = (state, props) => ({
  menuConfig: _get(state, "application.menuConfig", {}),
  currentCampaignSetupStep: _get(state, "campaignSetup.summary.common.currentStep", 1),
  isNavbarVisible: _get(state, "application.isNavbarVisible", false)
});

const mapDispatchToProps = (dispatch) => ({
  resetCampaignSetup: () => dispatch(resetCampaignSetup())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Sidebar)));
