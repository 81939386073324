import { call, fork, put, takeLatest } from "redux-saga/effects";
import { get as _get } from "lodash";

import { CHANGE_USER_PASSWORD_ERROR, CHANGE_USER_PASSWORD_REQUEST, CHANGE_USER_PASSWORD_SUCCESS } from "../actions/user";
import { UPDATE_APP_LOADING } from "../actions/application";

import { doPasswordChange } from "../services/user";

function* workerDoChangePassword(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(doPasswordChange, action.data);

    if ((response.flag || false) === true) {

      yield put({
        type: CHANGE_USER_PASSWORD_SUCCESS,
        data: {
          flag: true,
          data: _get(response, "data", {}),
          message: _get(response, "message", "Password changed successfully."),
        }
      });
    } else {
      yield put({
        type: CHANGE_USER_PASSWORD_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while changing password."),
        }
      });
    }
  } catch (error) {
    yield put({
      type: CHANGE_USER_PASSWORD_ERROR,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(
          error,
          "response.message",
          "Something went wrong while changing password."
        ),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}


function* watcherDoPasswordChange() {
  yield takeLatest(CHANGE_USER_PASSWORD_REQUEST, workerDoChangePassword);
}

const userSaga = [
  fork(watcherDoPasswordChange)
]

export default userSaga;
