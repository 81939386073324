// Updatee URI in redux action
export const UPDATE_URL = "UPDATE_URL";

// Set Authentication Token
export const AUTHENTICATION_SET_TOKEN = "AUTHENTICATION_SET_TOKEN";

// Login actions
export const AUTHENTICATION_LOGIN_REQUEST = "AUTHENTICATION_LOGIN_REQUEST";
export const AUTHENTICATION_LOGIN_SUCCESS = "AUTHENTICATION_LOGIN_SUCCESS";
export const AUTHENTICATION_LOGIN_ERROR = "AUTHENTICATION_LOGIN_ERROR";

// Delete authentication token actions
export const DELETE_AUTHENTICATION_TOKEN_REQUEST = "DELETE_AUTHENTICATION_TOKEN_REQUEST";
export const DELETE_AUTHENTICATION_TOKEN_SUCCESS = "DELETE_AUTHENTICATION_TOKEN_SUCCESS";

//Forgot Password Actions
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

// Reset Password Actions
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

// Reset Password Actions
export const CHECK_RESET_PASSWORD_CRITERIA = "CHECK_RESET_PASSWORD_CRITERIA";
export const RESET_PASSWORD_VALID = "RESET_PASSWORD_VALID";
export const RESET_PASSWORD_INVALID = "RESET_PASSWORD_INVALID";

// Clear Authentication actions
export const CLEAR_AUTHENTICATION_SUCCESS = "CLEAR_AUTHENTICATION_SUCCESS";

//Clear Authenction Error Actions
export const CLEAR_AUTHENTICATION_ERROR = "CLEAR_AUTHENTICATION_ERROR";

// Clear Forgot Password Error
export const CLEAR_FORGOT_PASSWORD_ERROR = "CLEAR_FORGOT_PASSWORD_ERROR";

// Clear Forgot Password Response
export const CLEAR_FORGOT_PASSWORD_RESPONSE = "CLEAR_FORGOT_PASSWORD_RESPONSE";

// Clear Check Reset Password Criteria Response
export const CLEAR_CHECK_RESET_PASSWORD_RESPONSE = "CLEAR_CHECK_RESET_PASSWORD_RESPONSE";

// Clear Reset
export const CLEAR_RESET_PASSWORD_RESPONSE = "CLEAR_RESET_PASSWORD_RESPONSE";

// Update user details
export const UPDATE_USER_AUTHENTICATION_DATA = "UPDATE_USER_AUTHENTICATION_DATA";

export const updateUrl = (uri) => {
  return ({
    type: UPDATE_URL,
    uri
  });
}

export const setAuthenticationToken = (userToken) => {
  return ({
    type: AUTHENTICATION_SET_TOKEN,
    userToken
  });
}

export const doLogin = (data) => {
  return ({
    type: AUTHENTICATION_LOGIN_REQUEST,
    data
  });
}

export const clearAuthenticationError = () => {
  return ({
    type: CLEAR_AUTHENTICATION_ERROR
  });
}

export const clearAuthenticationSuccess = () => {
  return ({
    type: CLEAR_AUTHENTICATION_SUCCESS
  });
}


export const deleteAuthenticationToken = () => {
  return ({
    type: DELETE_AUTHENTICATION_TOKEN_REQUEST
  });
}

export const forgotPassowrd = (data) => {
  return ({
    type: FORGOT_PASSWORD_REQUEST,
    data
  });
}

export const clearForgotPasswordError = () => {
  return ({
    type: CLEAR_FORGOT_PASSWORD_ERROR
  });
}

export const clearForgotPasswordResponse = () => {
  return ({
    type: CLEAR_FORGOT_PASSWORD_RESPONSE
  });
}

export const checkIfResetPasswordTokenValid = (data) => {
  return ({
    type: CHECK_RESET_PASSWORD_CRITERIA,
    data
  });
}

export const clearResetPasswordTokenValidResponse = () => {
  return ({
    type: CLEAR_CHECK_RESET_PASSWORD_RESPONSE
  });
}

export const passwordReset = (data) => {
  return ({
    type: RESET_PASSWORD_REQUEST,
    data
  });
}

export const clearResetPasswordResponse = () => {
  return ({
    type: CLEAR_RESET_PASSWORD_RESPONSE
  });
}

// Update user details
export const updateUserAuthenticationData = (data) => {
  return ({
    type: UPDATE_USER_AUTHENTICATION_DATA,
    data
  });
}
