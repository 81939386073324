import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { get as _get } from "lodash";

import { showAlertMessage } from "../../../resusableComponents/common/formElements";
import { hasAccess } from "../../../resusableComponents/hoc/AccessManagement";

import { doGetRoleList } from "../../../redux/services/userMangement";

import CreateNewClient from "./component/Client/CreateNewClient";
import CreateNewAgency from "./component/Agency/CreateNewAgency";
import UserManagementTab from "./component/UserManagementTab";
import CreateNewUser from "./component/User/CreateNewUser";
import ClientList from "./component/Client/ClientList";
import AgencyList from "./component/Agency/AgencyList";
import UserList from "./component/User/UserList";
import Helmet from "react-helmet";

class UserManagement extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: (hasAccess("admin", "user-management", ["page-view"])) ? "User" : ((hasAccess("admin", "agency-management", ["page-view"])) ? "Agency" : "Client"),
      userRolesDropDownData: [],
    };

  };

  componentDidMount() {
    this._fetchUserTypeList();
  }

  handleTabChange(tab) {
    this.setState({ activeTab: tab });
  }

  _fetchUserTypeList = async () => {

    try {
      const response = await doGetRoleList();

      if ((response.flag || false) === true) {

        this.setState({ userRolesDropDownData: _get(response, "data.responseData", []) });
      } else {

        showAlertMessage((_get(response, "message", "Something went wrong while fetching user types.")));
        this.setState({ userRolesDropDownData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching user types.")));
    }
  };

  _renderActiveTabContent = () => {
    const { activeTab, userRolesDropDownData } = this.state;

    if ((activeTab === "User") && hasAccess("admin", "user-management", ["create-new-user"])) {
      return <CreateNewUser userRolesDropDownData={(userRolesDropDownData || [])} />;
    }

    if ((activeTab === "Agency") && hasAccess("admin", "agency-management", ["create-agency"])) {
      return <CreateNewAgency />;
    }

    if ((activeTab === "Client") && hasAccess("admin", "client-management", ["create-client"])) {
      return <CreateNewClient />;
    }
  }

  _renderActiveTabListContent = () => {
    const { activeTab, userRolesDropDownData } = this.state;

    if ((activeTab === "User") && hasAccess("admin", "user-management", ["user-list"])) {
      return <UserList userRolesDropDownData={(userRolesDropDownData || [])} />;
    }

    if ((activeTab === "Agency") && hasAccess("admin", "agency-management", ["agency-list"])) {
      return <AgencyList />;
    }

    if ((activeTab === "Client") && hasAccess("admin", "client-management", ["client-list"])) {
      return <ClientList />;
    }
  };

  _renderView = () => {
    const { activeTab } = this.state;

    return (
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 px-6 py-6">
          <div className="row">
            <div className="col-lg-12">

              <h1 className="heading-04 fw-semibold text-dark-35 text-uppercase mb-5">
                <FormattedMessage id="user_management.title" defaultMessage="User Management" />
              </h1>

            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">

                  <UserManagementTab onTabChange={(tab) => this.handleTabChange(tab)} activeTab={(activeTab || "User")} />

                  <h2 className="heading-04 fw-semibold text-dark-35 mb-8 text-capitalize">
                    <FormattedMessage id="user_management.create_new_field" defaultMessage="Create New {field}" values={{ field: activeTab }} />
                  </h2>

                  {this._renderActiveTabContent()}

                </div>
              </div>
            </div>
          </div>

          <div className="row mt-8">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h3 className="heading-04 fw-semibold text-dark-35 mb-5">
                    {`${activeTab} List`}
                  </h3>

                  {this._renderActiveTabListContent()}

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  };

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title>
            {intl.formatMessage({ id: "user_management.title", defaultMessage: "User Management" })}
          </title>
        </Helmet>

        {this._renderView()}

      </>
    );
  }
}

export default injectIntl(UserManagement);
