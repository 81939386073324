import React, { Component } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEmpty as _isEmpty, isEqual as _isEqual, map as _map } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { Select, showAlertMessage, ErrorMessage } from "../../../../resusableComponents/common/formElements";
import CampaignSetupProgress from "../CampaignSetupProgress";
import UploadNewAsset from "./UploadNewAsset";

import { updateCampaignSetupDetails, assignAssetCampaign, removeAssignedAssets, initiateAssetCampaign, resetCampaignSetupError } from "../../../../redux/actions/campaignSetup";
import { getAssetsDetails, doGetTopicList, doGetSubTopicList } from "../../../../redux/services/campaignSetup";
import { updateLoadingState } from "../../../../redux/actions/application";

const radioButtonList = [
  { id: "none", labelId: "campaign_setup.assets.none", defaultMessage: "none", defaultValue: "none", defaultChecked: true },
  { id: "useExisting", labelId: "campaign_setup.assets.use_existing", defaultMessage: "use existing", defaultValue: "useExisting" },
  { id: "uploadNew", labelId: "campaign_setup.assets.upload_new", defaultMessage: "upload new", defaultValue: "uploadNew" },
  { id: "physicalEvent", labelId: "campaign_setup.assets.physical_event", defaultMessage: "physical event", defaultValue: "physicalEvent" }
];

class Assets extends Component {

  constructor(props) {
    super(props);

    this.defultState = {
      searchAssets: null,
      physicalName: "",
      assetCode: "",
      assetTypeId: 6,
      physicalTopic: null,
      physicalSubTopic: null
    }

    this.state = {
      formData: _cloneDeep(this.defultState),
      selectedOption: "none",
      oldSelectedOption: "none",
      searchAssetsDropDownData: [],
      physicalTopicDropDownData: [],
      physicalSubTopicDropDownData: [],
      isFormSubmitted: false,
      isEditMode: false,
      formErrors: {},
    };
  }

  componentDidMount() {
    const { summary, summary: { common: { lastFilledStep } }, currentStep } = this.props;

    //Set default or existing values
    this.setState({
      selectedOption: _get(summary, `${currentStep}.selectedOption`, "none"),
      oldSelectedOption: _get(summary, `${currentStep}.oldSelectedOption`, "none"),
      formData: _get(summary, `${currentStep}.formData`, _cloneDeep(this.defultState)),
      isEditMode: (lastFilledStep > currentStep) ? true : false
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevState.selectedOption, _get(this.state, "selectedOption", "none")) && (_get(this.state, "selectedOption", "none") === "useExisting")) {
      this._getAssetsDetails();
    }

    if (!_isEqual(prevState.selectedOption, _get(this.state, "selectedOption", "none")) && (_get(this.state, "selectedOption", "none") === "physicalEvent")) {
      this._getTopicList();
    }

    if (!_isEqual(_get(prevState, "formData.physicalTopic", null), _get(this.state, "formData.physicalTopic", null))) {

      if ((_get(this.state, "formData.physicalTopic", null)) && (_get(this.state, "formData.physicalTopic.id", null) !== null)) {
        this._getSubTopicList();
      } else {
        this.setState({ physicalSubTopicDropDownData: [] });
      }
    }

    if (!_isEqual(prevState.formData, this.state.formData) && (_get(this.state, "isFormSubmitted", false) === true)) {
      this._handleValidation();
    }

    if (!_isEqual(prevProps.setupErrors, this.props.setupErrors) && !_isEmpty(this.props.setupErrors)) {
      showAlertMessage(_get(this.props, "setupErrors.message", ""));

      if (typeof this.props.resetCampaignSetupError === "function") { this.props.resetCampaignSetupError(); }
    }
  };

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { selectedOption, formData: { searchAssets, physicalName, assetCode, physicalTopic, physicalSubTopic } } = this.state;
    let formErrors = {};

    if (selectedOption === "useExisting") {

      if (_isEmpty(searchAssets)) {
        formErrors["searchAssets"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." },
          { field: intl.formatMessage({ id: "campaign_setup.assets.assets", defaultMessage: "Assets" }) });
      }
    } else if (selectedOption === "physicalEvent") {

      if (_isEmpty(physicalName)) {
        formErrors["physicalName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." },
          { field: intl.formatMessage({ id: "campaign_setup.assets.name", defaultMessage: "name" }) });
      }

      if (_isEmpty(assetCode)) {
        formErrors["assetCode"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." },
          { field: intl.formatMessage({ id: "campaign_setup.assets.asset_code", defaultMessage: "asset code" }) });
      }

      if (_isEmpty(physicalTopic)) {
        formErrors["physicalTopic"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." },
          { field: intl.formatMessage({ id: "campaign_setup.assets.topic", defaultMessage: "topic" }) });
      }

      if (_isEmpty(physicalSubTopic)) {
        formErrors["physicalSubTopic"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." },
          { field: intl.formatMessage({ id: "campaign_setup.assets.sub_topic", defaultMessage: "sub topic" }) });
      }
    }

    this.setState({ formErrors });

    if (returnFlag) {
      return (!_isEmpty(formErrors)) ? false : true;
    }
  }

  handleChange = (keyName, keyValue) => {
    this.setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [keyName]: keyValue,
      }
    }));
  }

  handleNext = () => {
    const { updateCampaignSetupDetails, assignAssetCampaign, removeAssignedAssets, initiateAssetCampaign,
      common, common: { clientId, currentStep, lastFilledStep, campaignId }, intl } = this.props;
    const { formErrors, selectedOption, oldSelectedOption, formData, formData: { searchAssets, physicalName, assetCode, assetTypeId, physicalTopic, physicalSubTopic }, isEditMode } = this.state;

    if (!_isEmpty(formErrors)) { return false; }

    if (!_isEmpty(formErrors) || (selectedOption === "uploadNew")) {
      showAlertMessage(intl.formatMessage({ id: "error.please_fill_up_the_proper_details", defaultMessage: "Please fill up the proper details." }));
      return false;
    }

    this.setState({ isFormSubmitted: true });

    const isValidForm = this._handleValidation(true);

    if (!isValidForm) { return false; }

    const variable = {
      "common": {
        ...common,
        currentStep: (currentStep + 1),
        lastFilledStep: (lastFilledStep > currentStep) ? lastFilledStep : (currentStep + 1),
      },
      [currentStep]: {
        summaryText: intl.formatMessage({ id: "campaign_setup.assets.title", defaultMessage: "assets" }),
        selectedOption: selectedOption,
        oldSelectedOption: selectedOption,
        formData: formData
      }
    }

    if (
      (selectedOption === "none") &&
      (isEditMode === true) &&
      (selectedOption !== oldSelectedOption) &&
      (typeof removeAssignedAssets === "function")
    ) {
      const payload = Object.assign({}, variable, { "payload": { campaign_id: campaignId } });

      removeAssignedAssets(payload);
    } else if ((["none", "physicalEvent"].includes(selectedOption)) && (selectedOption === oldSelectedOption) && (typeof updateCampaignSetupDetails === "function")) {

      updateCampaignSetupDetails(variable);
    } else if ((["useExisting"].includes(selectedOption)) && (typeof assignAssetCampaign === "function")) {

      const payload = Object.assign({}, variable, { "payload": { "asset_ids": _map(searchAssets, "id"), "campaign_id": campaignId } });

      assignAssetCampaign(payload);
    } else if ((["physicalEvent"].includes(selectedOption)) && (selectedOption !== oldSelectedOption) && (typeof initiateAssetCampaign === "function")) {

      const payload = Object.assign({}, variable, {
        "payload": {
          "asset_name": (physicalName || ""),
          "asset_code": (assetCode || ""),
          "topic_id": _get(physicalTopic, "id", null),
          "sub_topic_id": _get(physicalSubTopic, "id", null),
          "asset_url": "",
          "type_id": (assetTypeId || 6),
          "client_id": clientId,
          "campaign_id": campaignId
        }
      });

      initiateAssetCampaign(payload);
    }
  }

  _getAssetsDetails = async () => {
    const { common: { clientId }, updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getAssetsDetails(clientId, { dropdown_flag: 1 });

      if (_get(response, "flag", false) === true) {
        this.setState({ searchAssetsDropDownData: _get(response, "data", []) });
      } else {
        this.setState({ searchAssetsDropDownData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching assets details.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _getTopicList = async () => {
    const { updateLoadingState } = this.props;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetTopicList();

      if (_get(response, "flag", false) === true) {

        this.setState({ physicalTopicDropDownData: _get(response, "data", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching topic list.")));
        this.setState({ physicalTopicDropDownData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching topic list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _getSubTopicList = async () => {
    const { updateLoadingState } = this.props;
    const { formData: { physicalTopic } } = this.state;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetSubTopicList(physicalTopic.id);

      if (_get(response, "flag", false) === true) {
        this.setState({ physicalSubTopicDropDownData: _get(response, "data", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching sub topic list.")));
        this.setState({ physicalSubTopicDropDownData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching sub topic list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderRadioButton = ({ label, id, name, defaultValue, onChange }) => {
    const { selectedOption } = this.state;

    return (
      <div className="form-check form-check-inline">
        <input
          className="form-check-input show-data"
          type="radio"
          name={name}
          id={id}
          value={defaultValue}
          checked={selectedOption === defaultValue}
          onChange={onChange} />
        <label className="form-check-label text-capitalize" htmlFor={id}>{label}</label>
      </div>
    );
  }

  _renderTextFiled = ({ label, name, defaultValue, onChange, placeholder, isRequired = false, isDisabled = false }) => {

    return (
      <>
        <label className="form-label text-capitalize">
          {label}
          {(isRequired) && (<span className="text-danger">*</span>)}
        </label>
        <input
          type="text"
          className="form-control"
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
          disabled={isDisabled}
        />
      </>
    );
  }

  _renderUseExisting() {
    const { intl } = this.props;
    const { formData: { searchAssets }, formErrors, searchAssetsDropDownData } = this.state;

    return (
      <div className="col-lg-6">
        <div className="mb-6">
          <label className="form-label text-capitalize" >
            <FormattedMessage id="campaign_setup.assets.search_assets" defaultMessage="search assets" />
            <span className="text-danger">*</span>
          </label>
          <Select
            className="form-custom-select"
            placeholder={intl.formatMessage({ id: "campaign_setup.assets.search_assets", defaultMessage: "search assets" })}
            options={(searchAssetsDropDownData || [])}
            value={(searchAssets || null)}
            getOptionLabel={(option) => (option.asset_name || "")}
            getOptionValue={(option) => (option.id || null)}
            onChange={(selected) => this.handleChange("searchAssets", selected)}
            isMulti={true}
          />
          {_get(formErrors, "searchAssets", "") && <ErrorMessage message={_get(formErrors, "searchAssets", "")} />}
        </div>
      </div>
    );
  }

  _renderUploadNew() {
    const { formData } = this.state;

    return (
      <div className="col-lg-6">
        <div className="mb-6">
          <UploadNewAsset setState={(args) => this.setState(args)} formData={(formData || {})} />
        </div>
      </div>
    );
  }

  _renderPhysicalEvent() {
    const { intl } = this.props;
    const {
      formErrors, physicalTopicDropDownData, physicalSubTopicDropDownData, selectedOption, oldSelectedOption, isEditMode,
      formData: { physicalName, assetCode, physicalTopic, physicalSubTopic }
    } = this.state;

    const isDisableOnEditMode = ((selectedOption === oldSelectedOption) && (isEditMode === true)) ? true : false;

    return (
      <>
        <div className="col-lg-6">
          <div className="mb-6">
            {this._renderTextFiled({
              label: intl.formatMessage({ id: "campaign_setup.assets.name", defaultMessage: "name" }),
              placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                field: intl.formatMessage({ id: "campaign_setup.assets.name", defaultMessage: "name" })
              }),
              name: "physicalName",
              defaultValue: (physicalName || ""),
              onChange: (event) => this.handleChange("physicalName", _get(event, "target.value", "")),
              isRequired: true,
              isDisabled: isDisableOnEditMode
            })}
            {_get(formErrors, "physicalName", "") && <ErrorMessage message={_get(formErrors, "physicalName", "")} />}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="mb-6">
            {this._renderTextFiled({
              label: intl.formatMessage({ id: "campaign_setup.assets.asset_code", defaultMessage: "asset code" }),
              placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                field: intl.formatMessage({ id: "campaign_setup.assets.asset_code", defaultMessage: "asset code" })
              }),
              name: "assetCode",
              defaultValue: (assetCode || ""),
              onChange: (event) => this.handleChange("assetCode", _get(event, "target.value", "")),
              isRequired: true,
              isDisabled: isDisableOnEditMode
            })}
            {_get(formErrors, "assetCode", "") && <ErrorMessage message={_get(formErrors, "assetCode", "")} />}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="mb-6">
            <label className="form-label text-capitalize">
              <FormattedMessage id="campaign_setup.assets.topic" defaultMessage="topic" />
              <span className="text-danger">*</span>
            </label>
            <Select
              className="form-custom-select"
              placeholder={intl.formatMessage({ id: "placeholder.select", defaultMessage: "Select" })}
              options={(physicalTopicDropDownData || [])}
              value={(physicalTopic || null)}
              getOptionLabel={(option) => (option.topic || "")}
              getOptionValue={(option) => (option.id || null)}
              onChange={(selected) => this.handleChange("physicalTopic", selected)}
              isMulti={false}
              isDisabled={isDisableOnEditMode}
            />
            {_get(formErrors, "physicalTopic", "") && <ErrorMessage message={_get(formErrors, "physicalTopic", "")} />}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="mb-6">
            <label className="form-label text-capitalize">
              <FormattedMessage id="campaign_setup.assets.sub_topic" defaultMessage="sub topic" />
              <span className="text-danger">*</span>
            </label>
            <Select
              className="form-custom-select"
              placeholder={intl.formatMessage({ id: "placeholder.select", defaultMessage: "Select" })}
              options={(physicalSubTopicDropDownData || [])}
              value={(physicalSubTopic || null)}
              getOptionLabel={(option) => (option.sub_topic || "")}
              getOptionValue={(option) => (option.id || null)}
              onChange={(selected) => this.handleChange("physicalSubTopic", selected)}
              isMulti={false}
              isDisabled={isDisableOnEditMode}
            />
            {_get(formErrors, "physicalSubTopic", "") && <ErrorMessage message={_get(formErrors, "physicalSubTopic", "")} />}
          </div>
        </div>
      </>
    );
  }

  _renderLeftView() {
    const { common: { currentStep, totalStep }, /*onPreviousStep,*/ intl } = this.props;
    const { selectedOption } = this.state;

    return (
      <div className="col-lg-8">
        <div className="card">
          <div className="card-body py-5 vh-165">
            <h6 className="heading-04 fw-semibold mb-6">{`Step ${currentStep} of ${totalStep} - Assets`}</h6>
            <div className="row">

              <div className="col-lg-12">
                <div className="mb-8">
                  {(radioButtonList || []).map((button, index) => (
                    <React.Fragment key={index}>
                      {this._renderRadioButton({
                        key: index,
                        label: intl.formatMessage({ id: button.labelId, defaultMessage: button.defaultMessage }),
                        id: (button.id || ""),
                        name: (button.id || ""),
                        defaultValue: (button.defaultValue || "none"),
                        onChange: (event) => this.setState({ "selectedOption": _get(event, "target.value", "none"), formErrors: {} }),
                        defaultChecked: (button.defaultChecked || false)
                      })}
                    </React.Fragment>
                  ))}
                </div>
              </div>

              {(selectedOption === "useExisting") && this._renderUseExisting()}
              {(selectedOption === "uploadNew") && this._renderUploadNew()}
              {(selectedOption === "physicalEvent") && this._renderPhysicalEvent()}

            </div>

            <div className="row mt-auto">
              <div className="col-lg-12">
                <div className="d-flex align-items-center justify-content-end gap-4 mt-4">
                  {/* <button className="btn btn-primary text-capitalize" onClick={() => { if (typeof onPreviousStep === "function") { onPreviousStep() } }}>
                    <FormattedMessage id="btn.back" defaultMessage="back" />
                  </button> */}
                  <button className="btn btn-primary text-capitalize" onClick={() => this.handleNext()}>
                    <FormattedMessage id="btn.next" defaultMessage="next" />
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }

  _renderRightView() {

    return (
      <div className="col-lg-4">
        <CampaignSetupProgress />
      </div>
    );
  }

  render() {

    return (
      <div className="row">
        {this._renderLeftView()}

        {this._renderRightView()}
      </div>
    );
  }
};

const mapStateToProps = (state, props) => ({
  summary: _get(state, "campaignSetup.summary", {}),
  common: _get(state, "campaignSetup.summary.common", {}),
  setupErrors: _get(state, "campaignSetup.setupErrors", {}),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  updateCampaignSetupDetails: (data) => dispatch(updateCampaignSetupDetails(data)),
  assignAssetCampaign: (data) => dispatch(assignAssetCampaign(data)),
  removeAssignedAssets: (data) => dispatch(removeAssignedAssets(data)),
  initiateAssetCampaign: (data) => dispatch(initiateAssetCampaign(data)),
  resetCampaignSetupError: () => dispatch(resetCampaignSetupError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Assets));
