import { combineReducers } from "redux";
import { get as _get } from "lodash";
import actionTypes from "../actions";

const initialUserList = { flag: null, data: [], message: "" };
const userList = (state = initialUserList, action) => {

  switch (action.type) {
    case actionTypes.GET_USER_LIST_SUCCESS:
      return _get(action, "data", {});

    case actionTypes.GET_USER_LIST_ERROR:
      return initialUserList;

    default:
      return state;
  }
}

const initialAgencyList = { flag: null, data: [], message: "" };
const agencyList = (state = initialAgencyList, action) => {

  switch (action.type) {
    case actionTypes.GET_AGENCY_LIST_SUCCESS:
      return _get(action, "data", {});

    case actionTypes.GET_AGENCY_LIST_ERROR:
      return initialAgencyList;

    default:
      return state;
  }
}

const initialClientList = { flag: null, data: [], message: "" };
const clientList = (state = initialClientList, action) => {

  switch (action.type) {
    case actionTypes.GET_CLIENT_LIST_SUCCESS:
      return _get(action, "data", {});

    case actionTypes.GET_CLIENT_LIST_ERROR:
      return initialClientList;

    default:
      return state;
  }
}

const initialErrorList = { flag: null, data: {}, message: "" };
const errorList = (state = initialErrorList, action) => {

  switch (action.type) {
    case actionTypes.GET_USER_LIST_ERROR:
    case actionTypes.GET_AGENCY_LIST_ERROR:
    case actionTypes.GET_CLIENT_LIST_ERROR:
      return _get(action, "error", {});

    case actionTypes.CLEAR_LIST_ERROR:
      return initialErrorList;

    default:
      return state;
  }
}

const initialCreateUser = { flag: null, data: {}, message: "" };
const createUser = (state = initialCreateUser, action) => {
  switch (action.type) {
    case actionTypes.CREATE_NEW_USER_SUCCESS:
      return { flag: true, data: _get(action, "data.responseData", {}), message: _get(action, "data.responseMessage", "") };

    case actionTypes.CREATE_NEW_USER_ERROR:
      return { ...initialCreateUser, flag: false, message: _get(action, "error.message", "") };

    case actionTypes.CLEAR_CREATE_NEW_USER_RESPONSE:
      return { ...initialCreateUser };

    default:
      return state;
  }
}

const initialCreateAgency = { flag: null, data: {}, message: "" };
const createAgency = (state = initialCreateAgency, action) => {
  switch (action.type) {
    case actionTypes.CREATE_NEW_AGENCY_SUCCESS:
      return { flag: true, data: _get(action, "data.data", {}), message: _get(action, "message", "") };

    case actionTypes.CREATE_NEW_AGENCY_ERROR:
      return { ...initialCreateAgency, flag: false, message: _get(action, "error.message", "") };

    case actionTypes.CLEAR_CREATE_NEW_AGENCY_RESPONSE:
      return { ...initialCreateAgency };

    default:
      return state;
  }
}

const initialCreateClient = { flag: null, data: {}, message: "" };
const createClient = (state = initialCreateClient, action) => {
  switch (action.type) {
    case actionTypes.CREATE_NEW_CLIENT_SUCCESS:
      return { flag: true, data: _get(action, "data.data", {}), message: _get(action, "message", "") };

    case actionTypes.CREATE_NEW_CLIENT_ERROR:
      return { ...initialCreateClient, flag: false, message: _get(action, "error.message", "") };

    case actionTypes.CLEAR_CREATE_NEW_CLIENT_RESPONSE:
      return { ...initialCreateClient };

    default:
      return state;
  }
}

export default {
  userManagement: combineReducers({
    createUser,
    agencyList,
    clientList,
    userList,
    errorList,
    createAgency,
    createClient
  }),
};
