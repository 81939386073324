import { get as _get } from "lodash";
import api from "../../helpers/api";

export const doLogin = (uri, variables) => {
  return api().post("/authenticate/token", variables).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: (res.data || {})
    };
  }).catch((err) => {

    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.responseMessage", "Something went wrong while login!"),
      data: {}
    };
  });
}

export const forgotPassowrd = (variables) => {

  return api().patch("/user/reset/password/initiate", variables).then((res) => {

    return {
      flag: _get(res, "data.responseStatus", false),
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {

    return {
      flag: false,
      title: _get(err, "response.data.title", false),
      message: _get(err, "response.data.responseMessage", "Something went wrong while generating reset password link."),
      data: {},
    };
  });
};

export const checkResetPassword = (variables) => {
  return api().patch("/user/reset/password/criteria", variables).then((res) => {
    return {
      flag: _get(res, "data.responseStatus", false),
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.responseMessage", "Something went wrong while checking reset password token."),
      data: {},
    };
  });
};

export const passwordReset = (variables) => {
  return api().patch("/user/reset/password", variables).then((res) => {
    return {
      flag: _get(res, "data.responseStatus", false),
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", false),
      message: _get(err, "response.data.responseMessage", "Something went wrong while reseting password."),
      data: {},
    };
  });
};
