import React, { Component } from "react";
import { injectIntl } from "react-intl";

import { hasAccess } from "../../../../resusableComponents/hoc/AccessManagement";

const tabList = ["User", "Agency", "Client"];

class UserManagementTab extends Component {

  _renderView = () => {
    const { onTabChange, activeTab } = this.props;

    return (
      <ul className="nav nav-tabs bg-white-f0 rounded-1 mb-5" id="userManagementTabs" role="tablist">
        {(tabList || []).map((tab, index) => {

          if (hasAccess("admin", `${(tab).toLocaleLowerCase()}-management`, ["page-view"]) === false) { return null; }

          return (
            <li className="nav-item" key={index}>
              <button className={`nav-link ${tab === activeTab ? "active" : ""}`} onClick={() => onTabChange(tab)}>
                {tab}
              </button>
            </li>
          )
        })}
      </ul>
    );
  }

  render() {

    return (
      <>
        {this._renderView()}
      </>
    );
  }
}

export default injectIntl(UserManagementTab);
