import { get as _get } from "lodash";
import api from "../../helpers/api";

export const getUserConfiguration = () => {

  return api().get("/User/Configuration").then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.responseData", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching user configuration."),
      data: {}
    };
  });
}

export const downloadSampleFile = (source) => {
  return api().post(`/download/file/${source}`, {}).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.responseMessage", "File generated successfully."),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while downloading sample file."),
      data: {}
    };
  });
}

export const fetchRejectionTypeList = () => {
  return api().get("lookup/lead_manual_rejection_type").then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", []),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching rejection types."),
      data: {},
    };
  });
};

export const getDashboardData = (variables) => {
  return api().options("/lookup/dashboard", { data: variables }).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", "Dashboard data fetched successfully."),
      data: _get(res, "data.responseData", []),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "response.data.responseMessage", "Something went wrong while fetching dashboard data."),
      data: {}
    };
  });
}
