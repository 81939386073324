import React, { PureComponent } from "react";
import { isEmpty as _isEmpty } from "lodash";
import { injectIntl } from "react-intl";
import AsyncSelect from "react-select/async";
import ReactSelect from "react-select";

import { ErrorMessage } from "./ErrorMessage";

class Select extends PureComponent {

  static defaultProps = {
    classNamePrefix: "react-select",
    placeholder: "",
    options: [],
    onChange: () => { },
    onInputChange: () => { },
    loadOptions: () => { },
    isMulti: true,
    key: null,
    closeMenuOnSelect: true,
    keyboard: false,
    tabSelectsValue: false,
    isDisabled: false,
    isClearable: false,
    async: false,
  };

  render() {
    const { getOptionLabel, getOptionValue, error, label, onChange, onInputChange, async, loadOptions, ...restProps } = this.props;

    return (
      <>
        {(!_isEmpty(label)) && (
          <p className={`title title--bold title--sm mb-2 text-uppercase ${(!_isEmpty(error)) ? "text-danger" : ""}`}>
            {label}
          </p>
        )}

        {(async === true) && (
          <AsyncSelect
            className={`react-select-container cursor-pointer ${(!_isEmpty(error)) ? "react-select__control--danger" : ""}`}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            loadOptions={loadOptions}
            onInputChange={onInputChange}
            onChange={onChange}
            {...restProps}
          />
        )}

        {(async === false) && (
          <ReactSelect
            className={`react-select-container cursor-pointer ${(!_isEmpty(error)) ? "react-select__control--danger" : ""}`}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onChange={onChange}
            styles={{
              // Fixes the overlapping problem of the component
              menu: provided => ({ ...provided, zIndex: 9999 })
            }}
            {...restProps}
          />
        )}

        {(!_isEmpty(error)) && (
          <ErrorMessage message={error.charAt(0).toUpperCase() + error.slice(1)} />
        )}
      </>
    );
  }
}

export default injectIntl(Select);
