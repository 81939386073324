import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

export class CustomModal extends PureComponent {

  static defaultProps = {
    baseClassName: "Modal",
    modalTitleClassName: "heading-04",
    showCloseButton: true,
    onClose: () => { },
    onEntered: () => { },
    onExited: () => { },
  }

  render() {
    const {
      baseClassName = "",
      className = "",
      bodyClassName = "",
      modalTitle = "",
      modalTitleClassName = "",
      iconClassName = "",
      showCloseButton = false,
      children,
      isOpen = false,
      onClose = () => { },
      onEntered = () => { },
      onExited = () => { },
      center = true,
      ...props
    } = this.props;

    return (
      <Modal
        dialogClassName={`custom-modal ${baseClassName}`}
        className={className}
        onHide={onClose}
        centered={center}
        show={isOpen}
        onEntered={onEntered}
        onExited={onExited}
        onClose={() => onClose()}
        {...props}
      >
        <Modal.Header>
          <Modal.Title className={`text-capitalize ${modalTitleClassName}`}>{(modalTitle || "")}</Modal.Title>
          {showCloseButton && (<span type="button" className={`btn-close ${iconClassName}`} onClick={() => this.props.onClose()}>
          </span>)}
        </Modal.Header>
        <Modal.Body className={bodyClassName}>
          {children}
        </Modal.Body>
      </Modal>
    );
  }
}

CustomModal.propTypes = {
  portalClassName: PropTypes.string,
  overlayClassName: PropTypes.string,
  baseClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  showCloseButton: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onEntered: PropTypes.func,
  onExited: PropTypes.func,
  customCloseButton: PropTypes.node,
  center: PropTypes.bool,
};

export default CustomModal;
