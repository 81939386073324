export const _getSubdomain = (host = window.location.hostname) => {
  if (["127.0.0.1", "localhost"].includes(host)) {
    return "";
  }

  return (window.location.host || "").substring(0, (window.location.host || "").indexOf(`.${process.env.REACT_APP_FRONTEND_URL}`))
}

export const arrayIndexReorder = (arr, takeIndex, putIndex) => {
  let result = Array.from(arr);
  const [removed] = result.splice(takeIndex, 1);
  result.splice(putIndex, 0, removed);

  return result;
};

export const requireAllValues = requireContext =>
  requireContext.keys().map(requireContext);

export const ucfirst = string =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

//eslint-disable-next-line
export const isDomainNameValid = domainName => (/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(domainName));

export const isPasswordValid = (pass = "") =>
  /^.*(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\dX])(?=.*[!@#$%]).*$/.test(
    pass
  );

export const isPhoneValid = phone =>
  /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g.test(phone);

export const isEmailValid = (email = "") =>
  new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ).test(email);

// function uses to grab the query parameters from the url in object.
export const getURLParams = (url) => {
  const params = {};
  const parser = document.createElement('a');
  parser.href = url;

  const qry = parser.search.substring(1);
  const variables = qry.split('&');

  (variables || []).forEach((item) => {
    const pair = (item || "").split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  })

  return params;
};

// Check passed URL is valid or not
export const isValidURL = (urlString) => new RegExp('^(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$', 'i').test(urlString); // fragment locator

export const getDeviceModeByWidth = (width) => {
  let deviceMode = "desktop";

  if (width <= 1750) {
    deviceMode = "laptop";
  }

  if (width <= 1499) {
    deviceMode = "tablet";
  }

  if (width <= 768) {
    deviceMode = "mobile";
  }

  return deviceMode;
};

export const scrollTo = (element, change, duration, callback = () => { }) => {
  var start = element.scrollLeft;
  var currentTime = 0;
  var increment = 20;

  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

  var scrollAnimation = () => {
    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollLeft = val;
    if (currentTime < duration) {
      setTimeout(scrollAnimation, increment);
    } else {
      callback(element.scrollLeft);
    }
  };
  scrollAnimation();
};

export const checkIsJSON = (str) => {
  try {
    var obj = JSON.parse(str);
    if (obj && typeof obj === "object" && obj !== null) {
      return true;
    }
  } catch (err) { }
  return false;
}

export const getInitials = (string, divider = " ", limit = 2) =>
  (string || "")
    .split(divider)
    .map(
      (a, i) => {
        if (i < limit) return a.charAt(0);

        return null;
      }
    )
    .join("")
    .toUpperCase();

/**
* Download an Excel file
* @param  String Content type of the excel file
* @param  String String which can be convert into Array buffer
* @param  String Name of the file
* @return void
*/
export const downloadExcelFile = (contentType, contents, filename) => {

  let bin = atob(contents);
  let ab = s2ab(bin);
  let blob = new Blob([ab], { type: contentType });

  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
* Download file from URL
* @param  String url
* @param  String filename
* @return void
*/
export const downloadFileFromUrl = (url, fileName) => {

  if ((url) && (fileName)) {
    fetch(url).then(resp => resp.blob()).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch((err) => {
      console.error(err.message);
    });
  }
}

// Convert Object key value pair to URL query params @param Object Key Value pair object
export const objectToParams = (object) => {

  Object.entries(object).map(([key, val]) => val === null && delete object[key]);

  if (Object.keys(object).length > 0) {
    return new URLSearchParams(object).toString();
  } else {
    return "";
  }
}

function s2ab(s) {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}
export const isInt = (value) => {
  if (isNaN(value)) {
    return false;
  }

  const x = parseFloat(value);

  return (x | 0) === x;
};

export const validateAlphaNumericValue = (inputString) => {
  const inputWithoutSpaces = inputString.replace(/ /g, '');
  const pattern = /^[a-zA-Z0-9]+$/;

  return pattern.test(inputWithoutSpaces);
};

export const validateContainsBothAlphaNumericValue = (inputString) => {
  const inputWithoutSpaces = inputString.replace(/ /g, '');
  const pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;

  return pattern.test(inputWithoutSpaces);
};

export default {
  requireAllValues,
  _getSubdomain,
  isEmailValid,
  isPasswordValid,
  getInitials,
  checkIsJSON,
  objectToParams,
  downloadExcelFile,
  isInt
};
