import React, { Component } from "react";
import { cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty, get as _get } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { ErrorMessage, showAlertMessage } from "../../resusableComponents/common/formElements";
import { isPasswordValid } from "../../helpers/utils";
import config from "../../helpers/config";

import { checkIfResetPasswordTokenValid, clearResetPasswordTokenValidResponse, passwordReset, clearResetPasswordResponse } from "../../redux/actions/authentication";

class ResetPasswordUser extends Component {

  constructor(props) {
    super(props);

    this.frontEndURL = config.getFrontendAppURL(true, true);

    this.defaultCredentials = {
      newPassword: "",
      confirmPassword: "",
      key: "",
      id: null,
    };

    this.state = {
      credentials: _cloneDeep(this.defaultCredentials),
      formErrors: {},
      alertSuccess: {},
      alertError: {},
      isSubmitButtonClicked: false,
    };
  }

  componentDidMount(prevProps, prevState) {
    const { checkIfResetPasswordTokenValid } = this.props;

    let path = window.location.pathname.split("/");
    if (_get(path, "[2]", "") !== "") {
      if (typeof checkIfResetPasswordTokenValid === "function") { checkIfResetPasswordTokenValid({ reset_key: _get(path, "[2]", ""), subdomain: this.frontEndURL }); }
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.credentials, this.state.credentials)) { this._handleValidation(); }

    if (!_isEqual(prevProps.resetError, this.props.resetError) && !_isEmpty(this.props.resetError)) {
      const { clearResetPasswordTokenValidResponse, resetError } = this.props;

      showAlertMessage(_get(resetError, "message", "Something went wrong while resetting password."));

      if (typeof clearResetPasswordTokenValidResponse === "function") { clearResetPasswordTokenValidResponse(); }
    }

    if (!_isEqual(prevProps.resetSuccess, this.props.resetSuccess) && !_isEmpty(this.props.resetSuccess)) {
      const { clearResetPasswordResponse, resetSuccess } = this.props;

      showAlertMessage(_get(resetSuccess, "message", "Your password has been reset successfully."), "success");

      if (typeof clearResetPasswordResponse === "function") { clearResetPasswordResponse(); }
      setTimeout(() => { window.location.href = "/login"; }, 5000);
    }

    if (!_isEqual(prevProps.resetPassword, this.props.resetPassword) && this.props.resetPassword === true) {
      if (typeof this.props.clearResetPasswordResponse === "function") { this.props.clearResetPasswordResponse(); }
    }

    if (!_isEqual(prevProps.resetTokenResponse, this.props.resetTokenResponse) && this.props.resetTokenResponse > 0) {
      this.setState({
        credentials: { id: _get(this.props, "resetTokenResponse", null), }
      });

      if (typeof this.props.clearResetPasswordResponse === "function") { this.props.clearResetPasswordResponse(); }
    }
  }

  resetPasswordSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { passwordReset } = this.props;
    const { credentials: { id, newPassword, confirmPassword }, formErrors } = this.state;

    if (_isEmpty(newPassword) || !_isEqual(newPassword, confirmPassword) || (id === null) || !_isEmpty(formErrors)) {
      this._handleValidation();
      return;
    }

    try {

      if (typeof passwordReset === "function") {
        passwordReset({ new_password: newPassword, confirm_password: confirmPassword, id: id, subdomain: this.frontEndURL });
      }

    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while generating forgot password link."));
    }
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = (e.target || {});

    this.setState((prevState) => ({
      credentials: {
        ...prevState.credentials,
        [name]: value,
      }
    }));
  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { credentials: { newPassword, confirmPassword } } = this.state;

    let formErrors = {};
    if (_isEmpty(newPassword)) {
      formErrors["newPassword"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "reset_password.new_password", defaultMessage: "New password" })
      });
    }

    if (!_isEmpty(newPassword) && !isPasswordValid(newPassword)) {
      formErrors["newPassword"] = intl.formatMessage({ id: "error.password_valid_char", defaultMessage: "Password must be at least 8 characters long and contain an uppercase letter, a number, and a special character." });
    }

    if (!_isEqual(newPassword, confirmPassword)) {
      formErrors["confirmPassword"] = intl.formatMessage({ id: "error.password_not_match", defaultMessage: "{field} is no matched" }, {
        field: intl.formatMessage({ id: "reset_password.confirm_password", defaultMessage: "Confirm password", })
      });
    }

    this.setState({ formErrors });
  }

  render() {
    const { intl } = this.props;
    const { formErrors, alertError, isSubmitButtonClicked } = this.state;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "hermes.reset_password", defaultMessage: "Reset Password: Hermes" })} </title>
        </Helmet>

        <div className="login-wrapper">
          <div className="card">
            <div className="card-header">
              <img src={require("../../assets/images/logo.svg").default} className="img-logo img-fluid mx-auto d-block" alt="Hermes" />
            </div>

            <div className="card-body">
              <div>
                <h5 className="text-capitalize mb-3"><FormattedMessage id="reset_password.title" defaultMessage="reset password" /></h5>
                <p className="text-grey-74 text-capitalize-first mb-6"><FormattedMessage id="reset_password.sub_title" defaultMessage="Please enter new password below" /></p>
              </div>

              {!_isEmpty(alertError) && (
                <div className="mb-3 text-danger text-capitalize-first">
                  <span>{(alertError.message || "")}</span>
                </div>
              )}

              <Form autoComplete="off" onSubmit={(e) => this.resetPasswordSubmit(e)}>
                <div className="mb-5">
                  <label className="form-label text-capitalize" htmlFor="newPassword">
                    <FormattedMessage id="reset_password.new_password" defaultMessage="new password" />
                  </label>
                  <input
                    name="newPassword"
                    id="password"
                    type="password"
                    className="form-control"
                    placeholder="New password"
                    onChange={(e) => this._handleChange(e)}
                  />
                  {(_get(formErrors, "newPassword", "")) && ((isSubmitButtonClicked || false) === true) && (<ErrorMessage message={_get(formErrors, "newPassword", "")} />)}
                </div>

                <div className="mb-5">
                  <label className="form-label text-capitalize" htmlFor="confirmPassword">
                    <FormattedMessage id="reset_password.confirm_password" defaultMessage="confirm password" />
                  </label>
                  <input
                    name="confirmPassword"
                    id="confirmPassword"
                    type="password"
                    className="form-control"
                    placeholder="Confirm password"
                    onChange={(e) => this._handleChange(e)}
                  />
                  {(_get(formErrors, "confirmPassword", "")) && ((isSubmitButtonClicked || false) === true) && (<ErrorMessage message={_get(formErrors, "confirmPassword", "")} />)}
                </div>

                <div>
                  <button type="submit" className="btn btn-primary waves-effect w-100 text-capitalize mb-10" onClick={() => this.setState({ isSubmitButtonClicked: true })}>
                    <FormattedMessage id="btn.reset" defaultMessage="reset" />
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  resetSuccess: _get(state, "authentication.success", {}),
  resetError: _get(state, "authentication.error", {}),
  resetTokenResponse: _get(state, "authentication.resetAuthenticationToken", null)
});

const mapDispatchToProps = (dispatch) => ({
  passwordReset: (data) => dispatch(passwordReset(data)),
  clearResetPasswordResponse: () => dispatch(clearResetPasswordResponse()),
  checkIfResetPasswordTokenValid: (data) => dispatch(checkIfResetPasswordTokenValid(data)),
  clearResetPasswordTokenValidResponse: () => dispatch(clearResetPasswordTokenValidResponse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResetPasswordUser));
