import React from "react";
import { get as _get, isEmpty as _isEmpty, intersection as _intersection, isEqual as _isEqual } from "lodash";
import { Navigate } from "react-router-dom";

import { showAlertMessage } from "../../common/formElements";

import { store } from "../../../redux/configureStore";

// hoc which return component and can be like connect from react-redux.
export function withAccess(controller, method, operations, pattern = "AND") {

  return function (Component) {

    function WrapperComponent(props) {

      let allowed = hasAccess(controller, method, operations, pattern);

      if (allowed === true) {
        return (<Component {...props} />);
      }

      const state = store.getState();

      const roleWiseRedirections = _get(state, "application.constants.roleWiseRedirections", []);
      const roleId = _get(state, "authentication.userToken.role_id", null);

      let redirectUrl = "/";

      (roleWiseRedirections || []).forEach((role) => {
        if ((role.roleIds || []).includes(roleId) && role.redirectionPath !== null) {
          redirectUrl = (role.redirectionPath || "/");
        }
      });

      setTimeout(() => {
        showAlertMessage("You don't have access to visit this page.");
      }, 100);

      return (<Navigate to={redirectUrl} />);
    }

    return WrapperComponent;
  }

}

// hoc can be used within render
export const WithAccess = ({ controller, method, operations, children, pattern = "AND" }) => {

  const Component = withAccess(controller, method, operations, pattern)(() => (<>{children}</>));

  return (<Component />);
}

// utility function used to identify if current user is having access or not.
export const hasAccess = (controller, method, operations, pattern = "AND") => {
  const state = store.getState();

  const accessList = _get(state, "application.accessList", []);

  const filteredAccess = (accessList || []).find((p) => (p.controller === controller && p.method === method));

  if (_isEmpty(filteredAccess)) { return false; }

  // if (_isEmpty(operations) && filteredAccess.allowed === true) {
  if (_isEmpty(operations) && !_isEmpty(filteredAccess)) {
    return true;
  }

  const matchedAccess = _intersection((filteredAccess.operations || []), operations);

  let allowed = false;
  if (pattern === "OR") {
    allowed = !_isEmpty(matchedAccess);
  } else {
    allowed = _isEqual(matchedAccess, operations)
  }

  return allowed;
}
