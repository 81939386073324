import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty, map as _map } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { Select, showAlertMessage, SingleDropzone, ErrorMessage } from "../../../resusableComponents/common/formElements";
import DownloadSampleFile from "../../../resusableComponents/common/DownloadSampleFile";
import CampaignSetupProgress from "./CampaignSetupProgress";

import { resetCampaignSetupError, updateCampaignSetupDetails, assignExistingSuppression, removeAssignedSuppressionList } from "../../../redux/actions/campaignSetup";
import { getSuppressionList } from "../../../redux/services/campaignSetup";
import { updateLoadingState } from "../../../redux/actions/application";

const radioButtonList = [
  { id: "none", labelId: "campaign_setup.suppression_list.none", defaultMessage: "none", defaultValue: "none", defaultChecked: true },
  { id: "useExisting", labelId: "campaign_setup.suppression_list.use_existing", defaultMessage: "use existing", defaultValue: "useExisting" },
  { id: "uploadNew", labelId: "campaign_setup.suppression_list.upload_new", defaultMessage: "upload new", defaultValue: "uploadNew" },
];

class SuppressionList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      suppressionList: [],
      selectedOption: "none",
      oldSelectedOption: "none",
      existingSuppressionIds: null,
      fileUploaded: false,
      lastInsertedId: null,
      uploadedFileName: "",
      uploadedFileURL: "",
      disabled: false,
      isEditMode: false,
      errors: {}
    };
  }

  componentDidMount() {
    const { summary, summary: { common: { lastFilledStep } }, currentStep } = this.props;

    //Set default or existing values
    this.setState({
      selectedOption: _get(summary, `${currentStep}.selectedOption`, "none"),
      oldSelectedOption: _get(summary, `${currentStep}.oldSelectedOption`, "none"),
      existingSuppressionIds: _get(summary, `${currentStep}.existingSuppressionIds`, null),
      fileUploaded: _get(summary, `${currentStep}.fileUploaded`, false),
      uploadedFileName: _get(summary, `${currentStep}.uploadedFileName`, ""),
      uploadedFileURL: _get(summary, `${currentStep}.uploadedFileURL`, ""),
      lastInsertedId: _get(summary, `${currentStep}.lastInsertedId`, null),
      isEditMode: (lastFilledStep > currentStep) ? true : false
    });
  }

  componentDidUpdate(prevProps, prevStates) {
    if (
      (prevStates.selectedOption !== this.state.selectedOption) ||
      (!_isEqual(prevStates.existingSuppressionIds, this.state.existingSuppressionIds)) ||
      (!_isEqual(prevStates.uploadedFileName, this.state.uploadedFileName))
    ) {
      this._handleValidation();
    }

    if (
      (prevStates.selectedOption !== this.state.selectedOption) &&
      (this.state.selectedOption === "useExisting") &&
      (_get(this.props, "clientId", null))
    ) {
      this._fetchSuppressionListByClientId();
    }

    if (!_isEqual(prevProps.setupErrors, this.props.setupErrors) && !_isEmpty(this.props.setupErrors)) {
      showAlertMessage(_get(this.props, "setupErrors.message", ""));

      if (typeof this.props.resetCampaignSetupError === "function") { this.props.resetCampaignSetupError(); }
    }
  }

  _fetchSuppressionListByClientId = async () => {
    const { clientId, updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getSuppressionList({ client_id: clientId, dropdown_flag: 1 });

      if ((response.flag || false) === true) {
        this.setState({ suppressionList: _get(response, "data.suppression_list", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching suppression list.")));
        this.setState({ suppressionList: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching suppression list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleValidation() {
    const { intl } = this.props;
    const { selectedOption, existingSuppressionIds, uploadedFileName } = this.state;
    let errors = {};

    if (selectedOption === "useExisting") {
      //For existing suppression
      if (_isEmpty(existingSuppressionIds)) {
        errors["suppressionIds"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
          field: intl.formatMessage({ id: "campaign_setup.suppression_list.suppression", defaultMessage: "suppression" })
        });
      }
    } else if (selectedOption === "uploadNew") {

      // Single upload
      if (_isEmpty(uploadedFileName)) {
        errors["fileUploadation"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
          field: intl.formatMessage({ id: "file", defaultMessage: "file" })
        });
      }
    }

    this.setState({ disabled: !_isEmpty(errors), errors });
  }

  handleNext = () => {
    const { summary: { common }, summary: { common: { lastFilledStep } }, currentStep, campaignId, assignExistingSuppression, updateCampaignSetupDetails, removeAssignedSuppressionList, intl } = this.props;
    const { errors, isEditMode, selectedOption, oldSelectedOption, existingSuppressionIds, fileUploaded, lastInsertedId, uploadedFileName, uploadedFileURL } = this.state;

    if (!_isEmpty(errors)) { return; }

    const variable = {
      "common": {
        ...common,
        currentStep: (currentStep + 1),
        lastFilledStep: (lastFilledStep > currentStep) ? lastFilledStep : (currentStep + 1),
      },
      [currentStep]: {
        summaryText: intl.formatMessage({ id: "campaign_setup.suppression_list.title", defaultMessage: "suppression list" }),
        selectedOption,
        oldSelectedOption: selectedOption,
        existingSuppressionIds,
        fileUploaded: (fileUploaded || false),
        lastInsertedId: (lastInsertedId || null),
        uploadedFileName: (uploadedFileName || ""),
        uploadedFileURL: (uploadedFileURL || ""),
      }
    }

    // Remove assigned suppression from the campaign
    if (
      (selectedOption === "none") &&
      (isEditMode === true) &&
      (selectedOption !== oldSelectedOption) &&
      (typeof removeAssignedSuppressionList === "function")
    ) {
      const payload = Object.assign({}, variable, { "payload": { campaign_id: campaignId, suppression_list_ids: null } });

      removeAssignedSuppressionList(payload);
    } else if (
      (((selectedOption === "none") && (selectedOption === oldSelectedOption)) || (selectedOption === "uploadNew")) &&
      (typeof updateCampaignSetupDetails === "function")
    ) {
      updateCampaignSetupDetails(variable);
    } else if ((selectedOption === "useExisting") && (typeof assignExistingSuppression === "function")) {
      const payload = Object.assign({}, variable, {
        "payload": {
          campaign_id: campaignId,
          suppression_list_ids: _map(existingSuppressionIds, "id")
        }
      });

      assignExistingSuppression(payload);
    }
  }

  _renderRadioButton = ({ label, id, name, defaultValue, onChange }) => {
    const { selectedOption } = this.state;

    return (
      <div className="form-check form-check-inline">
        <input
          className="form-check-input show-data"
          type="radio"
          name={(name || "")}
          id={(id || "")}
          value={(defaultValue || "")}
          checked={selectedOption === defaultValue}
          onChange={onChange} />
        <label className="form-check-label text-capitalize" htmlFor={id}>{(label || "")}</label>
      </div>
    );
  };

  _renderUseExisting = () => {
    const { intl } = this.props;
    const { suppressionList, existingSuppressionIds, errors } = this.state;

    return (
      <div className="col-lg-6">
        <label className="form-label text-capitalize">
          <FormattedMessage id="campaign_setup.account_list.user_existing_label" defaultMessage="using existing" />
        </label>
        <Select
          className="form-custom-select"
          placeholder={intl.formatMessage({ id: "placeholder.select", defaultMessage: "select" })}
          options={(suppressionList || [])}
          value={(existingSuppressionIds || null)}
          getOptionLabel={(option) => (option.name || "Select")}
          getOptionValue={(option) => (option.id || null)}
          onChange={(selected) => this.setState({ "existingSuppressionIds": selected })}
          error={(errors.suppressionIds || "")}
        />
      </div>
    );
  }

  _renderUploadNew = () => {
    const { campaignId, clientId, allowedUploadFiles, allowedUploadFilesLabel } = this.props;
    const { uploadedFileName, uploadedFileURL, lastInsertedId, errors: { fileUploadation } } = this.state;


    return (
      <div className="col-lg-6">
        <div className="mb-6">
          <SingleDropzone
            acceptedFormat={allowedUploadFiles}
            allowedExtensions={allowedUploadFilesLabel}
            setState={(args) => this.setState(args)}
            source="suppression_list"
            campaignId={(campaignId || null)}
            clientId={(clientId || null)}
            uploadedFileName={uploadedFileName}
            uploadedFileURL={uploadedFileURL}
            lastInsertedId={lastInsertedId}
            preserveSelectedFile={true}
            displayUploadedFiles={true}
            displayUploadedRemoveBtn={true}
          />
          {(!_isEmpty(fileUploadation)) && (
            <ErrorMessage message={fileUploadation.charAt(0).toUpperCase() + fileUploadation.slice(1)} />
          )}
        </div>

        <DownloadSampleFile source="suppression_list" />
      </div>
    );
  }

  _renderRightPanel = () => {
    return (
      <div className="col-lg-4">
        <CampaignSetupProgress />
      </div>
    );
  }

  render() {
    const { currentStep, totalStep, onPreviousStep, intl } = this.props;
    const { selectedOption, disabled } = this.state;

    return (
      <div className="row">
        <div className="col-lg-8">
          <div className="card">
            <div className="card-body py-5 vh-165">
              <h6 className="heading-04 fw-semibold mb-6">{`Step ${currentStep} of ${totalStep} - Suppression Lists`}</h6>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-8">
                    {(radioButtonList || []).map((button, index) => (
                      <React.Fragment key={index}>
                        {this._renderRadioButton({
                          label: intl.formatMessage({ id: button.labelId, defaultMessage: button.defaultMessage }),
                          id: (button.id || "radio"),
                          name: (button.id || "selectedOption"),
                          defaultValue: (button.defaultValue || "none"),
                          defaultChecked: (button.defaultChecked || false),
                          onChange: (event) => this.setState({ "selectedOption": _get(event, "target.value", "none") }),
                        })}
                      </React.Fragment>
                    ))}
                  </div>
                </div>

                {(selectedOption === "useExisting") && this._renderUseExisting()}
                {(selectedOption === "uploadNew") && this._renderUploadNew()}
              </div>

              <div className="row mt-auto">
                <div className="col-lg-12">
                  <div className="d-flex align-items-center justify-content-between gap-4 mt-4">
                    <button className="btn btn-primary text-capitalize" onClick={() => { if (typeof onPreviousStep === "function") { onPreviousStep() } }}>
                      <FormattedMessage id="btn.back" defaultMessage="back" />
                    </button>
                    <button className="btn btn-primary text-capitalize" onClick={() => this.handleNext()} disabled={disabled}>
                      <FormattedMessage id="btn.next" defaultMessage="next" />
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        {this._renderRightPanel()}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  summary: _get(state, "campaignSetup.summary", {}),
  clientId: _get(state, "campaignSetup.summary.common.clientId", null),
  campaignId: _get(state, "campaignSetup.summary.common.campaignId", null),
  totalStep: _get(state, "campaignSetup.summary.common.totalStep", 8),
  currentStep: _get(state, "campaignSetup.summary.common.currentStep", 1),
  allowedUploadFiles: _get(state, "application.constants.allowedUploadFiles", ""),
  allowedUploadFilesLabel: _get(state, "application.constants.allowedUploadFilesLabel", ""),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  resetCampaignSetupError: () => dispatch(resetCampaignSetupError()),
  assignExistingSuppression: (data) => dispatch(assignExistingSuppression(data)),
  removeAssignedSuppressionList: (data) => dispatch(removeAssignedSuppressionList(data)),
  updateCampaignSetupDetails: (data) => dispatch(updateCampaignSetupDetails(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SuppressionList));
