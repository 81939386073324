import { call, fork, put, takeLatest } from "redux-saga/effects";
import { get as _get } from "lodash";

import { doGetUserList, doGetAgencyList, doCreateUser, doGetClientList, doCreateAgency, doCreateClient } from "../services/userMangement";

import { UPDATE_APP_LOADING } from "../actions/application";

import {
  GET_USER_LIST_REQUEST, GET_USER_LIST_SUCCESS, GET_USER_LIST_ERROR,
  GET_AGENCY_LIST_ERROR, GET_AGENCY_LIST_SUCCESS, GET_AGENCY_LIST_REQUEST,
  GET_CLIENT_LIST_REQUEST, GET_CLIENT_LIST_SUCCESS, GET_CLIENT_LIST_ERROR,
  CREATE_NEW_USER_REQUEST, CREATE_NEW_USER_SUCCESS, CREATE_NEW_USER_ERROR,
  CREATE_NEW_AGENCY_REQUEST, CREATE_NEW_AGENCY_SUCCESS, CREATE_NEW_AGENCY_ERROR,
  CREATE_NEW_CLIENT_REQUEST, CREATE_NEW_CLIENT_SUCCESS, CREATE_NEW_CLIENT_ERROR
} from "../actions/userMangement";

function* workerDoGetUserList(action) {

  try {

    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(doGetUserList, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: GET_USER_LIST_SUCCESS,
        data: (response.data || {}),
        message: (response.message || ""),
      });
    } else {
      yield put({
        type: GET_USER_LIST_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          data: {},
          message: _get(response, "message", "Something went wrong while fetching user list."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: GET_USER_LIST_ERROR,
      error: {
        data: {},
        message: _get(error, "response.message", "Something went wrong while fetching user list."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerDoGetAgencyList(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(doGetAgencyList, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: GET_AGENCY_LIST_SUCCESS,
        data: (response.data || {}),
        message: (response.message || ""),
      });
    } else {
      yield put({
        type: GET_AGENCY_LIST_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          data: {},
          message: _get(response, "message", "Something went wrong while fetching agency list."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: GET_AGENCY_LIST_ERROR,
      error: {
        data: {},
        message: _get(error, "response.message", "Something went wrong while fetching agency list."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerDoCreateUser(action) {

  try {

    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(doCreateUser, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: CREATE_NEW_USER_SUCCESS,
        data: (response.data || {}),
        message: (response.message || "User added successfully."),
      });
    } else {
      yield put({
        type: CREATE_NEW_USER_ERROR,
        error: {
          data: {},
          message: _get(response, "message", "Something went wrong while creating user."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: CREATE_NEW_USER_ERROR,
      error: {
        data: {},
        message: _get(error, "response.message", "Something went wrong while creating user."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerDoGetClientList(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(doGetClientList, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: GET_CLIENT_LIST_SUCCESS,
        data: (response.data || {}),
        message: (response.message || ""),
      });
    } else {
      yield put({
        type: GET_CLIENT_LIST_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          data: {},
          message: _get(response, "message", "Something went wrong while fetching client list."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: GET_CLIENT_LIST_ERROR,
      error: {
        data: {},
        message: _get(error, "response.message", "Something went wrong while fetching client list."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerDoCreateAgency(action) {

  try {

    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(doCreateAgency, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: CREATE_NEW_AGENCY_SUCCESS,
        data: (response.data || {}),
        message: (response.message || "Agency added successfully."),
      });
    } else {
      yield put({
        type: CREATE_NEW_AGENCY_ERROR,
        error: {
          data: {},
          message: _get(response, "message", "Something went wrong while creating agency."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: CREATE_NEW_AGENCY_ERROR,
      error: {
        data: {},
        message: _get(error, "response.message", "Something went wrong while creating agency."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerDoCreateClient(action) {

  try {

    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(doCreateClient, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: CREATE_NEW_CLIENT_SUCCESS,
        data: (response.data || {}),
        message: (response.message || "Client added successfully."),
      });
    } else {
      yield put({
        type: CREATE_NEW_CLIENT_ERROR,
        error: {
          data: {},
          message: _get(response, "message", "Something went wrong while creating client."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: CREATE_NEW_CLIENT_ERROR,
      error: {
        data: {},
        message: _get(error, "response.message", "Something went wrong while creating client."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* watcherDoGetUserList() {
  yield takeLatest(GET_USER_LIST_REQUEST, workerDoGetUserList);
}

function* watcherDoGetAgencyList() {
  yield takeLatest(GET_AGENCY_LIST_REQUEST, workerDoGetAgencyList);
}

function* watcherDoCreateUser() {
  yield takeLatest(CREATE_NEW_USER_REQUEST, workerDoCreateUser);
}

function* watcherDoCreateAgency() {
  yield takeLatest(CREATE_NEW_AGENCY_REQUEST, workerDoCreateAgency);
}

function* watcherDoCreateClient() {
  yield takeLatest(CREATE_NEW_CLIENT_REQUEST, workerDoCreateClient);
}

function* watcherDoGetClientList() {
  yield takeLatest(GET_CLIENT_LIST_REQUEST, workerDoGetClientList);
}

const userManagementSaga = [
  fork(watcherDoGetAgencyList),
  fork(watcherDoCreateUser),
  fork(watcherDoGetClientList),
  fork(watcherDoGetUserList),
  fork(watcherDoCreateAgency),
  fork(watcherDoCreateClient),
];

export default userManagementSaga;
