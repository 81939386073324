export default {

  //Site
  "hermes.logo_small_title": "CS",
  "hermes.logo_big_title": "Hermes",

  // Page Titles
  "hermes.login": "Hermes",
  "hermes.forgot_password": "Forgot Password: Hermes",
  "hermes.reset_password": "Reset Password: Hermes",
  "hermes.generic_page.coming_soon": "Coming soon: Hermes",
  "hermes.generic_page.not_found": "Page not found: Hermes",
  "hermes.dashboard": "Dashboard: Hermes",
  "hermes.settings": "Settings: Hermes",
  "hermes.campaign_dashboard": "Campaign Dashboard: Hermes",
  "hermes.campaign_content": "Campaign Content: Hermes",
  "hermes.campaign_data_history": "Campaign Data History: Hermes",
  "hermes.upload_lead": "Upload Lead: Hermes",
  "hermes.agency_dashboard": "Agency Dashboard: Hermes",

  // Common texts
  "email": "email",
  "username": "username",
  "password": "password",
  "new_password": "new password",
  "old_password": "old password",
  "confirm_password": "confirm password",
  "privacy_policy": "privacy policy",
  "terms_and_conditions": "Terms & Condition",
  "change_password": "change password",
  "logout": "Logout",
  "start_date": "start date",
  "end_date": "end date",
  "action": "action",
  "empty": "empty",
  "date": "date",
  "settings": "settings",
  "user": "user",
  "agency": "agency",
  "client": "client",
  "image": "image",
  "file": "file",
  "fields": "fields",
  "brief": "brief",
  "lead": "lead",
  "search": "search",
  "asset": "asset",
  "delete": "delete",
  "download": "download",
  "campaign": "campaign",

  // Common labels
  "label.select_field": "select {field}",
  "label.yes": "yes",
  "label.no": "no",

  // Modal
  "modal.confirm": "confirm",
  "modal.alert": "alert !",

  // Record count display
  "showing_records_of_total": "showing {currentPage} out of {totalPages}",

  //Menu
  "menu.dashboard": "dashboard",
  "menu.campaigns": "campaigns",
  "menu.campaign_list": "campaign list",
  "menu.campaign_setup": "campaign setup",
  "menu.admin": "admin",
  "menu.user_activity_log": "user activity log",
  "menu.user_management": "user management",
  "menu.terms_and_conditions": "terms & conditions",

  // error validation messages
  "error.required": "{field} is required.",
  "error.are_required": "{field} are required.",
  "error.select": "Please select {field}.",
  "error.select_atleast_one": "Please select at least one {field}.",
  "error.select_atleast_one_with_operation": "Please select at least one {field} to {operation}.",
  "error.select_only_one": "Please select only one {field}.",
  "error.select_single": "Please select single {field}.",
  "error.enter": "Please enter {field}.",
  "error.valid": "Please enter valid {field}.",
  "error.select_valid": "Please select valid {field}.",
  "error.invalid": "Invalid {field}.",
  "error.invalid_rate_value": "rate should be a number with up to 2 decimal points, less than 8 digits.",
  "error.password_not_match": "Confirm password does not match.",
  "error.password_min_char": "Password should contains minimum 8 characters.",
  "error.password_valid_char": "Password must be at least 8 characters long and contain an uppercase letter, a number, and a special character.",
  "error.exact_number_field": "Please enter {value} number {field}.",
  "error.less_than_value": "{field} should be less than {value}.",
  "error.atleast_value": "{field} should be atleast {value}.",
  "error.something_error": "There is some error inside the fields.",
  "error.less_than_value_character": "{field} should be less than {value} characters.",
  "error.after_start_date": "End date field should be after start date",
  "error.after_start_time": "End time should be after start time",
  "error.after_today": "End date should be today or future date.",
  "error.start_after_today": "Start date should be today or future date.",
  "error.date_after_today": "Date should be today or future date.",
  "error.greater_than_value": "{field} should be greater than {value}.",
  "error.greatereq_than_value": "{field} should be greater than or equal to {value}.",
  "error.image_upload": "Image not uploaded successfully",
  "error.after_date": "{field} should be greater than or equal to current date",
  "error.any_one_option_required": "Anyone option should be enable for {field}",
  "error.not_matched_with_val": "{field} not matched with {value}",
  "error.please_remove_uploaded_file": "Please remove uploaded file",
  "error.please_fill_up_the_proper_details": "Please fill up the proper details.",
  "error.campaign_id_is_missing": "Campaign id is missing, Please contact support team.",
  "error.file_size_is_larger_than": "File size is larger than {size} MB.",

  // Success message
  "success.updated_successfully": "{field} updated successfully.",
  "success.lead_status_updated_success": "Lead {status} successfully",

  //Placeholder
  "placeholder.select": "Select",
  "placeholder.select_field": "Select {field}",
  "placeholder.enter_field": "Enter {field}",
  "placeholder.please_enter_field": "Please enter {field}",
  "placeholder.fix_field": "Fix {field}",
  "placeholder.search": "search",
  "placeholder.select_all": "select all",
  "placeholder.start_typing_to_search": "start typing to search",

  // Message
  "message.no_found_data": "no data found",
  "message.this_action_will_reset_all_your_form_data": "Note: This action will reset all your form data",
  "message.access_error": "You don't have access to visit this page.",
  "message.file_generated_successfully": "File generated successfully.",

  // Confirm message
  "confirm.are_you_sure": "Are you sure want to {field}?",
  "confirm.are_you_sure_delete": "Are you sure want to delete {field}?",
  "confirm.are_you_sure_want_to_change_upload_type": "Are you sure want to change upload type?",
  "confirm.changes_you_made_may_not_be_saved": "Changes you made may not be saved.",
  "confirm.delete_asset": "Are you sure you want to remove assets from the campaign?",
  "confirm.delete_leads": "Are you sure you want to delete selected leads?",
  "confirm.change_lead_status": "Are you sure want to change the selected lead(s) status?",

  // File upload
  "file.browse": "Browse...",
  "file.no_file_selected": "No file selected.",
  "file.file_upload": "file upload",
  "file.drop_file_here_or_click_to_upload": "Drop files here or click to upload",
  "file.allowed_files": "Allowed file extensions : {allowedExtensions}",
  "file.file_name": "file name",
  "file.required_fields": "required fields",
  "file.invalid_values": "invalid values",
  "file.email": "email:",
  "file.row": "row:",
  "file.reasons": "reasons:",
  "file.reason_item": "{key} : {reason}",
  "file.removed": "File removed successfully.",
  "file.removed_error": "Something went wrong while removing file.",
  "file.data_upload.column_relationship": "column relationship",
  "file.download_sample_file": "Download sample file",

  // Buttons
  "btn.cancel": "cancel",
  "btn.submit": "submit",
  "btn.edit": "edit",
  "btn.update": "update",
  "btn.close": "close",
  "btn.reset": "reset",
  "btn.next": "next",
  "btn.back": "back",
  "btn.ok": "ok",
  "btn.reject": "reject",
  "btn.delete": "delete",
  "btn.yes": "yes",
  "btn.no": "no",
  "btn.continue": "continue",
  "btn.export": "export",
  "btn.filter": "filter",
  "btn.download": "download",
  "btn.send_email": "send email",

  //Login page
  "login.title": "Hermes login",
  "login.email": "email",
  "login.password": "password",
  "login.login": "Login",
  "login.remember_me": "remember me",
  "login.forgot_password_link": "Forgot your password?",

  //Forgot Password Page
  "forgot_password.title": "forgot password",
  "forgot_password.sub_title": "Please enter email address",
  "forgot_password.go_back_to": "Go back to",
  "forgot_password.login": " login",
  "forgot_password.email": "email",

  //Reset Password Page
  "reset_password.title": "reset password",
  "reset_password.sub_title": "Please enter new password below",
  "reset_password.new_password": "new password",
  "reset_password.confirm_password": "confirm password",
  "reset_password.show_password": "show password",
  "reset_password.hide_password": "hide password",

  //Generic page
  "generic_page.coming_soon.title": "coming soon",
  "generic_page.coming_soon.sub_title": "This page is under construction.",
  "generic_page.not_found.title": "not found",
  "generic_page.not_found.sub_title": "The requested page is not found.",

  // Date Range picker
  "date_range_picker.default_label": "select a date range",
  "date_range_picker.date_range": "{startDate} - {endDate}",

  // Profile
  "profile.title": "settings",
  "profile.change_password": "Change password",
  "profile.password": "Password",
  "profile.confirm_password": "Confirm password",
  "profile.profile_image": "Profile Image",
  "profile.upload_photo": "Upload Photo",

  //userManagement
  "user_management.title": "User Management",
  "user_management.create_new_field": "Create New {field}",
  "user_management.usertype_label": "User Type",
  "user_management.enabled_status_label": "Enabled Status",
  "user_management.usertype_option": "Admin or Operations",
  "user_management.username_label": "Username",
  "user_management.firstname_label": "First Name",
  "user_management.firstname": "first name",
  "user_management.lastname_label": "Last Name",
  "user_management.lastname": "last name",
  "user_management.email_label": "Email",
  "user_management.password_label": "Password",
  "user_management.confirmpassword_label": "Confirm Password",
  "user_management.client_name": "client name",
  "user_management.website_url": "website url",
  "user_management.leads_booked": "leads booked",
  "user_management.agency_option": "Select Agency",
  "user_management.upload_logo": "Upload Logo",
  "user_management.agency_name": "agency name",
  "user_management.email_address": "email address",
  "user_management.agency_placeholder": "Agency list",
  "user_management.client_placeholder": "Client list",
  "user_management.user.agency": "agency",
  "user_management.user.client": "client",
  "user_management.user.user_type": "userType",
  "user_management.user.list.table.column_name": "name",
  "user_management.user.list.table.column_last_login": "last login",
  "user_management.user.list.table.column_email": "email",
  "user_management.user.list.table.column_role": "role",
  "user_management.user.list.table.column_company": "company",
  "user_management.user.list.table.column_enabled": "enabled",
  "user_management.user.list.table.column_status": "status",
  "user_management.user.list.table.column_action": "action",
  "user_management.user.edit.modal.title": "edit user",

  // User Management > Agency
  "user_management.agency.list.table.column_name": "name",
  "user_management.agency.list.table.column_email": "email",
  "user_management.agency.list.table.column_website": "website",
  "user_management.agency.list.table.leads_booked": "leads booked",
  "user_management.agency.list.table.gross": "gross",
  "user_management.agency.list.table.net": "net",
  "user_management.agency.list.table.column_deleted": "deleted",
  "user_management.agency.list.table.column_locked": "locked",
  "user_management.agency.list.table.column_action": "action",
  "user_management.agency.list.action.edit": "edit",
  "user_management.agency.list.action.delete": "delete",
  "user_management.agency.list.modal.edit.title": "edit agency",
  "user_management.agency.create.agency_name": "agency name",
  "user_management.agency.create.email_address": "email address",
  "user_management.agency.create.website_url": "Website URL",
  "user_management.agency.create.gross": "gross",
  "user_management.agency.create.net": "net",

  // User Management > Client
  "user_management.client.client_name": "client name",
  "user_management.client.website_url": "website URL",
  "user_management.client.agency_option": "select agency",
  "user_management.client.upload_logo": "upload logo",
  "user_management.client.agency": "Agency",

  "user_management.edit_client.title": "edit client",
  "user_management.edit_client.client_name": "client name",
  "user_management.edit_client.website_url": "website URL",
  "user_management.edit_client.agency_option": "select agency",
  "user_management.edit_client.upload_logo": "upload logo",

  "user_management.client.list.table.column_name": "name",
  "user_management.client.list.table.column_agency": "agency",
  "user_management.client.list.table.column_website": "website",
  "user_management.client.list.table.column_deleted": "deleted",
  "user_management.client.list.table.column_locked": "locked",
  "user_management.client.list.table.column_action": "action",
  "user_management.client.list.action.edit": "edit",
  "user_management.client.list.action.delete": "delete",

  //dashboard
  "dashboard.title": "dashboard",
  "dashboard.active": "active",
  "dashboard.campaigns": "campaigns",
  "dashboard.leads": "leads",
  "dashboard.delivered": "delivered",
  "dashboard.leads_value": "leads value",
  "dashboard.contract": "contract",
  "dashboard.value": "value",
  "dashboard.outstanding": "outstanding",
  "dashboard.credit_value": "credit value",

  //agencyDashboard
  "agency_dashboard.add_credits": "add credits",
  "agency_dashboard.placeholder.credits": "credits",
  "agency_dashboard.total_credits": "total credits",
  "agency_dashboard.used_credits": "used credits",
  "agency_dashboard.available_credits": "available credits",
  "agency_dashboard.current_clients": "current clients",
  "agency_dashboard.live_campaigns": "live campaigns",
  "agency_dashboard.lead_value_delivered": "lead value delivered",
  "agency_dashboard.contract_gross_value": "contract gross value",
  "agency_dashboard.value_available": "value available",
  "agency_dashboard.traditional_media": "agency profit using traditional media",
  "agency_dashboard.rethink_demand": "agency profit using rethink demand",
  "agency_dashboard.additional_profit": "additional profit using rethink demand",

  // CampaignSetup
  "campaign_setup.title": "Campaign Setup",
  "campaign_setup.summary.step1": "Campaign Details Step 1 of {totalStep}",
  "campaign_setup.summary.step2": "Assets Step 2 of {totalStep}",
  "campaign_setup.summary.step3": "Account List Step 3 of {totalStep}",
  "campaign_setup.summary.step4": "Suppression List Step 4 of {totalStep}",
  "campaign_setup.summary.step5": "Media Plan Upload Step 5 of {totalStep}",
  "campaign_setup.summary.step6": "Required Fields Step 6 of {totalStep}",

  // CampaignSetup > Campaign Detail
  "campaign_setup.campaign_detail.title": "campaign details",
  "campaign_setup.campaign_detail.agency_label": "agency name",
  "campaign_setup.campaign_detail.agency_list": "agency list",
  "campaign_setup.campaign_detail.marketing_practice": "The Marketing Practice",
  "campaign_setup.campaign_detail.client_label": "client name",
  "campaign_setup.campaign_detail.currency": "currency",
  "campaign_setup.campaign_detail.campaign_label": "campaign name",
  "campaign_setup.campaign_detail.po_number_label": "PO number",
  "campaign_setup.campaign_detail.rate": "rate",
  "campaign_setup.campaign_detail.rate_type": "rate type",
  "campaign_setup.campaign_detail.campaign_start_date": "campaign start date",
  "campaign_setup.campaign_detail.campaign_end_date": "campaign end date",
  "campaign_setup.campaign_detail.start_date": "Start Date",
  "campaign_setup.campaign_detail.end_date": "End Date",
  "campaign_setup.campaign_detail.assign.start_date_placeholder": "Choose a start date",
  "campaign_setup.campaign_detail.assign.end_date_placeholder": "Choose an end date",
  "campaign_setup.campaign_detail.type_only_text_number": "PO number should only contain text and number.",
  "campaign_setup.campaign_detail.upload_day": "upload day",

  // CampaignSetup > Assets
  "campaign_setup.assets.title": "assets",
  "campaign_setup.assets.none": "none",
  "campaign_setup.assets.use_existing": "use existing",
  "campaign_setup.assets.upload_new": "upload new",
  "campaign_setup.assets.physical_event": "physical event",
  "campaign_setup.assets.assets": "assets",
  "campaign_setup.assets.search_assets": "search assets",
  "campaign_setup.assets.name": "name",
  "campaign_setup.assets.enter_name": "Enter name",
  "campaign_setup.assets.asset_code": "asset code",
  "campaign_setup.assets.topic": "topic",
  "campaign_setup.assets.sub_topic": "sub topic",
  "campaign_setup.assets.file_upload": "file upload",

  "campaign_setup.assets.create.title": "create asset",
  "campaign_setup.assets.create.label_name": "name",
  "campaign_setup.assets.create.label_topics": "topics",
  "campaign_setup.assets.create.label_sub_topics": "sub topics",
  "campaign_setup.assets.create.label_asset_code": "asset code",
  "campaign_setup.assets.create.label_asset_type": "asset type",
  "campaign_setup.assets.create.label_url": "Url",
  "campaign_setup.assets.create.label_edit_url": "(edit)",

  // CampaignSetup > Account List
  "campaign_setup.account_list.title": "account list",
  "campaign_setup.account_list.none": "none",
  "campaign_setup.account_list.use_existing": "use existing",
  "campaign_setup.account_list.upload_new": "upload new",
  "campaign_setup.account_list.user_existing_label": "using existing",
  "campaign_setup.account_list.account": "account",

  // CampaignSetup > Suppression List
  "campaign_setup.suppression_list.title": "suppression list",
  "campaign_setup.suppression_list.none": "none",
  "campaign_setup.suppression_list.use_existing": "use existing",
  "campaign_setup.suppression_list.upload_new": "upload new",
  "campaign_setup.suppression_list.user_existing_label": "using existing",
  "campaign_setup.suppression_list.suppression": "suppression",

  // CampaignSetup > Upload Media plan
  "campaign_setup.media_plan.title": "Upload Media Plan",
  "campaign_setup.media_plan.media_plan_file": "media plan file",
  "campaign_setup.media_plan.upload_new": "upload new",

  // CampaignSetup > requiredFields
  "campaign_setup.required_fields.title": "required fields",
  "campaign_setup.required_fields.salutation_label": "salutation",
  "campaign_setup.required_fields.first_name_label": "first name",
  "campaign_setup.required_fields.last_name_label": "last name",
  "campaign_setup.required_fields.job_title_label": "job title",
  "campaign_setup.required_fields.seniority_level_label": "seniority level",
  "campaign_setup.required_fields.set_values": "set values",
  "campaign_setup.required_fields.department_job_function_label": "department/job function",
  "campaign_setup.required_fields.address_line_1_label": "address line 1",
  "campaign_setup.required_fields.address_line_2_label": "address line 2",
  "campaign_setup.required_fields.address_line_3_label": "address line 3",
  "campaign_setup.required_fields.city_label": "city",
  "campaign_setup.required_fields.postcode_label": "post code",
  "campaign_setup.required_fields.country_label": "country",
  "campaign_setup.required_fields.phone_switchboard_label": "phone switch board",
  "campaign_setup.required_fields.linkedin_url_label": "linkedin URL",
  "campaign_setup.required_fields.email_label": "email",
  "campaign_setup.required_fields.website_label": "website",
  "campaign_setup.required_fields.employees_label": "employees",
  "campaign_setup.required_fields.revenue_label": "revenue",
  "campaign_setup.required_fields.industry_label": "industry",
  "campaign_setup.required_fields.asset_code_label": "asset code",
  "campaign_setup.required_fields.tactic_code_label": "tactic code",
  "campaign_setup.required_fields.note_label": "note",
  "campaign_setup.required_fields.lead_action_at_label": "lead action at",
  "campaign_setup.required_fields.freetext1_label": "freetext 1",
  "campaign_setup.required_fields.freetext2_label": "freetext 2",
  "campaign_setup.required_fields.freetext3_label": "freetext 3",
  "campaign_setup.required_fields.freetext4_label": "freetext 4",
  "campaign_setup.required_fields.freetext5_label": "freetext 5",
  "campaign_setup.required_fields.freetext6_label": "freetext 6",
  "campaign_setup.required_fields.freetext7_label": "freetext 7",
  "campaign_setup.required_fields.freetext8_label": "freetext 8",
  "campaign_setup.required_fields.freetext9_label": "freetext 9",
  "campaign_setup.required_fields.freetext10_label": "freetext 10",
  "campaign_setup.required_fields.brief_name_label": "brief name",
  "campaign_setup.required_fields.gdpr_consent_label": "GDPR consent",
  "campaign_setup.required_fields.gdpr_consent_date_label": "GDPR consent date",
  "campaign_setup.required_fields.gdpr_consent_message_label": "GDPR consent message",

  // CampaignSetup > campaignBrief
  "campaign_setup.campaign_brief.select_paceholder": "assign",
  "campaign_setup.campaign_brief.add_new_brief": "add new brief",
  "campaign_setup.campaign_brief.table.column.id": "ID",
  "campaign_setup.campaign_brief.table.column.name": "name",
  "campaign_setup.campaign_brief.table.column.tactic_code": "tactic code",
  "campaign_setup.campaign_brief.table.column.supplier": "supplier",
  "campaign_setup.campaign_brief.table.column.date": "date",
  "campaign_setup.campaign_brief.table.column.criteria": "criteria",
  "campaign_setup.campaign_brief.table.column.region": "region",
  "campaign_setup.campaign_brief.table.column.quantity": "quantity",
  "campaign_setup.campaign_brief.table.column.rate": "rate",
  "campaign_setup.campaign_brief.table.column.rate_type": "rate type",
  "campaign_setup.campaign_brief.table.column.site": "site",
  "campaign_setup.campaign_brief.table.column.notes": "notes",
  "campaign_setup.campaign_brief.add_edit_brief": "Add/Edit Brief",
  "campaign_setup.campaign_brief.add_edit.name": "name",
  "campaign_setup.campaign_brief.add_edit.tactic_code": "tactic code",
  "campaign_setup.campaign_brief.add_edit.start_date": "start date",
  "campaign_setup.campaign_brief.add_edit.end_date": "end date",
  "campaign_setup.campaign_brief.add_edit.start_date_placeholder": "Choose a start date",
  "campaign_setup.campaign_brief.add_edit.end_date_placeholder": "Choose an end date",
  "campaign_setup.campaign_brief.add_edit.region": "region",
  "campaign_setup.campaign_brief.add_edit.quantity": "quantity",
  "campaign_setup.campaign_brief.add_edit.rate": "rate",
  "campaign_setup.campaign_brief.add_edit.rate_type": "rate type",
  "campaign_setup.campaign_brief.add_edit.select_supplier": "select supplier",
  "campaign_setup.campaign_brief.add_edit.select_currency": "select currency",
  "campaign_setup.campaign_brief.add_edit.supplier": "supplier",
  "campaign_setup.campaign_brief.add_edit.currency": "currency",
  "campaign_setup.campaign_brief.add_edit.site": "site",
  "campaign_setup.campaign_brief.add_edit.notes": "notes",
  "campaign_setup.campaign_brief.action": "action",
  "campaign_setup.campaign_brief.assign": "assign",
  "campaign_setup.campaign_brief.assign.data_type": "assign {dataType}",
  "campaign_setup.campaign_brief.assign.modal.title": "assign {dataType} to briefs",
  "campaign_setup.campaign_brief.assign.account": "account",
  "campaign_setup.campaign_brief.assign.reset_account": "reset",
  "campaign_setup.campaign_brief.assign.job_functions": "job functions",
  "campaign_setup.campaign_brief.assign.industry": "industry",
  "campaign_setup.campaign_brief.assign.company_size": "company size",
  "campaign_setup.campaign_brief.assign.region": "region",
  "campaign_setup.campaign_brief.assign.suppression": "suppression",
  "campaign_setup.campaign_brief.assign.dates": "dates",
  "campaign_setup.campaign_brief.assign.start_date": "start date",
  "campaign_setup.campaign_brief.assign.end_date": "end date",
  "campaign_setup.campaign_brief.assign.start_date_placeholder": "Choose a start date",
  "campaign_setup.campaign_brief.assign.end_date_placeholder": "Choose an end date",
  "campaign_setup.campaign_brief.enable_personality_card": "enable personality card",
  "campaign_setup.campaign_brief.assign.modal.enable": "enable",
  "campaign_setup.campaign_brief.assign.assets": "assets",
  "campaign_setup.campaign_brief.edit_required_fields": "edit required fields",

  // Campaign brief: brief criteria
  "campaign_brief.criteria.title": "brief criteria",
  "campaign_brief.criteria.employees_formatted": "company size: ",
  "campaign_brief.criteria.company_size": "company size: ",
  "campaign_brief.criteria.industry_formatted": "industry: ",
  "campaign_brief.criteria.industry": "industry: ",
  "campaign_brief.criteria.job_function": "job function: ",
  "campaign_brief.criteria.notes": "notes: ",
  "campaign_brief.criteria.region": "region: ",
  "campaign_brief.criteria.asset": "assets: ",
  "campaign_brief.criteria.suppression": "suppression: ",
  "campaign_brief.criteria.job_title": "job title: ",
  "campaign_brief.criteria.job_titles": "job titles: ",
  "campaign_brief.criteria.criteria": "brief criteria: ",
  "campaign_brief.criteria.outcome": "outcome: ",
  "campaign_brief.criteria.assets": "assets: ",
  "campaign_brief.modal.date_start_date": "start date",
  "campaign_brief.modal.date_end_date": "end date",

  // CampaignSetup > initiateCampaign
  "campaign_setup.initiate_campaign.initiate_campaign": "initiate campaign",

  // CampaignSetup > campaignSetupProgress
  "campaign_setup.campaign_setup_progress.campaign_setup_progress": "campaign setup progress",
  "campaign_setup.campaign_setup_progress.campaign_name": "campaign name",
  "campaign_setup.campaign_setup_progress.dates": "dates",

  //CampaignList
  "campaign_list.tab.live": "live",
  "campaign_list.tab.completed": "completed",
  "campaign_list.select.agency.label": "agency",
  "campaign_list.select.client.label": "client",
  "campaign_list.select.client.placeholder": "select client",
  "campaign_list.select.agency.placeholder": "select agency",
  "campaign_list.campaign_box.campaigns": "campaigns",
  "campaign_list.campaign_box.completed": "completed",
  "campaign_list.campaign_box.leads_generated": "leads generated",
  "campaign_list.campaign_box.approved": "approved",
  "campaign_list.campaign_box.leads_panding": "leads pending",
  "campaign_list.campaign_box.pending": "pending",
  "campaign_list.campaign_box.leads_rejected": "leads rejected",
  "campaign_list.campaign_box.rejected": "rejected",
  "campaign_list.live.table.column_id": "id",
  "campaign_list.live.table.column_client_name": "client name",
  "campaign_list.live.table.column_jobtype": "job type",
  "campaign_list.live.table.column_campaign_name": "campaign name",
  "campaign_list.live.table.column_type": "type",
  "campaign_list.live.table.column_percentage": "%",
  "campaign_list.live.table.column_start": "start",
  "campaign_list.live.table.column_end": "end",
  "campaign_list.button.mark_as_complete": "mark as complete",
  "campaign_list.button.mark_as_live": "mark as live",
  "campaign_list.live.table.column_client_logo": "client logo",
  "campaign_list.live.table.column_booked": "booked",
  "campaign_list.live.table.column_delivered": "delivered",
  "campaign_list.live.table.column_new": "new",
  "campaign_list.live.table.column_mark_as_complete": "mark as complete",
  "campaign_list.live.table.column_agency": "agency",
  "campaign_list.live.table.column_client": "client",
  "campaign_list.live.table.column_ponumber": "PO number",
  "campaign_list.live.table.column_upload_day": "upload day",
  "campaign_list.select.date.label": "date",
  "campaign_list.campaign": "campaign",
  "campaign_list.model_header_delete_campaign": "delete campaign",
  "campaign_list.model.radio_button.delete_permanently": "delete permanently",
  "campaign_list.model.radio_button.mark_as_deleted": "mark as deleted",
  "campaign_list.mark_as_complete_campaign": "mark this campaign as completed",
  "campaign_list.mark_as_live_campaign": "mark this campaign as live",
  "campaign_list.file_generated_successfully": "File generated successfully.",

  // Campaign Dashboard
  "campaign_dashboard.title": "dashboard",
  "campaign_dashboard.campaign_tracker": "campaign tracker",
  "campaign_dashboard.tiles.completed": "completed",
  "campaign_dashboard.tiles.approved_leads": "approved leads",
  "campaign_dashboard.tiles.rejected_leads": "rejected leads",
  "campaign_dashboard.tiles.pending_leads": "pending leads",

  "campaign_dashboard.btn.edit_campaign": "edit campaign",
  "campaign_dashboard.btn.campaign_content": "campaign content",
  "campaign_dashboard.btn.upload_leads": "upload leads",
  "campaign_dashboard.btn.data_history": "data history",
  "campaign_dashboard.btn.view_leads": "view leads",
  "campaign_dashboard.btn.mark_as_completed": "mark as completed",

  // Campaign Content
  "campaign_content.title": "campaign content",
  "campaign_content.table.asset_name": "asset name",
  "campaign_content.table.topic": "topic",
  "campaign_content.table.sub_topic": "sub topic",
  "campaign_content.table.asset_code": "asset code",
  "campaign_content.table.file_name": "file name",
  "campaign_content.table.download": "download",
  "campaign_content.btn.campaign_dashboard": "campaign dashboard",
  "campaign_content.btn.add": "add",
  "campaign_content.btn.delete": "delete",
  "campaign_content.btn.export": "export",
  "campaign_content.btn.download_selected": "download selected",
  "campaign_content.create_asset_modal.title": "create asset",
  "campaign_content.create_asset_modal.asset_type": "asset type",
  "campaign_content.create_asset_modal.name": "name",
  "campaign_content.create_asset_modal.topics": "topics",
  "campaign_content.create_asset_modal.sub_topics": "sub topics",
  "campaign_content.create_asset_modal.asset_code": "asset code",
  "campaign_content.create_asset_modal.url": "url",
  "campaign_content.create_asset_modal.edit_url": "(edit)",
  "campaign_content.create_asset_modal.existing_assets": "use existing assets",
  "campaign_content.create_asset_modal.create_new_asset": "Create new asset",
  "campaign_content.create_asset_modal.uploaded_file": "Uploaded File",
  "campaign_content.placeholder.create_new_topic": "Click to create new topic: ",
  "campaign_content.placeholder.create_new_sub_topic": "Click to create new sub topic: ",
  "campaign_content.assets.label_asset_type": "asset type",
  "campaign_content.assets.physical_asset": "Physical Asset",
  "campaign_content.assets.web_asset": "Web Asset",
  "campaign_content.create_asset_modal.verify": " (verify)",

  // Upload Lead
  "upload_lead.title": "upload lead",
  "upload_lead.btn.campaign_dashboard": "campaign dashboard",
  "upload_lead.btn.export_upload_template": "export upload template",
  "upload_lead.btn.get_api_keys": "Get API Keys",
  "upload_lead.btn.download_rejected_leads": "download rejected leads",
  "upload_lead.btn.match_values": "match values",
  "upload_lead.lead_upload": "lead upload",
  "upload_lead.attached_file": "attached file",
  "upload_lead.lead_ownership": "Lead Ownership",

  //Campaign Brief
  "campaign_brief.title": "campaign brief",
  "campaign_brief.btn.campaign_dashboard": "campaign dashboard",
  "campaign_brief.btn.all_brief_export": "all brief export",
  "campaign_brief.btn.export_upload_template": "export upload template",
  "campaign_brief.btn.copy_brief": "copy brief",
  "campaign_brief.no_brief_available": "No briefs available to export.",
  "campaign_brief.copy_brief.title": "copy brief",
  "campaign_brief.export_upload_template.title": "export upload template",
  "campaign_brief.export_upload_template.email_to_label": "email to",
  "campaign_brief.export_upload_template.email_to": "email to: (add email addresses separated by comma)",
  "campaign_brief.export_upload_template.note": "note",
  "campaign_brief.export_upload_template.add_me_to_cc": "add me to CC",

  // View Leads
  "view_leads.title": "view leads",
  "view_leads.btn.mark_as_approved": "mark as approved",
  "view_leads.btn.mark_as_rejected": "mark as rejected",
  "view_leads.btn.mark_as_pending": "mark as pending",
  "view_leads.filter.export": "export",
  "view_leads.filter.update": "update",
  "view_leads.filter.filter": "filter",
  "view_leads.filter.search": "search",
  "view_leads.table.lead_id": "Lead ID",
  "view_leads.table.date": "Date",
  "view_leads.table.company": "Company",
  "view_leads.table.name": "Name",
  "view_leads.table.email": "Email",
  "view_leads.table.job_title": "Job Title",
  "view_leads.table.qa_delivery": "QA Delivery",
  "view_leads.table.qa_criteria": "QA Criteria",
  "view_leads.table.approved": "Approved",
  "view_leads.table.card_status": "Card status",
  "view_leads.reject_lead_modal.title": "Reject Lead",
  "view_leads.reject_lead_modal.reason": "reason",
  "view_leads.reject_lead_modal.rejection_type": "rejection type",
  "view_leads.filter.title": "filter",
  "view_leads.filter.approval": "approval",
  "view_leads.filter.assets": "assets",
  "view_leads.filter.upload_ids": "Upload IDs",
  "view_leads.filter.column": "column",
  "view_leads.filter.value": "value",
  "view_leads.filter.piped": "piped",
  "view_leads.filter.instruction": "Instruction",
  "view_leads.filter.instruction_message": "Multiple values should be separated by new line.\n-- Use wild card character * to find matched values.\n Multiple values are not allowed in case of wild card.",
  "view_leads.update.column": "column",
  "view_leads.update.value": "value",
  "view_leads.pipe_to_crm": "Pipe to CRM",
  "view_leads.crm": "CRM",
  "view_leads.pipe_to_crm.pipe_to_crm_is_not_configured_yet_for_this_campaign": "Pipe-to-CRM is not configured yet for this campaign.",
  "view_leads.pipe_to_crm.no_of_leads_selected": "No. of leads selected: ",
  "view_leads.pipe_to_crm.add_emails_with_comma_seperated": "add emails with comma (,) seperated",
  "view_leads.knob.total_uploaded": "{total} Records Uploaded to Client CRM",
  "view_leads.knob.total_awaiting": "{total} Records Awaiting Upload to Client CRM",
  "view_leads.knob.total_pending": "{total} Records Pending Upload to Client CRM",
  "view_leads.knob.failed": "{total} Records Failed During Upload to Client CRM",
  "view_leads.select_leads": "select leads",
  "view_leads.select_all_pages": "select all pages",
  "view_leads.select_this_page": "select this page only",

  // Campaign History
  "campaign_history.title": "data history",
  "campaign_history.table_column.column_id": "id",
  "campaign_history.table_column.file_name": "file name",
  "campaign_history.table_column.upload_type": "upload type",
  "campaign_history.table_column.records": "records",
  "campaign_history.table_column.processed": "processed",
  "campaign_history.table_column.attempted": "attempted",
  "campaign_history.table_column.approved": "approved",
  "campaign_history.table_column.panding": "panding",
  "campaign_history.table_column.rejected": "rejected",
  "campaign_history.table_column.user": "user",
  "campaign_history.table_column.company": "company",
  "campaign_history.table_column.upload_date": "upload date",
};

