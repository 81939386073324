import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { get as _get } from "lodash";
import storage from "redux-persist/lib/storage";

import { DEFAULT_WEB_LANGUAGE, tempConstant } from "../../helpers/constants";
// import { sidebarMenuConfiguration } from "../../helpers/sidebarMenuConfiguration";
import { getDeviceModeByWidth } from "../../helpers/utils";
import configs from "../../helpers/config";

import actionTypes from "../actions";

const persistConfigData = {
  key: `${configs.store_name}__language`,
  storage,
};

const loading = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_APP_LOADING:
      return action.loading;

    default:
      return state;
  }
}

const initialViewPortSize = {
  width: window.innerWidth,
  height: window.innerHeight,
  mode: getDeviceModeByWidth(window.innerWidth)
}

const viewport = (state = initialViewPortSize, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_VIEWPORT:
      return ({
        ...action.size,
        mode: getDeviceModeByWidth(action.size.width)
      });

    default:
      return state;
  }
}

// const menuConfig = (state = { ...sidebarMenuConfiguration }, action) => {
const menuConfig = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_CONFIGURATION_SUCCESS:
      return _get(action, "payload.config.menu", {});

    default:
      return state;
  }
}

const accessList = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_CONFIGURATION_SUCCESS:
      return _get(action, "payload.permissions", []);

    case actionTypes.DELETE_AUTHENTICATION_TOKEN_REQUEST:
      return [];

    default:
      return state;
  }
}

const language = (state = DEFAULT_WEB_LANGUAGE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LANGUAGE:
      return action.language;

    default:
      return state;
  }
}

const currentActiveMenu = (state = "", action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_ACTIVE_MENU:
      return (action.payload || "");

    default:
      return state;
  }
}

const languages = combineReducers({
  defaultLanguage: language
});

const constants = (state = tempConstant, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_CONFIGURATION_SUCCESS:
      // return action.payload.constants || {};
      return state;

    default:
      return state;
  }
}

const isNavbarVisible = (state = true, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_NAVBAR_MENU:
      return (action.payload || false);

    default:
      return state;
  }
}

export default {
  application: combineReducers({
    loading,
    currentActiveMenu,
    isNavbarVisible,
    viewport,
    language: persistReducer(persistConfigData, languages),
    menuConfig,
    accessList,
    constants,
  })
}
