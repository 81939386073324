import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty, } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { showAlertMessage, SingleDropzone } from "../../../resusableComponents/common/formElements";
import DownloadSampleFile from "../../../resusableComponents/common/DownloadSampleFile";
import CampaignSetupProgress from "./CampaignSetupProgress";

import { resetCampaignSetupError, updateCampaignSetupDetails } from "../../../redux/actions/campaignSetup";
import { updateLoadingState } from "../../../redux/actions/application";

class UploadMediaPlan extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fileUploaded: false,
      lastInsertedId: null,
      uploadedFileName: "",
      uploadedFileURL: "",
      disabled: false,
      isEditMode: false,
      errors: {}
    };
  }

  componentDidMount() {
    const { summary, summary: { common: { lastFilledStep } }, currentStep } = this.props;

    //Set default or existing values
    this.setState({
      fileUploaded: _get(summary, `${currentStep}.fileUploaded`, false),
      uploadedFileName: _get(summary, `${currentStep}.uploadedFileName`, ""),
      uploadedFileURL: _get(summary, `${currentStep}.uploadedFileURL`, ""),
      lastInsertedId: _get(summary, `${currentStep}.lastInsertedId`, null),
      isEditMode: (lastFilledStep > currentStep) ? true : false
    });
  }

  componentDidUpdate(prevProps, prevStates) {
    if (!_isEqual(prevProps.setupErrors, this.props.setupErrors) && !_isEmpty(this.props.setupErrors)) {
      showAlertMessage(_get(this.props, "setupErrors.message", ""));

      if (typeof this.props.resetCampaignSetupError === "function") { this.props.resetCampaignSetupError(); }
    }
  }

  handleNext = () => {
    const { summary: { common }, summary: { common: { lastFilledStep } }, currentStep, updateCampaignSetupDetails, intl } = this.props;
    const { fileUploaded, lastInsertedId, uploadedFileName, uploadedFileURL } = this.state;

    if (_isEmpty(uploadedFileName)) {
      showAlertMessage(intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.media_plan.media_plan_file", defaultMessage: "Media plan file" })
      }));
      return false;
    }

    if ((typeof updateCampaignSetupDetails === "function")) {
      const variable = {
        "common": {
          ...common,
          currentStep: (currentStep + 1),
          lastFilledStep: (lastFilledStep > currentStep) ? lastFilledStep : (currentStep + 1),
        },
        [currentStep]: {
          summaryText: intl.formatMessage({ id: "campaign_setup.media_plan.title", defaultMessage: "Upload Media Plan" }),
          fileUploaded: (fileUploaded || false),
          lastInsertedId: (lastInsertedId || null),
          uploadedFileName: (uploadedFileName || ""),
          uploadedFileURL: (uploadedFileURL || ""),
        }
      }

      updateCampaignSetupDetails(variable);
    }
  }

  _renderUploadMPFile = () => {
    const { campaignId, clientId, allowedMediaPlanFiles, allowedMediaPlanFilesLabel } = this.props;
    const { uploadedFileName, uploadedFileURL, lastInsertedId } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-lg-6">
            <div className="mb-6">
              <SingleDropzone
                acceptedFormat={allowedMediaPlanFiles}
                allowedExtensions={allowedMediaPlanFilesLabel}
                setState={(args) => this.setState(args)}
                source="brief_file"
                campaignId={(campaignId || null)}
                clientId={(clientId || null)}
                uploadedFileName={uploadedFileName}
                uploadedFileURL={uploadedFileURL}
                lastInsertedId={lastInsertedId}
                preserveSelectedFile={true}
                displayUploadedFiles={true}
                displayUploadedRemoveBtn={true}
              />
            </div>
            <DownloadSampleFile source="brief_file" />
          </div>
        </div>
      </>
    );
  }

  render() {
    const { currentStep, totalSteps, onPreviousStep } = this.props;

    return (
      <div className="row">
        <div className="col-lg-8">
          <div className="card">
            <div className="card-body py-5 vh-165">
              <h6 className="heading-04 fw-semibold mb-6">{`Step ${currentStep} of ${totalSteps} - Upload Media Plan`}</h6>

              {this._renderUploadMPFile()}

              <div className="row mt-auto">
                <div className="col-lg-12">
                  <div className="d-flex align-items-center justify-content-between gap-4 mt-4">
                    <button className="btn btn-primary text-capitalize" onClick={() => { if (typeof onPreviousStep === "function") { onPreviousStep() } }}>
                      <FormattedMessage id="btn.back" defaultMessage="back" />
                    </button>
                    <button className="btn btn-primary text-capitalize" onClick={() => this.handleNext()}>
                      <FormattedMessage id="btn.next" defaultMessage="next" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <CampaignSetupProgress />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  summary: _get(state, "campaignSetup.summary", {}),
  clientId: _get(state, "campaignSetup.summary.common.clientId", null),
  campaignId: _get(state, "campaignSetup.summary.common.campaignId", null),
  currentStep: _get(state, "campaignSetup.summary.common.currentStep", 1),
  allowedMediaPlanFiles: _get(state, "application.constants.allowedMediaPlanFiles", ""),
  allowedMediaPlanFilesLabel: _get(state, "application.constants.allowedMediaPlanFilesLabel", ""),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  resetCampaignSetupError: () => dispatch(resetCampaignSetupError()),
  updateCampaignSetupDetails: (data) => dispatch(updateCampaignSetupDetails(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UploadMediaPlan));
