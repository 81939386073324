import React, { Component } from "react";
import { cloneDeep as _cloneDeep, get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { withCookies, Cookies } from "react-cookie";
import { Navigate, Link } from "react-router-dom";
import { instanceOf } from "prop-types";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { ErrorMessage } from "../../resusableComponents/common/formElements";
import { encryptUserData, decryptUserData } from "../../helpers/crypto";
import { withRouter } from "../../resusableComponents/hoc/withRouter";
import { isEmailValid } from "../../helpers/utils";

import { doLogin, clearAuthenticationError } from "../../redux/actions/authentication";

class LoginUser extends Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;
    const tokenUserData = decryptUserData(cookies.get("loginData"));

    this.defaultFormData = { userName: _get(tokenUserData, "userName", ""), password: _get(tokenUserData, "password", ""), rememberMe: _get(tokenUserData, "rememberMe", false) };

    this.state = {
      formData: _cloneDeep(this.defaultFormData),
      formErrors: {},
      alertError: {},
      isLoginButtonClicked: false,
      disabled: true
    };
  }

  componentDidMount() {
    const { loginError, clearAuthenticationError } = this.props;

    if (!_isEmpty(loginError)) {
      this.setState({
        alertError: {
          title: _get(loginError, "title", "Alert"),
          message: _get(loginError, "message", "Something went wrong while login in.")
        }
      });

      if (typeof clearAuthenticationError === "function") { clearAuthenticationError(); }
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.token, _get(this.props, "userToken", "")) && !_isEmpty(_get(this.props, "userToken", ""))) {
      const { cookies, userToken } = this.props;

      const tokenCookie = encryptUserData({ "userToken": userToken });
      cookies.set("userTokenData", tokenCookie, { path: "/", domain: `.${process.env.REACT_APP_FRONTEND_WEB_APP_DOMAIN}` });
    }

    if (!_isEqual(prevProps.loginError, _get(this.props, "loginError", {})) && !_isEmpty(_get(this.props, "loginError", {}))) {
      this.setState({
        alertError: {
          title: _get(this.props, "loginError.title", "Alert"),
          message: _get(this.props, "loginError.message", ""),
        },
      });

      if (typeof this.props.clearAuthenticationError === "function") { this.props.clearAuthenticationError(); }
    }

    if (!_isEqual(prevState.formData, _get(this.state, "formData", {}))) { this._handleValidation(); }
  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { formData: { userName, password } } = this.state;
    let formErrors = {};

    if (!isEmailValid(userName)) {
      formErrors["userName"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}" }, {
        field: intl.formatMessage({ id: "email", defaultMessage: "email" }),
      });
    }

    if (_isEmpty(password)) {
      formErrors["password"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "password", defaultMessage: "password" }),
      });
    }

    this.setState({
      alertError: {},
      disabled: !_isEmpty(formErrors),
      formErrors
    });
  }

  _doLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { doLogin, cookies } = this.props;
    const { formData: { userName, password, rememberMe }, formErrors } = this.state;

    if (_isEmpty(userName) || _isEmpty(password) || !_isEmpty(formErrors)) {
      this._handleValidation();

      return;
    }

    if (typeof doLogin === "function") { doLogin({ userName: userName, password: password }); }

    const saveCookieData = encryptUserData({ "userName": (userName || ""), "password": (password || ""), "rememberMe": (rememberMe || false) });

    if (rememberMe === true) {

      cookies.set("loginData", saveCookieData, { path: "/", domain: `.${process.env.REACT_APP_FRONTEND_WEB_APP_DOMAIN}` });
    } else {
      cookies.remove("loginData", { path: "/", domain: `.${process.env.REACT_APP_FRONTEND_WEB_APP_DOMAIN}` });
    }
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = (e.target || {});

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value
      },
      alertError: {}
    }));
  }

  render() {
    const { userToken, location: { search = "" }, roleWiseRedirections, roleId, intl } = this.props;
    const { formData, formErrors, alertError, isLoginButtonClicked } = this.state;

    let redirectUrl = "/";

    if (!_isEmpty(userToken)) {
      const params = new URLSearchParams(search);

      (roleWiseRedirections || []).forEach((role) => {
        if ((role.roleIds || []).includes(roleId) && role.redirectionPath !== null) {
          redirectUrl = (role.redirectionPath || "/");
        }
      });

      const url = (params.get("continue") || redirectUrl);

      return (<Navigate to={url} />);
    }

    return (
      <>
        <Helmet>
          <title>
            {intl.formatMessage({
              id: "hermes.login",
              defaultMessage: "Hermes",
            })}
          </title>
        </Helmet>

        <div className="login-wrapper">
          <div className="card">
            <div className="card-header">
              <img src={require("../../assets/images/logo.svg").default} className="img-logo img-fluid mx-auto d-block" alt="Hermes" />
            </div>

            <div className="card-body">
              {!_isEmpty(alertError) && (
                <div className="mb-3 text-danger text-capitalize-first">
                  <span>{(alertError.message || "")}</span>
                </div>
              )}

              <Form autoComplete="off" onSubmit={(e) => this._doLogin(e)}>
                <div className="mb-5">
                  <label className="form-label text-capitalize" htmlFor="useremail">
                    <FormattedMessage id="login.email" defaultMessage="email" />
                  </label>
                  <input id="useremail"
                    type="text"
                    name="userName"
                    className="form-control"
                    placeholder="Email"
                    value={_get(formData, "userName", "")}
                    onChange={(e) => this._handleChange(e)}
                  />
                  {(_get(formErrors, "userName", "")) && ((isLoginButtonClicked || false) === true) && (<ErrorMessage message={_get(formErrors, "userName", "")} />)}
                </div>

                <div className="mb-5">
                  <label className="form-label text-capitalize" htmlFor="userpassword">
                    <FormattedMessage id="login.password" defaultMessage="password" />
                  </label>
                  <input
                    id="userpassword"
                    type="password"
                    name="password"
                    className="form-control pe-14"
                    placeholder="Password"
                    value={_get(formData, "password", "")}
                    onChange={(e) => this._handleChange(e)}
                  />
                  {(_get(formErrors, "password", "")) && ((isLoginButtonClicked || false) === true) && (<ErrorMessage message={_get(formErrors, "password", "")} />)}
                </div>

                <div className="mb-7">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="rememberme"
                      className="form-check-input"
                      onClick={(e) =>
                        this.setState((prevState) => ({
                          formData: {
                            ...prevState.formData,
                            rememberMe: !_get(prevState, "formData.rememberMe", false)
                          },
                        }))
                      } />
                    <label className="form-check-label fw-medium ms-1 text-capitalize" htmlFor="rememberme">
                      <FormattedMessage id="login.remember_me" defaultMessage="remember me" />
                    </label>
                  </div>
                </div>

                <div className="mb-19">
                  <button type="submit" className="btn btn-primary waves-effect w-100 text-capitalize" onClick={() => this.setState({ isLoginButtonClicked: !isLoginButtonClicked })}>
                    <FormattedMessage id="login.login" defaultMessage="login" />
                  </button>
                </div>

                <div className="text-center">
                  <Link to="/forgot-password" className="link-grey-93 fw-medium text-capitalize-first">
                    <FormattedMessage id="login.forgot_password_link" defaultMessage="forgot your password?" />
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userToken: _get(state, "authentication.userToken"),
  loginError: _get(state, "authentication.error", {}),
  roleId: _get(state, "authentication.userToken.role_id", null),
  roleWiseRedirections: _get(state, "application.constants.roleWiseRedirections", []),
});

const mapDispatchToProps = (dispatch) => ({
  clearAuthenticationError: () => dispatch(clearAuthenticationError()),
  doLogin: (data) => dispatch(doLogin(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withCookies(LoginUser))));
