import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, cloneDeep as _cloneDeep, map as _map } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";

import { CustomModal, showAlertMessage, SingleDropzone } from "../common/formElements";

import { getClientBriefAccounts, assignBriefCriteria } from "../../redux/services/campaignSetup";

import { updateLoadingState } from "../../redux/actions/application";

class AssignAccountsToBrief extends Component {

  constructor(props) {
    super(props);

    this.defaultFormData = {
      searchText: "",
      selectAccountIds: [],
      accountListData: [],
      initialAccountListData: [],
      recheckDataValidation: false
    };

    this.state = {
      ...this.defaultFormData,
      checkAllIds: false,
      showModal: false,
      fileUploaded: false
    };
  }

  componentDidUpdate(prevProps, prevStates) {

    if (!_isEqual(prevStates.showModal, this.state.showModal) && _get(this.state, "showModal", false) === true) {

      this._fetchListData();
    }

    if (!_isEqual(prevStates.searchText, this.state.searchText)) {
      const { initialAccountListData, searchText } = this.state;

      let tmpListData = _cloneDeep(initialAccountListData);

      tmpListData = (tmpListData || []).filter(({ account_list_name }) => (account_list_name || "").toLowerCase().includes((searchText || "").toLowerCase()));

      this.setState({ accountListData: tmpListData });
    }

    if (!_isEqual(prevStates.fileUploaded, _get(this.state, "fileUploaded", false)) && (_get(this.state, "fileUploaded", false) === true)) {
      showAlertMessage("Accounts assigned to briefs successfully.", "success");

      this._closeModal();
    }
  }

  // Fetch data list
  _fetchListData = async () => {
    const { selectedBriefIds, clientId, updateLoadingState, intl } = this.props;
    const { selectAccountIds } = this.state;
    let tmpSelectAccountIds = _cloneDeep(selectAccountIds || []);

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getClientBriefAccounts(clientId, { brief_ids: (selectedBriefIds || []) });

      if ((response.flag || false) === true) {

        _get(response, "data", []).forEach(account => {
          if (_get(account, "is_assigned_to_brief", 0) === 1) {
            tmpSelectAccountIds.push(_get(account, "account_list_id", 0));
          }
        });

        this.setState({
          accountListData: _get(response, "data", []),
          initialAccountListData: _get(response, "data", []),
          selectAccountIds: (tmpSelectAccountIds || []),
        });
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while fetching accounts."));
      }

    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while fetching accounts."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _showModal = () => {
    const { selectedBriefIds, intl } = this.props;

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    this.setState({ ...this.defaultFormData, showModal: true });
  }

  _closeModal = () => {

    this.setState({ ...this.defaultFormData, showModal: false, fileUploaded: false });
  }

  _selectAccountIds = (id = null, source = "single") => {
    const { selectAccountIds, checkAllIds, accountListData } = this.state;
    let updatedState = null;
    let tmpSelectAccountIds = _cloneDeep(selectAccountIds || []);

    switch (source) {
      case "single":
        let tmpCheckAllIds = _cloneDeep(checkAllIds);

        if (tmpSelectAccountIds.includes(id)) {
          tmpSelectAccountIds = (tmpSelectAccountIds).filter(c => (c !== id));
          tmpCheckAllIds = false;
        } else {
          tmpSelectAccountIds.push(id);
        }

        updatedState = { selectAccountIds: tmpSelectAccountIds, checkAllIds: tmpCheckAllIds };

        break;

      case "all":
        updatedState = {
          selectAccountIds: (checkAllIds === true) ? [] : _map(accountListData, "account_list_id"),
          checkAllIds: !checkAllIds
        };
        break;

      default:
        break;
    }

    this.setState(updatedState);
  }

  _assignIdsToBriefs = async (isReset = false) => {
    const { selectedBriefIds, updateLoadingState, intl } = this.props;
    const { selectAccountIds } = this.state;

    if (((isReset || false) === false) && ((selectAccountIds || []).length < 1)) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.account", defaultMessage: "account" }) }
      ));
      return false;
    }

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const payload = {
        "brief_ids": selectedBriefIds,
        "criteria_values": ((isReset || false) === false) ? selectAccountIds : [],
        "criteria_name": "account_list"
      };

      const response = await assignBriefCriteria(payload);

      if ((response.flag || false) === true) {

        showAlertMessage(_get(response, "data.message", "Accounts assigned to brief successfully."), "success");

        this._closeModal();
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while assigning accounts."));
      }

    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while assigning accounts."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderModalContent = () => {
    const { source = "campaign_setup", selectedBriefIds, allowedUploadFiles, allowedUploadFilesLabel, campaignId, clientId, intl } = this.props;
    const { showModal, accountListData, selectAccountIds, checkAllIds } = this.state;

    return (
      <CustomModal
        isOpen={showModal}
        className="text-capitalize"
        size="md"
        modalTitle={(intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.modal.title", defaultMessage: "assign {dataType} to briefs" },
          { dataType: intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.account", defaultMessage: "account" }) }))}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
      >

        <form className="modalForm assign-brief-modal">
          {(source === "campaign_edit") && (
            <div className="row mb-5">
              <div className="col-md-12">
                <div>
                  <SingleDropzone
                    source="account_list"
                    pageSource="campaign_edit"
                    briefIds={(selectedBriefIds || [])}
                    existingSelectedIds={(selectAccountIds || [])}
                    campaignId={(campaignId || null)}
                    clientId={(clientId || null)}
                    acceptedFormat={allowedUploadFiles}
                    allowedExtensions={allowedUploadFilesLabel}
                    setState={(args) => this.setState(args)}
                  />
                </div>
              </div>
              <span className="border-bottom border-2" />
            </div>
          )}

          <div className="mb-3">
            <input
              className="form-control fuzzy-search"
              type="text"
              name="search"
              placeholder={intl.formatMessage({ id: "search", defaultMessage: "search" })}
              onChange={(e) => {
                const { target: { value } } = e;

                this.setState({ searchText: value });
              }}
            />
          </div>
          <div>
            <div className="form-check mb-2" onClick={(e) => this._selectAccountIds(null, "all")}>
              <input
                type="checkbox"
                className="form-check-input"
                name="outcome"
                key="check-all-briefs"
                checked={checkAllIds}
                onChange={() => { }}
              />
              <label className="form-check-label text-capitalize" htmlFor="check-all-briefs">
                <FormattedMessage id="placeholder.select_all" defaultMessage="select all" />
              </label>
            </div>
          </div>

          <div className="assign-brief-container">

            <>
              {(accountListData).map((l) => {
                const id = (l.account_list_id || null);

                return (
                  <div key={`main-dev-${id}`} onClick={(e) => this._selectAccountIds(id)}>
                    <div className="form-check mb-2">
                      <input
                        key={`checkbox-${id}`}
                        type="checkbox"
                        className="form-check-input"
                        name="outcome"
                        checked={((selectAccountIds || []).includes(id))}
                        onChange={() => { }}
                        value={id}
                      />
                      <label className="form-check-label" htmlFor={`checkbox-${id}`}>{(l.account_list_name || "")}</label>
                    </div>
                  </div>
                );
              })}
            </>
          </div>
        </form>

        <div className="pt-4 px-4 mx-n4 border-top">
          <div className="row">
            <div className="col-md-4">
              <button className="btn btn-outline-primary text-capitalize" onClick={() => this._assignIdsToBriefs(true)}>
                <FormattedMessage id="campaign_setup.campaign_brief.assign.reset_account" defaultMessage="reset" />
              </button>
            </div>
            <div className="col-md-8">
              <div className="d-flex gap-3 justify-content-end">
                <button className="btn btn-secondary" onClick={() => this._closeModal()} >
                  <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
                </button>
                <button className="btn btn-primary text-capitalize" onClick={() => this._assignIdsToBriefs(false)}>
                  <FormattedMessage id="campaign_setup.campaign_brief.assign.data_type" defaultMessage="assign {dataType}" values={{ dataType: "accounts" }} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    );
  }

  render() {

    return (
      <>

        {this._renderModalContent()}

        <Dropdown.Item className="dropdown-item d-block waves-effect text-capitalize" onClick={() => this._showModal()} >
          <FormattedMessage id="campaign_setup.campaign_brief.assign.data_type" defaultMessage="assign {dataType}" values={{ dataType: "accounts" }} />
        </Dropdown.Item>

      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  allowedUploadFiles: _get(state, "application.constants.allowedUploadFiles", ""),
  allowedUploadFilesLabel: _get(state, "application.constants.allowedUploadFilesLabel", ""),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignAccountsToBrief));
