import { takeLatest, call, fork, select, put } from "redux-saga/effects";
import { getUserConfiguration } from "../services/application";
import { get as _get } from "lodash";

import {
  UPDATE_APP_LOADING, FETCH_USER_CONFIGURATION_REQUEST, FETCH_USER_CONFIGURATION_SUCCESS, FETCH_USER_CONFIGURATION_ERROR
} from "../actions/application";

function* workerFetchUserConfiguration(action) {
  try {

    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const uri = yield select((state) => _get(state, "authentication.uri", ""));

    const response = yield call(getUserConfiguration, uri);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_USER_CONFIGURATION_SUCCESS,
        payload: (response.data || {})
      });
    } else {
      yield put({
        type: FETCH_USER_CONFIGURATION_ERROR,
        payload: (response.data || {})
      });
    }
  } catch (error) {
  } finally {

    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }

}

function* watcherFetchUserConfiguration() {
  yield takeLatest(FETCH_USER_CONFIGURATION_REQUEST, workerFetchUserConfiguration);
}

const applicationSaga = [
  fork(watcherFetchUserConfiguration)
]

export default applicationSaga;
