import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, debounce as _debounce } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { EmptyLabel, RenderTableCellValue, RenderTableHeaderValue, showAlertMessage } from "../../resusableComponents/common/formElements";
import ReactDataTable from "../../resusableComponents/common/ReactDataTable";
import { hasAccess } from "../../resusableComponents/hoc/AccessManagement";
import { withRouter } from "../../resusableComponents/hoc/withRouter";
import { downloadExcelFile } from "../../helpers/utils";

import { getCampaignDataHistory, downloadLeadsFile } from "../../redux/services/campaign";
import { updateLoadingState } from "../../redux/actions/application";

class CampaignDataHistory extends Component {

  constructor(props) {
    super(props);

    const { intl } = this.props;

    this.defaultPayload = {
      campaign_id: _get(this.props, "params.id", 0),
      page_no: 1,
      page_size: 10,
      search_text: ""
    }

    this.state = {
      campaignId: _get(this.props, "params.id", 0),
      defaultPayload: { ...this.defaultPayload },
      dataHistoryPaginationInfo: {},
      dataHistoryList: [],
      campaignDetail: {},
      searchText: "",
    }

    this.searchDebounce = _debounce(this._handleSearchUsers, 1000);

    this.columns = [
      {
        name: (<RenderTableHeaderValue className="text-uppercase" value={intl.formatMessage({ id: "campaign_history.table_column.column_id", defaultMessage: "id" })} />),
        selector: (row) => (_get(row, "upload_id", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "upload_id", "")} />),
        sortable: true,
        width: "50px",
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_history.table_column.file_name", defaultMessage: "file name" })} />),
        selector: (row) => (_get(row, "file_name", "")),
        cell: (row) => {
          return (
            <div role="button" onClick={() => this._handleDownloadFile(_get(row, "file_path", ""))}>
              <RenderTableCellValue className="text-primary" value={_get(row, "file_name", "")} />
            </div>
          )
        },
        sortable: true,
        grow: 3
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_history.table_column.upload_type", defaultMessage: "upload type" })} />),
        selector: (row) => (_get(row, "upload_type", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "upload_type", "")} />),
        sortable: true,
        grow: 2
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_history.table_column.records", defaultMessage: "records" })} />),
        selector: (row) => (_get(row, "record_count", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "record_count", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_history.table_column.processed", defaultMessage: "processed" })} />),
        selector: (row) => (_get(row, "processed", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "processed", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_history.table_column.attempted", defaultMessage: "attempted" })} />),
        selector: (row) => (_get(row, "attempted", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "attempted", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue textClassName="text-success" value={intl.formatMessage({ id: "campaign_history.table_column.approved", defaultMessage: "approved" })} />),
        selector: (row) => (_get(row, "approved", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "approved", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue textClassName="text-warning" value={intl.formatMessage({ id: "campaign_history.table_column.panding", defaultMessage: "panding" })} />),
        selector: (row) => (_get(row, "pending", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "pending", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue textClassName="text-danger" value={intl.formatMessage({ id: "campaign_history.table_column.rejected", defaultMessage: "rejected" })} />),
        selector: (row) => (_get(row, "rejected", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "rejected", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_history.table_column.user", defaultMessage: "user" })} />),
        selector: (row) => (_get(row, "uploaded_by", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "uploaded_by", "")} />),
        sortable: true,
        grow: 2
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_history.table_column.upload_date", defaultMessage: "upload date" })} />),
        selector: (row) => (_get(row, "uploaded_date", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "uploaded_date", "")} />),
        sortable: true,
        grow: 3
      },
    ];
  }

  componentDidMount() {
    this._fetchCampaignDataHistory();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.defaultPayload, this.state.defaultPayload)) {
      this._fetchCampaignDataHistory();
    }
  }

  _fetchCampaignDataHistory = async () => {
    const { updateLoadingState } = this.props;
    const { defaultPayload } = this.state;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const dataHistoryResponse = await getCampaignDataHistory(defaultPayload);

      if (_get(dataHistoryResponse, "flag", false) === true) {
        this.setState({
          dataHistoryList: _get(dataHistoryResponse, "data.lead_upload_history", []),
          dataHistoryPaginationInfo: _get(dataHistoryResponse, "data.lead_upload_info[0]", {}),
          campaignDetail: _get(dataHistoryResponse, "data.campaign_detail[0]", {}),
        });
      } else {
        showAlertMessage((_get(dataHistoryResponse, "message", "Something went wrong while fetching campaign data history.")));
      }

    } catch (err) {
      showAlertMessage((_get(err, "message", "Something went wrong while fetching campaign data history.")));
    } finally {

      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleDownloadFile = async (url) => {
    const { intl } = this.props;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await downloadLeadsFile(url, false)

      if (_get(response, "flag", false) === true) {

        const contentType = _get(response, "data.contentType" || "");
        const contents = _get(response, "data.Contents" || "");
        const filename = _get(response, "data.filename" || "");

        if ((contentType !== "") && (contents !== "") && (filename !== "")) {

          downloadExcelFile(contentType, contents, filename);
          showAlertMessage(intl.formatMessage({ id: "message.file_generated_successfully", defaultMessage: "File generated successfully." }), "success");
        }
      } else {
        showAlertMessage(response.message || "Something went wrong while downloading file.");
      }
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while downloading file.");
    } finally {

      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  handlePaginationChange = (pageNo) => {
    const { defaultPayload } = this.state;

    this.setState({ defaultPayload: { ...defaultPayload, page_no: pageNo } });
  }

  _handleSearchUsers = (searchText) => {
    const { defaultPayload } = this.state;

    this.setState({ defaultPayload: { ...defaultPayload, search_text: searchText } });
  }

  _handleReset = () => {

    this.setState({ defaultPayload: { ...this.defaultPayload }, searchText: "" });
  }

  _renderCampaignHeading = () => {
    const { campaignDetail } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center gap-5 py-3 px-4">
                <p className="heading-04 text-uppercase">
                  {_get(campaignDetail, "campaign_name", <EmptyLabel />)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderDataHistoryHeading = () => {

    return (
      <div className="row mt-5">
        <div className="col-lg-12">
          <h1 className="heading-04 fw-semibold text-dark-35 text-uppercase mb-5">
            <FormattedMessage id="campaign_history.title" defaultMessage="data history" />
          </h1>
        </div>
      </div>
    );
  }

  _renderCampaignDashboardButton = () => {
    const { campaignId } = this.state;

    return (
      <div className="col-lg-3">
        <div className="input-group">
          <span className="input-group-text bg-primary-66 border-primary-66 px-3 d-flex align-items-center">
            <img src={require("../../assets/icons/icon-dashboard-alt.svg").default} className="img-fluid" alt="img" />
          </span>
          <Link to={`/campaigns/dashboard/${campaignId}`} className="btn btn-primary waves-effect">
            <FormattedMessage id="campaign_content.btn.campaign_dashboard" defaultMessage="campaign dashboard" />
          </Link>
        </div>
      </div>
    );
  }

  _renderButtonsAndSearch = () => {
    const { searchText } = this.state;

    return (
      <div className="row gy-4">

        {this._renderCampaignDashboardButton()}

        <div className="col-lg-9">
          <div className="d-flex flex-wrap gap-3 align-items-center justify-content-end">

            {(hasAccess("campaigns", "data-history", ["search"])) && (
              <div className="input-group w-auto">
                <input type="text"
                  className="form-control"
                  value={searchText}
                  placeholder="Search ..."
                  onChange={(e) => {
                    this.setState({ searchText: _get(e, "target.value", "") });
                    this.searchDebounce(_get(e, "target.value", ""));
                  }} />
                <span className="input-group-text bg-primary waves-effect py-1 d-flex align-items-center">
                  <img src={require("../../assets/icons/icon-search-sm.svg").default} className="img-fluid" alt="img" />
                </span>
              </div>
            )}

            <button className="btn btn-dark waves-effect text-capitalize" onClick={() => { this._handleReset() }}>
              <FormattedMessage id="btn.reset" defaultMessage="reset" />
            </button>

          </div>
        </div>
      </div>
    );
  }

  _renderTableData = () => {
    const { dataHistoryList, dataHistoryPaginationInfo } = this.state;

    return (
      <div className="row mt-5">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <ReactDataTable
                columns={this.columns}
                data={dataHistoryList || []}
                pagination={true}
                totalRecords={_get(dataHistoryPaginationInfo, "total_records", 10)}
                onChangePage={(e) => this.handlePaginationChange(e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderView = () => {

    return (
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 px-6 py-6">
          {this._renderCampaignHeading()}
          {this._renderDataHistoryHeading()}
          {this._renderButtonsAndSearch()}
          {this._renderTableData()}
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "hermes.campaign_data_history", defaultMessage: "Campaign Data History: Hermes" })} </title>
        </Helmet>

        {(hasAccess("campaigns", "data-history", ["page-view"])) && (
          this._renderView()
        )}

      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data))
})

export default withRouter(connect(null, mapDispatchToProps)(injectIntl(CampaignDataHistory)));
