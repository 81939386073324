import React, { Component, createRef } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty, map as _map } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from "react-redux";
import Dropzone from "react-dropzone";

import { Select, CustomModal, ErrorMessage, showAlertMessage } from "../../resusableComponents/common/formElements";

import { uploadSingle, verifyExternalAsset, doGetAssetTypeList, getAssetsDetails, doGetTopicList, doGetSubTopicList } from "../../redux/services/campaignSetup";
import { createAssetsWithExisting } from "../../redux/services/campaign";
import { updateLoadingState } from "../../redux/actions/application";

const dropzoneRef = createRef();

class AddAsset extends Component {

  constructor(props) {
    super(props);

    this.defaultAssetState = {
      typeId: null,
      topic: [],
      subTopic: [],
      name: "",
      fileName: "",
      assetCode: "",
      url: "",
      azureUrl: "",
      rawJson: "",
      isExternalURL: false
    }

    this.state = {
      newAsset: _cloneDeep(this.defaultAssetState),
      errors: {},
      selectedFile: {},
      progress: 0,
      existingAssetsData: [],
      existingAssets: [],
      topicList: [],
      subTopicList: [],
      assetTypeList: [],
    };
  }

  componentDidUpdate(prevProps, prevStates) {
    if (!_isEqual(prevStates.newAsset, this.state.newAsset)) {
      this._handleValidation();
    }

    if (prevProps.isOpen !== this.props.isOpen) {
      const { clientId, isOpen } = this.props;

      if ((isOpen || false) === true) {

        this._getTopicList();
        this._getAssetTypeList();
        this._getAssetsDetails((clientId || null));
      } else {
        this._resetCreateAssetForm();
      }
    }
  }

  _getTopicList = async () => {
    const { updateLoadingState } = this.props;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetTopicList();

      if (_get(response, "flag", false) === true) {

        this.setState({ topicList: _get(response, "data", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching topic list.")));
        this.setState({ topicList: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching topic list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _onDrop = async (acceptedFiles, rejectedFiles) => {
    const { campaignId, updateLoadingState, intl } = this.props;
    const { topicList } = this.state;

    if (typeof updateLoadingState === "function") {
      updateLoadingState(true);
    }

    let errorMessage = "";

    if (!_isEmpty(rejectedFiles)) {

      rejectedFiles.forEach(element => {
        if (!_isEmpty(element.errors || [])) {
          const errors = (element.errors || []);

          errors.forEach(col => {
            let message = _get(col, "message", "");
            const code = _get(col, "code", "");
            if (code === "file-too-large") {
              message = intl.formatMessage({ id: "error.file_size_is_larger_than", defaultMessage: "File size is larger than {size} MB." }, { size: (process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE || 200) });
            }

            if (code !== "file-invalid-type") {
              errorMessage = ((errorMessage || "") === "") ? (message || "") : `${errorMessage || ""}<br>${message || ""}`;
            }
          });
        }
      });
    }

    if (_isEmpty(acceptedFiles)) {
      const emptyMessage = intl.formatMessage({ id: "error.select_valid", defaultMessage: "Please select valid {field}." }, {
        field: intl.formatMessage({ id: "file", defaultMessage: "file" })
      });

      errorMessage = ((errorMessage || "") === "") ? (emptyMessage || "") : `${(errorMessage || "")}<br>${(emptyMessage || "")}`;
    }

    if (!_isEmpty(errorMessage)) {
      showAlertMessage((errorMessage || ""));

      if (typeof updateLoadingState === "function") { updateLoadingState(false) };
      return false;
    }

    const selectedFile = acceptedFiles[0];

    this.setState({ selectedFile, progress: 1 });

    let data = new FormData();

    data.append("formfile", selectedFile);

    if (!_isEmpty(campaignId)) {
      data.append("campaign_id", campaignId);
    }

    try {
      const response = await uploadSingle("assets", data, ({ progress = 0 }) => this.setState({ progress }));

      if ((response.flag || false) === true) {

        const existingTopic = (topicList || []).find(item => item.topic === _get(response, "data.topic", ""));
        const topic = { id: _get(response, "data.topic", ""), topic: _get(response, "data.topic", "") };
        const subTopic = { id: _get(response, "data.sub_topic", ""), sub_topic: _get(response, "data.sub_topic", "") };

        this.setState((prevState) => ({
          newAsset: {
            ...prevState.newAsset,
            url: _get(response, "data.url", ""),
            azureUrl: _get(response, "data.url", ""),
            fileName: _get(response, "data.filename", ""),
            topic: [_get(response, "data.topic", "")],
            subTopic: [_get(response, "data.sub_topic", "")],
            rawJson: _get(response, "data.raw_json", "")
          },
          topicList: (!_isEmpty(existingTopic)) ? topicList : [...topicList, topic],
          subTopicList: [subTopic],
        }));

      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while uploading assets."));
        this.setState({ selectedFile: {}, progress: 1 });
      }
    } catch (err) {
      showAlertMessage(_get(err, "message", "Something went wrong while uploading assets."));
      this.setState({ selectedFile: {}, progress: 1 });
    } finally {
      if (typeof updateLoadingState === "function") {
        updateLoadingState(false)
      };
    }
  }

  _handleChange = (e, name) => {
    e.preventDefault();

    const { value } = (e.target || {});

    this.setState((prevState) => ({
      newAsset: {
        ...prevState.newAsset,
        [name]: value
      }
    }));
  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { newAsset } = this.state;
    let errors = {};

    if (_isEqual(newAsset, this.defaultAssetState)) {
      this.setState({
        errors: {}
      });

      return false;
    }

    if (_isEmpty(newAsset.typeId)) {
      errors["typeId"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_content.create_asset_modal.asset_type", defaultMessage: "asset type" })
      });
    }

    if (_isEmpty(newAsset.name)) {
      errors["name"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_content.create_asset_modal.name", defaultMessage: "name" })
      });
    }

    if (_isEmpty(newAsset.topic)) {
      errors["topic"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_content.create_asset_modal.topics", defaultMessage: "topics" })
      });
    }

    if (_isEmpty(newAsset.subTopic)) {
      errors["subTopic"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_content.create_asset_modal.sub_topics", defaultMessage: "sub topics" })
      });
    }

    if (_isEmpty(newAsset.assetCode)) {
      errors["assetCode"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_content.create_asset_modal.asset_code", defaultMessage: "asset code" })
      });
    }

    if (_isEmpty(newAsset.url)) {
      errors["url"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_content.create_asset_modal.url", defaultMessage: "url" })
      });
    }

    this.setState({ errors });
  }

  _verifyAsset = async () => {
    const { updateLoadingState, intl } = this.props;
    const { newAsset, topicList } = this.state;

    if (_isEmpty(newAsset.url)) {
      showAlertMessage(intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_content.create_asset_modal.url", defaultMessage: "url" })
      }));
    }

    if (typeof updateLoadingState === "function") {
      updateLoadingState(true);
    }
    try {
      const response = await verifyExternalAsset({ file_url: _get(newAsset, "url", "") });

      if ((response.flag || false) === true) {
        const existingTopic = (topicList || []).find(item => item.topic === _get(response, "data.topic", ""));
        const topic = { id: _get(response, "data.topic", ""), topic: _get(response, "data.topic", "") };
        const subTopic = { id: _get(response, "data.sub_topic", ""), sub_topic: _get(response, "data.sub_topic", "") };

        this.setState((prevState) => ({
          newAsset: {
            ...prevState.newAsset,
            url: _get(response, "data.url", ""),
            azureUrl: _get(response, "data.url", ""),
            fileName: _get(response, "data.filename", ""),
            topic: [_get(response, "data.topic", "")],
            subTopic: [_get(response, "data.sub_topic", "")],
            rawJson: _get(response, "data.raw_json", "")
          },
          topicList: (!_isEmpty(existingTopic)) ? topicList : [...topicList, topic],
          subTopicList: [subTopic],
        }));
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while verifying asset."));
      }
    } catch (err) {
      showAlertMessage(_get(err, "message", "Something went wrong while verifying asset."));
    } finally {
      if (typeof updateLoadingState === "function") {
        updateLoadingState(false)
      };
    }
  }

  _handleSave = async () => {
    try {
      const { campaignId = null, clientId, intl } = this.props;
      const { newAsset, existingAssets, errors } = this.state;

      if (_isEmpty(existingAssets) && ((newAsset.assetCode || "") === "")) {
        showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
          { field: intl.formatMessage({ id: "asset", defaultMessage: "asset" }) }));
        return false;
      }

      if (!_isEmpty(errors)) {
        showAlertMessage(intl.formatMessage({ id: "error.please_fill_up_the_proper_details", defaultMessage: "Please fill up the proper details." }));
        return false;
      }

      let variables = {
        "campaign_assets": {
          "asset_ids": _map(existingAssets, "id"),
          "campaign_id": parseInt(campaignId || 0),
          "delete_existing_assets": false
        },
        "client_id": (clientId || 0),
        "asset_name": (newAsset.name || ""),
        "asset_code": (newAsset.assetCode || ""),
        "asset_url": (newAsset.url || ""),
        "asset_file_name": (newAsset.fileName || ""),
        "topic": _get(newAsset, "topic[0].topic", _get(newAsset, "topic[0]", 0)),
        "sub_topic": _get(newAsset, "subTopic[0].sub_topic", _get(newAsset, "subTopic[0]", 0)),
        "type_id": _get(newAsset, "typeId.id", 0),
        "raw_json": _get(newAsset, "rawJson", ""),
      };

      const response = await createAssetsWithExisting(variables);

      if ((response.flag || false) === true) {

        this._resetCreateAssetForm(true);
        showAlertMessage(_get(response, "message", "Campaign asset details updated successfully."), "success");
      } else {

        showAlertMessage(_get(response, "message", "Something went wrong while creating assets."));
      }

    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while creating assets."));
    }
  }

  // Fetch subtopic by selected topic
  _fetchSubTopicsByTopic = async (topicId) => {
    const { updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetSubTopicList([topicId]);

      if ((response.flag || false) === true) {

        this.setState({ subTopicList: _get(response, "data", []) });
      } else {

        showAlertMessage(_get(response, "message", "Something went wrong while fetching sub topics."));
      }

    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while fetching sub topics."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _getAssetTypeList = async () => {
    const { updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetAssetTypeList();

      if (_get(response, "flag", false) === true) {

        this.setState({ assetTypeList: _get(response, "data", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching topic list.")));
        this.setState({ assetTypeList: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching topic list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _getAssetsDetails = async (clientId) => {
    const { campaignId, updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getAssetsDetails(clientId, { dropdown_flag: 1, campaign_id: (campaignId || 0) });

      if (_get(response, "flag", false) === true) {
        this.setState({ existingAssetsData: _get(response, "data", []) });
      } else {
        this.setState({ existingAssetsData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching assets details.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _resetCreateAssetForm = (fetch = false) => {
    const { onClose } = this.props;

    this.setState({
      newAsset: _cloneDeep(this.defaultAssetState),
      existingAssets: [],
      selectedFile: {},
      progress: 0,
      errors: {},
    });

    if (typeof onClose === "function") {
      onClose(fetch)
    };
  }

  _handleRemoveFile = () => {
    this.setState((prevState) => ({
      newAsset: {
        ...prevState.newAsset,
        url: "",
        azureUrl: "",
        fileName: "",
      },
      selectedFile: {},
      progress: 0
    }));
  }

  _setAssetType = (assetType) => {
    this.setState((prevState) => ({
      newAsset: {
        ...prevState.newAsset,
        url: "",
        azureUrl: "",
        fileName: "",
        topic: [],
        subTopic: [],
        rawJson: ""
      },
      subTopicList: [],
      isExternalURL: assetType
    }))
  }

  _renderModalBody = () => {
    const { intl, allowedAssetFiles, allowedAssetFilesLabel, source = "campaign_content" } = this.props;
    const { isExternalURL, newAsset, errors, topicList, subTopicList, assetTypeList, existingAssetsData, existingAssets: existingAssetsState, selectedFile } = this.state;

    const maxFileSize = parseInt(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE || 200) * 1048576;

    return (
      <div className="modalForm">
        <div className="row gutters-10">
          {(source === "campaign_content") && (
            <div className="col-sm-4 col-md-4">
              <div className="mb-6">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_content.create_asset_modal.existing_assets" defaultMessage="use existing assets" />
                </label>

                <Select
                  className="form-custom-select"
                  key={(existingAssetsData || null)}
                  value={(existingAssetsState || null)}
                  options={(existingAssetsData || [])}
                  getOptionValue={(option) => (option.id || 0)}
                  getOptionLabel={(option) => (option.asset_name || "")}
                  onChange={(newExistingAssets) => this.setState({ existingAssets: _cloneDeep(newExistingAssets) })}
                />
              </div>
            </div>
          )}

          <div className="col-sm-8 col-md-8">
            <label className="form-label text-capitalize">
              <FormattedMessage id="campaign_content.assets.label_asset_type" defaultMessage="Asset Type" />
            </label>
            <div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input show-data"
                  type="radio"
                  name="physical_asset"
                  id="physical_asset"
                  value={(isExternalURL || false)}
                  checked={(isExternalURL || false) === false}
                  onChange={(e) => this._setAssetType(false)} />
                <label className="form-check-label text-capitalize cursor-pointer" htmlFor="physical_asset">
                  <FormattedMessage id="campaign_content.assets.physical_asset" defaultMessage="Physical Asset" />
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  className="form-check-input show-data"
                  type="radio"
                  name="web_asset"
                  id="web_asset"
                  value={(isExternalURL || false)}
                  checked={(isExternalURL || false) === true}
                  onChange={(e) => this._setAssetType(true)} />
                <label className="form-check-label text-capitalize cursor-pointer" htmlFor="web_asset">
                  <FormattedMessage id="campaign_content.assets.web_asset" defaultMessage="Web Asset" />
                </label>
              </div>
            </div>
          </div>

          {((isExternalURL || false) === false) && (_isEmpty(selectedFile || {})) && (
            <div className="col-sm-4 col-md-4">
              <div className="mb-6">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_content.create_asset_modal.create_new_asset" defaultMessage="Create new asset" />
                </label>

                <Dropzone
                  maxFiles={1}
                  multiple={false}
                  maxSize={parseInt(maxFileSize || 209715200)}
                  accept={allowedAssetFiles}
                  onDrop={(f) => this._onDrop(f)}
                  ref={dropzoneRef}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div className="mb-6" {...getRootProps({ className: "dropzone" })}>
                      <div>
                        <div className="dropzone-input form-control">
                          <span>Browse...</span>
                          No file selected.
                        </div>
                        <input {...getInputProps()} />
                      </div>
                      <label htmlFor="singleUpload" className="mt-2 text-grey-93">
                        <FormattedMessage
                          id="file.allowed_files"
                          defaultMessage="Allowed file extensions : {allowedExtensions}"
                          values={{ allowedExtensions: allowedAssetFilesLabel }}
                        />
                      </label>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          )}

          {((isExternalURL || false) === false) && (!_isEmpty(selectedFile || {})) && (
            <div className="col-sm-4 col-md-4">
              <div className="row g-0">
                <div className="col-sm-12">
                  <label className="form-label text-capitalize">
                    <FormattedMessage id="campaign_content.create_asset_modal.uploaded_file" defaultMessage="Uploaded File" />
                  </label>
                  <div className="position-relative">
                    <ul className="dropzone-chip list-unstyled">
                      <li>
                        <div className="dropzone-chip-list">
                          <label>{(selectedFile.name || "")}</label>
                          <span aria-hidden="true" onClick={() => this._handleRemoveFile()}>×</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-sm-4 col-md-4">
            <div className="mb-6">
              <label htmlFor="create_url" className="form-label text-capitalize">
                <FormattedMessage id="campaign_content.create_asset_modal.url" defaultMessage="url" />
                {((isExternalURL || false) === false) && (
                  <span className="span-link m-l-5"><FormattedMessage id="campaign_content.create_asset_modal.edit_url" defaultMessage="(edit)" /> </span>
                )}

                {((isExternalURL || false) === true) && (
                  <span
                    className="link-blue-22 m-l-5 cursor-pointer"
                    onClick={() => this._verifyAsset()}
                  >
                    <FormattedMessage id="campaign_content.create_asset_modal.verify" defaultMessage=" (verify)" />
                  </span>
                )}
              </label>
              <input
                id="create_url"
                name="create_url"
                className="form-control"
                defaultValue={(newAsset.url || "")}
                placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "campaign_content.create_asset_modal.url", defaultMessage: "url" })
                })}
                onChange={(e) => this._handleChange(e, "url")}
              />
              {(errors.name !== "") && (<ErrorMessage message={(errors.url || "")} />)}
            </div>
          </div>

          <div className="col-sm-4 col-md-4">
            <div className="mb-6">
              <label className="form-label text-capitalize">
                <FormattedMessage id="campaign_content.create_asset_modal.asset_type" defaultMessage="asset type" />
              </label>

              <Select
                className="form-custom-select"
                placeholder={intl.formatMessage({ id: "placeholder.select_field", defaultMessage: "Select {field}" },
                  { field: intl.formatMessage({ id: "campaign_content.create_asset_modal.asset_type", defaultMessage: "asset type" }) })}
                value={(newAsset.typeId || null)}
                options={(assetTypeList || [])}
                error={(errors.typeId || "")}
                getOptionValue={(option) => (option.id)}
                getOptionLabel={(option) => (option.asset_type)}
                onChange={(selectedOptions) => this.setState((prevState) => ({
                  newAsset: {
                    ...prevState.newAsset,
                    typeId: selectedOptions
                  }
                }))}
                isMulti={false}
              />
            </div>
          </div>

          <div className="col-sm-4 col-md-4">
            <div className="mb-6">
              <label className="form-label text-capitalize">
                <FormattedMessage id="campaign_content.create_asset_modal.topics" defaultMessage="topics" />
              </label>
              <Typeahead
                id="custom-typeahead"
                placeholder={intl.formatMessage({ id: "placeholder.start_typing_to_search", defaultMessage: "start typing to search" })}
                newSelectionPrefix={intl.formatMessage({ id: "campaign_content.placeholder.create_new_topic", defaultMessage: "Click to create new topic: " })}
                allowNew
                labelKey="topic"
                selected={(newAsset.topic || "")}
                options={(topicList || []).map(option => _get(option, "topic", ""))}
                onChange={(selectedOptions) => {

                  if (selectedOptions.length > 0) {
                    const lastSelected = selectedOptions[selectedOptions.length - 1];

                    if (typeof lastSelected === 'string' && (topicList || []).some(option => option.topic === lastSelected)) {

                      // If Existing topic selected then fetch sub topics
                      const selectedOption = (topicList || []).find(option => option.topic === lastSelected);
                      if (selectedOption) {
                        this._fetchSubTopicsByTopic(_get(selectedOption, "id", 0));
                      }
                    }
                  }

                  this.setState((prevState) => ({
                    newAsset: {
                      ...prevState.newAsset,
                      topic: selectedOptions
                    }
                  }));

                }}
              />
              {(errors.topic !== "") && (<ErrorMessage message={(errors.topic || "")} />)}
            </div>
          </div>

          <div className="col-sm-4 col-md-4">
            <div className="mb-6">
              <label className="form-label text-capitalize">
                <FormattedMessage id="campaign_content.create_asset_modal.sub_topics" defaultMessage="sub topics" />
              </label>
              <Typeahead
                id="custom-typeahead"
                placeholder={intl.formatMessage({ id: "placeholder.start_typing_to_search", defaultMessage: "start typing to search" })}
                newSelectionPrefix={intl.formatMessage({ id: "campaign_content.placeholder.create_new_sub_topic", defaultMessage: "Click to create new sub topic: " })}
                allowNew
                labelKey="sub_topic"
                selected={(newAsset.subTopic || "")}
                options={(subTopicList || []).map(option => _get(option, "sub_topic", ""))}
                onChange={(selectedOptions) => {

                  this.setState((prevState) => ({
                    newAsset: {
                      ...prevState.newAsset,
                      subTopic: selectedOptions
                    }
                  }));

                }}
              />
              {(errors.subTopic !== "") && (<ErrorMessage message={(errors.subTopic || "")} />)}
            </div>
          </div>

          <div className="col-sm-4 col-md-4">
            <div className="mb-6">
              <label htmlFor="create_name" className="form-label text-capitalize">
                <FormattedMessage id="campaign_content.create_asset_modal.name" defaultMessage="name" />
              </label>

              <input
                id="create_name"
                name="create_name"
                className="form-control"
                value={(newAsset.name || "")}
                placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "campaign_content.create_asset_modal.name", defaultMessage: "name" })
                })}
                onChange={(e) => this._handleChange(e, "name")}
              />
              {(errors.name !== "") && (<ErrorMessage message={(errors.name || "")} />)}
            </div>
          </div>

          <div className="col-sm-4 col-md-4">
            <div className="mb-6">
              <label htmlFor="create_code" className="form-label text-capitalize">
                <FormattedMessage id="campaign_content.create_asset_modal.asset_code" defaultMessage="asset code" />
              </label>
              <input
                id="create_code"
                name="create_code"
                className="form-control"
                value={(newAsset.assetCode || "")}
                placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "campaign_content.create_asset_modal.asset_code", defaultMessage: "asset code" })
                })}
                onChange={(e) => this._handleChange(e, "assetCode")}
              />
              {(errors.assetCode !== "") && (<ErrorMessage message={(errors.assetCode || "")} />)}
            </div>
          </div>

          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button className="btn btn-secondary" onClick={() => this._resetCreateAssetForm()}>
              <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
            </button>
            <button className="btn btn-primary text-capitalize" onClick={() => this._handleSave()}>
              <FormattedMessage id="btn.submit" defaultMessage="Submit" />
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { isOpen, intl, source = "campaign_content" } = this.props;

    if (source === "campaign_content") {
      return (
        <CustomModal
          isOpen={isOpen}
          size="xl"
          className="addContentModal"
          modalTitle={(intl.formatMessage({ id: "campaign_content.create_asset_modal.title", defaultMessage: "create asset" }))}
          onHide={() => this._resetCreateAssetForm()}
          onClose={() => this._resetCreateAssetForm()}
        >
          {this._renderModalBody()}
        </CustomModal>
      )
    }

    return (
      <>
        {this._renderModalBody()}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  allowedAssetFiles: _get(state, "application.constants.allowedAssetFiles", ""),
  allowedAssetFilesLabel: _get(state, "application.constants.allowedAssetFilesLabel", ""),
  clientsData: _get(state, "users.clients.data.clients", []),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddAsset));
