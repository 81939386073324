import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { Navigate, Outlet } from "react-router-dom";
import { withCookies } from "react-cookie";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { showAlertMessage } from "../../common/formElements";
import { hasAccess } from "../AccessManagement";
import { withRouter } from "../withRouter";

import { setAuthenticationToken } from "../../../redux/actions/authentication";

class PrivateRoute extends Component {

  render() {
    const { userToken, roleWiseRedirections, roleId, intl, ...rest } = this.props;

    const from = _get(rest, "location.pathname", "/");

    if (!_isEmpty(userToken)) {
      const path = (from || "").split("/").filter((s) => !_isEmpty(s));

      if ((path || []).length >= 1 && !_isEmpty(userToken)) {
        const checkAccess = hasAccess(path[0], (path[1] || "index"));

        if (checkAccess === false) {
          let redirectUrl = "/";

          (roleWiseRedirections || []).forEach((role) => {
            if ((role.roleIds || []).includes(roleId) && role.redirectionPath !== null) {
              redirectUrl = (role.redirectionPath || "/");
            }
          });

          setTimeout(() => {
            showAlertMessage(intl.formatMessage({ id: "message.access_error", defaultMessage: "You don't have access to visit this page." }));
          }, 100);

          if (from !== redirectUrl) {
            return (<Navigate to={redirectUrl} />);
          }
        }
      }
    }

    return (
      (!_isEmpty(userToken)) ? <Outlet {...rest} /> : <Navigate to={{ pathname: "/login", search: `?continue=${from || "/"}` }} />
    )
  }
}

const stateToPropsMapping = (state) => ({
  userToken: _get(state, "authentication.userToken", {}),
  roleId: _get(state, "authentication.userToken.role_id", null),
  roleWiseRedirections: _get(state, "application.constants.roleWiseRedirections", []),
});

const dispatchToPropsMapping = (dispatch) => ({
  setAuthenticationToken: (userToken) => dispatch(setAuthenticationToken(userToken))
});

export default withRouter(connect(stateToPropsMapping, dispatchToPropsMapping)(injectIntl(withCookies(PrivateRoute))));
