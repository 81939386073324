import axios from "axios";
import { get as _get, isEmpty as _isEmpty } from "lodash";

// import { showAlertMessage } from "../resusableComponents/common/formElements";
import { store } from "../redux/configureStore";

import { deleteAuthenticationToken } from "../redux/actions/authentication";

export default function () {

  const state = store.getState();
  const userToken = _get(state, "authentication.userToken", {});
  const uri = _get(state, "authentication.uri", "");
  const { token_type = "", access_token = "" } = !_isEmpty(userToken) ? userToken : {};

  const axInstance = axios.create({
    baseURL: `${uri}`,
    headers: {
      "Authorization": `${token_type} ${access_token}`,
      "X-Requested-With": "XMLHttpRequest",
    }
  });

  axInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorStatusCode = _get(error, "response.status", "");

      switch (errorStatusCode) {
        case 403:
        case 405:
          const newState = store.getState();
          const roleId = _get(newState, "authentication.user.role_id", null);

          if (roleId) {
            let redirectUrl = "/";

            const roleWiseRedirections = _get(newState, "application.constants.roleWiseRedirections", []);

            (roleWiseRedirections || []).forEach((role) => {

              if (((role.roleIds || []).includes(roleId)) && (role.redirectionPath !== null)) {
                redirectUrl = (role.redirectionPath || "/");
              }
            });

            // showAlertMessage("You don't have permission to visit this page.");

            setTimeout(() => {
              window.location.href = redirectUrl;
            }, 10000);
          }

          break;

        case 401:
          store.dispatch(deleteAuthenticationToken());
          break;

        default:
          break;
      }

      return Promise.reject(error);
    }
  );

  return axInstance;
}
