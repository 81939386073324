import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, chain as _chain } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { CustomModal, showAlertMessage, EmptyLabel, Select } from "../../../resusableComponents/common/formElements";
import { hasAccess } from "../../../resusableComponents/hoc/AccessManagement";
import { LeadStatus } from "../../../helpers/constants";

import { fetchCampaignLeadFilter } from "../../../redux/services/campaign";
import { updateLoadingState } from "../../../redux/actions/application";

class LeadFilters extends Component {

  constructor(props) {
    super(props);

    this.state = {
      stateLeadsFilter: {},
      filterValues: {}
    };
  }

  componentDidMount() {
    const { leadsFilter, showLeadFilterModal } = this.props;

    if (showLeadFilterModal) { this._getFilterValues(); }

    this.setState({ stateLeadsFilter: { ...leadsFilter } });
  }

  _getFilterValues = async () => {
    const { campaignId, updateLoadingState } = this.props;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await fetchCampaignLeadFilter(campaignId);

      if (_get(response, "flag", false) === true) {

        this.setState({ filterValues: _get(response, "data", {}) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching filter values.")));
        this.setState({ filterValues: {} });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching filter values.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _updateFilterState(key, value) {

    if (key === "rejectionValues") {
      // Rejection values
      let updateValue = _chain((value || [])).map(r => {
        const splitValue = r.split("^");
        const rejectionType = parseInt(_get(splitValue, "[0]", 0));
        const rejectionTypeText = _get(splitValue, "[1]", "");

        return { rejectionType, rejectionTypeText };
      }).groupBy("rejectionType").map(r => {
        const rejectionType = _get(r, "[0].rejectionType", 0);
        const rejectionTypeText = _chain(r).map(r => (r.rejectionTypeText || null)).value();

        return {
          "rejectiontype": rejectionType,
          "values": rejectionTypeText
        };
      }).value();

      this.setState((prevState) => ({
        stateLeadsFilter: {
          ...prevState.stateLeadsFilter,
          "rejectionPayloadValues": updateValue
        }
      }));
    } else {
      this.setState((prevState) => ({
        stateLeadsFilter: {
          ...prevState.stateLeadsFilter,
          [key]: value
        }
      }));
    }
  }

  _handleAccordianClick = (key, value) => {
    const { onClickAccordian } = this.props;

    if (typeof onClickAccordian === "function") {
      onClickAccordian(key, value);
    }
  }

  _closeModal = () => {
    const { setState } = this.props;

    if (typeof setState === "function") {
      setState({ showLeadFilterModal: false });
    }
  }

  _handleSubmit = () => {
    const { onUpdateLeadFilter } = this.props;
    const { stateLeadsFilter } = this.state;

    if (typeof onUpdateLeadFilter === "function") {
      onUpdateLeadFilter({ stateLeadsFilter: { ...stateLeadsFilter }, showLeadFilterModal: false });
    }
  }

  _renderApprovalNodes() {
    const approval = _chain(LeadStatus).orderBy(["order"]).map(a => {
      return {
        value: (a.status || 0),
        label: (a.text || <EmptyLabel />),
        icon: false
      }
    }).value();

    return [{
      value: "approval",
      label: <span className="text-capitalize"><FormattedMessage id="view_leads.filter.approval" defaultMessage="Approval" /></span>,
      showCheckbox: false,
      icon: false,
      children: approval
    }]
  }

  _renderAssetsIdNodes = () => {
    const { filterValues } = this.state;

    const assetsLists = _get(filterValues, "campaign_asset", []);

    const assetIds = (assetsLists || []).map((d) => ({
      value: (d.asset_id || 0),
      label: (d.asset_name || <EmptyLabel />),
      icon: false
    }));

    return [{
      value: "assetIds",
      label: <span className="text-capitalize"><FormattedMessage id="view_leads.filter.assets" defaultMessage="assets" /></span>,
      showCheckbox: false,
      icon: false,
      children: assetIds
    }]
  }

  _renderUploadIdNodes = () => {
    const { filterValues } = this.state;

    const uploadHistory = _get(filterValues, "lead_upload_history", []);

    const uploadIds = (uploadHistory || []).map((d) => ({
      value: (d.upload_id || 0),
      label: (d.upload_id || 0),
      icon: false
    }));

    return [{
      value: "uploadIds",
      label: <span className="text-capitalize"><FormattedMessage id="view_leads.filter.upload_ids" defaultMessage="upload ids" /></span>,
      showCheckbox: false,
      icon: false,
      children: uploadIds
    }]
  }

  _renderBooleanNodes(lanKey, defaultMsg) {
    const booleanNodes = [
      { value: 1, label: "Yes", icon: false },
      { value: 0, label: "No", icon: false },
      { value: 2, label: "Failed", icon: false },
    ]

    return [{
      value: lanKey,
      label: <span className="text-capitalize"><FormattedMessage id="view_leads.filter.piped" defaultMessage="piped" /></span>,
      showCheckbox: false,
      icon: false,
      children: booleanNodes
    }]
  }

  _renderFilterModal = () => {
    const { accordianFilter = {}, showLeadFilterModal, disableApprovalFilter = false, intl } = this.props;
    const { filterValues, stateLeadsFilter } = this.state;

    const approvalNodes = this._renderApprovalNodes();
    const assetIdsNodes = this._renderAssetsIdNodes();
    const uploadIdsNodes = this._renderUploadIdNodes();
    const pipedNodes = this._renderBooleanNodes();

    const approvalClassName = ((accordianFilter.showApprovalSection || false) === false) ? "d-none" : "";
    const assetsClassName = ((accordianFilter.showAssetsSection || false) === false) ? "d-none" : "";
    const uploadIdsClassName = ((accordianFilter.showUploadIdsSection || false) === false) ? "d-none" : "";
    const pipedClassName = ((accordianFilter.showPipedSection || false) === false) ? "d-none" : "";

    return (
      <CustomModal
        size="lg"
        isOpen={showLeadFilterModal}
        modalTitle={(intl.formatMessage({ id: "view_leads.filter.title", defaultMessage: "filter" }))}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
      >
        <div className="filter-scroll custom-scroll">
          {(hasAccess("campaigns", "view-leads", ["filter-approval"]) === true) && (
            <div className="filter-accordion">
              <h5 className="text-capitalize" onClick={() => this._handleAccordianClick("showApprovalSection", !accordianFilter.showApprovalSection)}>
                {((accordianFilter.showApprovalSection || false) === false) && (
                  <img src={require("../../../assets/icons/icon-plus.svg").default} className="img-fluid" alt="img" />
                )}

                {((accordianFilter.showApprovalSection || false) === true) && (
                  <img src={require("../../../assets/icons/icon-minus.svg").default} className="img-fluid" alt="img" />
                )}
                <FormattedMessage id="view_leads.filter.approval" defaultMessage="approval" />
              </h5>
              <div className={`filter-accordion-content ${approvalClassName}`}>
                {_isEmpty(_get(approvalNodes, "[0].children", [])) && (
                  <h4 className="text-capitalize text-center">
                    <FormattedMessage id="message.no_found_data" defaultMessage="no data found" />
                  </h4>
                )}

                {!_isEmpty(_get(approvalNodes, "[0].children", [])) && (
                  <div className="d-flex flex-column gap-2">
                    {(Object.values(_get(approvalNodes, "[0].children", [])).map((approval, key) => {

                      const isChecked = ((stateLeadsFilter.approval || []).includes(approval.value)) ? true : false;
                      return (
                        <div key={`approval-${key}`} className="form-check">
                          <input
                            type="checkbox"
                            id={`approval-${key}`}
                            className={`approval-${key} form-check-input`}
                            value={(approval.value || 0)}
                            checked={(isChecked || false)}
                            disabled={(isChecked) ? false : (disableApprovalFilter || false)}
                            onChange={(e) => {
                              if (disableApprovalFilter === true) { return; }

                              const isChecked = _get(e, "target.checked", false);
                              const selectedApproval = parseInt(_get(e, "target.value", 0));

                              let selectedApprovalds = (stateLeadsFilter.approval || []);
                              if (isChecked) {

                                selectedApprovalds.push(selectedApproval);
                              } else {

                                const index = (selectedApprovalds || []).indexOf(selectedApproval);
                                if (index > -1) {
                                  (selectedApprovalds || []).splice(index, 1);
                                }
                              }

                              this._updateFilterState("approval", selectedApprovalds);
                            }}
                          />
                          <label className="form-check-label" htmlFor={`approval-${key}`}>{(approval.label || "")}</label>
                        </div>
                      );
                    })
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {(hasAccess("campaigns", "view-leads", ["filter-assets"]) === true) && (
            <div className="filter-accordion">
              <h5 className="text-capitalize" onClick={() => this._handleAccordianClick("showAssetsSection", !accordianFilter.showAssetsSection)}>
                {((accordianFilter.showAssetsSection || false) === false) && (
                  <img src={require("../../../assets/icons/icon-plus.svg").default} className="img-fluid" alt="img" />
                )}

                {((accordianFilter.showAssetsSection || false) === true) && (
                  <img src={require("../../../assets/icons/icon-minus.svg").default} className="img-fluid" alt="img" />
                )}
                <FormattedMessage id="view_leads.filter.assets" defaultMessage="assets" />
              </h5>
              <div className={`filter-accordion-content ${assetsClassName}`}>
                {_isEmpty(_get(assetIdsNodes, "[0].children", [])) && (
                  <h4 className="text-capitalize text-center">
                    <FormattedMessage id="message.no_found_data" defaultMessage="no data found" />
                  </h4>
                )}

                {!_isEmpty(_get(assetIdsNodes, "[0].children", [])) && (
                  <div className="d-flex flex-column gap-2">
                    {(Object.values(_get(assetIdsNodes, "[0].children", [])).map((assets, key) => {

                      return (
                        <div key={`assets-${key}`}>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              id={`assets-${key}`}
                              className={`assets-${key} form-check-input`}
                              value={(assets.value || 0)}
                              checked={((stateLeadsFilter.assetIds || []).includes(assets.value)) ? true : false}
                              onChange={(e) => {
                                const isChecked = _get(e, "target.checked", false);
                                const selectedAsset = parseInt(_get(e, "target.value", 0));

                                let selectedAssetIds = (stateLeadsFilter.assetIds || []);
                                if (isChecked) {

                                  selectedAssetIds.push(selectedAsset);
                                } else {

                                  const index = (selectedAssetIds || []).indexOf(selectedAsset);
                                  if (index > -1) {
                                    (selectedAssetIds || []).splice(index, 1);
                                  }
                                }

                                this._updateFilterState("assetIds", selectedAssetIds);
                              }}
                            />
                            <label className="form-check-label" htmlFor={`assets-${key}`}>{(assets.label || "")}</label>
                          </div>
                        </div>
                      );
                    })
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {(hasAccess("campaigns", "view-leads", ["filter-upload-ids"]) === true) && (
            <div className="filter-accordion">
              <h5 className="text-capitalize" onClick={() => this._handleAccordianClick("showUploadIdsSection", !accordianFilter.showUploadIdsSection)}>
                {((accordianFilter.showUploadIdsSection || false) === false) && (
                  <img src={require("../../../assets/icons/icon-plus.svg").default} className="img-fluid" alt="img" />
                )}

                {((accordianFilter.showUploadIdsSection || false) === true) && (
                  <img src={require("../../../assets/icons/icon-minus.svg").default} className="img-fluid" alt="img" />
                )}
                <FormattedMessage id="view_leads.filter.upload_ids" defaultMessage="upload IDs" />
              </h5>
              <div className={`filter-accordion-content ${uploadIdsClassName}`}>
                {_isEmpty(_get(uploadIdsNodes, "[0].children", [])) && (
                  <h4 className="text-capitalize text-center">
                    <FormattedMessage id="message.no_found_data" defaultMessage="no data found" />
                  </h4>
                )}

                {!_isEmpty(_get(uploadIdsNodes, "[0].children", [])) && (
                  <div className="d-flex flex-column gap-2">
                    {(Object.values(_get(uploadIdsNodes, "[0].children", [])).map((uploadId, key) => {

                      return (
                        <div key={`uploadId-${key}`} className="form-check">
                          <input
                            type="checkbox"
                            id={`uploadId-${key}`}
                            className={`uploadId-${key} form-check-input`}
                            value={(uploadId.value || 0)}
                            checked={((stateLeadsFilter.uploadIds || []).includes(uploadId.value)) ? true : false}
                            onChange={(e) => {
                              const isChecked = _get(e, "target.checked", false);
                              const selectedUploadId = parseInt(_get(e, "target.value", 0));

                              let selectedUploadIds = (stateLeadsFilter.uploadIds || []);
                              if (isChecked) {

                                selectedUploadIds.push(selectedUploadId);
                              } else {

                                const index = (selectedUploadIds || []).indexOf(selectedUploadId);
                                if (index > -1) {
                                  (selectedUploadIds || []).splice(index, 1);
                                }
                              }

                              this._updateFilterState("uploadIds", selectedUploadIds);
                            }}
                          />
                          <label className="form-check-label" htmlFor={`uploadId-${key}`}>{(uploadId.label || "")}</label>
                        </div>
                      );
                    })
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {(hasAccess("campaigns", "view-leads", ["filter-piped"]) === true) && (
            <div className="filter-accordion">
              <h5 className="text-capitalize" onClick={() => this._handleAccordianClick("showPipedSection", !accordianFilter.showPipedSection)}>
                {((accordianFilter.showPipedSection || false) === false) && (
                  <img src={require("../../../assets/icons/icon-plus.svg").default} className="img-fluid" alt="img" />
                )}

                {((accordianFilter.showPipedSection || false) === true) && (
                  <img src={require("../../../assets/icons/icon-minus.svg").default} className="img-fluid" alt="img" />
                )}
                <FormattedMessage id="view_leads.filter.piped" defaultMessage="piped" />
              </h5>
              <div className={`filter-accordion-content ${pipedClassName}`}>
                {_isEmpty(_get(pipedNodes, "[0].children", [])) && (
                  <h4 className="text-capitalize text-center">
                    <FormattedMessage id="message.no_found_data" defaultMessage="no data found" />
                  </h4>
                )}

                {!_isEmpty(_get(pipedNodes, "[0].children", [])) && (
                  <div className="d-flex flex-column gap-2">
                    {(Object.values(_get(pipedNodes, "[0].children", [])).map((piped, key) => {

                      return (
                        <div key={`piped-${key}`} className="form-check">
                          <input
                            type="checkbox"
                            id={`piped-${key}`}
                            className={`piped-${key} form-check-input`}
                            value={(piped.value || null)}
                            checked={(stateLeadsFilter.piped === piped.value) ? true : false}
                            onChange={(e) => {

                              const isChecked = _get(e, "target.checked", false);
                              const selectedPipe = parseInt(_get(e, "target.value", 0));

                              if (isChecked) {
                                this._updateFilterState("piped", selectedPipe);

                                this.setState((prevState) => ({
                                  stateLeadsFilter: {
                                    ...prevState.piped,
                                    piped: selectedPipe,
                                  }
                                }));
                              }

                              this._updateFilterState("piped", selectedPipe);
                            }}
                          />
                          <label className="form-check-label" htmlFor={`piped-${key}`}>{(piped.label || "")}</label>
                        </div>
                      );
                    })
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {(hasAccess("campaigns", "view-leads", ["filter-column-values"]) === true) && (
            <div className="filter-accordion">
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <h5 className="form-label text-capitalize d-block">
                      <FormattedMessage id="view_leads.filter.column" defaultMessage="column" />
                    </h5>
                    <Select
                      isMulti={false}
                      className="form-custom-select"
                      key={(stateLeadsFilter.columnObj || null)}
                      value={(stateLeadsFilter.columnObj || null)}
                      getOptionValue={(option) => (option.id || 0)}
                      getOptionLabel={(option) => (option.friendly_name || "")}
                      options={_get(filterValues, "lead_required_field_lookup", [])}
                      onChange={(selectedOption) => {
                        this.setState((prevState) => ({
                          stateLeadsFilter: {
                            ...prevState.stateLeadsFilter,
                            columnObj: (selectedOption || null),
                            columnId: (selectedOption.id || 0),
                          }
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="mb-3">
                    <h5 htmlFor="filter-value" className="form-label text-capitalize">
                      <FormattedMessage id="view_leads.filter.value" defaultMessage="value" />
                    </h5>
                    <textarea
                      id="filter-value"
                      name="filter-value"
                      className="form-control"
                      value={(stateLeadsFilter.columnValues || "")}
                      placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                        field: intl.formatMessage({ id: "view_leads.filter.value", defaultMessage: "value" })
                      })}
                      onChange={(e) => this._updateFilterState("columnValues", _get(e, "currentTarget.value", ""))}
                    />
                    <div className="mt-1">
                      <span
                        className="cursor-pointer text-primary heading-05 text-capitalize"
                        title={intl.formatMessage({ id: "view_leads.filter.instruction_message", defaultMessage: "Multiple values should be separated by new line.\n-- Use wild card character * to find matched values.\n Multiple values are not allowed in case of wild card." })}
                      >
                        <FormattedMessage id="view_leads.filter.instruction" defaultMessage="Instruction" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
          <button className="btn btn-secondary" onClick={() => this._closeModal()}>
            <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
          </button>
          <button className="btn btn-primary text-capitalize" onClick={() => this._handleSubmit()}>
            <FormattedMessage id="btn.submit" defaultMessage="Submit" />
          </button>
        </div>
      </CustomModal>
    );
  }

  render() {
    const { setState } = this.props;

    return (
      <>
        <div className="col-lg-2">
          <label className="form-label text-capitalize d-block">
            <FormattedMessage id="view_leads.filter.filter" defaultMessage="filter" />
          </label>
          <button className="btn btn-outline-primary waves-effect w-100" onClick={() => { if (typeof setState === "function") { setState({ showLeadFilterModal: true }); } }}>
            <FormattedMessage id="btn.filter" defaultMessage="filter" />
          </button>
        </div>
        {this._renderFilterModal()}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "dd/MM/yyyy"),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LeadFilters));
