import React, { Component } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { Select, CustomModal, ErrorMessage, showAlertMessage } from "../../../resusableComponents/common/formElements";

import { fetchRejectionTypeList } from "../../../redux/services/application";
import { updateLoadingState } from "../../../redux/actions/application";

class RejectLeadModal extends Component {

  constructor(props) {
    super(props);

    this.defaultState = {
      reason: "",
      rejectionType: null,
    }

    this.state = {
      formData: _cloneDeep(this.defaultState),
      errors: {},
      rejectionTypeList: []
    };
  }

  componentDidUpdate(prevProps, prevStates) {
    if (!_isEqual(prevStates.formData, this.state.formData)) {
      this._handleValidation();
    }
  }

  _getRejectionTypeList = async () => {
    const { updateLoadingState } = this.props;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await fetchRejectionTypeList();

      if (_get(response, "flag", false) === true) {

        this.setState({ formData: _cloneDeep(this.defaultState), errors: {}, rejectionTypeList: _get(response, "data", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching rejection types.")));
        this.setState({ formData: _cloneDeep(this.defaultState), errors: {}, rejectionTypeList: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching rejection types.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { formData } = this.state;
    let errors = {};

    if (_isEmpty(formData.rejectionType)) {
      errors["rejectionType"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "view_leads.reject_lead_modal.rejection_type", defaultMessage: "rejection type" })
      });
    }

    if (_isEmpty(formData.reason)) {
      errors["reason"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "view_leads.reject_lead_modal.reason", defaultMessage: "reason" })
      });
    }

    this.setState({ errors });
  }

  _handleSave = async () => {
    const { onSubmit, intl } = this.props;
    const { formData: { rejectionType, reason }, errors } = this.state;

    if (!_isEmpty(errors) || _isEmpty(rejectionType) || _isEmpty(reason)) {
      showAlertMessage(intl.formatMessage({ id: "error.please_fill_up_the_proper_details", defaultMessage: "Please fill up the proper details." }));
      return false;
    }

    if (typeof onSubmit === "function") {
      onSubmit(2, (reason || ""), (rejectionType.id || ""))
    }
  }

  _renderModalBody = () => {
    const { onHide, intl } = this.props;
    const { formData: { reason, rejectionType }, errors, rejectionTypeList } = this.state;

    return (
      <div className="modalForm">
        <div className="row gutters-10">
          <div className="col-sm-12 col-md-12">
            <div className="mb-6">
              <label htmlFor="reason" className="form-label text-capitalize">
                <FormattedMessage id="view_leads.reject_lead_modal.reason" defaultMessage="reason" />
              </label>
              <textarea
                id="reason"
                name="reason"
                className="form-control"
                value={(reason || "")}
                placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "view_leads.reject_lead_modal.reason", defaultMessage: "reason" })
                })}
                onChange={(e) => this.setState((prevState) => ({ formData: { ...prevState.formData, "reason": _get(e, "target.value", "") } }))}
              />
              {(errors.reason !== "") && (<ErrorMessage message={(errors.reason || "")} />)}
            </div>
          </div>

          <div className="col-sm-12 col-md-12">
            <div className="mb-6">
              <label className="form-label text-capitalize">
                <FormattedMessage id="view_leads.reject_lead_modal.rejection_type" defaultMessage="rejection type" />
              </label>
              <Select
                className="form-custom-select"
                placeholder={intl.formatMessage({ id: "placeholder.select_field", defaultMessage: "Select {field}" },
                  { field: intl.formatMessage({ id: "view_leads.reject_lead_modal.rejection_type", defaultMessage: "rejection type" }) })}
                value={(rejectionType || null)}
                options={(rejectionTypeList || [])}
                error={(errors.rejectionType || "")}
                getOptionValue={(option) => (option.id)}
                getOptionLabel={(option) => (option.rejection_type)}
                onChange={(selectedOptions) => this.setState((prevState) => ({ formData: { ...prevState.formData, "rejectionType": selectedOptions } }))}
                isMulti={false}
              />
            </div>
          </div>

          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button className="btn btn-secondary" onClick={() => { if (typeof onHide === "function") { onHide() } }}>
              <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
            </button>
            <button className="btn btn-primary text-capitalize" onClick={() => this._handleSave()}>
              <FormattedMessage id="btn.submit" defaultMessage="Submit" />
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { onHide, show, intl } = this.props;

    return (
      <CustomModal
        isOpen={show}
        modalTitle={(intl.formatMessage({ id: "view_leads.reject_lead_modal.title", defaultMessage: "Reject Lead" }))}
        onHide={() => { if (typeof onHide === "function") { onHide() } }}
        onClose={() => { if (typeof onHide === "function") { onHide() } }}
        onEntered={() => this._getRejectionTypeList()}
      >
        {this._renderModalBody()}
      </CustomModal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data))
});

export default connect(null, mapDispatchToProps)(injectIntl(RejectLeadModal));
