import React, { PureComponent } from "react";
import { isEmpty as _isEmpty } from "lodash";
import PropTypes from "prop-types";

import { EmptyLabel } from ".";

import { isInt } from "../../../helpers/utils";

class RenderTableCellValue extends PureComponent {

  render() {
    const { value = "", altToolText = "", tooltip = true, ...restProps } = this.props;

    let toolTipText = "";
    if ((typeof altToolText === "string") && !_isEmpty(altToolText)) {
      toolTipText = altToolText;
    } else if ((typeof value === "string") && !_isEmpty(value)) {
      toolTipText = value;
    }

    return (
      <span
        {...(((tooltip === true) && !_isEmpty(toolTipText)) ? { title: toolTipText } : {})}
        {...restProps}
      >
        {(isInt(value) === true) ? (value || 0) : (value || <EmptyLabel />)}
      </span>
    )
  }
};

RenderTableCellValue.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.bool,
};

export default RenderTableCellValue;
