import React, { Component, Fragment } from "react";
import { cloneDeep as _cloneDeep, get as _get, isEmpty as _isEmpty, isEqual as _isEqual, filter as _filter } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { Select, showAlertMessage, DateRangePicker, RenderTableHeaderValue, RenderTableCellValue } from "../../resusableComponents/common/formElements";
import ReactDataTable from "../../resusableComponents/common/ReactDataTable";
import { hasAccess } from "../../resusableComponents/hoc/AccessManagement";
import { downloadExcelFile } from "../../helpers/utils";
import { USER_ROLES } from "../../helpers/constants";

import MarkAsButton from "../../resusableComponents/common/MarkAsButton";
import DeleteCampaign from "./components/DeleteCampaign";

import { doGetCampaignList, clearCampaignListError } from "../../redux/actions/campaign";
import { doGetAgencyList, doGetClientList } from "../../redux/services/userMangement";
import { exportAllCampaignAssets } from "../../redux/services/campaign";
import { updateLoadingState } from "../../redux/actions/application";


const tabList = ["Live", "Completed"];

class CampaignList extends Component {

  constructor(props) {
    super(props);

    this.timer = null;
    const { roleId, agencyId, intl } = this.props;

    const selAgencyId = [_get(USER_ROLES, "AGENCY.id", "0"), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", "0")].includes(roleId) ? agencyId : null;

    this.defultCampaignListPayload = {
      agency_id: selAgencyId,
      client_id: null,
      start_date: null,
      end_date: null,
      search_text: "",
      page_no: 1,
      page_size: 5000
    }

    this.state = {
      campaignListPayload: _cloneDeep(this.defultCampaignListPayload),
      showDeleteCampaignConfirmBox: false,
      filterCompletedCampaignList: [],
      filterLiveCampaignList: [],
      completedCampaignList: [],
      liveCampaignList: [],
      agencyDropDownData: [],
      clientDropDownData: [],
      selectedCampaignIds: [],
      selectedAgencyList: null,
      activeTab: "Live",
      searchText: "",
      selectedClientList: null,
      reloadCampaignList: false,
      resetDateRangePicker: false
    };

    this.agencyAdminAccountColumns = [
      {
        name: (<RenderTableHeaderValue className="text-uppercase" value={intl.formatMessage({ id: "campaign_list.live.table.column_id", defaultMessage: "id" })} />),
        selector: (row) => (_get(row, "id", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "id", "")} />),
        sortable: true,
        grow: 0
      },
      {
        name: (<RenderTableHeaderValue className="text-capitalize" value={intl.formatMessage({ id: "campaign_list.live.table.column_client_name", defaultMessage: "client name" })} />),
        selector: (row) => (_get(row, "client", "")),
        cell: (row) => {
          const logoUrl = (_get(row, "website", "")) ? `https://logo.clearbit.com/${_get(row, "website", "")}` : require("../../assets/images/no-img-icon.png");

          return (
            <Fragment>
              <img className="img-fluid me-5" width="30" src={logoUrl} alt="" />
              <RenderTableCellValue className="text-truncate" value={_get(row, "client", "")} tooltip={true} />
            </Fragment>
          );
        },
        sortable: true,
        grow: 2
      },
      {
        name: (<RenderTableHeaderValue className="text-capitalize" value={intl.formatMessage({ id: "campaign_list.live.table.column_campaign_name", defaultMessage: "campaign name" })} />),
        selector: (row) => (_get(row, "campaign_name", "")),
        cell: (row) => {

          return (
            <Link to={`/campaigns/dashboard/${_get(row, "id", "")}`} target="_blank">
              <RenderTableCellValue className="text-blue-22 text-truncate" value={_get(row, "campaign_name", "")} />
            </Link>
          );
        },
        sortable: true,
        grow: 2
      },
      {
        name: (<RenderTableHeaderValue className="text-capitalize" value={intl.formatMessage({ id: "campaign_list.live.table.column_booked", defaultMessage: "booked" })} />),
        selector: (row) => (_get(row, "booked", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "booked", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue className="text-capitalize" value={intl.formatMessage({ id: "campaign_list.live.table.column_delivered", defaultMessage: "delivered" })} />),
        selector: (row) => (_get(row, "delivered", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "delivered", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue className="text-capitalize" value={intl.formatMessage({ id: "campaign_list.live.table.column_new", defaultMessage: "new" })} />),
        selector: (row) => (_get(row, "new", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "new", "")} />),
        sortable: true,
        grow: 0
      },
    ];

    this.RdAdminOprationColumns = [
      {
        name: (<RenderTableHeaderValue className="text-uppercase" value={intl.formatMessage({ id: "campaign_list.live.table.column_id", defaultMessage: "id" })} />),
        selector: (row) => (_get(row, "id", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "id", "")} />),
        sortable: true,
        width: "50px"
      },
      {
        name: (<RenderTableHeaderValue className="text-capitalize" value={intl.formatMessage({ id: "campaign_list.live.table.column_agency", defaultMessage: "agency" })} />),
        selector: (row) => (_get(row, "agency", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "agency", "")} />),
        sortable: true,
        grow: 1
      },
      {
        name: (<RenderTableHeaderValue className="text-capitalize" value={intl.formatMessage({ id: "campaign_list.live.table.column_client", defaultMessage: "client" })} />),
        selector: (row) => (_get(row, "client", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "client", "")} />),
        sortable: true,
        grow: 2
      },
      {
        name: (<RenderTableHeaderValue className="text-capitalize" value={intl.formatMessage({ id: "campaign_list.live.table.column_campaign_name", defaultMessage: "campaign name" })} />),
        selector: (row) => (_get(row, "campaign_name", "")),
        cell: (row) => {

          return (
            <Link to={`/campaigns/dashboard/${_get(row, "id", "")}`} target="_blank">
              <RenderTableCellValue className="text-blue-22 text-truncate" value={_get(row, "campaign_name", "")} />
            </Link>
          );
        },
        sortable: true,
        width: "300px"
      },
      {
        name: (<RenderTableHeaderValue className="text-capitalize" value={intl.formatMessage({ id: "campaign_list.live.table.column_ponumber", defaultMessage: "PO number" })} />),
        selector: (row) => (_get(row, "po_number", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "po_number", "")} />),
        sortable: true,
        grow: 2
      },
      {
        name: (<RenderTableHeaderValue className="text-capitalize" value={intl.formatMessage({ id: "campaign_list.live.table.column_jobtype", defaultMessage: "job type" })} />),
        selector: (row) => (_get(row, "job_type", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "job_type", "")} />),
        grow: 2
      },
      {
        name: (<RenderTableHeaderValue className="text-capitalize" value={intl.formatMessage({ id: "campaign_list.live.table.column_booked", defaultMessage: "booked" })} />),
        selector: (row) => (_get(row, "booked", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "booked", "-")} />),
        sortable: true,
        grow: 2
      },
      {
        name: (<RenderTableHeaderValue className="text-capitalize" value={intl.formatMessage({ id: "campaign_list.live.table.column_delivered", defaultMessage: "delivered" })} />),
        selector: (row) => (_get(row, "delivered", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "delivered", "")} />),
        sortable: true,
        grow: 1
      },
      {
        name: (<RenderTableHeaderValue className="text-capitalize" value={intl.formatMessage({ id: "campaign_list.live.table.column_upload_day", defaultMessage: "upload day" })} />),
        selector: (row) => _get(row, "upload_day", ""),
        cell: (row) => (<RenderTableCellValue value={_get(row, "upload_day", "")} />),
        grow: 2
      }
    ];
  }

  componentDidMount() {
    this._fetchAgency();
    this.fetchCampaignList();
    this.setCampaignListData();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(_get(prevState, "selectedAgencyList", null), _get(this.state, "selectedAgencyList", null))) {

      if ((_get(this.state, "selectedAgencyList", null)) && (_get(this.state, "selectedAgencyList.id", null) !== null)) {
        this._fetchClient();
      } else {
        this.setState({
          clientDropDownData: [],
          selectedClientList: null,
          campaignListPayload: { ...this.state.campaignListPayload, client_id: null }
        });
      }
    }

    if (!_isEqual(prevState.campaignListPayload, this.state.campaignListPayload) && !_isEmpty(this.state.campaignListPayload)) {
      this.fetchCampaignList();
    }

    if (!_isEqual(prevProps.campaignListDataResponse, this.props.campaignListDataResponse) && !_isEmpty(this.props.campaignListDataResponse)) {
      this.setCampaignListData();
    }

    if (!_isEqual(prevState.searchText, this.state.searchText)) {
      this._localSearch();
    }

    if (!_isEqual(prevState.reloadCampaignList, this.state.reloadCampaignList) && (_get(this.state, "reloadCampaignList", false) === true)) {
      this.fetchCampaignList();
      this.setState({ reloadCampaignList: false });
    }
  }

  _deleteCampaignsConfirmBox = () => {
    const { intl } = this.props;
    const { selectedCampaignIds } = this.state;

    if ((selectedCampaignIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "campaign_list.campaign", defaultMessage: "campaign" }) }
      ));
      return false;
    }
    this.setState({ showDeleteCampaignConfirmBox: true });
  }

  _handleExportAllCampaign = async () => {
    const { updateLoadingState, intl } = this.props;
    const { selectedCampaignIds, campaignListPayload, searchText } = this.state;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }
      let variables = {};

      if ((selectedCampaignIds || []).length > 0) {
        variables = {
          campaign_ids: selectedCampaignIds,
        };
      } else {
        variables = {
          campaign_ids: [],
          start_date: _get(campaignListPayload, "start_date", null),
          end_date: _get(campaignListPayload, "end_date", null),
          search_text: (searchText || ""),
          agency_id: _get(campaignListPayload, "agency_id", null),
          client_id: _get(campaignListPayload, "client_id", null),
        };
      }

      const response = await exportAllCampaignAssets(variables);

      if (_get(response, "flag", false) === true) {

        const downloadFileDetails = _get(response, "data", []);

        const contentType = (downloadFileDetails.contentType || "");
        const contents = (downloadFileDetails.Contents || "");
        const filename = (downloadFileDetails.filename || "");

        if ((contentType !== "") && (contents !== "") && (filename !== "")) {
          downloadExcelFile(contentType, contents, filename);

          showAlertMessage(intl.formatMessage({ id: "campaign_list.file_generated_successfully", defaultMessage: "File generated successfully." }), "success");
        }

      } else {
        showAlertMessage(response.message || "Something went wrong while exporting campaigns.");
      }

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while exporting campaigns.");
    } finally {

      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _fetchAgency = async () => {
    const { agencyId, agencyName, roleId, updateLoadingState } = this.props;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      let selAgencyList = null;
      const selAgencyId = [_get(USER_ROLES, "AGENCY.id", "0"), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", "0")].includes(roleId) ? agencyId : null;

      const response = await doGetAgencyList({ dropdown_flag: 1, agency_id: selAgencyId });

      if (([_get(USER_ROLES, "AGENCY.id", "0"), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", "0")].includes(roleId))) {
        selAgencyList = { "id": agencyId, "agency_name": agencyName };
      }

      if ((response.flag || false) === true) {
        const fetchData = _get(response, "data.responseData.agency_list", []);
        let agencyDropDownData = [...fetchData];
        agencyDropDownData.unshift({ id: null, agency_name: "All" });

        this.setState({ agencyDropDownData: agencyDropDownData, selectedAgencyList: selAgencyList });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching agency.")));
        this.setState({ agencyDropDownData: [], selectedAgencyList: selAgencyList });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching agency.")));
      this.setState({ agencyDropDownData: [], selectedAgencyList: null });
    } finally {

      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _fetchClient = async () => {
    const { updateLoadingState } = this.props;
    const { selectedAgencyList } = this.state;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetClientList({ agency_id: _get(selectedAgencyList, "id", null), dropdown_flag: 1 });

      if ((response.flag || false) === true) {
        const fetchedData = _get(response, "data.responseData.client_list", []);
        let clientDropDownData = [...fetchedData];
        clientDropDownData.unshift({ id: null, client_name: "All" });
        this.setState((prevState) => ({ clientDropDownData: clientDropDownData, campaignListPayload: { ...prevState.campaignListPayload, client_id: null }, selectedClientList: null }));
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching client.")));
        this.setState((prevState) => ({ clientDropDownData: [], campaignListPayload: { ...prevState.campaignListPayload, client_id: null }, selectedClientList: null }));
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching client.")));
      this.setState((prevState) => ({ clientDropDownData: [], campaignListPayload: { ...prevState.campaignListPayload, client_id: null }, selectedClientList: null }));
    } finally {

      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  handleSearchText = (value) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.setState({ searchText: value }), 500)
  }

  setCampaignListData = () => {
    const { campaignListDataResponse, clearCampaignListError } = this.props;

    if (_get(campaignListDataResponse, "flag", null) === null) { return false; }

    if (_get(campaignListDataResponse, "flag", null) === true) {
      const completedCampaigns = _filter((_get(campaignListDataResponse, "data.responseData.campaign_list", []) || []), (campaign) => ((campaign.is_completed || false) === true));
      const liveCampaigns = _filter((_get(campaignListDataResponse, "data.responseData.campaign_list", []) || []), (campaign) => ((campaign.is_completed || false) !== true));

      this.setState({
        completedCampaignList: (completedCampaigns || []),
        filteredCompletedCampaignList: (completedCampaigns || []),
        liveCampaignList: (liveCampaigns || []),
        filterLiveCampaignList: (liveCampaigns || []),
      });
    } else if (_get(campaignListDataResponse, "flag", null) === false) {
      showAlertMessage(_get(campaignListDataResponse, "message", "Something went wrong while fetching Campaign list"));

      if (typeof clearCampaignListError === "function") { clearCampaignListError(); }
    }

  }

  _localSearch = () => {
    const { activeTab, searchText, liveCampaignList, completedCampaignList } = this.state;

    let tmpData = [];

    if (activeTab === "Live") {
      tmpData = _cloneDeep(liveCampaignList);
    } else {
      tmpData = _cloneDeep(completedCampaignList);
    }

    if ((searchText || "").length > 0) {

      tmpData = (tmpData || []).filter((a) => {

        let f = true;
        let r = false;
        let p;

        if ((searchText || "").length > 0) {

          for (p of ["agency", "campaign_name", "client", "job_type", "po_number", "upload_day"]) {

            const v = (_get(a, p, "") || "").toLowerCase();

            f = v.includes(searchText.toLowerCase());
            if (f === true) break;
          }
        }

        if ((searchText || "").length > 0) {
          return f;
        }
        return r;
      });
    }

    if (activeTab === "Live") {
      this.setState({ filterLiveCampaignList: tmpData });
    } else {
      this.setState({ filteredCompletedCampaignList: tmpData });
    }
  }

  fetchCampaignList = () => {
    const { doGetCampaignList } = this.props;
    const { campaignListPayload } = this.state;

    if (typeof doGetCampaignList === "function") { doGetCampaignList(campaignListPayload) };
  }

  handleTabChange = (tab) => {
    this.setState({ activeTab: tab, selectedCampaignIds: [] });
  }

  handleUpdateDateRange = (startDate, endDate) => {
    const { campaignListPayload } = this.state;

    this.setState({
      campaignListPayload: {
        ...campaignListPayload,
        start_date: startDate,
        end_date: endDate
      },
      resetDateRangePicker: false
    });
  }

  _handleReset = () => {
    const { roleId, agencyId, agencyName } = this.props;

    let selAgencyList = null;
    if (([_get(USER_ROLES, "AGENCY.id", "0"), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", "0")].includes(roleId))) {
      selAgencyList = { "id": agencyId, "agency_name": agencyName };
    }

    this.setState({
      campaignListPayload: { ...this.defultCampaignListPayload },
      searchText: "",
      resetDateRangePicker: true,
      selectedAgencyList: selAgencyList,
      selectedClientList: null,
    });
  }

  _renderCampaignBox = () => {
    const { campaignListDataResponse } = this.props;

    const campaignNumbers = _get(campaignListDataResponse, "data.responseData.campaign_numbers[0]", {});

    return (
      <div className="row gy-4">

        <div className="col-lg-3">
          <div className="card-static border-blue-55">
            <p className="text-capitalize">
              <FormattedMessage id="campaign_list.campaign_box.campaigns" defaultMessage="campaigns" />
            </p>
            <h6 className="text-blue-55">{_get(campaignNumbers, "complete_percent", 0)}%</h6>
            <p className="text-capitalize">
              <FormattedMessage id="campaign_list.campaign_box.completed" defaultMessage="completed" />
            </p>
          </div>
        </div>

        <div className="col-lg-3">
          <div className="card-static border-success">
            <p className="text-capitalize">
              <FormattedMessage id="campaign_list.campaign_box.leads_generated" defaultMessage="leads generated" />
            </p>
            <h6 className="text-success">{_get(campaignNumbers, "approved_leads", 0)}</h6>
            <p className="text-capitalize">
              <FormattedMessage id="campaign_list.campaign_box.approved" defaultMessage="approved" />
            </p>
          </div>
        </div>

        <div className="col-lg-3">
          <div className="card-static border-danger">
            <p className="text-capitalize">
              <FormattedMessage id="campaign_list.campaign_box.leads_rejected" defaultMessage=" leads rejected" />
            </p>
            <h6 className="text-danger">{_get(campaignNumbers, "rejected_leads", 0)}</h6>
            <p className="text-capitalize">
              <FormattedMessage id="campaign_list.campaign_box.rejected" defaultMessage="rejected" />
            </p>
          </div>
        </div>

        <div className="col-lg-3">
          <div className="card-static border-warning">
            <p className="text-capitalize">
              <FormattedMessage id="campaign_list.campaign_box.leads_panding" defaultMessage="leads pending" />
            </p>
            <h6 className="text-warning">{_get(campaignNumbers, "pending_leads", 0)}</h6>
            <p className="text-capitalize">
              <FormattedMessage id="campaign_list.campaign_box.pending" defaultMessage="pending" />
            </p>
          </div>
        </div>

      </div >
    );
  }

  _renderFilters = () => {
    const { roleId, intl } = this.props;
    const { selectedClientList, selectedAgencyList, agencyDropDownData, clientDropDownData, resetDateRangePicker } = this.state;

    return (
      <div className="row gy-4">

        {(hasAccess("campaigns", "list", ["date-filter"])) && (
          <div className="col-lg-3">
            <div>
              <label className="form-label text-capitalize">
                <FormattedMessage id="campaign_list.select.date.label" defaultMessage="date" />
              </label>

              <DateRangePicker resetDateRangePicker={(resetDateRangePicker || false)} onChange={(startDate, endDate) => this.handleUpdateDateRange(startDate, endDate)} />
            </div>
          </div>
        )}

        {(hasAccess("campaigns", "list", ["agency-filter"])) && (
          <div className="col-lg-3">
            <div>
              <label className="form-label text-capitalize" >
                <FormattedMessage id="campaign_list.select.agency.label" defaultMessage="agency" />
              </label>
              <Select
                className="form-custom-select text-capitalize"
                placeholder={intl.formatMessage({ id: "campaign_list.select.agency.placeholder", defaultMessage: "select agency" })}
                options={(agencyDropDownData || [])}
                value={(selectedAgencyList || null)}
                getOptionLabel={(option) => (option.agency_name || "")}
                getOptionValue={(option) => (option.id || null)}
                onChange={(selected) => this.setState(prevState => ({
                  campaignListPayload: {
                    ...prevState.campaignListPayload,
                    agency_id: _get(selected, "id", null)
                  },
                  selectedAgencyList: selected
                }))}
                isDisabled={[_get(USER_ROLES, "AGENCY.id", "0"), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", "0")].includes(roleId) ? true : false}
                isMulti={false}
              />
            </div>
          </div>
        )}

        {(hasAccess("campaigns", "list", ["client-filter"])) && (
          <div className="col-lg-3">
            <div>
              <label className="form-label text-capitalize" >
                <FormattedMessage id="campaign_list.select.client.label" defaultMessage="client" />
              </label>
              <Select
                className="form-custom-select text-capitalize"
                placeholder={intl.formatMessage({ id: "campaign_list.select.client.placeholder", defaultMessage: "select client" })}
                options={(clientDropDownData || [])}
                value={(selectedClientList || null)}
                getOptionLabel={(option) => (option.client_name || "")}
                getOptionValue={(option) => (option.id || null)}
                onChange={(selected) => this.setState(prevState => ({
                  campaignListPayload: {
                    ...prevState.campaignListPayload,
                    client_id: _get(selected, "id", null)
                  },
                  selectedClientList: selected
                }))}
                isMulti={false}
              />
            </div>
          </div>
        )}

        {(hasAccess("campaigns", "list", ["search"])) && (
          <div className="col-lg-2">
            <div>
              <label className="form-label text-capitalize">
                <FormattedMessage id="placeholder.search" defaultMessage="search" />
              </label>
              <div className="input-group">
                <input type="text"
                  className="form-control"
                  placeholder="Search ..."
                  onChange={(event) => this.handleSearchText(_get(event, "target.value", ""))} />
                <span className="input-group-text bg-primary waves-effect py-1 d-flex align-items-center">
                  <img src={require("../../assets/icons/icon-search-sm.svg").default} alt="Hermes" className="img-fluid" />
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="col-lg-1">
          <div className="d-flex mt-7 justify-content-end">
            <button className="btn btn-dark waves-effect text-capitalize" onClick={() => { this._handleReset() }}>
              <FormattedMessage id="btn.reset" defaultMessage="reset" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  _renderTabs = () => {
    const { activeTab } = this.state;

    return (
      <ul className="nav nav-tabs bg-white-f0 rounded-1" id="myTab" role="tablist">

        {(tabList || []).map((tab, index) => {

          return (
            <li className="nav-item" key={index}>
              <button className={`nav-link text-capitalize ${tab === activeTab ? "active" : ""} `}
                onClick={() => this.handleTabChange(tab)}>
                {tab}
              </button>
            </li>
          )
        })}
      </ul>
    );
  }

  _renderCommonButtons = () => {
    const { activeTab, selectedCampaignIds } = this.state;
    return (
      <div className="d-flex flex-wrap gap-3 align-items-center justify-content-end my-3">

        {(hasAccess("campaigns", "list", ["export"])) && (
          <button className="btn btn-light text-capitalize" onClick={() => this._handleExportAllCampaign()}>
            <FormattedMessage id="btn.export" defaultMessage="export" />
          </button>
        )}

        {(hasAccess("campaigns", "list", ["delete"])) && (
          <button className="btn btn-light text-capitalize" onClick={() => this._deleteCampaignsConfirmBox()}>
            <FormattedMessage id="btn.delete" defaultMessage="delete" />
          </button>
        )}

        {((activeTab === "Live") && hasAccess("campaigns", "list", ["mark-as-complete"])) && (
          <MarkAsButton
            buttonId="campaign_list.button.mark_as_complete"
            buttonDefultMessage="mark as complete"
            operationStatus="complete"
            selectedCampaignIds={selectedCampaignIds || []}
            source="campaign_list"
            setState={(args) => this.setState(args)}
          />
        )}

        {((activeTab === "Completed") && (hasAccess("campaigns", "list", ["mark-as-live"]))) && (
          <MarkAsButton
            buttonId="campaign_list.button.mark_as_live"
            buttonDefultMessage="mark as live"
            operationStatus="live"
            selectedCampaignIds={(selectedCampaignIds || [])}
            source="campaign_list"
            setState={(args) => this.setState(args)}
          />
        )}
      </div>
    );
  }

  _renderTabContent = () => {
    const { roleId } = this.props;
    const { filterLiveCampaignList, filteredCompletedCampaignList, activeTab } = this.state;

    const tableColumns = ([_get(USER_ROLES, "AGENCY.id", "0"), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", "0")].includes(roleId)
      ? this.agencyAdminAccountColumns
      : this.RdAdminOprationColumns);

    return (
      <div className="tab-content">
        <div className="tab-pane fade show active">
          <div className="row">
            <div className="col-lg-12">

              {this._renderCommonButtons()}

              {(activeTab === "Live") && <ReactDataTable
                columns={(tableColumns || [])}
                data={(filterLiveCampaignList || [])}
                pagination={false}
                selectableRows
                onChangePage={() => { }}
                onSelectedRowsChange={(selectedRows) => {
                  const tmpSelectedCampaignIds = _get(selectedRows, "selectedRows", []).map(row => { return row.id });
                  this.setState({ selectedCampaignIds: tmpSelectedCampaignIds });
                }}
                className="custom-scroll-460"
              />}

              {(activeTab === "Completed") && <ReactDataTable
                columns={(tableColumns || [])}
                data={(filteredCompletedCampaignList || [])}
                pagination={false}
                selectableRows
                onChangePage={() => { }}
                onSelectedRowsChange={(selectedRows) => {
                  const tmpSelectedCampaignIds = _get(selectedRows, "selectedRows", []).map(row => { return row.id });
                  this.setState({ selectedCampaignIds: tmpSelectedCampaignIds });
                }}
                className="custom-scroll-460"
              />}

            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderView = () => {
    const { showDeleteCampaignConfirmBox, selectedCampaignIds } = this.state;

    return (
      <>
        <div className="content-wrapper">
          <div className="container-fluid flex-grow-1 px-6 py-6">

            {this._renderCampaignBox()}

            <div className="row mt-5">
              <div className="col-lg-12">
                <h1 className="heading-04 fw-semibold text-dark-35 text-uppercase mb-5">
                  <FormattedMessage id="menu.campaign_list" defaultMessage="campaign list" />
                </h1>
              </div>
            </div>

            {this._renderFilters()}

            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">

                    {this._renderTabs()}

                    {this._renderTabContent()}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DeleteCampaign
          setState={(args) => this.setState(args)}
          selectedCampaignIds={(selectedCampaignIds || [])}
          showDeleteCampaignConfirmBox={showDeleteCampaignConfirmBox}
        />
      </>
    );
  }

  render() {

    return (
      <>
        {(hasAccess("campaigns", "list", ["page-view"])) && (
          this._renderView()
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  campaignListDataResponse: _get(state, "campaign.campaignList", {}),
  roleId: _get(state, "authentication.user.role_id", 0),
  agencyId: _get(state, "authentication.user.agency_id", null),
  agencyName: _get(state, "authentication.user.agency_name", ""),
});

const mapDispatchToProps = (dispatch) => ({
  clearCampaignListError: () => dispatch(clearCampaignListError()),
  doGetCampaignList: (data) => dispatch(doGetCampaignList(data)),
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaignList));
