import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual, debounce as _debounce } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { RenderTableCellValue, RenderTableHeaderValue, showAlertMessage, EmptyLabel, ConfirmBox } from "../../resusableComponents/common/formElements";
import { hasAccess } from "../../resusableComponents/hoc/AccessManagement";
import { withRouter } from "../../resusableComponents/hoc/withRouter";
import { downloadExcelFile } from "../../helpers/utils";
import ReactDataTable from "../../resusableComponents/common/ReactDataTable";
import AddAsset from "./AddAsset";

import { getCampaignAssetList, deleteCampaignAssets, exportSelectedCampaignAssets, downloadCampaignAssetsAsZip } from "../../redux/services/campaign";
import { updateLoadingState } from "../../redux/actions/application";

class CampaignContent extends Component {

  constructor(props) {
    super(props);

    const { intl } = props;

    this.defaultPayload = {
      campaign_id: parseInt(_get(this.props, "params.id", 0)),
      search_text: "",
      page_no: 1,
      page_size: 10
    }

    const hasDownloadAcccess = hasAccess("campaigns", "content", ["download"]);

    this.searchDebounce = _debounce(this._handleSearchCampaignAssets, 1000);

    this.state = {
      selectAllIds: false,
      selectedIds: [],
      campaignDetails: {},
      assetListData: [],
      assetListPaginationInfo: {},
      campaignId: _get(this.props, "params.id", 0),
      defaultPayload: { ...this.defaultPayload },
      showDeleteConfirmBox: false,
      addAssetModal: false,
    }

    this.columns = [
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_content.table.asset_name", defaultMessage: "asset name" })} />),
        selector: (row) => (_get(row, "asset_name", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "asset_name", "")} tooltip={true} />),
        sortable: true,
        grow: 2
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_content.table.topic", defaultMessage: "topic" })} />),
        selector: (row) => (_get(row, "topic", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "topic", "")} tooltip={true} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_content.table.sub_topic", defaultMessage: "sub topic" })} />),
        selector: (row) => (_get(row, "sub_topic_name", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "sub_topic_name", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_content.table.asset_code", defaultMessage: "asset code" })} />),
        selector: (row) => (_get(row, "enabled", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "asset_code", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_content.table.file_name", defaultMessage: "file name" })} />),
        selector: (row) => (_get(row, "asset_file_name", "")),
        cell: (row) => (<RenderTableCellValue value={(_get(row, "asset_file_name", ""))} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_content.table.download", defaultMessage: "download" })} />),
        selector: (row) => {
          const fileUrl = _get(row, "asset_url", "");

          return (
            <>
              {(_isEmpty(fileUrl)) && (<EmptyLabel />)}

              {(!_isEmpty(fileUrl) && (hasDownloadAcccess)) && (
                <Link to={fileUrl} className="text-truncate cursor-pointer text-center d-block" target="_blank" rel="noopener noreferrer">
                  <img src={require("../../assets/icons/icon-download.svg").default} className="img-fluid" alt="" />
                </Link>
              )}
            </>
          )
        },
        sortable: false,
        width: "80px",
        center: "true",
      },
    ];
  }

  componentDidMount() {
    this._fetchAssetList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevState.defaultPayload, this.state.defaultPayload)) {
      this._fetchAssetList();
    }
  }

  _fetchAssetList = async () => {
    const { updateLoadingState } = this.props;
    const { defaultPayload } = this.state;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const assetResponse = await getCampaignAssetList(defaultPayload);

      if (_get(assetResponse, "flag", false) === true) {
        this.setState({
          assetListData: _get(assetResponse, "data.asset_details", []),
          assetListPaginationInfo: _get(assetResponse, "data.asset_list_info[0]", {}),
          campaignDetails: _get(assetResponse, "data.campaign_details[0]", {}),
        });
      } else {
        showAlertMessage((_get(assetResponse, "message", "Something went wrong while fetching campaign content data.")));
      }

    } catch (err) {
      showAlertMessage((_get(err, "message", "Something went wrong while fetching campaign content datas.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleSearchCampaignAssets = (searchText) => {
    const { defaultPayload } = this.state;

    this.setState({ defaultPayload: { ...defaultPayload, search_text: searchText } });
  }

  _handleMultipleDelete = async () => {
    const { updateLoadingState, intl } = this.props;
    const { selectedIds, campaignId } = this.state;

    if (_isEmpty(selectedIds)) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one_with_operation", defaultMessage: "Please select at least one {field} to {operation}." }, { field: "asset", operation: "delete" }));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const deleteResponse = await deleteCampaignAssets(campaignId, { asset_ids: selectedIds });

      if (_get(deleteResponse, "flag", false) === true) {
        showAlertMessage(_get(deleteResponse, "message", "Campaign asset deleted successfully."), "success");
        this.setState({ selectedIds: [], showDeleteConfirmBox: false });

        this._fetchAssetList();
      } else {
        this.setState({ showDeleteConfirmBox: false });
        showAlertMessage((_get(deleteResponse, "message", "Something went wrong while deleting campaign assets.")));
      }
    } catch (err) {
      this.setState({ showDeleteConfirmBox: false });
      showAlertMessage((_get(err, "message", "Something went wrong while deleting campaign assets.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }

  }

  _handleExportCampaignAssets = async () => {
    const { updateLoadingState, intl } = this.props;
    const { campaignId, selectAllIds, selectedIds } = this.state;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await exportSelectedCampaignAssets({
        campaign_id: (campaignId || null),
        asset_ids: ((selectAllIds || false) === true) ? [] : (selectedIds || []),
      });

      if (_get(response, "flag", false) === true) {

        const downloadFileDetails = _get(response, "data", []);

        const contentType = (downloadFileDetails.contentType || "");
        const contents = (downloadFileDetails.Contents || "");
        const filename = (downloadFileDetails.filename || "");

        if ((contentType !== "") && (contents !== "") && (filename !== "")) {
          downloadExcelFile(contentType, contents, filename);

          showAlertMessage(intl.formatMessage({ id: "message.file_generated_successfully", defaultMessage: "File generated successfully." }), "success");
        }

      } else {
        showAlertMessage(response.message || "Something went wrong while exporting asset(s)");
      }

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while exporting asset(s)");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _handleDownloadCampaignAssets = async () => {
    const { updateLoadingState, intl } = this.props;
    const { campaignId, selectAllIds, selectedIds } = this.state;

    if (_isEmpty(selectedIds)) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one_with_operation", defaultMessage: "Please select at least one {field} to {operation}." },
        {
          field: intl.formatMessage({ id: "asset", defaultMessage: "asset" }),
          operation: intl.formatMessage({ id: "download", defaultMessage: "download" })
        }));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const downloadResponse = await downloadCampaignAssetsAsZip({
        campaign_id: (campaignId || null),
        asset_ids: ((selectAllIds || false) === true) ? [] : (selectedIds || []),
      });

      if (_get(downloadResponse, "flag", false) === false) {
        showAlertMessage(downloadResponse.message || "Something went wrong during downloading file. Please try after sometime.");
        return false;
      }

      const contentType = _get(downloadResponse, "data.contentType", "");
      const contents = _get(downloadResponse, "data.Contents", "");
      const filename = _get(downloadResponse, "data.filename", "");

      if (contentType && contents && filename) {
        downloadExcelFile(contentType, contents, filename);

        showAlertMessage(intl.formatMessage({ id: "message.file_generated_successfully", defaultMessage: "File generated successfully." }), "success");
      } else {
        showAlertMessage(downloadResponse.message || "Something went wrong during downloading file. Please try after sometime.");
      }

      this.setState({ selectedIds: [] });
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong during downloading file. Please try after sometime.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  handlePaginationChange = (pageNo) => {
    const { defaultPayload } = this.state;

    this.setState({ defaultPayload: { ...defaultPayload, page_no: pageNo } });
  }

  _renderButtons = () => {
    const { intl } = this.props;
    const { campaignId, selectedIds } = this.state;

    return (
      <div className="row gy-4">
        {(hasAccess("campaigns", "content", ["campaign-dashboard"])) && (
          <div className="col-xxl-3 col-xl-auto col-lg-auto">
            <div className="input-group">
              <span className="input-group-text bg-primary-66 border-primary-66 px-3 d-flex align-items-center">
                <img src={require("../../assets/icons/icon-dashboard-alt.svg").default} className="img-fluid" alt="img" />
              </span>
              <Link to={`/campaigns/dashboard/${campaignId}`} className="btn btn-primary waves-effect">
                <FormattedMessage id="campaign_content.btn.campaign_dashboard" defaultMessage="campaign dashboard" />
              </Link>
            </div>
          </div>
        )}
        <div className="col-xxl-9 col-xl col-lg">
          <div className="d-flex flex-wrap gap-3 align-items-center justify-content-end">
            {(hasAccess("campaigns", "content", ["search"])) && (
              <div className="input-group w-auto">
                <input type="text" className="form-control" placeholder="Search ..." onChange={(e) => this.searchDebounce(_get(e, "target.value", ""))} />
                <span className="input-group-text bg-primary waves-effect py-1 d-flex align-items-center">
                  <img src={require("../../assets/icons/icon-search-sm.svg").default} className="img-fluid" alt="img" />
                </span>
              </div>
            )}
            {(hasAccess("campaigns", "content", ["add"])) && (
              <button className="btn btn-outline-primary waves-effect" onClick={() => this.setState({ addAssetModal: true })}>
                <FormattedMessage id="campaign_content.btn.add" defaultMessage="add" />
              </button>
            )}
            {(hasAccess("campaigns", "content", ["delete"])) && (
              <button
                className="btn btn-outline-primary waves-effect"
                onClick={() => {
                  if (_isEmpty(selectedIds)) {
                    showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one_with_operation", defaultMessage: "Please select at least one {field} to {operation}." },
                      {
                        field: intl.formatMessage({ id: "asset", defaultMessage: "asset" }),
                        operation: intl.formatMessage({ id: "delete", defaultMessage: "delete" })
                      }));
                    return false;
                  }

                  this.setState({ showDeleteConfirmBox: true });
                }}
              >
                <FormattedMessage id="campaign_content.btn.delete" defaultMessage="delete" />
              </button>
            )}
            {(hasAccess("campaigns", "content", ["export"])) && (
              <button className="btn btn-primary waves-effect" onClick={() => this._handleExportCampaignAssets()}>
                <FormattedMessage id="campaign_content.btn.export" defaultMessage="export" />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  _renderTableData = () => {
    const { assetListData, assetListPaginationInfo } = this.state;

    return (
      <div className="row mt-5">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <ReactDataTable
                columns={this.columns}
                data={(assetListData || [])}
                pagination={true}
                totalRecords={_get(assetListPaginationInfo, "total_records", 10)}
                onChangePage={(e) => this.handlePaginationChange(e)}
                selectableRows
                onSelectedRowsChange={(selectedRows) => {
                  this.setState({
                    selectAllIds: (selectedRows.allSelected || false),
                    selectedIds: (selectedRows.selectedRows || []).map(row => { return row.id })
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { intl } = this.props;
    const { campaignDetails, addAssetModal, showDeleteConfirmBox, campaignId } = this.state;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "hermes.campaign_content", defaultMessage: "Campaign Content: Hermes" })} </title>
        </Helmet>

        <div className="container-fluid flex-grow-1 px-6 py-6">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center gap-5 py-3 px-4">
                    <p className="heading-04 text-uppercase">{_get(campaignDetails, "campaign_name", <EmptyLabel />)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12">
              <h1 className="heading-04 fw-semibold text-dark-35 text-uppercase mb-5">
                <FormattedMessage id="campaign_content.title" defaultMessage="campaign content" />
              </h1>
            </div>
          </div>

          {this._renderButtons()}

          {this._renderTableData()}

          {(hasAccess("campaigns", "content", ["download-selected"])) && (
            <div className="row mt-4">
              <div className="col-lg-12">
                <div className="d-flex flex-wrap gap-3 align-items-center justify-content-end">
                  <button className="btn btn-outline-primary waves-effect" onClick={() => this._handleDownloadCampaignAssets()}>
                    <FormattedMessage id="campaign_content.btn.download_selected" defaultMessage="download selected" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <ConfirmBox
          onConfirm={() => this._handleMultipleDelete()}
          isOpen={(showDeleteConfirmBox || false)}
          onClose={() => this.setState({ showDeleteConfirmBox: false })}
          content={intl.formatMessage({ id: "confirm.delete_asset", defaultMessage: "Are you sure you want to remove assets from the campaign?" })}
        />

        <AddAsset
          isOpen={addAssetModal}
          campaignId={parseInt(campaignId || 0)}
          source="campaign_content"
          clientId={_get(campaignDetails, "client_id", 0)}
          onClose={(fetch) => {
            this.setState({
              addAssetModal: false
            });

            if (fetch === true) {
              this._fetchAssetList();
            }
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  dashboardData: _get(state, "campaign.dashboard", {}),
  campaignTrackerDisplayDateFormat: _get(state, "application.constants.campaignTrackerDisplayDateFormat", "DD MMM YY"),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaignContent)));
