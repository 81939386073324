import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { withCookies, CookiesProvider, Cookies } from "react-cookie";
import PropTypes, { instanceOf } from "prop-types";
import { ToastContainer } from "react-toastify";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import moment from "moment";

import { AppLoader } from "./resusableComponents/loaders/AppLoader";
import PrivateRoute from "./resusableComponents/hoc/PrivateRoute";

// pages
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Application from "./pages/Application";
import Logout from "./pages/Logout";
import Login from "./pages/Login";

import { LANGUAGES, DEFAULT_WEB_LANGUAGE } from "./helpers/constants";
import config from "./helpers/config";

import { updateLanguage, fetchUserConfiguration } from "./redux/actions/application";
import { updateUrl } from "./redux/actions/authentication";

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    // set locale language of moment globally
    require(`moment/locale/${this.props.lang}`);
    moment.updateLocale(this.props.lang, { week: { dow: 0, doy: 6 } });

    const langFileName = _get(LANGUAGES, `${this.props.lang}.file_name`, "en.js");

    this.state = { langFile: require(`./languages/${langFileName}`).default };
  }

  componentDidMount() {
    const { lang, updateUrl } = this.props;

    if (typeof updateUrl === "function") { updateUrl(config.getBackendAppURL(true, true)); }

    this._fetchUserConfiguration();

    if (typeof updateLanguage === "function") { updateLanguage(lang || "en-gb"); }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {

      // set locale language of moment globally
      require(`moment/locale/${this.props.lang}`);
      moment.updateLocale(this.props.lang, { week: { dow: 0, doy: 6 } });

      const langFileName = _get(LANGUAGES, `${this.props.lang}.file_name`, "en.js");
      const langFile = require(`./languages/${langFileName}`).default;

      this.setState({ langFile });

      if (typeof this.props.updateLanguage === "function") this.props.updateLanguage(this.props.lang || "en-gb");

      // force update the loaded components
      this.forceUpdate();
    }

    if (!_isEqual(prevProps.userToken, this.props.userToken)) {
      this._fetchUserConfiguration();
    }
  }

  _fetchUserConfiguration() {
    const { userToken, fetchUserConfiguration } = this.props;

    if (!_isEmpty(userToken) && typeof fetchUserConfiguration === "function") {
      fetchUserConfiguration();
    }
  }

  render() {
    const { userToken, accessList, loading, lang, cookies } = this.props;
    const { langFile = {} } = this.state;

    if (!_isEmpty(userToken) && _isEmpty(accessList)) {
      return (<AppLoader />);
    }

    return (
      <IntlProvider locale={lang} messages={langFile}>
        <CookiesProvider cookies={cookies}>
          <Helmet defaultTitle="Hermes" />
          <Router>
            <Routes>
              {/* Public routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:id" element={<ResetPassword />} />

              {/* Private routes */}
              <Route element={<PrivateRoute />}>
                <Route path="*" element={<Application />} />
              </Route>
            </Routes>
          </Router>
          {(loading) && (<AppLoader />)}
          <ToastContainer />
        </CookiesProvider>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  userToken: PropTypes.shape({
    token_type: PropTypes.string,
    expires_in: PropTypes.number,
    access_token: PropTypes.string,
    refresh_token: PropTypes.string
  })
};

App.defaultProps = {
  userToken: PropTypes.object
};

const stateToPropsMapping = (state) => ({
  loading: _get(state, "application.loading", false),
  lang: _get(state, "application.language.defaultLanguage", DEFAULT_WEB_LANGUAGE),
  error: _get(state, "authentication.account.error", ""),
  accessList: _get(state, "application.accessList", []),
  userToken: _get(state, "authentication.userToken", {}),
});

const dispatchToPropsMapping = (dispatch) => ({
  updateUrl: (data) => dispatch(updateUrl(data)),
  updateLanguage: (data) => dispatch(updateLanguage(data)),
  fetchUserConfiguration: () => dispatch(fetchUserConfiguration())
});

export default connect(stateToPropsMapping, dispatchToPropsMapping)(withCookies(App));
