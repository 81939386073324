import React, { Component, createRef } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from "react-redux";
import Dropzone from "react-dropzone";

import { Select, ErrorMessage, showAlertMessage, CustomModal } from "../../../../resusableComponents/common/formElements";

import { updateLoadingState } from "../../../../redux/actions/application";
import { doGetAssetTypeList, doGetTopicList, doGetSubTopicList, createNewAsset, uploadSingleAsset, verifyExternalAsset } from "../../../../redux/services/campaignSetup";

const dropzoneRef = createRef();

class UploadNewAsset extends Component {

  constructor(props) {
    super(props);

    this.defaultAssetState = {
      clientId: _get(props, "clientId", null),
      name: "",
      assetCode: "",
      url: "",
      azureStorageURL: "",
      fileName: "",
      topic: [],
      subTopic: [],
      typeId: null,
      rawJson: "",
      isExternalURL: false
    }

    this.state = {
      newAsset: _cloneDeep(this.defaultAssetState),
      errors: {},
      uploadErrors: {},
      showModal: false,
      assetTypeList: [],
      topicList: [],
      subTopicList: [],
      isFormSubmitted: false
    };
  }

  componentDidUpdate(prevProps, prevStates) {
    if (!_isEqual(prevStates.newAsset, this.state.newAsset) && (_get(this.state, "isFormSubmitted", false) === true)) {
      this._handleValidation();
    }

    if (!_isEqual(prevStates.showModal, this.state.showModal) && (_get(this.state, "showModal", false) === true)) {
      this._getAssetTypeList();
      this._getTopicList();
    }
  }

  _getAssetTypeList = async () => {
    const { updateLoadingState } = this.props;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetAssetTypeList();

      if (_get(response, "flag", false) === true) {

        this.setState({ assetTypeList: _get(response, "data", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching topic list.")));
        this.setState({ assetTypeList: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching topic list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _getTopicList = async () => {
    const { updateLoadingState } = this.props;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetTopicList();

      if (_get(response, "flag", false) === true) {

        this.setState({ topicList: _get(response, "data", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching topic list.")));
        this.setState({ topicList: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching topic list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _getSubTopicList = async (topicId) => {
    const { updateLoadingState } = this.props;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetSubTopicList([topicId]);

      if (_get(response, "flag", false) === true) {
        this.setState({ subTopicList: _get(response, "data", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching sub topic list.")));
        this.setState({ subTopicList: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching sub topic list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _onDrop = async (acceptedFiles) => {
    const { updateLoadingState } = this.props;
    const { topicList } = this.state;

    if (typeof updateLoadingState === "function") updateLoadingState(true);

    const firstFile = acceptedFiles[0];

    let data = new FormData();

    data.append("formfile", firstFile);
    // data.append("campaign_id", campaignId);

    try {

      const response = await uploadSingleAsset(data);

      if (_get(response, "flag", false) === true) {
        const existingTopic = (topicList || []).find(item => item.topic === _get(response, "data.topic", ""));
        const topic = { id: _get(response, "data.topic", ""), topic: _get(response, "data.topic", "") };
        const subTopic = { id: _get(response, "data.sub_topic", ""), sub_topic: _get(response, "data.sub_topic", "") };

        this.setState((prevState) => ({
          showModal: true,
          newAsset: {
            ...prevState.newAsset,
            url: _get(response, "data.url", ""),
            azureStorageURL: _get(response, "data.url", ""),
            fileName: _get(response, "data.filename", ""),
            topic: [_get(response, "data.topic", "")],
            subTopic: [_get(response, "data.sub_topic", "")],
            rawJson: _get(response, "data.raw_json", "")
          },
          topicList: (!_isEmpty(existingTopic)) ? topicList : [...topicList, topic],
          subTopicList: [subTopic],
        }));
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while uploading assets."));
      }
    } catch (err) {
      showAlertMessage((err.message || "Something went wrong while uploading on server."));

    } finally {

      if (typeof updateLoadingState === "function") updateLoadingState(false);
    }
  }

  _verifyAsset = async () => {
    const { updateLoadingState, intl } = this.props;
    const { newAsset, topicList } = this.state;

    if (_isEmpty(newAsset.url)) {
      showAlertMessage(intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_content.create_asset_modal.url", defaultMessage: "url" })
      }));
    }

    if (typeof updateLoadingState === "function") {
      updateLoadingState(true);
    }
    try {
      const response = await verifyExternalAsset({ file_url: _get(newAsset, "url", "") });

      if ((response.flag || false) === true) {
        const existingTopic = (topicList || []).find(item => item.topic === _get(response, "data.topic", ""));
        const topic = { id: _get(response, "data.topic", ""), topic: _get(response, "data.topic", "") };
        const subTopic = { id: _get(response, "data.sub_topic", ""), sub_topic: _get(response, "data.sub_topic", "") };

        this.setState((prevState) => ({
          showModal: true,
          newAsset: {
            ...prevState.newAsset,
            url: _get(response, "data.url", ""),
            azureUrl: _get(response, "data.url", ""),
            fileName: _get(response, "data.filename", ""),
            topic: [_get(response, "data.topic", "")],
            subTopic: [_get(response, "data.sub_topic", "")],
            rawJson: _get(response, "data.raw_json", "")
          },
          topicList: (!_isEmpty(existingTopic)) ? topicList : [...topicList, topic],
          subTopicList: [subTopic],
        }));
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while verifying asset."));
      }
    } catch (err) {
      showAlertMessage(_get(err, "message", "Something went wrong while verifying asset."));
    } finally {
      if (typeof updateLoadingState === "function") {
        updateLoadingState(false)
      };
    }
  }

  _handleChange = (e, name) => {
    e.preventDefault();

    const { value } = (e.target || {});

    this.setState((prevState) => ({
      newAsset: {
        ...prevState.newAsset,
        [name]: value
      }
    }));
  }

  _handleValidation(returnFlag = false) {
    const { intl } = this.props;
    const { newAsset } = this.state;
    let errors = {};

    if (_isEmpty(newAsset.typeId)) {
      errors["typeId"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.assets.create.label_asset_type", defaultMessage: "asset type" })
      });
    }
    if (_isEmpty(newAsset.name)) {
      errors["name"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.assets.create.label_name", defaultMessage: "name" })
      });
    }
    if (_isEmpty(newAsset.topic)) {
      errors["topic"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.assets.create.label_topics", defaultMessage: "topics" })
      });
    }
    if (_isEmpty(newAsset.subTopic)) {
      errors["subTopic"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.assets.create.label_sub_topics", defaultMessage: "sub topics" })
      });
    }
    if (_isEmpty(newAsset.assetCode)) {
      errors["assetCode"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.assets.create.label_asset_code", defaultMessage: "asset code" })
      });
    }

    if (_isEmpty(newAsset.url)) {
      errors["url"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.assets.create.label_url", defaultMessage: "url" })
      });
    }

    this.setState({ errors });

    if (returnFlag) {
      return (!_isEmpty(errors)) ? false : true;
    }
  }

  _handleCreateNewAsset = async () => {
    const { clientId, formData, setState, updateLoadingState, intl } = this.props;
    const { newAsset, errors } = this.state;

    if (!_isEmpty(errors)) {
      showAlertMessage(intl.formatMessage({ id: "error.please_fill_up_the_proper_details", defaultMessage: "Please fill up the proper details." }));
      return false;
    }

    this.setState({ isFormSubmitted: true });

    const isValidForm = this._handleValidation(true);

    if (!isValidForm) {
      showAlertMessage(intl.formatMessage({ id: "error.please_fill_up_the_proper_details", defaultMessage: "Please fill up the proper details." }));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const payload = {
        client_id: (clientId || null),
        asset_name: (newAsset.name || ""),
        asset_code: (newAsset.assetCode || ""),
        asset_url: (newAsset.url || ""),
        topic: _get(newAsset, "topic[0].topic", _get(newAsset, "topic[0]", 0)),
        sub_topic: _get(newAsset, "subTopic[0].sub_topic", _get(newAsset, "subTopic[0]", 0)),
        type_id: _get(newAsset, "typeId.id", null),
        raw_json: _get(newAsset, "rawJson", ""),
      };

      const response = await createNewAsset(payload);

      if (_get(response, "flag", null) === true) {

        showAlertMessage(_get(response, "message", "Asset created successfully."), "success");

        this._resetCreateAssetForm();

        if (typeof setState === "function") {
          setState({
            selectedOption: "useExisting",
            formData: { ...formData, searchAssets: [{ id: _get(response, "data.asset_id", null), asset_name: _get(response, "data.asset_name", "") }] }
          });
        }
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while creating new asset."));
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while creating new asset.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _resetCreateAssetForm = () => {
    this.setState({
      newAsset: _cloneDeep(this.defaultAssetState),
      errors: {},
      uploadErrors: {},
      showModal: false,
      isFormSubmitted: false
    })
  }

  _setAssetType = (assetType) => {
    this.setState((prevState) => ({
      newAsset: {
        ...prevState.newAsset,
        url: "",
        azureUrl: "",
        fileName: "",
        topic: [],
        subTopic: [],
        rawJson: ""
      },
      subTopicList: [],
      isExternalURL: assetType
    }))
  }

  _renderAddAssetModal() {
    const { intl } = this.props;
    const { topicList, subTopicList, assetTypeList, showModal, newAsset, errors } = this.state;

    return (
      <CustomModal
        size="lg"
        isOpen={showModal}
        className="addContentModal"
        modalTitle={(intl.formatMessage({ id: "campaign_setup.assets.create.title", defaultMessage: "create asset" }))}
        onHide={() => this._resetCreateAssetForm()}
        onClose={() => this._resetCreateAssetForm()}
      >
        <>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.create.label_asset_type" defaultMessage="asset type" />
                  <span className="text-danger">*</span>
                </label>
                <Select
                  className="form-custom-select"
                  placeholder={intl.formatMessage({ id: "placeholder.select_field", defaultMessage: "Select {field}" },
                    { field: intl.formatMessage({ id: "campaign_setup.assets.create.label_asset_type", defaultMessage: "asset type" }) })}
                  value={(newAsset.typeId || null)}
                  options={(assetTypeList || [])}
                  error={(errors.typeId || "")}
                  getOptionValue={(option) => (option.id)}
                  getOptionLabel={(option) => (option.asset_type)}
                  onChange={(selectedOptions) => this.setState((prevState) => ({ newAsset: { ...prevState.newAsset, typeId: selectedOptions } }))}
                  isMulti={false}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.create.label_topics" defaultMessage="topics" />
                  <span className="text-danger">*</span>
                </label>
                <Typeahead
                  id="custom-typeahead"
                  placeholder={intl.formatMessage({ id: "placeholder.start_typing_to_search", defaultMessage: "start typing to search" })}
                  newSelectionPrefix={intl.formatMessage({ id: "campaign_content.placeholder.create_new_topic", defaultMessage: "Click to create new topic: " })}
                  allowNew
                  labelKey="topic"
                  selected={(newAsset.topic || "")}
                  options={(topicList || []).map(option => _get(option, "topic", ""))}
                  onChange={(selectedOptions) => {

                    if (selectedOptions.length > 0) {
                      const lastSelected = selectedOptions[selectedOptions.length - 1];

                      if (typeof lastSelected === 'string' && (topicList || []).some(option => option.topic === lastSelected)) {

                        // If Existing topic selected then fetch sub topics
                        const selectedOption = (topicList || []).find(option => option.topic === lastSelected);
                        if (selectedOption) {
                          this._getSubTopicList(_get(selectedOption, "id", 0));
                        }
                      }
                    }

                    this.setState((prevState) => ({
                      newAsset: {
                        ...prevState.newAsset,
                        topic: selectedOptions
                      }
                    }));

                  }}
                />
                {(errors.topic !== "") && (<ErrorMessage message={(errors.topic || "")} />)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.create.label_sub_topics" defaultMessage="sub topics" />
                  <span className="text-danger">*</span>
                </label>
                <Typeahead
                  id="custom-typeahead"
                  placeholder={intl.formatMessage({ id: "placeholder.start_typing_to_search", defaultMessage: "start typing to search" })}
                  newSelectionPrefix={intl.formatMessage({ id: "campaign_content.placeholder.create_new_sub_topic", defaultMessage: "Click to create new sub topic: " })}
                  allowNew
                  labelKey="sub_topic"
                  selected={(newAsset.subTopic || "")}
                  options={(subTopicList || []).map(option => _get(option, "sub_topic", ""))}
                  onChange={(selectedOptions) => {

                    this.setState((prevState) => ({
                      newAsset: {
                        ...prevState.newAsset,
                        subTopic: selectedOptions
                      }
                    }));

                  }}
                />
                {(errors.subTopic !== "") && (<ErrorMessage message={(errors.subTopic || "")} />)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.create.label_name" defaultMessage="name" />
                  <span className="text-danger">*</span>
                </label>
                <input
                  id="create_name"
                  name="create_name"
                  className="form-control"
                  defaultValue={(newAsset.name || "")}
                  placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                    field: intl.formatMessage({ id: "campaign_setup.assets.create.label_name", defaultMessage: "name" })
                  })}
                  onChange={(e) => this._handleChange(e, "name")}
                />
                {(errors.name !== "") && (<ErrorMessage message={(errors.name || "")} />)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.create.label_asset_code" defaultMessage="asset code" />
                  <span className="text-danger">*</span>
                </label>
                <input
                  id="create_code"
                  name="create_code"
                  className="form-control"
                  defaultValue={(newAsset.assetCode || "")}
                  placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                    field: intl.formatMessage({ id: "campaign_setup.assets.create.label_asset_code", defaultMessage: "asset code" })
                  })}
                  onChange={(e) => this._handleChange(e, "assetCode")}
                />
                {(errors.assetCode !== "") && (<ErrorMessage message={(errors.assetCode || "")} />)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.assets.create.label_url" defaultMessage="url" />
                  <span className="span-link m-l-5"><FormattedMessage id="campaign_setup.assets.create.label_edit_url" defaultMessage="(edit)" /> </span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  id="create_url"
                  name="create_url"
                  className="form-control"
                  defaultValue={(newAsset.url || "")}
                  placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                    field: intl.formatMessage({ id: "campaign_setup.assets.create.label_url", defaultMessage: "url" })
                  })}
                  onChange={(e) => this._handleChange(e, "url")}
                />
                {(errors.name !== "") && (<ErrorMessage message={(errors.url || "")} />)}
              </div>
            </div>
          </div>

          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button className="btn btn-secondary text-capitalize" onClick={() => this._resetCreateAssetForm()} >
              <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
            </button>
            <button className="btn btn-primary text-capitalize" onClick={() => this._handleCreateNewAsset()} >
              <FormattedMessage id="btn.submit" defaultMessage="Submit" />
            </button>
          </div>
        </>
      </CustomModal >
    );
  }

  render() {
    const { allowedAssetFiles, allowedAssetFilesLabel, intl } = this.props;
    const { newAsset, showModal, isExternalURL, errors } = this.state;

    return (
      <>

        <div className="col-sm-8 col-md-8 mb-6">
          <label className="form-label text-capitalize">
            <FormattedMessage id="campaign_content.assets.label_asset_type" defaultMessage="Asset Type" />
          </label>
          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input show-data"
                type="radio"
                name="physical_asset"
                id="physical_asset"
                value={(isExternalURL || false)}
                checked={(isExternalURL || false) === false}
                onChange={(e) => this._setAssetType(false)} />
              <label className="form-check-label text-capitalize cursor-pointer" htmlFor="physical_asset">
                <FormattedMessage id="campaign_content.assets.physical_asset" defaultMessage="Physical Asset" />
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input show-data"
                type="radio"
                name="web_asset"
                id="web_asset"
                value={(isExternalURL || false)}
                checked={(isExternalURL || false) === true}
                onChange={(e) => this._setAssetType(true)} />
              <label className="form-check-label text-capitalize cursor-pointer" htmlFor="web_asset">
                <FormattedMessage id="campaign_content.assets.web_asset" defaultMessage="Web Asset" />
              </label>
            </div>
          </div>
        </div>
        {(showModal === true) && this._renderAddAssetModal()}

        {((isExternalURL || false) === false) && (showModal === false) && (
          <Dropzone
            maxFiles={1}
            multiple={false}
            accept={allowedAssetFiles}
            onDrop={(f) => this._onDrop(f)}
            ref={dropzoneRef}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div className="mb-6" {...getRootProps({ className: "dropzone" })}>
                <div>
                  <label className="form-label text-capitalize">
                    <FormattedMessage id="file.file_upload" defaultMessage="file upload" />
                  </label>
                  <div className="dropzone-input form-control">
                    <span>Browse...</span>
                    No file selected.
                  </div>
                  <input {...getInputProps()} />
                </div>
                <label htmlFor="singleUpload" className="mt-2 text-grey-93">
                  <FormattedMessage
                    id="file.allowed_files"
                    defaultMessage="Allowed file extensions : {allowedExtensions}"
                    values={{ allowedExtensions: allowedAssetFilesLabel }}
                  />
                </label>
              </div>
            )}
          </Dropzone>
        )}

        {((isExternalURL || false) === true) && (showModal === false) && (
          <div className="col-sm-12 col-md-12">
            <div className="mb-6">
              <label htmlFor="create_url" className="form-label text-capitalize">
                <FormattedMessage id="campaign_content.create_asset_modal.url" defaultMessage="url" />
                <span
                  className="link-blue-22 m-l-5 cursor-pointer"
                  onClick={() => this._verifyAsset()}
                >
                  <FormattedMessage id="campaign_content.create_asset_modal.verify" defaultMessage=" (verify)" />
                </span>
              </label>
              <input
                id="create_url"
                name="create_url"
                className="form-control"
                defaultValue={(newAsset.url || "")}
                placeholder={intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "campaign_content.create_asset_modal.url", defaultMessage: "url" })
                })}
                onChange={(e) => this._handleChange(e, "url")}
              />
              {(errors.name !== "") && (<ErrorMessage message={(errors.url || "")} />)}
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  clientId: _get(state, "campaignSetup.summary.common.clientId", null),
  campaignId: _get(state, "campaignSetup.summary.common.campaignId", null),
  allowedAssetFiles: _get(state, "application.constants.allowedAssetFiles", ""),
  allowedAssetFilesLabel: _get(state, "application.constants.allowedAssetFilesLabel", ""),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UploadNewAsset));
