import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return (
      <Component
        params={params}
        navigate={navigate}
        location={location}
        {...props}
      />
    );
  };

  return Wrapper;
};