import React, { Component } from "react";
import { injectIntl } from "react-intl";
import DataTable from "react-data-table-component";

import CustomPagination from './CustomPagination';

class ReactDataTable extends Component {

  render() {
    const { columns, data, totalRecords, className, onChangePage, ...restProps } = this.props;

    return (
      <DataTable
        className={(className || "")}
        columns={(columns || [])}
        data={(data || [])}
        paginationServer
        paginationComponent={CustomPagination}
        paginationTotalRows={totalRecords}
        onChangePage={onChangePage}
        persistTableHead={true}
        {...restProps}
      />
    );
  };
};

export default injectIntl(ReactDataTable);
