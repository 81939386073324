import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual, debounce as _debounce, isArray as _isArray } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

import { doGetUserList, clearListError } from "../../../../../redux/actions/userMangement";

import { RenderTableCellValue, RenderTableHeaderValue, showAlertMessage, ConfirmBox, Select, EmptyLabel } from "../../../../../resusableComponents/common/formElements";
import { hasAccess } from "../../../../../resusableComponents/hoc/AccessManagement";
import ReactDataTable from "../../../../../resusableComponents/common/ReactDataTable";
import EditUser from "./EditUser";

import { ENABLED_STATUSES } from "../../../../../helpers/constants";

import { deleteUser } from "../../../../../redux/services/userMangement";

class UserList extends Component {

  constructor(props) {
    super(props);

    const { intl, displayDateTimeFormat } = props;

    this.defaultUserListPayload = {
      agency_id: null,
      client_id: null,
      role_id: null,
      enabled_status: null,
      page_no: 1,
      page_size: 10,
      search_text: ""
    }

    this.state = {
      userTableData: [],
      userListPayload: { ...this.defaultUserListPayload },
      selectedUser: {},
      showEditUserModal: false,
      showDeleteUserConfirmBox: false,
      userType: null,
      enabledStatus: null,
      reloadUserList: false
    };

    this.searchDebounce = _debounce(this._handleSearchUsers, 1000);

    this.columns = [
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.user.list.table.column_name", defaultMessage: "name" })} />),
        selector: (row) => (_get(row, "first_name", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={`${_get(row, "first_name", "")} ${_get(row, "last_name", "")}`} tooltip={true} />),
        sortable: true,
        grow: 2
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.user.list.table.column_last_login", defaultMessage: "last login" })} />),
        selector: (row) => (_get(row, "last_login_time", "")),
        cell: (row) => (<RenderTableCellValue value={((!_isEmpty(_get(row, "last_login_time", ""))) ? moment(_get(row, "last_login_time", "")).format(displayDateTimeFormat) : (<EmptyLabel />))} />),
        sortable: true,
        grow: 2
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.user.list.table.column_email", defaultMessage: "email" })} />),
        selector: (row) => (_get(row, "email", "")),
        cell: (row) => (<RenderTableCellValue className="text-blue-22 text-truncate" value={_get(row, "email", "")} tooltip={true} />),
        sortable: true,
        grow: 2
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.user.list.table.column_role", defaultMessage: "role" })} />),
        selector: (row) => (_get(row, "role", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "role", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.user.list.table.column_enabled", defaultMessage: "enabled" })} />),
        selector: (row) => (_get(row, "enabled", "")),
        cell: (row) => (<RenderTableCellValue value={(_get(row, "enabled", false) === true) ? "Yes" : "No"} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.user.list.table.column_status", defaultMessage: "status" })} />),
        selector: (row) => (_get(row, "user_status", "")),
        cell: (row) => (<RenderTableCellValue value={(_get(row, "user_status", ""))} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "user_management.user.list.table.column_action", defaultMessage: "action" })} />),
        selector: (row) => {

          return hasAccess("admin", "user-management", ["update-user", "delete-user"], "OR") ? (
            <Dropdown>
              <Dropdown.Toggle as="a" variant="link" className="fw-normal dropdown-toggle-no cursor-pointer">
                <img src={require("../../../../../assets/icons/icon-three-dot.svg").default} className="img-fluid" alt="img" />
              </Dropdown.Toggle>

              <Dropdown.Menu as="ul" className="dropdown-menu">
                {hasAccess("admin", "user-management", ["update-user"]) && (
                  <Dropdown.Item className="dropdown-item d-block waves-effect text-capitalize" onClick={() => this.setState({ showEditUserModal: true, selectedUser: row })}>
                    <FormattedMessage id="user_management.client.list.action.edit" defaultMessage="edit" />
                  </Dropdown.Item>
                )}
                {hasAccess("admin", "user-management", ["delete-user"]) && (
                  <Dropdown.Item className="dropdown-item d-block waves-effect text-capitalize" onClick={() => this.setState({ showDeleteUserConfirmBox: true, selectedUser: row })}>
                    <FormattedMessage id="user_management.client.list.action.delete" defaultMessage="delete" />
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : (<></>)
        },
        sortable: false,
        width: "80px",
        center: "true",
      },
    ];
  }

  componentDidMount() {

    this.fetchUserList();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.errorList, this.props.errorList) && !_isEmpty(_get(this.props, "errorList.message", ""))) {
      const { errorList, clearListError } = this.props;

      showAlertMessage(_get(errorList, "message", "Something went wrong while fetching user list"));

      if (typeof clearListError === "function") { clearListError(); }
    }

    if (!_isEqual(prevState.userListPayload, this.state.userListPayload)) {
      this.fetchUserList();
    }

    if (!_isEqual(prevState.reloadUserList, this.state.reloadUserList) && (_get(this.state, "reloadUserList", false) === true)) {
      this.fetchUserList();
      this.setState({ reloadUserList: false });
    }
  }

  fetchUserList = () => {
    const { doGetUserList } = this.props;
    const { userListPayload } = this.state;

    if (typeof doGetUserList === "function") { doGetUserList(userListPayload); }
  }

  onDeleteUser = async () => {
    const { /*updateLoadingState,*/ intl } = this.props;
    const { selectedUser } = this.state;

    if (_isEmpty(selectedUser) || (_get(selectedUser, "user_id", 0) === 0)) {

      showAlertMessage(intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "user", defaultMessage: "user" })
      }));
      return false;
    }

    try {
      //if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await deleteUser(_get(selectedUser, "user_id", 0));

      if ((response.flag || false) === true) {

        showAlertMessage(_get(response, "data.responseMessage", "User deleted successfully."), "success");

        this.fetchUserList();
      } else {

        showAlertMessage(_get(response, "data.responseMessage", "Something went wrong while deleting user."));
      }

      this.setState({ selectedUser: {}, showDeleteUserConfirmBox: false });
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while deleting user.");
    } finally {
      //if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  handlePaginationChange = (pageNo) => {
    const { userListPayload } = this.state;

    this.setState({ userListPayload: { ...userListPayload, page_no: pageNo } });
  }

  _handleSearchUsers = (searchText) => {
    const { userListPayload } = this.state;

    this.setState({ userListPayload: { ...userListPayload, search_text: searchText } });
  }

  render() {
    const { userListData, userRolesDropDownData, intl } = this.props;
    const { selectedUser, showDeleteUserConfirmBox, userType, enabledStatus, showEditUserModal } = this.state;

    let rolesArray = [{ id: "", name: "All" }]
    if (_isArray(userRolesDropDownData) && (userRolesDropDownData || []).length !== 0) {
      userRolesDropDownData.map((d) => rolesArray.push({ id: (d.id || null), name: (d.name || "") }))
    }

    return (
      <>
        {hasAccess("admin", "user-management", ["user-list"]) && (
          <>
            <table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-group w-auto">
                          <input type="text" className="form-control" placeholder="Search ..." onChange={(e) => this.searchDebounce(_get(e, "target.value", ""))} />
                          <span className="input-group-text bg-primary waves-effect py-1 d-flex align-items-center">
                            <img src={require("../../../../../assets/icons/icon-search-sm.svg").default} className="img-fluid" alt="" />
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <Select
                          className="form-custom-select"
                          placeholder={intl.formatMessage({ id: "user_management.usertype_label", defaultMessage: "User Type" })}
                          options={(rolesArray || [])}
                          value={(userType || null)}
                          getOptionLabel={(option) => (option.name)}
                          getOptionValue={(option) => (option.id)}
                          onChange={(selected) => this.setState(prevState => ({
                            userListPayload: {
                              ...prevState.userListPayload,
                              role_id: _get(selected, "id", null)
                            },
                            userType: selected
                          }))}
                          isMulti={false}
                        />
                      </div>
                      <div className="col-lg-1"></div>
                      <div className="col-lg-2">
                        <Select
                          className="form-custom-select"
                          placeholder={intl.formatMessage({ id: "user_management.enabled_status_label", defaultMessage: "Enabled Status" })}
                          options={(ENABLED_STATUSES || [])}
                          value={(enabledStatus || null)}
                          getOptionLabel={(option) => (option.name)}
                          getOptionValue={(option) => (option.value)}
                          onChange={(selected) => this.setState(prevState => ({
                            userListPayload: {
                              ...prevState.userListPayload,
                              enabled_status: _get(selected, "value", null)
                            },
                            enabledStatus: selected
                          }))}
                          isMulti={false}
                        />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>

            <ReactDataTable
              columns={this.columns}
              data={_get(userListData, "user_list", [])}
              pagination={true}
              totalRecords={_get(userListData, "paging_info[0].total_records", 10)}
              onChangePage={(e) => this.handlePaginationChange(e)}
            />
          </>
        )}

        {hasAccess("admin", "user-management", ["delete-user"]) && (
          <ConfirmBox
            onConfirm={() => this.onDeleteUser()}
            isOpen={(showDeleteUserConfirmBox || false)}
            onClose={() => this.setState({ showDeleteUserConfirmBox: false })}
            content={intl.formatMessage({ id: "confirm.are_you_sure_delete", defaultMessage: "Are you sure want to delete {field}?" }, { field: (_get(selectedUser, "name", "") || "User") })}
          />
        )}

        {hasAccess("admin", "user-management", ["update-user"]) && (
          <EditUser
            setState={(args) => this.setState(args)}
            userRolesDropDownData={(userRolesDropDownData || [])}
            selectedUserObj={(selectedUser || {})}
            showEditUserModal={(showEditUserModal || false)}
          />
        )}
      </>
    );
  };
};

const mapStateToProps = (state) => ({
  errorList: _get(state, "userManagement.errorList", {}),
  userListData: _get(state, "userManagement.userList.responseData", []),
  displayDateTimeFormat: _get(state, "application.constants.displayDateTimeFormat", "DD/MM/YYYY HH:mm:ss")
});

const mapDispatchToProps = (dispatch) => ({
  doGetUserList: (data) => dispatch(doGetUserList(data)),
  clearListError: () => dispatch(clearListError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserList));
