export const SAVE_CAMPAIGN_DETAILS_REQUEST = "SAVE_CAMPAIGN_DETAILS_REQUEST";
export const SAVE_CAMPAIGN_DETAILS_SUCCESS = "SAVE_CAMPAIGN_DETAILS_SUCCESS";
export const SAVE_CAMPAIGN_DETAILS_ERROR = "SAVE_CAMPAIGN_DETAILS_ERROR";
export const RESET_CAMPAIGN_SETUP_ERROR = "RESET_CAMPAIGN_SETUP_ERROR";

export const INITIATE_ASSET_CAMPAIGN_REQUEST = "INITIATE_ASSET_CAMPAIGN_REQUEST";
export const INITIATE_ASSET_CAMPAIGN_SUCCESS = "INITIATE_ASSET_CAMPAIGN_SUCCESS";
export const INITIATE_ASSET_CAMPAIGN_ERROR = "INITIATE_ASSET_CAMPAIGN_ERROR";

export const saveCampaignDetails = (payload) => {
  return ({
    type: SAVE_CAMPAIGN_DETAILS_REQUEST,
    payload
  });
}

export const UPDATE_CAMPAIGN_SETUP_DETAILS = "UPDATE_CAMPAIGN_SETUP_DETAILS";
export const updateCampaignSetupDetails = (payload) => {
  return ({
    type: UPDATE_CAMPAIGN_SETUP_DETAILS,
    payload
  });
}

export const RESET_CAMPAIGN_SETUP = "RESET_CAMPAIGN_SETUP";
export const resetCampaignSetup = (payload) => {
  return ({
    type: RESET_CAMPAIGN_SETUP,
    payload
  });
}

export const resetCampaignSetupError = () => {
  return ({
    type: RESET_CAMPAIGN_SETUP_ERROR
  });
}

// File upload
export const UPLOAD_READ_FILE_SUCCESS = "UPLOAD_READ_FILE_SUCCESS";
export const uploadReadFileSuccess = (payload) => {
  return ({
    type: UPLOAD_READ_FILE_SUCCESS,
    payload
  });
}

export const UPLOAD_READ_FILE_ERROR = "UPLOAD_READ_FILE_ERROR";
export const uploadReadFileError = (payload) => {
  return ({
    type: UPLOAD_READ_FILE_ERROR,
    payload
  });
}

export const SAVE_UPLOAD_MAPPING_DATA_REQUEST = "SAVE_UPLOAD_MAPPING_DATA_REQUEST";
export const SAVE_UPLOAD_MAPPING_DATA_SUCCESS = "SAVE_UPLOAD_MAPPING_DATA_SUCCESS";
export const SAVE_UPLOAD_MAPPING_DATA_ERROR = "SAVE_UPLOAD_MAPPING_DATA_ERROR";
export const saveUploadMappingData = (payload) => {
  return ({
    type: SAVE_UPLOAD_MAPPING_DATA_REQUEST,
    payload
  });
}

export const RESET_DATA_MAPPING_ERROR = "RESET_DATA_MAPPING_ERROR";
export const resetDataMappingError = () => {
  return ({
    type: RESET_DATA_MAPPING_ERROR
  });
}

export const RESET_DATA_MAPPING_CONFIG = "RESET_DATA_MAPPING_CONFIG";
export const resetDataMappingConfig = () => {
  return ({
    type: RESET_DATA_MAPPING_CONFIG
  });
}

export const REMOVE_UPLOADED_FILE_REQUEST = "REMOVE_UPLOADED_FILE_REQUEST";
export const REMOVE_UPLOADED_FILE_SUCCESS = "REMOVE_UPLOADED_FILE_SUCCESS";
export const REMOVE_UPLOADED_FILE_ERROR = "REMOVE_UPLOADED_FILE_ERROR";

export const removeUploadedFile = (payload) => {
  return ({
    type: REMOVE_UPLOADED_FILE_REQUEST,
    payload
  });
}

export const RESET_REMOVE_UPLOADED_FILE = "RESET_REMOVE_UPLOADED_FILE";
export const resetRemoveUploadedFile = () => {
  return ({
    type: RESET_REMOVE_UPLOADED_FILE,
  });
}

export const ASSIGN_ASSET_CAMPAIGN_REQUEST = "ASSIGN_ASSET_CAMPAIGN_REQUEST";
export const ASSIGN_ASSET_CAMPAIGN_SUCCESS = "ASSIGN_ASSET_CAMPAIGN_SUCCESS";
export const ASSIGN_ASSET_CAMPAIGN_ERROR = "ASSIGN_ASSET_CAMPAIGN_ERROR";

export const assignAssetCampaign = (payload) => {
  return ({
    type: ASSIGN_ASSET_CAMPAIGN_REQUEST,
    payload
  });
}

export const REMOVE_ASSIGNED_ASSETS_REQUEST = "REMOVE_ASSIGNED_ASSETS_REQUEST";
export const REMOVE_ASSIGNED_ASSETS_SUCCESS = "REMOVE_ASSIGNED_ASSETS_SUCCESS";
export const REMOVE_ASSIGNED_ASSETS_ERROR = "REMOVE_ASSIGNED_ASSETS_ERROR";

export const removeAssignedAssets = (payload) => {
  return ({
    type: REMOVE_ASSIGNED_ASSETS_REQUEST,
    payload
  });
}

export const initiateAssetCampaign = (payload) => {
  return ({
    type: INITIATE_ASSET_CAMPAIGN_REQUEST,
    payload
  });
}

// Account List
export const ASSIGN_EXISTING_ACCOUNTS_REQUEST = "ASSIGN_EXISTING_ACCOUNTS_REQUEST";
export const ASSIGN_EXISTING_ACCOUNTS_SUCCESS = "ASSIGN_EXISTING_ACCOUNTS_SUCCESS";
export const ASSIGN_EXISTING_ACCOUNTS_ERROR = "ASSIGN_EXISTING_ACCOUNTS_ERROR";

export const assignExistingAccounts = (payload) => {
  return ({
    type: ASSIGN_EXISTING_ACCOUNTS_REQUEST,
    payload
  });
}

export const REMOVE_ASSIGNED_ACCOUNT_LIST_REQUEST = "REMOVE_ASSIGNED_ACCOUNT_LIST_REQUEST";
export const REMOVE_ASSIGNED_ACCOUNT_LIST_SUCCESS = "REMOVE_ASSIGNED_ACCOUNT_LIST_SUCCESS";
export const REMOVE_ASSIGNED_ACCOUNT_LIST_ERROR = "REMOVE_ASSIGNED_ACCOUNT_LIST_ERROR";

export const removeAssignedAccountList = (payload) => {
  return ({
    type: REMOVE_ASSIGNED_ACCOUNT_LIST_REQUEST,
    payload
  });
}

// Suppression List
export const ASSIGN_EXISTING_SUPPRESSION_REQUEST = "ASSIGN_EXISTING_SUPPRESSION_REQUEST";
export const ASSIGN_EXISTING_SUPPRESSION_SUCCESS = "ASSIGN_EXISTING_SUPPRESSION_SUCCESS";
export const ASSIGN_EXISTING_SUPPRESSION_ERROR = "ASSIGN_EXISTING_SUPPRESSION_ERROR";

export const assignExistingSuppression = (payload) => {
  return ({
    type: ASSIGN_EXISTING_SUPPRESSION_REQUEST,
    payload
  });
}

export const REMOVE_ASSIGNED_SUPPRESSION_LIST_REQUEST = "REMOVE_ASSIGNED_SUPPRESSION_LIST_REQUEST";
export const REMOVE_ASSIGNED_SUPPRESSION_LIST_SUCCESS = "REMOVE_ASSIGNED_SUPPRESSION_LIST_SUCCESS";
export const REMOVE_ASSIGNED_SUPPRESSION_LIST_ERROR = "REMOVE_ASSIGNED_SUPPRESSION_LIST_ERROR";

export const removeAssignedSuppressionList = (payload) => {
  return ({
    type: REMOVE_ASSIGNED_SUPPRESSION_LIST_REQUEST,
    payload
  });
}

export const SAVE_REQUIRED_FIELDS_REQUEST = "SAVE_REQUIRED_FIELDS_REQUEST";
export const SAVE_REQUIRED_FIELDS_SUCCESS = "SAVE_REQUIRED_FIELDS_SUCCESS";
export const SAVE_REQUIRED_FIELDS_ERROR = "SAVE_REQUIRED_FIELDS_ERROR";

export const saveRequiredFields = (payload) => {
  return ({
    type: SAVE_REQUIRED_FIELDS_REQUEST,
    payload
  });
}

export const FETCH_CAMPAIGN_BRIEFS_REQUEST = "FETCH_CAMPAIGN_BRIEFS_REQUEST";
export const FETCH_CAMPAIGN_BRIEFS_SUCCESS = "FETCH_CAMPAIGN_BRIEFS_SUCCESS";
export const FETCH_CAMPAIGN_BRIEFS_ERROR = "FETCH_CAMPAIGN_BRIEFS_ERROR";

export const fetchCampaignBriefs = (payload) => {
  return ({
    type: FETCH_CAMPAIGN_BRIEFS_REQUEST,
    payload
  });
}

// Initiate campaign
export const INITIATE_CAMPAIGN_REQUEST = "INITIATE_CAMPAIGN_REQUEST";
export const INITIATE_CAMPAIGN_SUCCESS = "INITIATE_CAMPAIGN_SUCCESS";
export const INITIATE_CAMPAIGN_ERROR = "INITIATE_CAMPAIGN_ERROR";

export const initiateCampaign = (payload) => {
  return ({
    type: INITIATE_CAMPAIGN_REQUEST,
    payload
  });
}
