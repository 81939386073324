import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, chain as _chain } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { EmptyLabel, Select, SingleDropzone, showAlertMessage } from "../../resusableComponents/common/formElements";
import { hasAccess } from "../../resusableComponents/hoc/AccessManagement";
import { withRouter } from "../../resusableComponents/hoc/withRouter";

import ExportUploadTemplate from "../../resusableComponents/campaignBrief/ExportUploadTemplate";

import { fetchCampaignDetails } from "../../redux/services/campaign";

class UploadLead extends Component {

  constructor(props) {
    super(props);

    this.state = {
      campaignId: parseInt(_get(props, "params.id", 0)),
      campaignDetails: {},
      campaignOwners: [],
      selectedCampaignOwner: null
    }

  }

  componentDidMount() {

    this._fetchCampaignDetails();
  }

  _fetchCampaignDetails = async () => {
    const { updateLoadingState } = this.props;
    const { campaignId } = this.state;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await fetchCampaignDetails((campaignId || null));

      if (_get(response, "flag", false) === true) {
        const defaultCampaignOwner = _chain(_get(response, "data.lead_owner", [])).find({ is_default: true }).value();

        this.setState({
          campaignDetails: !_isEmpty(_get(response, "data.campaign_detail", [])) ? _get(response, "data.campaign_detail[0]", {}) : {},
          campaignOwners: _get(response, "data.lead_owner", []),
          selectedCampaignOwner: (defaultCampaignOwner || null)
        });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching campaign details.")));
      }

    } catch (err) {
      showAlertMessage((_get(err, "message", "Something went wrong while fetching campaign details.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderButtons = () => {
    const { campaignId } = this.state;

    return (
      <div className="row gy-4">
        <div className="col-lg-12">
          <div className="d-flex flex-wrap gap-3 align-items-center">
            {(hasAccess("campaigns", "upload-lead", ["campaign-dashboard"])) && (
              <div className="input-group w-auto">
                <span className="input-group-text bg-primary-66 border-primary-66 px-3 d-flex align-items-center">
                  <img src={require("../../assets/icons/icon-dashboard-alt.svg").default} className="img-fluid" alt="" />
                </span>
                <Link to={`/campaigns/dashboard/${campaignId}`} className="btn btn-primary text-capitalize">
                  <FormattedMessage id="upload_lead.btn.campaign_dashboard" defaultMessage="Campaign Dashboard" />
                </Link>
              </div>
            )}
            {(hasAccess("campaigns", "upload-lead", ["export-upload-template"])) && (
              <ExportUploadTemplate campaignId={campaignId} selectedBriefIds={[]} />
            )}
          </div>
        </div>
      </div>
    )
  }

  renderUploadLeadSection = () => {
    const { intl, allowedDataUploadFiles, allowedDataUploadFilesLabel } = this.props;
    const { campaignId, campaignDetails, campaignOwners, selectedCampaignOwner } = this.state;

    return (
      <div className="content-wrapper" >
        <div className="container-fluid flex-grow-1 px-6 py-6">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center gap-5 py-3 px-4">
                    <p className="heading-04 text-uppercase">
                      {_get(campaignDetails, "campaign_name", <EmptyLabel />)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-12">
              <h1 className="heading-04 fw-semibold text-dark-35 text-uppercase mb-5">
                <FormattedMessage id="upload_lead.title" defaultMessage="upload lead" />
              </h1>
            </div>
          </div>

          {this._renderButtons()}

          <div className="row mt-5">
            <div className="col-lg-12">
              <div className="card card-file-upload">
                <div className="card-body">
                  {(hasAccess("campaigns", "upload-lead", ["ownership-dropdown"])) && (
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="mb-8">
                          <label className="form-label text-capitalize">
                            <FormattedMessage id="upload_lead.lead_upload" defaultMessage="lead upload" />
                          </label>
                          <Select
                            className="form-custom-select"
                            placeholder={intl.formatMessage({ id: "upload_lead.lead_ownership", defaultMessage: "Lead Ownership" })}
                            options={(campaignOwners || [])}
                            value={(selectedCampaignOwner || null)}
                            getOptionLabel={(option) => (option.name || "User Type")}
                            getOptionValue={(option) => (option.id || null)}
                            onChange={(selected) => this.setState({ selectedCampaignOwner: selected })}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-lg-12">
                      <label className="form-label text-capitalize">
                        <FormattedMessage id="upload_lead.attached_file" defaultMessage="attached file" />
                      </label>

                      <SingleDropzone
                        acceptedFormat={allowedDataUploadFiles}
                        allowedExtensions={allowedDataUploadFilesLabel}
                        setState={(args) => this.setState(args)}
                        source="lead_upload_file"
                        campaignId={(campaignId || 0)}
                        clientId={_get(campaignDetails, "client_id", 0)}
                        leadOwners={(selectedCampaignOwner || null)}
                      />

                      {/*<div className="file-upload-list">
                          <p>file-name-287654479.csv</p>
                          <a href="/">Delete</a>
                        </div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "hermes.upload_lead", defaultMessage: "Upload Lead: Hermes" })} </title>
        </Helmet>

        {(hasAccess("campaigns", "upload-lead", ["page-view"])) && (
          this.renderUploadLeadSection()
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  allowedDataUploadFiles: _get(state, "application.constants.allowedDataUploadFiles", ""),
  allowedDataUploadFilesLabel: _get(state, "application.constants.allowedDataUploadFilesLabel", ""),
});

export default withRouter(connect(mapStateToProps, null)(injectIntl(UploadLead)));
