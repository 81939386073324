// Update Allocation Loader State
export const UPDATE_APP_LOADING = "UPDATE_APP_LOADING";

//Ferch Application
export const FETCH_USER_CONFIGURATION_REQUEST = "FETCH_USER_CONFIGURATION_REQUEST";
export const FETCH_USER_CONFIGURATION_SUCCESS = "FETCH_USER_CONFIGURATION_SUCCESS";
export const FETCH_USER_CONFIGURATION_ERROR = "FETCH_USER_CONFIGURATION_ERROR";

// Set Sidebar current menu
export const SET_CURRENT_ACTIVE_MENU = "SET_CURRENT_ACTIVE_MENU";

// Update Language state
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

export const updateLanguage = (language) => {
  return ({
    type: UPDATE_LANGUAGE,
    language
  });
}

export const fetchUserConfiguration = () => {
  return ({
    type: FETCH_USER_CONFIGURATION_REQUEST,
  });
}

export const setCurrentActiveMenuRequest = (payload) => {
  return ({
    type: SET_CURRENT_ACTIVE_MENU,
    payload
  });
}

export const updateLoadingState = (loading) => {
  return ({
    type: UPDATE_APP_LOADING,
    loading
  });
}

// Toggle Navbar menu
export const TOGGLE_NAVBAR_MENU = "TOGGLE_NAVBAR_MENU";
export const toggleNavbarMenu = (payload) => {
  return ({
    type: TOGGLE_NAVBAR_MENU,
    payload
  });
}
