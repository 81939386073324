export default {
  url: {
    frontend: `#{account}.${process.env.REACT_APP_FRONTEND_WEB_APP_DOMAIN}`,
    backend: `#{account}.${process.env.REACT_APP_BACKEND_WEB_APP_DOMAIN}`
  },
  getBackendAppURL(isAddSlashes = false, httpProtocol = false, endpoint = "") {

    let backendURL = (httpProtocol === true) ? process.env.REACT_APP_BACKEND_HTTP_PROTOCOL : "";
    backendURL += isAddSlashes ? "//" : "";
    backendURL += this.url.backend.replace("#{account}", (process.env.REACT_APP_BACKEND_SUBDOMAIN || ""));
    backendURL += endpoint;

    return backendURL;
  },
  getFrontendAppURL(isAddSlashes = false, httpProtocol = false, endpoint = "", port = true) {

    let frontURL = (httpProtocol === true) ? process.env.REACT_APP_FRONTEND_HTTP_PROTOCOL : "";
    frontURL += isAddSlashes ? "//" : "";
    frontURL += this.url.frontend.replace("#{account}", process.env.REACT_APP_FRONTEND_SUBDOMAIN);
    frontURL += process.env.REACT_APP_FRONTEND_DOMAIN_PORT;
    frontURL += endpoint;

    return frontURL;
  },
  store_name: (process.env.REACT_APP_REDUX_STORE || "hermes-store"),
};
