import React, { Component } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";

import { showAlertMessage, ErrorMessage, DatePicker } from "../../../resusableComponents/common/formElements";
import { Select } from "../../../resusableComponents/common/formElements";
import { WeekDays } from "../../../helpers/constants";

import { saveCampaignDetails, resetCampaignSetupError } from "../../../redux/actions/campaignSetup";
import { updateLoadingState } from "../../../redux/actions/application";

import { doGetClientNameList, getCurrencyList, getRateTypes } from "../../../redux/services/campaignSetup";
import { doGetAgencyList } from "../../../redux/services/userMangement";

class CampaignDetails extends Component {

  constructor(props) {
    super(props);

    this.defultState = {
      agencyId: null,
      clientName: null,
      campaignName: "",
      poNumber: "",
      currencySymbol: "",
      rateValue: "",
      rateType: "",
      campaignStartDate: "",
      campaignEndDate: "",
      uploadDay: null,
    }

    this.state = {
      formData: _cloneDeep(this.defultState),
      agencyNameDropDownData: [],
      clientNameDropDownData: [],
      currencyDropDownData: [],
      rateTypesDropDownData: [],
      isFormSubmitted: false,
      formErrors: {},
    };
  };

  componentDidMount() {
    this._fetchAgencyNameList();
    this._fetchCurrencies();
    this._fetchRateTypes();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(_get(prevState, "formData.agencyId", null), _get(this.state, "formData.agencyId", null))) {

      if ((_get(this.state, "formData.agencyId", null)) && (_get(this.state, "formData.agencyId.id", null) !== null)) {
        this._fetchClientNameList();
      } else {
        this.setState({ clientNameDropDownData: [] });
      }
    }

    if (!_isEqual(prevState.formData, this.state.formData) && (_get(this.state, "isFormSubmitted", false) === true)) {
      this._handleValidation();
    }

    if (!_isEqual(prevProps.setupErrors, this.props.setupErrors) && !_isEmpty(this.props.setupErrors)) {
      showAlertMessage(_get(this.props, "setupErrors.message", ""));

      if (typeof this.props.resetCampaignSetupError === "function") { this.props.resetCampaignSetupError(); }
    }

  };

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { formData: { agencyId, clientName, campaignName, poNumber, currencySymbol, rateValue, rateType, campaignStartDate, campaignEndDate, uploadDay } } = this.state;
    let formErrors = {};

    if (_isEmpty(agencyId)) {
      formErrors["agencyId"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.agency_list", defaultMessage: "agency list" })
      });
    }

    if (_isEmpty(clientName)) {
      formErrors["clientName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.client_label", defaultMessage: "client name" })
      });
    }

    if (_isEmpty(campaignName)) {
      formErrors["campaignName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.campaign_label", defaultMessage: " campaign name" })
      });
    }

    if ((campaignStartDate || "") === "") {
      formErrors["campaignStartDate"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.campaign_start_date", defaultMessage: "campaign start date" })
      });
    }

    if ((campaignEndDate || "") === "") {
      formErrors["campaignEndDate"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.campaign_end_date", defaultMessage: "campaign end date" })
      });
    } else if (moment(campaignEndDate).isBefore(campaignStartDate, "day")) {
      formErrors["campaignEndDate"] = intl.formatMessage({ id: "error.after_start_date", defaultMessage: "End date field should be after start date." });
    }

    if (_isEmpty(poNumber)) {
      formErrors["poNumber"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.po_number_label", defaultMessage: "PO number" })
      });
    }

    if (_isEmpty(currencySymbol)) {
      formErrors["currencySymbol"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.currency", defaultMessage: "currency" })
      });
    }

    if (_isEmpty(rateValue)) {
      formErrors["rateValue"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.rate", defaultMessage: "rate" })
      });
    }

    if (!_isEmpty(rateValue) && parseFloat(rateValue) <= 0) {
      formErrors["rateValue"] = intl.formatMessage({ id: "error.greater_than_value", defaultMessage: "{field} should be greater than {value}." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.rate", defaultMessage: "rate" }), value: 0
      });
    }

    const regexRateValidation = /^(\d{8}|\d{1,8}(\.\d{1,2})?)$/;
    if (!_isEmpty(rateValue) && (regexRateValidation.test(rateValue) === false)) {
      formErrors["rateValue"] = intl.formatMessage({ id: "error.invalid_rate_value", defaultMessage: "rate should be a number with up to 2 decimal points, less than 8 digits." });
    }

    if (_isEmpty(rateType)) {
      formErrors["rateType"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.rate_type", defaultMessage: "rate type" })
      });
    }

    if (_isEmpty(uploadDay)) {
      formErrors["uploadDay"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.upload_day", defaultMessage: "upload day" })
      });
    }

    this.setState({ formErrors });

    if (returnFlag) {
      return (!_isEmpty(formErrors)) ? false : true;
    }
  }

  handleChange = (keyName, keyValue) => {
    this.setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [keyName]: keyValue,
      }
    }));
  }

  handleNext = () => {
    const { intl, saveCampaignDetails, common, common: { currentStep, lastFilledStep }, momentDisplayDateFormat } = this.props;
    const { formErrors, formData: { agencyId, clientName, campaignName, poNumber, currencySymbol, rateValue, rateType, campaignStartDate, campaignEndDate, uploadDay } } = this.state;

    if (!_isEmpty(formErrors)) { return false; }

    this.setState({ isFormSubmitted: true });

    const isValidForm = this._handleValidation(true);

    if (!isValidForm) { return false; }

    if (typeof saveCampaignDetails === "function") {

      const variable = {
        "common": {
          ...common,
          currentStep: (currentStep + 1),
          agencyId: _get(agencyId, "id", null),
          clientId: _get(clientName, "id", null),
          lastFilledStep: (lastFilledStep > currentStep) ? lastFilledStep : (currentStep + 1),
        },
        [currentStep]: {
          summaryText: intl.formatMessage({ id: "campaign_setup.campaign_detail.title", defaultMessage: "campaign details" }),
          agencyId,
          clientName,
          campaignName,
          dates: `${moment(campaignStartDate).format(momentDisplayDateFormat)} - ${moment(campaignEndDate).format(momentDisplayDateFormat)}`,
          poNumber,
          currencySymbol,
          rateValue,
          rateType,
          uploadDay
        },
        "payload": {
          "campaign_name": campaignName,
          "client_id": _get(clientName, "id", null),
          "start_date": campaignStartDate,
          "end_date": campaignEndDate,
          "po_number": poNumber,
          "currency": _get(currencySymbol, "symbol", ""),
          "rate": rateValue,
          "rate_type": _get(rateType, "rate_type", ""),
          "upload_day": _get(uploadDay, "value", null)
        }
      }

      saveCampaignDetails(variable);
    }
  };

  _fetchAgencyNameList = async () => {
    const { updateLoadingState } = this.props;
    let { agencyNameDropDownData } = this.state;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetAgencyList({ dropdown_flag: 1 });

      if ((response.flag || false) === true) {
        const fetchedData = _get(response, "data.responseData.agency_list", []);
        agencyNameDropDownData = [...fetchedData];
        agencyNameDropDownData.unshift({ id: null, agency_name: "All" });

        this.setState((prevState) => ({ agencyNameDropDownData: agencyNameDropDownData, clientNameDropDownData: [], formData: { ...prevState.formData, agencyId: null, clientName: null } }));
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching agency list.")));
        this.setState((prevState) => ({ agencyNameDropDownData: [], clientNameDropDownData: [], formData: { ...prevState.formData, agencyId: null, clientName: null } }));
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching agency list.")));
    } finally {

      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _fetchClientNameList = async () => {
    const { updateLoadingState } = this.props;
    const { formData: { agencyId } } = this.state;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetClientNameList({ agency_id: _get(agencyId, "id", null), dropdown_flag: 1 });

      if ((response.flag || false) === true) {
        this.setState({ clientNameDropDownData: _get(response, "data.responseData.client_list", []) });

        this.setState((prevState) => ({ clientNameDropDownData: _get(response, "data.responseData.client_list", []), formData: { ...prevState.formData, clientName: null } }));
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching client list.")));
        this.setState((prevState) => ({ clientNameDropDownData: [], formData: { ...prevState.formData, clientName: null } }));
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching client list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _fetchCurrencies = async () => {
    const { updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getCurrencyList();

      if ((response.flag || false) === true) {
        this.setState({ currencyDropDownData: _get(response, "data.responseData", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching currencies.")));
        this.setState({ currencyDropDownData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching currencies.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _fetchRateTypes = async () => {
    const { updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getRateTypes();

      if ((response.flag || false) === true) {
        this.setState({ rateTypesDropDownData: _get(response, "data.responseData", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while rate types.")));
        this.setState({ rateTypesDropDownData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while rate types.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _renderTextFiled = ({ label, name, defaultValue, onChange, placeholder, isRequired = false, type = "text" }) => {

    return (
      <>
        <label className="form-label text-capitalize">
          {label}
          {(isRequired) && (<span className="text-danger">*</span>)}
        </label>
        <input
          type={type}
          className="form-control"
          name={(name || "")}
          placeholder={(placeholder || "")}
          defaultValue={(defaultValue || "")}
          onChange={onChange} />
      </>
    );
  }

  _renderDateTimeFiled = ({ name, defaultValue, onChange, placeholder }) => {

    return (

      <div className="input-group">
        <input type="text"
          className="form-control"
          placeholder={placeholder}
          name={name}
          defaultValue={defaultValue}
          onChange={onChange} />
        <span className="input-group-text bg-white waves-effect py-1 d-flex align-items-center border-grey-97 rounded-1 ms-n1">
          <img src={require("../../../assets/icons/icon-date.svg").default} alt="date_icon" className="img-fluid" />
        </span>
      </div>
    );
  }

  _renderView() {
    const { common: { currentStep, totalStep }, intl } = this.props;
    const { displayDateFormat, agencyNameDropDownData, clientNameDropDownData, currencyDropDownData, rateTypesDropDownData, formErrors,
      formData: { agencyId, clientName, campaignName, poNumber, currencySymbol, rateValue, rateType, campaignStartDate, campaignEndDate, uploadDay } }
      = this.state;
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body py-5 vh-165">
              <h6 className="heading-04 fw-semibold mb-6">{`Step ${currentStep} of ${totalStep} - Campaign Details`}</h6>
              <div className="row">

                <div className="col-lg-4">
                  <div className="mb-6">
                    <label className="form-label text-capitalize">
                      <FormattedMessage id="campaign_setup.campaign_detail.agency_label" defaultMessage="agency name" />
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      className="form-custom-select"
                      placeholder={intl.formatMessage({ id: "placeholder.select", defaultMessage: "select" })}
                      options={(agencyNameDropDownData || [])}
                      value={(agencyId || null)}
                      getOptionLabel={(option) => (option.agency_name || "")}
                      getOptionValue={(option) => (option.id || null)}
                      onChange={(selected) => this.handleChange("agencyId", selected)}
                      isMulti={false}
                    />
                    {_get(formErrors, "agencyId", "") && <ErrorMessage message={_get(formErrors, "agencyId", "")} />}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="mb-6">
                    <label className="form-label text-capitalize">
                      <FormattedMessage id="campaign_setup.campaign_detail.client_label" defaultMessage="client name" />
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      className="form-custom-select"
                      placeholder={intl.formatMessage({ id: "placeholder.select", defaultMessage: "select" })}
                      options={(clientNameDropDownData || [])}
                      value={(clientName || null)}
                      getOptionLabel={(option) => (option.client_name || "")}
                      getOptionValue={(option) => (option.id || null)}
                      onChange={(selected) => this.handleChange("clientName", selected)}
                      isMulti={false}
                    />
                    {_get(formErrors, "clientName", "") && <ErrorMessage message={_get(formErrors, "clientName", "")} />}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="mb-6">
                    {this._renderTextFiled({
                      label: intl.formatMessage({ id: "campaign_setup.campaign_detail.campaign_label", defaultMessage: "campaign name" }),
                      name: "campaignName",
                      defaultValue: (campaignName || ""),
                      onChange: (event) => this.handleChange("campaignName", _get(event, "target.value", "")),
                      placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field} " }, {
                        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.campaign_label", defaultMessage: "campaign name" })
                      }),
                      required: true
                    })}
                    {_get(formErrors, "campaignName", "") && <ErrorMessage message={_get(formErrors, "campaignName", "")} />}
                  </div>

                </div>

                <div className="col-lg-4">
                  <div className="mb-6">
                    <label className="form-label text-capitalize">
                      <FormattedMessage id="campaign_setup.campaign_detail.campaign_start_date" defaultMessage="campaign start date" />
                      <span className="text-danger">*</span>
                    </label>

                    <DatePicker
                      className="form-control"
                      selected={(campaignStartDate || "")}
                      placeholderText={intl.formatMessage({ id: "campaign_setup.campaign_detail.assign.start_date_placeholder", defaultMessage: "Choose a start date" })}
                      onChange={(e) => this.handleChange("campaignStartDate", moment(e).format())}
                      dateFormat={displayDateFormat || "dd/MM/yyyy"}
                    />
                    {_get(formErrors, "campaignStartDate", "") && <ErrorMessage message={_get(formErrors, "campaignStartDate", "")} />}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="mb-6">
                    <label className="form-label text-capitalize">
                      <FormattedMessage id="campaign_setup.campaign_detail.campaign_end_date" defaultMessage="campaign end date" />
                      <span className="text-danger">*</span>
                    </label>
                    <DatePicker
                      className="form-control"
                      selected={(campaignEndDate || "")}
                      placeholderText={intl.formatMessage({ id: "campaign_setup.campaign_detail.assign.end_date_placeholder", defaultMessage: "Choose an end date" })}
                      onChange={(e) => this.handleChange("campaignEndDate", moment(e).format())}
                      dateFormat={displayDateFormat || "dd/MM/yyyy"}
                      minDate={(campaignStartDate || "")}
                    />
                    {_get(formErrors, "campaignEndDate", "") && <ErrorMessage message={_get(formErrors, "campaignEndDate", "")} />}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="mb-6">
                    {this._renderTextFiled({
                      label: intl.formatMessage({ id: "campaign_setup.campaign_detail.po_number_label", defaultMessage: "PO number" }),
                      name: "poNumber",
                      defaultValue: (poNumber || ""),
                      onChange: (event) => this.handleChange("poNumber", _get(event, "target.value", "")),
                      placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field} " }, {
                        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.po_number_label", defaultMessage: "PO number" })
                      }),
                      isRequired: true,
                    })}
                    {_get(formErrors, "poNumber", "") && <ErrorMessage message={_get(formErrors, "poNumber", "")} />}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="mb-6">
                    <label className="form-label text-capitalize">
                      <FormattedMessage id="campaign_setup.campaign_detail.currency" defaultMessage="currency" />
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      className="form-custom-select"
                      placeholder={intl.formatMessage({ id: "placeholder.select", defaultMessage: "select" })}
                      options={(currencyDropDownData || [])}
                      value={(currencySymbol || null)}
                      getOptionLabel={(option) => (option.name || "")}
                      getOptionValue={(option) => (option.symbol || null)}
                      onChange={(selected) => this.handleChange("currencySymbol", selected)}
                      isMulti={false}
                    />
                    {_get(formErrors, "currencySymbol", "") && <ErrorMessage message={_get(formErrors, "currencySymbol", "")} />}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="mb-6">
                    {this._renderTextFiled({
                      label: intl.formatMessage({ id: "campaign_setup.campaign_detail.rate", defaultMessage: "rate" }),
                      name: "rateValue",
                      defaultValue: (rateValue || ""),
                      onChange: (event) => this.handleChange("rateValue", _get(event, "target.value", "")),
                      placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                        field: intl.formatMessage({ id: "campaign_setup.campaign_detail.rate", defaultMessage: "rate" })
                      }),
                      isRequired: true,
                      type: "number"
                    })}
                    {_get(formErrors, "rateValue", "") && <ErrorMessage message={_get(formErrors, "rateValue", "")} />}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="mb-6">
                    <label className="form-label text-capitalize">
                      <FormattedMessage id="campaign_setup.campaign_detail.rate_type" defaultMessage="rate type" />
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      className="form-custom-select"
                      placeholder={intl.formatMessage({ id: "placeholder.select", defaultMessage: "select" })}
                      options={(rateTypesDropDownData || [])}
                      value={(rateType || null)}
                      getOptionLabel={(option) => (option.rate_type || "")}
                      getOptionValue={(option) => (option.id || null)}
                      onChange={(selected) => this.handleChange("rateType", selected)}
                      isMulti={false}
                    />
                    {_get(formErrors, "rateType", "") && <ErrorMessage message={_get(formErrors, "rateType", "")} />}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="mb-6">
                    <label className="form-label text-capitalize">
                      <FormattedMessage id="campaign_setup.campaign_detail.upload_day" defaultMessage="upload day" />
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      className="form-custom-select"
                      placeholder={intl.formatMessage({ id: "campaign_setup.campaign_detail.upload_day", defaultMessage: "upload day" })}
                      options={(WeekDays || [])}
                      value={(uploadDay || null)}
                      onChange={(selected) => this.handleChange("uploadDay", selected)}
                      isMulti={false}
                    />
                    {_get(formErrors, "uploadDay", "") && <ErrorMessage message={_get(formErrors, "uploadDay", "")} />}
                  </div>
                </div>

              </div>

              <div className="row mt-auto">
                <div className="col-lg-12">
                  <div className="text-end">
                    <button className="btn btn-primary" onClick={() => this.handleNext()}>
                      <FormattedMessage id="btn.next" defaultMessage="next" />
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {

    return (
      <>
        {this._renderView()}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  common: _get(state, "campaignSetup.summary.common", {}),
  setupErrors: _get(state, "campaignSetup.setupErrors", {}),
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "dd/MM/yyyy"),
  momentDisplayDateFormat: _get(state, "application.constants.momentDisplayDateFormat", "DD/MM/YYYY"),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  saveCampaignDetails: (data) => dispatch(saveCampaignDetails(data)),
  resetCampaignSetupError: () => dispatch(resetCampaignSetupError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaignDetails));
