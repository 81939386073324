import { combineReducers } from "redux";
import { get as _get } from "lodash";

import actionTypes from "../actions";

const initialDashboardState = { flag: null, data: {}, message: "" };
const dashboard = (state = initialDashboardState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGN_DASHBOARD_DATA_SUCCESS:
      return { flag: true, data: _get(action, "data.data", {}), message: _get(action, "message", "") };

    case actionTypes.FETCH_CAMPAIGN_DASHBOARD_DATA_ERROR:
      return { ...initialDashboardState, flag: false, message: _get(action, "error.message", "") };

    case actionTypes.CLEAR_CAMPAIGN_DASHBOARD_ERROR:
      return { ...initialDashboardState };

    default:
      return state;
  }
}

const initialCampaignList = { flag: null, data: {}, message: "" };
const campaignList = (state = initialCampaignList, action) => {

  switch (action.type) {
    case actionTypes.GET_CAMPAIGN_LIST_SUCCESS:
      return { flag: true, data: _get(action, "data", {}), message: _get(action, "message", "") };

    case actionTypes.GET_CAMPAIGN_LIST_ERROR:
      return { ...initialCampaignList, flag: false, error: _get(action, "error.message", "") };

    case actionTypes.CLEAR_CAMPAIGN_LIST_RESPONSE:
      return { ...initialCampaignList };

    default:
      return state;
  }
}

const initialBriefs = { flag: null, data: [], message: "" };
const briefs = (state = initialBriefs, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGN_EDIT_BRIEFS_SUCCESS:
      return { ...initialBriefs, flag: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_CAMPAIGN_EDIT_BRIEFS_ERROR:
      return { ...initialBriefs, flag: false, message: _get(action, "error.message", "") };

    case actionTypes.CLEAR_CAMPAIGN_EDIT_BRIEFS_ERROR:
      return { ...initialBriefs };

    default:
      return state;
  }
}

const initialLeads = { flag: null, data: [], message: "" };
const leads = (state = initialLeads, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGN_LEAD_DETAILS_SUCCESS:
      return { ...initialLeads, flag: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_CAMPAIGN_LEAD_DETAILS_ERROR:
      return { ...initialLeads, flag: false, message: _get(action, "error.message", "") };

    case actionTypes.RESET_CAMPAIGN_LEAD_DETAILS:
      return { ...initialLeads };

    default:
      return state;
  }
}

export default {
  campaign: combineReducers({
    dashboard,
    campaignList,
    briefs,
    leads
  })
}
