import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { isEmpty as _isEmpty } from "lodash";
import parse from "html-react-parser";
import ReactModal from "react-modal";
import PropTypes from "prop-types";

const ConfirmBox = (props) => {

  const { isOpen, title, content, onConfirm, additionalButton, confirmBtnText, onClose, closeBtnText, key = "" } = props;

  return (
    <ReactModal
      key={key}
      isOpen={isOpen}
      onRequestClose={onClose}
      portalClassName="confirm-box"
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      <div className="confirm-wrapper">
        {(title) && (<span className="cb-title text-capitalize">{title}</span>)}
        {(content) && (
          <span className="cb-content text-capitalize-first">
            {(typeof content === "string") ? parse(content || "") : content}
          </span>
        )}

        <div className="cb-actions d-flex justify-content-end gap-4">
          {/* Render additional button if any*/}
          {(!_isEmpty(additionalButton)) && (additionalButton)}
          <button className="btn btn-primary text-capitalize" onClick={onConfirm} >
            {(confirmBtnText) && (confirmBtnText)}
          </button>
          <button onClick={onClose} className="btn btn-secondary text-capitalize">
            {(closeBtnText) && (closeBtnText)}
          </button>
        </div>
      </div>
    </ReactModal>
  )

}

ConfirmBox.defaultProps = {
  key: "",
  isOpen: false,
  title: (<FormattedMessage id="modal.confirm" defaultMessage="Confirm" />),
  content: "",
  onConfirm: () => { },
  confirmBtnText: (<FormattedMessage id="btn.ok" defaultMessage="Ok" />),
  onClose: () => { },
  closeBtnText: (<FormattedMessage id="btn.cancel" defaultMessage="Cancel" />),
  additionalButton: null
}

ConfirmBox.propTypes = {
  key: PropTypes.any,
  isOpen: PropTypes.bool,
  title: PropTypes.node,
  content: PropTypes.any,
  onConfirm: PropTypes.func,
  confirmBtnText: PropTypes.any,
  onClose: PropTypes.func,
  closeBtnText: PropTypes.any,
  additionalButton: PropTypes.node,
};

export default injectIntl(ConfirmBox);
