import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import { instanceOf } from "prop-types";
import { connect } from "react-redux";
import { get as _get } from "lodash";

import { withRouter } from "../../resusableComponents/hoc/withRouter";

import { deleteAuthenticationToken } from "../../redux/actions/authentication";

class Logout extends Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  componentDidMount() {
    const { deleteAuthenticationToken, cookies } = this.props;

    if (cookies) { cookies.remove("userTokenData", { path: "/", domain: `.${process.env.REACT_APP_FRONTEND_WEB_APP_DOMAIN}` }); }

    if (typeof deleteAuthenticationToken === "function") { deleteAuthenticationToken(); }
  };

  render() {
    const { location } = this.props;
    const from = _get(location, "state.from", "/");

    return (
      <Navigate
        to={{
          pathname: "/login",
          search: (from !== "/") ? `?continue=${from}` : ""
        }}
      />
    );
  }
}

const mapDistachToProps = (dispatch) => ({
  deleteAuthenticationToken: () => dispatch(deleteAuthenticationToken()),
});

export default withRouter(connect(null, mapDistachToProps)(withCookies(Logout)));
