import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";

import { showAlertMessage, Select, ErrorMessage, CustomModal, DatePicker } from "../common/formElements";
import { isValidURL } from "../../helpers/utils";

import { fetchSuppliersList, getCurrencyList, getRateTypes, updateBrief } from "../../redux/services/campaignSetup";
import { cloneSelectedBrief } from "../../redux/services/campaign";

import { updateLoadingState } from "../../redux/actions/application";

class CloneBrief extends Component {

  constructor(props) {
    super(props);

    this.defaultFormFields = {
      name: "",
      tacticCode: "",
      selectedSupplier: null,
      startDate: "",
      endDate: "",
      quantity: "",
      selectedCurrency: null,
      selectedRateType: null,
      cpl: "",
      site: "",
      notes: ""
    }

    this.state = {
      formErrors: {},
      formData: { ...this.defaultFormFields },
      newBriefId: null,
      showCloneBriefModal: false,
      isFormSubmitted: false,
      suppliersList: [],
      currencyDropDownData: [],
      rateTypesDropDownData: []
    };
  }

  componentDidMount() {
    this._fetchSuppliers();
    this._fetchCurrencies();
    this._fetchRateTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevState.formData, this.state.formData) && (_get(this.state, "isFormSubmitted", false) === true)) {
      this._handleValidation();
    }
  }

  _fetchSuppliers = async () => {
    const { updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await fetchSuppliersList({ dropdown_flag: 1 });

      if ((response.flag || false) === true) {

        this.setState({ suppliersList: _get(response, "data.responseData", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching suppliers.")));
        this.setState({ suppliersList: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching suppliers.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _fetchCurrencies = async () => {
    const { updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getCurrencyList();

      if ((response.flag || false) === true) {

        this.setState({ currencyDropDownData: _get(response, "data.responseData", []) });

      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching currencies.")));
        this.setState({ currencyDropDownData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching currencies.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _fetchRateTypes = async () => {
    const { updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getRateTypes();

      if ((response.flag || false) === true) {

        this.setState({ rateTypesDropDownData: _get(response, "data.responseData", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching rate types.")));
        this.setState({ rateTypesDropDownData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching rate types.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _cloneBriefAndFetchDetails = async () => {
    const { selectedBriefIds, updateLoadingState, displayDateTimeFormat, momentDefaultDateFormat, intl } = this.props;

    if ((selectedBriefIds || []).length !== 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }),
      }));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await cloneSelectedBrief({ brief_id: _get(selectedBriefIds, "[0]", 0) });

      if (_get(response, "flag", false) === true) {

        const resData = _get(response, "data.brief_detail[0]", {});

        this.setState({
          newBriefId: _get(resData, "brief_id", null),
          showCloneBriefModal: true,
          formData: {
            name: _get(resData, "brief_name", ""),
            tacticCode: _get(resData, "tactic_code", ""),
            startDate: moment(_get(resData, "start_date", ""), displayDateTimeFormat).format(momentDefaultDateFormat),
            endDate: moment(_get(resData, "end_date", ""), displayDateTimeFormat).format(momentDefaultDateFormat),
            quantity: _get(resData, "quantity", ""),
            cpl: _get(resData, "cost_per_lead", ""),
            site: _get(resData, "site", ""),
            notes: _get(resData, "notes", ""),
            selectedSupplier: { id: _get(resData, "supplier_id", ""), supplier_name: _get(resData, "supplier", "") },
            selectedCurrency: { name: _get(resData, "currency_name", ""), symbol: _get(resData, "currency", "") },
            selectedRateType: { id: _get(resData, "rate_type_id", ""), rate_type: _get(resData, "rate_type", "") },
          }
        });
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while copying brief."));
      }
    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while copying brief."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { formData: { name, /*tacticCode,*/ selectedSupplier, quantity, startDate, endDate, selectedCurrency, selectedRateType, cpl, site } } = this.state;

    let formErrors = {};

    if (_isEmpty(name)) {
      formErrors["name"] = intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.name", defaultMessage: "name" })
      });
    }

    /*if (_isEmpty(tacticCode)) {
      formErrors["tacticCode"] = intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.tactic_code", defaultMessage: "tactic code" })
      });
    }*/

    if (_isEmpty(selectedSupplier)) {
      formErrors["selectedSupplier"] = intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.supplier", defaultMessage: "supplier" })
      });
    }

    if ((startDate || "") === "") {
      formErrors["startDate"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.start_date", defaultMessage: "campaign start date" })
      });
    }

    if ((endDate || "") === "") {
      formErrors["endDate"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.end_date", defaultMessage: "campaign end date" })
      });
    }

    if ((quantity || 0) < 1) {
      formErrors["quantity"] = intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.quantity", defaultMessage: "quantity" })
      });
    }

    if (((cpl || 0) > 0) && _isEmpty(selectedCurrency)) {
      formErrors["selectedCurrency"] = intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.currency", defaultMessage: "currency" })
      });
    }

    if (((cpl || 0) > 0) && _isEmpty(selectedRateType)) {
      formErrors["selectedRateType"] = intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.rate_type", defaultMessage: "rate type" })
      });
    }

    if ((site || "") === "") {
      formErrors["site"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.site", defaultMessage: "site" }),
      });
    }

    if (((site || "") !== "") && (!isValidURL((site || "")))) {
      formErrors["site"] = intl.formatMessage({ id: "error.valid", defaultMessage: "Please enter valid {field}." }, {
        field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.site", defaultMessage: "site" }),
      });
    }

    this.setState({ formErrors });

    if (returnFlag) {
      return (!_isEmpty(formErrors)) ? false : true;
    }
  }

  handleChange = (keyName, keyValue) => {
    this.setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [keyName]: keyValue,
      }
    }));
  }

  _handleEditSubmit = async () => {
    const { campaignId, updateLoadingState } = this.props;
    const { formErrors, newBriefId, formData: { name, tacticCode, quantity, selectedSupplier, startDate, endDate, selectedCurrency, selectedRateType, cpl, site, notes } } = this.state;

    if (!newBriefId) {
      showAlertMessage("Copied brief id is missing.");
      return false;
    }

    if (!_isEmpty(formErrors)) { return false; }

    this.setState({ isFormSubmitted: true });

    const isValidForm = this._handleValidation(true);

    if (!isValidForm) { return false; }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const payload = {
        "name": (name || ""),
        "tactic_code": (tacticCode || ""),
        "supplier_id": _get(selectedSupplier, "id", 0),
        "start_date": (startDate || null),
        "end_date": (endDate || null),
        "quantity": (quantity || 0),
        "cost_per_lead": (cpl || 0),
        "currency": _get(selectedCurrency, "symbol", 0),
        "rate_type": _get(selectedRateType, "rate_type", 0),
        "site": (site || ""),
        "notes": (notes || "")
      }

      const response = await updateBrief(campaignId, newBriefId, payload);

      if ((response.flag || false) === true) {

        showAlertMessage(_get(response, "message", "Brief copied successfully."), "success");

        this._closeModal(false)
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while copying brief."));
      }

    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while copying brief.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderTextFiled = ({ label, placeholder, onChange, name, type = "text", value, isRequired = false }) => {
    const { formErrors } = this.state;

    return (
      <div className="mb-6">
        <label className="form-label text-capitalize">
          {label}
          {(isRequired) && (<span className="text-danger">*</span>)}
        </label>
        <input
          name={name}
          type={type}
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        {_get(formErrors, `${name}`, "") && <ErrorMessage message={_get(formErrors, `${name}`, "")} />}
      </div >
    );
  }

  _closeModal = (isDeleteBrief = true) => {
    const { cancelCloneBrief, setState } = this.props;
    const { newBriefId } = this.state;

    this.setState({
      formErrors: {},
      formData: { ...this.defaultFormFields },
      newBriefId: null,
      showCloneBriefModal: false,
      isFormSubmitted: false
    });

    if ((typeof cancelCloneBrief === "function") && ((isDeleteBrief || false) === true)) {
      cancelCloneBrief((newBriefId || 0));
    } else if ((typeof cancelCloneBrief === "function") && ((isDeleteBrief || false) === false)) {
      setState({ reloadBriefList: true });
    }
  }

  _renderView = () => {
    const { displayDateFormat, intl } = this.props;
    const { showCloneBriefModal, formErrors, formData: { name, tacticCode, quantity, selectedSupplier, startDate, endDate, selectedCurrency, selectedRateType, cpl, site, notes }, suppliersList, currencyDropDownData, rateTypesDropDownData } = this.state;

    return (
      <CustomModal
        isOpen={showCloneBriefModal}
        size="lg"
        modalTitle={intl.formatMessage({ id: "campaign_brief.copy_brief.title", defaultMessage: "Copy Brief" })}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
      >
        <>
          <div className="row">
            <div className="col-lg-4">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.name", defaultMessage: "name" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.name", defaultMessage: "name" })
                }),
                onChange: (event) => this.handleChange("name", _get(event, "target.value", "")),
                name: "name",
                isRequired: true,
                value: (name || "")
              })}
            </div>

            <div className="col-lg-4">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.tactic_code", defaultMessage: "tactic code" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.tactic_code", defaultMessage: "tactic code" })
                }),
                onChange: (event) => this.handleChange("tacticCode", _get(event, "target.value", "")),
                name: "tacticCode",
                isRequired: false,
                value: (tacticCode || "")
              })}
            </div>

            <div className="col-lg-4">
              <div className="mb-6">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.campaign_brief.add_edit.select_supplier" defaultMessage="Select Supplier" />
                  <span className="text-danger">*</span>
                </label>
                <Select
                  className="form-custom-select"
                  placeholder={intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.select_supplier", defaultMessage: "Select Supplier" })}
                  options={(suppliersList || [])}
                  value={(selectedSupplier || null)}
                  getOptionLabel={(option) => (option.supplier_name || "")}
                  getOptionValue={(option) => (option.id || null)}
                  onChange={(selected) => this.handleChange("selectedSupplier", selected)}
                  isMulti={false}
                />
                {_get(formErrors, "selectedSupplier", "") && <ErrorMessage message={_get(formErrors, "selectedSupplier", "")} />}
              </div>
            </div>

            <div className="col-lg-4">
              <div className="mb-4">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.campaign_brief.add_edit.start_date" defaultMessage="campaign start date" />
                  <span className="text-danger">*</span>
                </label>

                <DatePicker
                  className="form-control"
                  selected={(startDate || "")}
                  placeholderText={intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.start_date_placeholder", defaultMessage: "Choose a start date" })}
                  onChange={(e) => this.handleChange("startDate", moment(e).format())}
                  dateFormat={displayDateFormat || "dd/MM/yyyy"}
                />
                {_get(formErrors, "startDate", "") && <ErrorMessage message={_get(formErrors, "startDate", "")} />}
              </div>
            </div>

            <div className="col-lg-4">
              <div className="mb-6">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.campaign_brief.add_edit.end_date" defaultMessage="campaign end date" />
                  <span className="text-danger">*</span>
                </label>
                <DatePicker
                  className="form-control"
                  selected={(endDate || "")}
                  placeholderText={intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.end_date_placeholder", defaultMessage: "Choose an end date" })}
                  onChange={(e) => this.handleChange("endDate", moment(e).format())}
                  dateFormat={displayDateFormat || "dd/MM/yyyy"}
                  minDate={(startDate || "")}
                />
                {_get(formErrors, "endDate", "") && <ErrorMessage message={_get(formErrors, "endDate", "")} />}
              </div>
            </div>

            <div className="col-lg-4">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.quantity", defaultMessage: "quantity" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.quantity", defaultMessage: "quantity" })
                }),
                onChange: (event) => this.handleChange("quantity", _get(event, "target.value", "")),
                name: "quantity",
                type: "number",
                isRequired: true,
                value: (quantity || "")
              })}
            </div>

            <div className="col-lg-4">
              <div className="mb-6">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.campaign_brief.add_edit.select_currency" defaultMessage="select currency" />
                  <span className="text-danger">*</span>
                </label>
                <Select
                  className="form-custom-select"
                  placeholder={intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.select_currency", defaultMessage: "select currency" })}
                  options={(currencyDropDownData || [])}
                  value={(selectedCurrency || null)}
                  getOptionLabel={(option) => (option.name || "")}
                  getOptionValue={(option) => (option.symbol || null)}
                  onChange={(selected) => this.handleChange("selectedCurrency", selected)}
                  isMulti={false}
                />
                {_get(formErrors, "selectedCurrency", "") && <ErrorMessage message={_get(formErrors, "selectedCurrency", "")} />}
              </div>
            </div>

            <div className="col-lg-4">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.rate", defaultMessage: "rate" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.rate", defaultMessage: "rate" })
                }),
                onChange: (event) => this.handleChange("cpl", _get(event, "target.value", "")),
                name: "cpl",
                type: "number",
                value: (cpl || "")
              })}
            </div>

            <div className="col-lg-4">
              <div className="mb-6">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="campaign_setup.campaign_brief.add_edit.rate_type" defaultMessage="rate type" />
                  <span className="text-danger">*</span>
                </label>
                <Select
                  className="form-custom-select"
                  placeholder={intl.formatMessage({ id: "placeholder.select", defaultMessage: "select" })}
                  options={(rateTypesDropDownData || [])}
                  value={(selectedRateType || null)}
                  getOptionLabel={(option) => (option.rate_type || "")}
                  getOptionValue={(option) => (option.id || null)}
                  onChange={(selected) => this.handleChange("selectedRateType", selected)}
                  isMulti={false}
                />
                {_get(formErrors, "selectedRateType", "") && <ErrorMessage message={_get(formErrors, "selectedRateType", "")} />}
              </div>
            </div>

            <div className="col-lg-4">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.site", defaultMessage: "site" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.site", defaultMessage: "site" })
                }),
                onChange: (event) => this.handleChange("site", _get(event, "target.value", "")),
                name: "site",
                isRequired: true,
                value: (site || "")
              })}
            </div>

            <div className="col-lg-8">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.notes", defaultMessage: "notes" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "campaign_setup.campaign_brief.add_edit.notes", defaultMessage: "notes" })
                }),
                onChange: (event) => this.handleChange("notes", _get(event, "target.value", "")),
                name: "notes",
                value: (notes || "")
              })}
            </div>
          </div>
          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button type="button" className="btn btn-secondary" onClick={() => this._closeModal()}>
              <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
            </button>

            <button className="btn btn-primary" onClick={() => this._handleEditSubmit()}>
              <FormattedMessage id="btn.submit" defaultMessage="Submit" />
            </button>
          </div>
        </>
      </CustomModal>
    );
  }

  render() {

    return (
      <>
        <button className="btn btn-outline-primary waves-effect" onClick={() => this._cloneBriefAndFetchDetails()}>
          <FormattedMessage id="campaign_brief.btn.copy_brief" defaultMessage="copy brief" />
        </button>
        {this._renderView()}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "dd/MM/yyyy"),
  momentDefaultDateFormat: _get(state, "application.constants.momentDefaultDateFormat", "MM/DD/YYYY"),
  displayDateTimeFormat: _get(state, "application.constants.displayDateTimeFormat", "DD/MM/YYYY HH:mm:ss"),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CloneBrief));
