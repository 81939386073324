import React, { Component } from "react";
import { isEqual as _isEqual, isEmpty as _isEmpty, get as _get } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { ErrorMessage } from "../../resusableComponents/common/formElements";
import { isEmailValid } from "../../helpers/utils";
import config from "../../helpers/config";

import { forgotPassowrd, clearForgotPasswordError, clearForgotPasswordResponse } from "../../redux/actions/authentication";

class ForgotPassword extends Component {

  constructor(props) {
    super(props);

    this.frontEndURL = config.getFrontendAppURL(true, true);

    this.state = {
      email: "",
      formErrors: {},
      alertSuccess: {},
      alertError: {},
      isSubmitButtonClicked: false,
      isSubmitBtnDisabled: false
    };
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.email, _get(this.state, "email", {}))) { this._checkValidation(); }

    if (!_isEqual(prevProps.forgotError, _get(this.props, "forgotError", {})) && !_isEmpty(_get(this.props, "forgotError", {}))) {
      this.setState({
        alertError: {
          title: _get(this.props, "forgotError.title", "Alert"),
          message: _get(this.props, "forgotError.message", ""),
        },
      });

      if (typeof this.props.clearForgotPasswordError === "function") { this.props.clearForgotPasswordError(); }
    }

    if (!_isEqual(prevProps.forgotSuccess, this.props.forgotSuccess) && !_isEmpty(this.props.forgotSuccess)) {
      const { clearForgotPasswordResponse, forgotSuccess } = this.props;

      this.setState({
        alertSuccess: {
          title: _get(forgotSuccess, "title", "Alert"),
          message: _get(forgotSuccess, "message", ""),
        },
        isSubmitBtnDisabled: true
      });

      if (typeof clearForgotPasswordResponse === "function") { clearForgotPasswordResponse(); }
    }
  }

  _checkValidation = () => {
    const { intl } = this.props;
    const { email } = this.state;

    let formErrors = {};

    if (!isEmailValid(email)) {
      formErrors["email"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}" }, {
        field: intl.formatMessage({ id: "forgot_password.email", defaultMessage: "email", }),
      });
    }

    this.setState({ formErrors, alertError: {}, alertSuccess: {} });
  }

  forgotPasswordSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { forgotPassowrd } = this.props;
    const { email, formErrors } = this.state;

    if (_isEmpty(email) || !_isEmpty(formErrors)) {
      this._checkValidation();

      return;
    }

    if (typeof forgotPassowrd === "function") { forgotPassowrd({ email: (email || ""), subdomain: this.frontEndURL }); }
  }

  render() {
    const { intl } = this.props;
    const { isSubmitButtonClicked, formErrors, alertError, alertSuccess, isSubmitBtnDisabled } = this.state;

    return (
      <>
        <Helmet>
          <title>{intl.formatMessage({ id: "hermes.forgot_password", defaultMessage: "Forgot Password: Hermes" })}</title>
        </Helmet>

        <div className="login-wrapper">
          <div className="card">
            <div className="card-header">
              <img src={require("../../assets/images/logo.svg").default} className="img-logo img-fluid mx-auto d-block" alt="Hermes" />
            </div>

            <div className="card-body">
              <div>
                <h5 className="text-capitalize mb-3"><FormattedMessage id="forgot_password.title" defaultMessage="Forgot password" /></h5>
                <p className="text-grey-74 text-capitalize-first mb-6"><FormattedMessage id="forgot_password.sub_title" defaultMessage="Please enter email address" /></p>
              </div>

              {!_isEmpty(alertError) && (
                <div className="mb-3 text-danger text-capitalize-first">
                  <span>{(alertError.message || "")}</span>
                </div>
              )}

              {!_isEmpty(alertSuccess) && (
                <div className="mb-3 text-success text-capitalize-first">
                  <span>{(alertSuccess.message || "")}</span>
                </div>
              )}
              <Form autoComplete="off" onSubmit={(e) => this.forgotPasswordSubmit(e)}>
                <div className="mb-5">
                  <label className="form-label text-capitalize" htmlFor="useremail">
                    <FormattedMessage id="forgot_password.email" defaultMessage="email" />
                  </label>
                  <input
                    id="useremail"
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    onChange={(e) => this.setState({ email: _get(e, "target.value", "") })}
                  />
                  {(_get(formErrors, "email", "")) && ((isSubmitButtonClicked || false) === true) && (<ErrorMessage message={_get(formErrors, "email", "")} />)}
                </div>

                <div>
                  <button type="submit" className="btn btn-primary waves-effect w-100 text-capitalize mb-10" disabled={isSubmitBtnDisabled} onClick={() => this.setState({ isSubmitButtonClicked: true })}>
                    <FormattedMessage id="btn.submit" defaultMessage="submit" />
                  </button>
                </div>

                <div className="mb-2">
                  <p className="text-grey-74 text-center">
                    <FormattedMessage id="forgot_password.go_back_to" defaultMessage="Go back to" />
                    <NavLink to="/login" className="text-primary fw-normal text-capitalize">
                      <FormattedMessage id="forgot_password.login" defaultMessage=" login" />
                    </NavLink>
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  forgotPasswordResponse: _get(state, "authentication.forgotResponse", false),
  forgotSuccess: _get(state, "authentication.success", {}),
  forgotError: _get(state, "authentication.error", {}),
});

const mapDispatchToProps = (dispatch) => ({
  forgotPassowrd: (data) => dispatch(forgotPassowrd(data)),
  clearForgotPasswordError: () => dispatch(clearForgotPasswordError()),
  clearForgotPasswordResponse: () => dispatch(clearForgotPasswordResponse()),
});
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ForgotPassword));
