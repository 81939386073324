import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty, includes as _includes } from "lodash";
import { Popover, OverlayTrigger, Dropdown } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { EmptyLabel, RenderTableCellValue, RenderTableHeaderValue, showAlertMessage, ShowContentLoader } from "../../resusableComponents/common/formElements";
import { downloadExcelFile } from "../../helpers/utils";
import { hasAccess } from "../../resusableComponents/hoc/AccessManagement";
import { withRouter } from "../../resusableComponents/hoc/withRouter";
import ReactDataTable from "../../resusableComponents/common/ReactDataTable";

import { fetchBriefCriteria, deleteBriefs } from "../../redux/services/campaignSetup";
import { exportCampaignBriefs } from "../../redux/services/campaign";

import { fetchCampaignEditBriefs, clearCampaignEditBriefsError } from "../../redux/actions/campaign";
import { updateLoadingState } from "../../redux/actions/application";

import AssignJobFunctionsToBrief from "../../resusableComponents/campaignBrief/AssignJobFunctionsToBrief";
import AssignSuppressionsToBrief from "../../resusableComponents/campaignBrief/AssignSuppressionsToBrief";
import AssignCompanySizesToBrief from "../../resusableComponents/campaignBrief/AssignCompanySizesToBrief";
import AssignIndustriesToBrief from "../../resusableComponents/campaignBrief/AssignIndustriesToBrief";
import AssignAccountsToBrief from "../../resusableComponents/campaignBrief/AssignAccountsToBrief";
import AssignPersonalityCard from "../../resusableComponents/campaignBrief/AssignPersonalityCard";
import AssignRegionsToBrief from "../../resusableComponents/campaignBrief/AssignRegionsToBrief";
import ExportUploadTemplate from "../../resusableComponents/campaignBrief/ExportUploadTemplate";
import AssignDatesToBrief from "../../resusableComponents/campaignBrief/AssignDatesToBrief";
import EditRequiredFields from "../../resusableComponents/campaignBrief/EditRequiredFields";
import AddEditBrief from "../../resusableComponents/campaignBrief/AddEditBrief";
import AssignAssets from "../../resusableComponents/campaignBrief/AssignAssets";
import CloneBrief from "../../resusableComponents/campaignBrief/CloneBrief";

class CampaignEdit extends Component {

  constructor(props) {
    super(props);
    const { intl } = props;

    this._timeout = null;

    this.state = {
      campaignId: _get(this.props, "params.id", 0),
      campaignDetails: {},
      selectedBriefs: [],
      selectedBriefIds: [],
      showBriefAddEditModal: false,
      reloadBriefList: false,
      isEditBrief: false,
      criteriaLoading: false,
      briefList: [],
      briefCriteria: [],
      criteriaBriefId: null,
      pageSource: "campaign_edit"
    };

    this.briefTableColumns = [
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.id", defaultMessage: "ID" })} />),
        selector: (row) => _get(row, "id", ""),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "id", "")} tooltip={false} />),
        sortable: false,
        width: "50px"
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.name", defaultMessage: "name" })} />),
        selector: (row) => (_get(row, "name", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "name", "")} tooltip={true} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.tactic_code", defaultMessage: "tactic code" })} />),
        selector: (row) => (_get(row, "tactic_code", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "tactic_code", "")} tooltip={true} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.supplier", defaultMessage: "supplier" })} />),
        selector: (row) => (_get(row, "supplier", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "supplier", "")} tooltip={true} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.date", defaultMessage: "date" })} />),
        selector: (row) => (_get(row, "date", "")),
        cell: (row) => (<RenderTableCellValue className="text-truncate" value={_get(row, "date", "")} tooltip={true} />),
        sortable: true,
        grow: 1.2
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.criteria", defaultMessage: "criteria" })} />),
        selector: (row) => (_get(row, "net", "")),
        cell: (row) => {
          const { criteriaBriefId } = this.state;
          const briefId = _get(row, "id", null);

          return (
            <div className="align-items-center cursor-pointer">
              <OverlayTrigger
                placement="right"
                show={(criteriaBriefId === briefId)}
                shouldUpdatePosition={true}
                overlay={this._showCriteria(briefId)}
                rootClose={true}
              >
                <span onMouseEnter={() => this._handleShowPopper(briefId)}>
                  <img src={require("../../assets/icons/icon-info.svg").default} alt="info_icon" className="img-fluid" />
                </span>
              </OverlayTrigger>
            </div>
          );
        },
        sortable: false,
        center: "true",
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.quantity", defaultMessage: "quantity" })} />),
        selector: (row) => (_get(row, "quantity", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "quantity", "")} />),
        sortable: true,
        width: "100px",
        center: "true",
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.rate_type", defaultMessage: "rate type" })} />),
        selector: (row) => (_get(row, "rate_type", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "rate_type", "")} />),
        sortable: true,
        width: "100px",
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.rate", defaultMessage: "rate" })} />),
        selector: (row) => (_get(row, "cpl", "")),
        cell: (row) => (<RenderTableCellValue value={`${_get(row, "currency", "")}${_get(row, "cpl", "")}`} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.site", defaultMessage: "site" })} />),
        selector: (row) => (_get(row, "site", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "site", "")} />),
        sortable: true,
      },
      {
        name: (<RenderTableHeaderValue value={intl.formatMessage({ id: "campaign_setup.campaign_brief.table.column.notes", defaultMessage: "notes" })} />),
        selector: (row) => (_get(row, "notes", "")),
        cell: (row) => (<RenderTableCellValue value={_get(row, "notes", "")} />),
        sortable: true,
      },
    ];
  }

  componentDidMount() {
    this._fetchCampaignEditBriefs();
    this._setbriefListData();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.briefs, this.props.briefs) && !_isEmpty(this.props.briefs)) {
      const { briefs, clearCampaignEditBriefsError } = this.props;
      const responseFlag = _get(briefs, "flag", null);

      if (responseFlag === null) { return false; }

      if (responseFlag === true) {
        this._setbriefListData();
      } else if (responseFlag === false) {
        showAlertMessage(_get(briefs, "message", "Something went wrong while fetching campaign briefs."));
      }

      if (typeof clearCampaignEditBriefsError === "function") { clearCampaignEditBriefsError(); }
    }

    if (!_isEqual(prevState.reloadBriefList, this.state.reloadBriefList) && (_get(this.state, "reloadBriefList", false) === true)) {
      this._fetchCampaignEditBriefs();

      this.setState({ reloadBriefList: false });
    }

    if (!_isEqual(prevState.criteriaBriefId, this.state.criteriaBriefId) && this.state.criteriaBriefId !== null) {
      this._fetchBriefCriteria();
    }

    document.addEventListener("click", () => this._handleClickOutside());
  }

  componentWillUnmount() {
    document.removeEventListener("click", () => this._handleClickOutside());
  }

  _handleClickOutside = () => {
    const { criteriaBriefId } = this.state;

    if (criteriaBriefId !== null) {
      this.setState({ criteriaBriefId: null, briefCriteria: [] });
    }
  }

  _handleShowPopper = (criteriaBriefId) => {
    if (!_isEmpty(this._timeout)) { clearTimeout(this._timeout); }

    this._timeout = setTimeout(() => {
      this.setState({ criteriaBriefId });
    }, 300);
  }

  _setbriefListData = () => {
    const { briefs } = this.props;

    this.setState({ briefList: _get(briefs, "data.briefs", []), campaignDetails: _get(briefs, "data.campaign_detail[0]", {}) });
  }

  _fetchCampaignEditBriefs = async () => {
    const { fetchCampaignEditBriefs } = this.props;
    const { campaignId } = this.state;

    if (typeof fetchCampaignEditBriefs === "function") { fetchCampaignEditBriefs(campaignId); }
  }

  _fetchBriefCriteria = async () => {
    const { criteriaBriefId } = this.state;

    if (criteriaBriefId === null) { return false; }

    this.setState({ criteriaLoading: true });

    try {

      const response = await fetchBriefCriteria(criteriaBriefId);

      if ((response.flag || false) === true) {
        this.setState({ briefCriteria: _get(response, "data", []) });
      } else {
        this.setState({ briefCriteria: [] });
        showAlertMessage(_get(response, "message", "Something went wrong while fetching brief criteria."));
      }

    } catch (error) {
      this.setState({ briefCriteria: [] });
      showAlertMessage(_get(error, "message", "Something went wrong while fetching brief criteria."));
    } finally {
      this.setState({ criteriaLoading: false });
    }
  }

  _showCriteria = (briefId) => {
    const { briefCriteria, criteriaBriefId, criteriaLoading } = this.state;

    if (criteriaBriefId !== briefId) { return <></>; }

    return (
      <Popover
        id="popover-basic"
        placement="right"
        className="criteria_overlay"
        show={(criteriaBriefId === briefId)}
      >
        <h5 className="popover-header text-capitalize fw-bold">
          <FormattedMessage id="campaign_brief.criteria.title" defaultMessage="brief criteria" />
        </h5>

        {(criteriaLoading === true) && (
          <div className="popover-height">
            <div className="popover-body">
              <ShowContentLoader />
            </div>
          </div>
        )}

        {(criteriaLoading === false) && (
          <>
            {((briefCriteria || []).length === 0) && (
              <div className="popover-height">
                <div className="popover-body">
                  <FormattedMessage id="message.no_found_data" defaultMessage="no data found" />
                </div>
              </div>
            )}

            <div className="popover-body d-flex flex-column gap-1">
              {(briefCriteria || []).map((e, key) => (
                <div key={key}>
                  <span className="text-capitalize fw-semibold">
                    <FormattedMessage id={`campaign_brief.criteria.${(e.criteria || "criteria")}`} defaultMessage="Brief criteria: " />
                  </span>
                  <span>
                    {(e.criteria_value || "")}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
      </Popover>
    );
  }

  _onHideCriteria = () => {
    this.setState({ criteriaBriefId: null, briefCriteria: [] });
  }

  showEditBriefModal = () => {
    const { intl } = this.props;
    const { selectedBriefs } = this.state;

    if ((selectedBriefs || []).length < 1) {

      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }),
      }));
      return;
    } else if ((selectedBriefs || []).length > 1) {

      showAlertMessage(intl.formatMessage({ id: "error.select_only_one", defaultMessage: "Please select only one {field}." }, {
        field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }),
      }));
      return;
    }

    this.setState({ showBriefAddEditModal: true, isEditBrief: true });
  }

  _deleteSelectedBriefs = async (isShowAlertMessage = true) => {
    const { intl } = this.props;
    const { selectedBriefIds } = this.state;

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    try {
      const response = await deleteBriefs(selectedBriefIds);

      if (_get(response, "flag", false) === true) {

        if ((isShowAlertMessage || false) === true) {
          showAlertMessage(_get(response, "message", "brief(s) deleted successfully."), "success");
        }

        setTimeout(() => {
          window.onbeforeunload = false;
          window.location.reload();
        }, 500);
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while deleting briefs."));
      }
    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while deleting briefs."));
    }
  }

  _handleBriefExport = async () => {
    const { updateLoadingState, intl } = this.props;
    const { campaignId, selectedBriefIds, briefList } = this.state;

    if (_isEmpty(briefList)) {
      showAlertMessage(intl.formatMessage({ id: "campaign_brief.no_brief_available", defaultMessage: "No briefs available to export." }));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await exportCampaignBriefs({ campaign_id: campaignId, brief_ids: selectedBriefIds });

      if (_get(response, "flag", false) === true) {

        const downloadFileDetails = _get(response, "data", []);

        const contentType = _get(downloadFileDetails, "contentType", "");
        const contents = _get(downloadFileDetails, "Contents", "");
        const filename = _get(downloadFileDetails, "filename", "");

        if ((contentType !== "") && (contents !== "") && (filename !== "")) {
          downloadExcelFile(contentType, contents, filename);

          showAlertMessage(intl.formatMessage({ id: "message.file_generated_successfully", defaultMessage: "File generated successfully." }), "success");
        }
      } else {
        showAlertMessage(response.message || "Something went wrong while exporting briefs.");
      }

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while exporting briefs.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderTopActions = () => {
    const { selectedBriefIds, campaignId, campaignDetails, pageSource } = this.state;

    const clientId = _get(campaignDetails, "client_id", null);

    return (
      <>
        <Dropdown>
          <Dropdown.Toggle as="a" variant="success" className="btn btn-outline-primary text-capitalize-first dropdown-toggle-no cursor-pointer">
            <FormattedMessage id="campaign_setup.campaign_brief.assign" defaultMessage="Assign" />
          </Dropdown.Toggle>
          <Dropdown.Menu>

            {(hasAccess("campaigns", "edit", ["assign-accounts"])) && (
              <AssignAccountsToBrief selectedBriefIds={selectedBriefIds} source={pageSource} campaignId={(campaignId || 0)} clientId={(clientId || 0)} />
            )}

            {(hasAccess("campaigns", "edit", ["assign-suppressions"])) && (
              <AssignSuppressionsToBrief selectedBriefIds={selectedBriefIds} source={pageSource} campaignId={(campaignId || 0)} clientId={(clientId || 0)} />
            )}

            {(hasAccess("campaigns", "edit", ["assign-job-functions"])) && (
              <AssignJobFunctionsToBrief selectedBriefIds={selectedBriefIds} source={pageSource} campaignId={(campaignId || 0)} />
            )}

            {(hasAccess("campaigns", "edit", ["assign-regions"])) && (
              <AssignRegionsToBrief selectedBriefIds={selectedBriefIds} source={pageSource} campaignId={(campaignId || 0)} />
            )}

            {(hasAccess("campaigns", "edit", ["assign-company-size"])) && (
              <AssignCompanySizesToBrief selectedBriefIds={selectedBriefIds} source={pageSource} campaignId={(campaignId || 0)} />
            )}

            {(hasAccess("campaigns", "edit", ["assign-industry"])) && (
              <AssignIndustriesToBrief selectedBriefIds={selectedBriefIds} source={pageSource} campaignId={(campaignId || 0)} />
            )}

            {(hasAccess("campaigns", "edit", ["assign-dates"])) && (
              <AssignDatesToBrief selectedBriefIds={selectedBriefIds} source={pageSource} campaignId={(campaignId || 0)} setState={(args) => this.setState(args)} />
            )}

            <AssignAssets selectedBriefIds={selectedBriefIds} source={pageSource} campaignId={(campaignId || 0)} setState={(args) => this.setState(args)} />

          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }

  _renderCampaignActions = () => {
    const { campaignId, campaignDetails } = this.state;

    const isEnabledPersonalityCard = _get(campaignDetails, "humantics_processing_flag", 0);

    return (
      <>
        <Dropdown>
          <Dropdown.Toggle as="a" variant="success" className="btn btn-outline-primary text-capitalize-first dropdown-toggle-no cursor-pointer">
            <FormattedMessage id="campaign_setup.campaign_brief.action" defaultMessage="action" />
          </Dropdown.Toggle>
          <Dropdown.Menu>

            {(hasAccess("campaigns", "edit", ["assign-personality-card"])) && (
              <AssignPersonalityCard campaignId={(campaignId || 0)} isEnabledPersonalityCard={isEnabledPersonalityCard} setState={(args) => this.setState(args)} />
            )}

            <EditRequiredFields campaignId={(campaignId || 0)} setState={(args) => this.setState(args)} />

          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }

  showSelectedRows = (row) => {
    const { selectedBriefIds } = this.state;

    return _includes(selectedBriefIds, row.id);
  };

  render() {
    const { briefList, selectedBriefIds, selectedBriefs, showBriefAddEditModal, criteriaBriefId, briefCriteria, criteriaLoading, isEditBrief, campaignDetails, campaignId } = this.state;

    const uniqueKey = `${(criteriaBriefId || 1)}-${(briefCriteria || "criteria")}-${(criteriaLoading || "loader")}`;

    return (
      <>
        <div className="container-fluid flex-grow-1 px-6 py-6">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center gap-5 py-3 px-4">
                    <p className="heading-04 text-uppercase">{_get(campaignDetails, "campaign_name", <EmptyLabel />)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-12">
              <h1 className="heading-04 fw-semibold text-dark-35 text-uppercase mb-5">
                <FormattedMessage id="campaign_brief.title" defaultMessage="campaign brief" />
              </h1>
            </div>
          </div>

          <div className="row gy-4">
            <div className="col-lg-3">
              {(
                hasAccess("campaigns", "edit", ["assign-accounts", "assign-suppressions", "assign-job-functions", "assign-regions", "assign-company-size", "assign-industry", "assign-dates"], "OR")
              ) &&
                this._renderTopActions()}
            </div>
            <div className="col-lg-9">
              <div className="d-flex flex-wrap gap-3 align-items-center justify-content-end">
                {(hasAccess("campaigns", "edit", ["add-new-brief"])) && (
                  <button className="btn btn-outline-primary waves-effect text-capitalize" onClick={() => this.setState({ showBriefAddEditModal: true })}>
                    <FormattedMessage id="campaign_setup.campaign_brief.add_new_brief" defaultMessage="add new brief" />
                  </button>
                )}
                {(hasAccess("campaigns", "edit", ["update"])) && (
                  <button className="btn btn-outline-primary waves-effect text-capitalize" onClick={() => this.showEditBriefModal()}>
                    <FormattedMessage id="btn.update" defaultMessage="btn.update" />
                  </button>
                )}
                {(hasAccess("campaigns", "edit", ["delete"])) && (
                  <button className="btn btn-outline-primary waves-effect text-capitalize" onClick={() => this._deleteSelectedBriefs()}>
                    <FormattedMessage id="btn.delete" defaultMessage="delete" />
                  </button>
                )}

                {hasAccess("campaigns", "edit", ["assign-personality-card"], "OR") && this._renderCampaignActions()}
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body py-5">
                  <div className="table-responsive custom-scroll">
                    <ReactDataTable
                      key={(uniqueKey || "brief-table")}
                      columns={this.briefTableColumns}
                      data={(briefList || [])}
                      pagination={false}
                      selectableRows
                      selectableRowSelected={this.showSelectedRows}
                      onSelectedRowsChange={(selectedRows) => {
                        const tmpSelectedBriefIds = _get(selectedRows, "selectedRows", []).map(row => { return row.id });

                        this.setState({ selectedBriefs: _get(selectedRows, "selectedRows", []), selectedBriefIds: tmpSelectedBriefIds });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="d-flex flex-wrap gap-3 align-items-center">
                {(hasAccess("campaigns", "edit", ["campaign-dashboard"])) && (
                  <div className="input-group w-auto">
                    <span className="input-group-text bg-primary-66 border-primary-66 px-3 d-flex align-items-center">
                      <img src={require("../../assets/icons/icon-dashboard-alt.svg").default} className="img-fluid" alt="" />
                    </span>
                    <Link to={`/campaigns/dashboard/${campaignId}`} className="btn btn-primary text-capitalize waves-effect">
                      <FormattedMessage id="campaign_brief.btn.campaign_dashboard" defaultMessage="campaign dashboard" />
                    </Link>
                  </div>
                )}

                {(hasAccess("campaigns", "edit", ["all-brief-export"])) && (
                  <button className="btn btn-outline-primary waves-effect" onClick={() => this._handleBriefExport()}>
                    <FormattedMessage id="campaign_brief.btn.all_brief_export" defaultMessage="all brief export" />
                  </button>
                )}

                {(hasAccess("campaigns", "edit", ["export-upload-template"])) && (
                  <ExportUploadTemplate campaignId={campaignId} selectedBriefIds={(selectedBriefIds || [])} />
                )}

                {(hasAccess("campaigns", "edit", ["copy-brief"])) && (
                  <CloneBrief
                    campaignId={campaignId}
                    setState={(args) => this.setState(args)}
                    selectedBriefIds={(selectedBriefIds || [])}
                    cancelCloneBrief={(newBriefId) => {

                      this.setState({
                        selectedBriefIds: [(newBriefId || 0)]
                      }, () => {
                        this._deleteSelectedBriefs(false)
                      });
                      //
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <AddEditBrief
          setState={(args) => this.setState(args)}
          selectedBriefIds={(selectedBriefIds || [])}
          selectedBrief={(selectedBriefs || [])}
          isEditBrief={(isEditBrief || false)}
          showBriefAddEditModal={(showBriefAddEditModal || false)}
          campaignId={(campaignId || 0)}
        />
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  briefs: _get(state, "campaign.briefs", {})
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  fetchCampaignEditBriefs: (data) => dispatch(fetchCampaignEditBriefs(data)),
  clearCampaignEditBriefsError: () => dispatch(clearCampaignEditBriefsError()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaignEdit)));
