import React from "react";
import { FormattedMessage } from "react-intl";

export const EmptyLabel = () => (
  <span className="empty-text text-capitalize text-grey-97 fst-italic pe-2">
    <FormattedMessage id="empty" defaultMessage="Empty" />
  </span>
);

export const NALabel = () => (
  <span className="text-italic text-capitalize">
    <FormattedMessage id="n/a" defaultMessage="N/A" />
  </span>
);

export const NoDataMessage = ({ message }) => (
  <div className="text-capitalize text-center no-message-wrapper">
    <span className="empty-text" >{message || ""}</span>
  </div>
);

export const showContentLoader = (props) => {
  return (
    <div className={`loader ${(props.className || "")}`} />
  );
};
