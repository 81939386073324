import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { showAlertMessage, Select, ErrorMessage, CustomModal } from "../../../../../resusableComponents/common/formElements";
import { hasAccess } from "../../../../../resusableComponents/hoc/AccessManagement";
import { isDomainNameValid } from "../../../../../helpers/utils"

import { doGetAgencyList, getClientDetailsById, editClient } from "../../../../../redux/services/userMangement";
import { updateLoadingState } from "../../../../../redux/actions/application";

class EditClient extends Component {

  constructor(props) {
    super(props);

    this.defaultEditState = {
      clientName: "",
      websiteUrl: "",
      selectedAgency: null,
      formFile: ""
    }

    this.state = {
      formErrors: {},
      formData: { ...this.defaultEditState },
      isFormSubmitted: false,
      agencyListDropDownData: [],
    };
  }

  // handleFileUpload = async (e) => {
  //   let file = _get(e, "target.files[0]", null);

  //   this.setState({
  //     formFile: file
  //   });
  // }

  componentDidMount() {
    const { showEditClientModal } = this.props;

    if (showEditClientModal) {
      this._fetchClientDetails();
      this._fetchAgencyList();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevProps.showEditClientModal, this.props.showEditClientModal) && (_get(this.props, "showEditClientModal", false) === true)) {
      this._fetchClientDetails();
      this._fetchAgencyList();
    }

    if (!_isEqual(prevState.formData, this.state.formData) && (_get(this.state, "isFormSubmitted", false) === true)) {
      this._handleValidation();
    }
  }

  _fetchClientDetails = async () => {
    const { selectedClientObj, updateLoadingState, intl } = this.props;

    if (_isEmpty(selectedClientObj) || (_get(selectedClientObj, "id", 0) === 0)) {
      showAlertMessage(intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "client", defaultMessage: "client" })
      }));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getClientDetailsById(_get(selectedClientObj, "id", 0));

      const resData = _get(response, "data.responseData", {});

      if ((response.flag || false) === true) {
        this.setState({
          formData: {
            clientName: _get(resData, "client_name", ""),
            websiteUrl: _get(resData, "website", ""),
            selectedAgency: { id: _get(resData, "agency_id", ""), agency_name: _get(resData, "agency_name", "") },
            formFile: _get(resData, "client_logo", "")
          }
        });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching client details.")));
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching client details.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _fetchAgencyList = async () => {
    const { updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetAgencyList({ agency_id: null, search_text: null, dropdown_flag: 1 });

      if ((response.flag || false) === true) {
        this.setState({ agencyListDropDownData: _get(response, "data.responseData.agency_list", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching agency list.")));
        this.setState({ agencyListDropDownData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching agency list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { formData: { clientName, websiteUrl, selectedAgency, /* formFile */ } } = this.state;

    let formErrors = {};

    if (_isEmpty(selectedAgency)) {
      formErrors["selectedAgency"] = intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "agency", defaultMessage: "agency" })
      });
    }

    if (_isEmpty(clientName)) {
      formErrors["clientName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.edit_client.client_name", defaultMessage: "client name" })
      });
    }

    if (_isEmpty(websiteUrl)) {
      formErrors["websiteUrl"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.edit_client.website_url", defaultMessage: "website URL" })
      });
    }

    if (isDomainNameValid(websiteUrl) === false) {
      formErrors["websiteUrl"] = intl.formatMessage({ id: "error.valid", defaultMessage: "Please enter valid {field}." }, {
        field: intl.formatMessage({ id: "user_management.edit_client.website_url", defaultMessage: "website URL" })
      });
    }

    // if (_isEmpty(formFile)) {
    //   formErrors["formFile"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
    //     field: intl.formatMessage({ id: "password", defaultMessage: "password" })
    //   });
    // }

    this.setState({ formErrors });

    if (returnFlag) {
      return (!_isEmpty(formErrors)) ? false : true;
    }
  }

  handleChange = (keyName, keyValue) => {
    this.setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [keyName]: keyValue,
      }
    }));
  }

  _handleSubmit = async () => {
    const { selectedClientObj, updateLoadingState, setState, intl } = this.props;
    const { formErrors, formData: { clientName, websiteUrl, selectedAgency } } = this.state;

    if (_isEmpty(selectedClientObj) || (_get(selectedClientObj, "id", 0) === 0)) {
      showAlertMessage(intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "client", defaultMessage: "client" })
      }));
      return false;
    }

    if (!_isEmpty(formErrors)) { return false; }

    this.setState({ isFormSubmitted: true });

    const isValidForm = this._handleValidation(true);

    if (!isValidForm) { return false; }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      let data = new FormData();
      data.append("client_name", (clientName || ""));
      data.append("website", (websiteUrl || ""));
      data.append("agency_id", _get(selectedAgency, "id", null));

      const response = await editClient(data, _get(selectedClientObj, "id", 0));

      if (_get(response, "data.responseStatus", null) === true) {

        showAlertMessage(_get(response, "data.responseMessage", "Client details updated successfully."), "success");

        this.setState({ formErrors: {}, isFormSubmitted: false })

        if (typeof setState === "function") {
          setState({ selectedClient: {}, showEditClientModal: false, reloadClientList: true });
        }
      } else {
        showAlertMessage(_get(response, "data.responseMessage", "Something went wrong while updating client details."));
      }

    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while updating client details.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _renderTextFiled = ({ label, placeholder, onChange, name, value, isRequired = false }) => {
    const { formErrors } = this.state;

    return (
      <div className="mb-6">
        <label className="form-label text-capitalize">
          {label}
          {(isRequired) && (<span className="text-danger">*</span>)}
        </label>
        <input
          name={name}
          type="text"
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        {_get(formErrors, `${name}`, "") && <ErrorMessage message={_get(formErrors, `${name}`, "")} />}
      </div >
    );
  }

  _cloneModal = () => {
    const { setState } = this.props;

    if (typeof setState === "function") { setState({ showEditClientModal: false, selectedClient: {} }); }
  }

  _renderView = () => {
    const { showEditClientModal, intl } = this.props;
    const { formErrors, formData: { clientName, websiteUrl, selectedAgency }, agencyListDropDownData } = this.state;

    return (
      <CustomModal
        isOpen={showEditClientModal}
        modalTitle={intl.formatMessage({ id: "user_management.edit_client.title", defaultMessage: "edit client" })}
        onHide={() => this._cloneModal()}
        onClose={() => this._cloneModal()}
      >
        <>
          <div className="row">
            <div className="col-lg-6">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "user_management.edit_client.client_name", defaultMessage: "client name" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "user_management.edit_client.client_name", defaultMessage: "client name" })
                }),
                onChange: (event) => this.handleChange("clientName", _get(event, "target.value", "")),
                name: "clientName",
                isRequired: true,
                value: (clientName || "")
              })}
            </div>

            <div className="col-lg-6">
              {this._renderTextFiled({
                label: intl.formatMessage({ id: "user_management.edit_client.website_url", defaultMessage: "website URL" }),
                placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                  field: intl.formatMessage({ id: "user_management.edit_client.website_url", defaultMessage: "website URL", }),
                }),
                onChange: (event) => this.handleChange("websiteUrl", _get(event, "target.value", "")),
                name: "websiteUrl",
                isRequired: true,
                value: (websiteUrl || "")
              })}
            </div>

            <div className="col-lg-6">
              <div className="mb-6">
                <label className="form-label text-capitalize">
                  <FormattedMessage id="user_management.edit_client.agency_option" defaultMessage="Select Agency" />
                  <span className="text-danger">*</span>
                </label>
                <Select
                  className="form-custom-select"
                  placeholder={intl.formatMessage({ id: "user_management.edit_client.agency_option", defaultMessage: "Select Agency" })}
                  options={(agencyListDropDownData || [])}
                  value={(selectedAgency || null)}
                  getOptionLabel={(option) => (option.agency_name || "")}
                  getOptionValue={(option) => (option.id || null)}
                  onChange={(selected) => this.handleChange("selectedAgency", selected)}
                  isMulti={false}
                />
                {_get(formErrors, "selectedAgency", "") && <ErrorMessage message={_get(formErrors, "selectedAgency", "")} />}
              </div>
            </div>

            {/* <div className="col-lg-6">
          <div className="mb-6">
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
                <FormattedMessage id="user_management.edit_client.upload_logo" defaultMessage="Upload Logo" />
              </label>
              <input className="form-control" type="file" id="formFile" onChange={(event) => this.handleFileUpload(event)} />
            </div>
          </div>
        </div> */}
          </div>
          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button type="button" className="btn btn-secondary" onClick={() => this._cloneModal()}>
              <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
            </button>
            <button className="btn btn-primary" onClick={() => this._handleSubmit()}>
              <FormattedMessage id="btn.submit" defaultMessage="Submit" />
            </button>
          </div>
        </>
      </CustomModal>
    );
  }

  render() {

    return (
      <>
        {hasAccess("admin", "client-management", ["update-client"]) && this._renderView()}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(null, mapDispatchToProps)(injectIntl(EditClient));
