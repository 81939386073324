import { showAlertMessage as showAlertTmp } from "./AlertMessage";
import { ErrorMessage as ErrorMessageTmp, showContentLoader as showContentLoaderTmp } from "./ErrorMessage";
import { EmptyLabel as EmptyLabelTmp } from "./EmptyLabel";
import { NoDataMessage as NoDataMessageTmp } from "./EmptyLabel";
import { CustomModal as ModalTmp } from "./CustomModal";
import RenderTableHeaderValueTmp from "./RenderTableHeaderValue";
import RenderTableCellValueTmp from "./RenderTableCellValue";
import DateRangePickerTmp from "./DateRangePicker";
import SingleDropzoneTmp from "./SingleDropzone";
import ConfirmBoxTmp from "./ConfirmBox";
import DatePickerTmp from "./DatePicker";
import HighChartTmp from "./HighChart";
import SelectTmp from "./Select";

export const RenderTableHeaderValue = RenderTableHeaderValueTmp;
export const RenderTableCellValue = RenderTableCellValueTmp;
export const ShowContentLoader = showContentLoaderTmp;
export const DateRangePicker = DateRangePickerTmp;
export const SingleDropzone = SingleDropzoneTmp;
export const NoDataMessage = NoDataMessageTmp;
export const showAlertMessage = showAlertTmp;
export const ErrorMessage = ErrorMessageTmp;
export const EmptyLabel = EmptyLabelTmp;
export const ConfirmBox = ConfirmBoxTmp;
export const DatePicker = DatePickerTmp;
export const HighChart = HighChartTmp;
export const Select = SelectTmp;
// export const Input = InputTmp;
export const CustomModal = ModalTmp;
