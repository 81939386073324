import moment from "moment";

// Language configuration
export const DEFAULT_WEB_LANGUAGE = "en-gb";
export const LANGUAGES = {
  "en-gb": {
    id: "en-gb",
    name: "english",
    file_name: "en.js"
  }
};

export const USER_ROLES = {
  ADMIN: { id: 1, role: "admin" },
  OPERATIONS: { id: 2, role: "operations" },
  AGENCY: { id: 3, role: "agency" },
  AGENCY_ACCOUNTS: { id: 4, role: "agency accounts" },
};

export const ENABLED_STATUSES = [
  { name: "All", value: null },
  { name: "Enabled", value: 1 },
  { name: "Disabled", value: 0 },
];

// Routes: Defined here if same route used multiple places
export const CAMPAIGN_SETUP_ROUTE = "campaigns/setup";
export const VIEW_PENDING_LEADS_ROUTE = "/campaigns/pending-leads/:id";
export const VIEW_APPROVED_LEADS_ROUTE = "/campaigns/approved-leads/:id";
export const VIEW_REJECTED_LEADS_ROUTE = "/campaigns/rejected-leads/:id";

// Application routes
export const applicationRoutes = [
  // Default routes
  {
    path: "/",
    exact: true,
    component: "campaignList",
    currentMenu: { group: "campaigns", name: "campaign_list" }
  }, {
    path: "/dashboard",
    exact: true,
    component: "dashboard",
    currentMenu: { group: "dashboard", name: "dashboard" }
  }, {
    path: "/dashboard/agency/:id",
    exact: true,
    component: "agencyDashboard",
    currentMenu: { group: "dashboard", name: "dashboard" }
  },
  {
    path: "/campaigns/list",
    exact: true,
    component: "campaignList",
    currentMenu: { group: "campaigns", name: "campaign_list" }
  },
  {
    path: "/campaigns/dashboard/:id",
    exact: true,
    component: "campaignDashboard",
    currentMenu: { group: "campaigns", name: "campaign_list" }
  },
  {
    path: "/campaigns/content/:id",
    exact: true,
    component: "campaignContent",
    currentMenu: { group: "campaigns", name: "campaign_list" }
  },
  {
    path: "/campaigns/data-history/:id",
    exact: true,
    component: "campaignHistory",
    currentMenu: { group: "campaigns", name: "campaign_list" }
  },
  {
    path: "/campaigns/upload-lead/:id",
    exact: true,
    component: "uploadLead",
    currentMenu: { group: "campaigns", name: "campaign_list" }
  },
  {
    path: CAMPAIGN_SETUP_ROUTE,
    exact: true,
    component: "campaignSetup",
    currentMenu: { group: "campaigns", name: "campaign_setup" }
  },
  {
    path: "/campaigns/edit/:id",
    exact: true,
    component: "campaignEdit",
    currentMenu: { group: "campaigns", name: "campaign_list" }
  },
  {
    path: "/campaigns/view-leads/:id",
    exact: true,
    component: "viewLeads",
    currentMenu: { group: "campaigns", name: "campaign_list" }
  },
  // View lead page with predefined filter value, Like: Show rejected, Approved, Pending leads
  {
    path: VIEW_PENDING_LEADS_ROUTE,
    exact: true,
    component: "viewLeads",
    currentMenu: { group: "campaigns", name: "campaign_list" }
  },
  {
    path: VIEW_APPROVED_LEADS_ROUTE,
    exact: true,
    component: "viewLeads",
    currentMenu: { group: "campaigns", name: "campaign_list" }
  },
  {
    path: VIEW_REJECTED_LEADS_ROUTE,
    exact: true,
    component: "viewLeads",
    currentMenu: { group: "campaigns", name: "campaign_list" }
  },
  {
    path: "/admin/user-activity-log",
    exact: true,
    component: "underConstruction",
    currentMenu: { group: "admin", name: "user_activity_log" }
  },
  {
    path: "/admin/user-management",
    exact: true,
    component: "userManagement",
    currentMenu: { group: "admin", name: "user_management" }
  },
  {
    path: "/admin/agency-management",
    exact: true,
    component: "userManagement",
    currentMenu: { group: "admin", name: "user_management" }
  },
  {
    path: "/admin/client-management",
    exact: true,
    component: "userManagement",
    currentMenu: { group: "admin", name: "user_management" }
  },
  {
    path: "/settings",
    exact: true,
    component: "settings",
    currentMenu: { group: "", name: "" }
  }, {
    path: "/terms-and-conditions",
    exact: true,
    component: "underConstruction",
    currentMenu: { group: "", name: "" }
  },
];

export const tempConstant = {
  "APIRequestDateFormat": "YYYY-MM-DD",
  "momentDefaultDateFormat": "MM/DD/YYYY",
  "momentDisplayDateFormat": "DD/MM/YYYY",
  "displayDateFormat": "dd/MM/yyyy",
  "displayDateTimeFormat": "DD/MM/YYYY HH:mm:ss",
  "campaignTrackerDisplayDateFormat": "DD MMM YY",
  "allowedUploadFiles": {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.ms-excel": [".xls"],
    "text/csv": [".csv"]
  },
  "allowedUploadFilesLabel": ".xlsx, .xls, .csv",
  "allowedMediaPlanFiles": {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
    "application/vnd.ms-excel": [".xls"],
    "text/csv": [".csv"]
  },
  "allowedMediaPlanFilesLabel": ".xlsx, .xls, .xlsm, .csv",
  "allowedAssetFiles": {
    "application/pdf": [".pdf"],
    "video/mp4": [".mp4"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "text/csv": [".csv"]
  },
  "allowedAssetFilesLabel": ".pdf, .doc, .docx, .xls, .xlsx, .csv, .mp4",
  "allowedDataUploadFiles": {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "text/csv": [".csv"]
  },
  "allowedDataUploadFilesLabel": ".xlsx, .xls, .csv",
  "dateRangeOptions": {
    "No Date": [moment().toDate(), moment().subtract(1, "days").toDate()],
    "This Week": [moment().startOf("week"), moment().endOf("week")],
    "Last Week": [
      moment().subtract(1, "weeks").startOf("week"),
      moment().subtract(1, "weeks").endOf("week")
    ],
    "This Month": [
      moment().startOf("month").toDate(),
      moment().endOf("month").toDate(),
    ],
    "Last Month": [
      moment().subtract(1, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
    "Last 3 Month": [
      moment().subtract(3, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
    "Last 6 Month": [
      moment().subtract(6, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
    "Last 12 Month": [
      moment().subtract(12, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
  },
  "roleWiseRedirections": [
    {
      roleIds: [1, 2, 3, 4],
      roles: ["admin", "operations", "agency admin", "agency accounts"],
      redirectionPath: "/campaigns/list"
    }
  ]
};

export const WeekDays = [
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
  { value: "Sunday", label: "Sunday" }
];

export const LeadStatusWithAttributes = {
  "labelClass": {
    2: "text-danger bg-danger-df", 1: "text-success bg-success-df", 0: "bg-warning bg-warning-df", 3: "bg-warning bg-warning-df",
  },
  "labelFontClass": {
    2: "lead-reject-text", 1: "", 0: "lead-pending-text", 3: "lead-pending-text",
  },
  "qaLabels": {
    2: "FAIL", 1: "PASS", 0: "PENDING", 3: "WARNING",
  },
  "approveLabels": {
    2: "REJECTED", 1: "APPROVED", 0: "PENDING", 3: "WARNING",
  }
}

export const LeadStatus = {
  "1": { status: 1, "text": "Approved", "order": 0 },
  "0": { status: 0, "text": "Pending", "order": 1 },
  "2": { status: 2, "text": "Rejected", "order": 2 },
}
