import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { get as _get } from "lodash";

import { CustomModal, showAlertMessage } from "../common/formElements";

import { enableDisablePersonalityCard } from "../../redux/services/campaignSetup";

import { updateLoadingState } from "../../redux/actions/application";

class AssignPersonalityCard extends Component {

  constructor(props) {
    super(props);

    this.defaultState = {
      allowPersonalityCard: (_get(props, "isEnabledPersonalityCard", 0) === 1) ? true : false,
    };

    this.state = {
      ...this.defaultState,
      showModal: false
    };
  }

  _showModal = () => {
    const { campaignId, intl } = this.props;

    if ((campaignId || null) === null) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "campaign", defaultMessage: "campaign" }) }
      ));
      return false;
    }

    this.setState({ ...this.defaultState, showModal: true });
  }

  _closeModal = () => {
    const { setState } = this.props;

    this.setState({ ...this.defaultState, showModal: false });

    //Set Parent Component States
    if (typeof setState === "function") { setState({ reloadBriefList: true }); }
  }

  async _submitPersonalityCard() {
    const { campaignId, updateLoadingState, intl } = this.props;
    const { allowPersonalityCard } = this.state;

    if ((campaignId || null) === null) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "campaign", defaultMessage: "campaign" }) }
      ));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const payload = {
        "campaignId": (campaignId || null),
        "isEnabled": ((allowPersonalityCard || false) === true) ? "enable" : "disable",
      };

      const response = await enableDisablePersonalityCard(payload);

      if ((response.flag || false) === true) {

        showAlertMessage(_get(response, "data.message", "Personality card enabled successfully for this campaign."), "success");

        setTimeout(() => {
          window.onbeforeunload = false;
          window.location.reload();
        }, 500);
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while enabling personality card for this campaign."));
      }

    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while enabling personality card for this campaign."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderModalContent = () => {
    const { intl } = this.props;
    const { showModal, allowPersonalityCard } = this.state;

    return (
      <CustomModal
        isOpen={showModal}
        className="text-capitalize"
        bodyClassName="overflow-unset"
        modalTitle={(intl.formatMessage({ id: "campaign_setup.campaign_brief.enable_personality_card", defaultMessage: "enable personality card" }))}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
      >
        <>
          <div className="row mb-4">
            <div className="col-md-5">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="enable-personality-card"
                  className="form-check-input"
                  name="checkbox"
                  defaultChecked={(allowPersonalityCard || false)}
                  onClick={(e) => {
                    const checked = _get(e, "currentTarget.checked", false);

                    this.setState({ allowPersonalityCard: checked });
                  }}
                />
                <label className="form-check-label" htmlFor="enable-personality-card" >
                  <FormattedMessage id="campaign_setup.campaign_brief.assign.modal.enable" defaultMessage="enable" />
                </label>
              </div>
            </div>
          </div>

          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button className="btn btn-secondary" onClick={() => this._closeModal()} >
              <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
            </button>
            <button className="btn btn-primary text-capitalize" onClick={() => this._submitPersonalityCard()}>
              <FormattedMessage id="btn.submit" defaultMessage="submit" />
            </button>
          </div>
        </>
      </CustomModal>
    );
  }

  render() {

    return (
      <>

        {this._renderModalContent()}

        <Dropdown.Item className="dropdown-item d-block waves-effect text-capitalize" onClick={() => this._showModal()} >
          <FormattedMessage id="campaign_setup.campaign_brief.enable_personality_card" defaultMessage="enable personality card" />
        </Dropdown.Item>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(null, mapDispatchToProps)(injectIntl(AssignPersonalityCard));
