import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get as _get } from "lodash";

class CampaignProgressBar extends Component {
  render() {
    const { common: { currentStep, totalStep } } = this.props;

    const active = (step) => {

      if (currentStep >= step) {
        return "active";
      }
    }

    const generateSteps = () => {
      const items = [];
      for (let i = 1; i <= totalStep; i++) {
        items.push(
          <div key={i} className={`timeline-setup-item ${active(i)}`}>
            <p>{i}</p>
          </div>
        );
      }
      return items;
    };

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="d-flex flex-wrap align-items-center gap-lg-8 gap-4 mb-5">
            <h1 className="heading-04 fw-semibold text-dark-35 text-uppercase">
              <FormattedMessage id="campaign_setup.title" defaultMessage="CAMPAIGN SETUP" />
            </h1>

            <div className="timeline-setup" style={{ '--data-progress': `${(currentStep - 1) * 14}% ` }}>
              {generateSteps()}
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  common: _get(state, "campaignSetup.summary.common", {}),
});

export default connect(mapStateToProps, null)(injectIntl(CampaignProgressBar));
