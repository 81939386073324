import React, { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get as _get } from "lodash";

import { withRouter } from "../../resusableComponents/hoc/withRouter";
import { applicationRoutes } from "./../../helpers/constants";

import UnderConstruction from "../CommonPages/UnderConstruction";
import CampaignDataHistory from "../CampaignDataHistory";
import UserManagement from "../Admin/UserManagement";
import CampaignDashboard from "../CampaignDashboard";
import AgencyDashboard from "../AgencyDashboard";
import CampaignContent from "../CampaignContent";
import CampaignSetup from "../CampaignSetup";
import CampaignList from "../CampaignList";
import CampaignEdit from "../CampaignEdit";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import UploadLead from "../UploadLead";
import Dashboard from "../Dashboard";
import ViewLeads from "../ViewLeads";
import Settings from "../Settings";

const resusableComponents = {
  underConstruction: UnderConstruction,
  campaignDashboard: CampaignDashboard,
  campaignHistory: CampaignDataHistory,
  agencyDashboard: AgencyDashboard,
  campaignContent: CampaignContent,
  userManagement: UserManagement,
  campaignSetup: CampaignSetup,
  campaignList: CampaignList,
  campaignEdit: CampaignEdit,
  uploadLead: UploadLead,
  viewLeads: ViewLeads,
  dashboard: Dashboard,
  settings: Settings,
}

class Application extends Component {

  render() {
    const { isNavbarVisible } = this.props;

    return (
      <React.Fragment>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <Sidebar />
            <div className={`layout-page ${(isNavbarVisible === true) ? "" : "active"}`}>
              <Header />
              <div className="content-wrapper">
                <Routes>
                  {
                    applicationRoutes.map(({ component, ...rest }, key) => {

                      const RouteComponent = (resusableComponents[component] || "");

                      if (typeof RouteComponent !== "undefined" && RouteComponent !== "") {
                        return <Route key={key} {...rest} element={<RouteComponent />} />
                      } else {
                        return null;
                      }
                    })
                  }
                  <Route path="*" element={<Navigate from="*" to="/" replace />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => ({
  isNavbarVisible: _get(state, "application.isNavbarVisible", false)
});

export default withRouter(connect(mapStateToProps, null)(injectIntl(Application)));
