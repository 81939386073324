import React, { Component } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import moment from "moment";

class DatePicker extends Component {

  static defaultProps = {
    id: null,
    popperPlacement: "bottom-start",
    dropdownMode: "select",
    selected: moment().toDate(),
    popperClassName: "",
    labelClassName: "text-capitalize",
    label: "Date",
    placeholderText: "Choose a start date",
    placeholder: "",
    showMonthDropdown: true,
    showYearDropdown: true,
    onChange: () => { },
    onChangeRaw: () => { },
    formatType: "dd/MM/yyyy"
  };

  constructor(props) {
    super(props);

    this.state = {
      date: (props.selected) ? moment(props.selected).toDate() : moment().toDate()
    };
  }

  componentDidMount() {
    registerLocale("en-GB", enGB)
  }

  render() {
    const { selected, inputBaseClassName, popperClassName, placeholder, inputClassName, labelClassName, label, ...restProps } = this.props;
    const { date } = this.state;

    return (
      <div className="input-group">
        <ReactDatePicker
          popperClassName={`${popperClassName} form-control dateLabel w-auto`}
          openToDate={date}
          selected={moment(selected).isValid() ? moment(selected).toDate() : ""}
          placeholderText={placeholder}
          {...restProps}
          locale={"en-GB"}
        />

        <span className="input-group-text bg-white waves-effect py-1 d-flex align-items-center border-grey-97 rounded-1 ms-n1">
          <img src={require("../../../assets/icons/icon-date.svg").default} className="img-fluid" alt="img" />
        </span>
      </div>
    );
  }
}

export default DatePicker;
