import React from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { get as _get } from "lodash";

import { downloadExcelFile } from "../../helpers/utils";
import { showAlertMessage } from "./formElements";

import { downloadSampleFile } from "../../redux/services/application";
import { updateLoadingState } from "../../redux/actions/application";

const DownloadSampleFile = ({ source = "" }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const _handleDownload = async () => {

    if (source === "") {
      showAlertMessage("Sample type is missing, Please contact support.", "error");
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { dispatch(updateLoadingState(true)); }

      const response = await downloadSampleFile(source);

      if (_get(response, "flag", false) === true) {

        const downloadFileDetails = _get(response, "data", []);

        const contentType = _get(downloadFileDetails, "contentType", "");
        const contents = _get(downloadFileDetails, "Contents", "");
        const filename = _get(downloadFileDetails, "filename", "");

        if ((contentType !== "") && (contents !== "") && (filename !== "")) {
          downloadExcelFile(contentType, contents, filename);

          showAlertMessage(intl.formatMessage({ id: "message.file_generated_successfully", defaultMessage: "File generated successfully." }), "success");
        }
      } else {
        showAlertMessage(response.message || "Something went wrong while exporting upload template.");
      }
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while exporting upload template.");
    } finally {
      if (typeof updateLoadingState === "function") { dispatch(updateLoadingState(false)); }
    }
  }

  return (
    <div className="col-lg-12">
      <div className="mb-6">
        <button type="submit" className="btn btn-primary" onClick={() => _handleDownload()}>
          <img src={require("../../assets/icons/icon-download.svg").default} className="img-fluid me-3" width="18" alt="" />
          <FormattedMessage id="file.download_sample_file" defaultMessage="Download sample file" />
        </button>
      </div>
    </div>
  );
};
export default injectIntl(DownloadSampleFile);
