import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, cloneDeep as _cloneDeep, map as _map } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";

import { CustomModal, showAlertMessage } from "../common/formElements";

import { getBriefMasterData, assignBriefCriteria } from "../../redux/services/campaignSetup";

import { updateLoadingState } from "../../redux/actions/application";

class AssignJobFunctionsToBrief extends Component {

  constructor(props) {
    super(props);

    this.defaultState = {
      searchText: "",
      selectedCriteriaIds: [],
      criteriaListData: [],
      initialCriteriaListData: [],
    };

    this.state = {
      ...this.defaultState,
      checkAllIds: false,
      showModal: false
    };
  }

  componentDidUpdate(prevProps, prevStates) {

    if (!_isEqual(prevStates.showModal, this.state.showModal) && _get(this.state, "showModal", false) === true) {

      this._fetchListData();
    }

    if (!_isEqual(prevStates.searchText, this.state.searchText)) {
      const { initialCriteriaListData, searchText } = this.state;

      let tmpListData = _cloneDeep(initialCriteriaListData);

      tmpListData = (tmpListData || []).filter(({ name }) => (name || "").toLowerCase().includes((searchText || "").toLowerCase()));

      this.setState({ criteriaListData: tmpListData });
    }
  }

  // Fetch data list
  _fetchListData = async () => {
    const { selectedBriefIds, updateLoadingState, intl } = this.props;

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getBriefMasterData("job_function");

      if ((response.flag || false) === true) {

        this.setState({
          criteriaListData: _get(response, "data", []),
          initialCriteriaListData: _get(response, "data", []),
        });
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while fetching brief master data."));
      }
    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while fetching brief master data."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _showModal = () => {
    const { selectedBriefIds, intl } = this.props;

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    this.setState({ ...this.defaultState, showModal: true });
  }

  _closeModal = () => {

    this.setState({ ...this.defaultState, showModal: false });
  }

  _selectIds = (id = null, source = "single") => {
    const { selectedCriteriaIds, checkAllIds, criteriaListData } = this.state;
    let updatedState = null;
    let tmpSelectIds = _cloneDeep(selectedCriteriaIds || []);

    switch (source) {
      case "single":
        let tmpCheckAllIds = _cloneDeep(checkAllIds);

        if (tmpSelectIds.includes(id)) {
          tmpSelectIds = (tmpSelectIds).filter(c => (c !== id));
          tmpCheckAllIds = false;
        } else {
          tmpSelectIds.push(id);
        }

        updatedState = { selectedCriteriaIds: tmpSelectIds, checkAllIds: tmpCheckAllIds };

        break;

      case "all":
        updatedState = {
          selectedCriteriaIds: (checkAllIds === true) ? [] : _map(criteriaListData, "id"),
          checkAllIds: !checkAllIds
        };
        break;

      default:
        break;
    }


    this.setState(updatedState);
  }

  async _assignIdsToBriefs() {
    const { selectedBriefIds, updateLoadingState, intl } = this.props;
    const { selectedCriteriaIds } = this.state;

    if ((selectedCriteriaIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.job_functions", defaultMessage: "job function" }) }
      ));
      return false;
    }

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const payload = {
        "brief_ids": selectedBriefIds,
        "criteria_values": selectedCriteriaIds,
        "criteria_name": "job_function"
      };

      const response = await assignBriefCriteria(payload);

      if ((response.flag || false) === true) {

        showAlertMessage(_get(response, "data.message", "Job functions assigned to brief successfully."), "success");

        this._closeModal();
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while assigning briefs."));
      }
    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while assigning briefs."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderModalContent = () => {
    const { /*source = "campaign_setup",*/ intl } = this.props;
    const { showModal, criteriaListData, selectedCriteriaIds, checkAllIds } = this.state;

    return (
      <CustomModal
        isOpen={showModal}
        className="text-capitalize"
        modalTitle={(intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.modal.title", defaultMessage: "assign {dataType} to briefs" },
          { dataType: intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.job_functions", defaultMessage: "job functions" }) }))}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
      >
        <form className="modalForm assign-brief-modal">

          {/*(source === "edit_campaign") && (
            <>
              <div className="form-check custom-checkbox">
                <input
                  type="checkbox"
                  id="re-check-data-validation"
                  className="form-check-input"
                  checked={(recheckDataValidation || false)}
                  value={(recheckDataValidation || false)}
                  onChange={() => this.setState({ recheckDataValidation: !recheckDataValidation })}
                />
                <label className="form-check-label text-capitalize" htmlFor="re-check-data-validation">
                  <FormattedMessage id="campaign_edit.modal.recheck_data_validation" defaultMessage="recheck data validation" />
                </label>
              </div>

              <div className="deviderLineBottomMargin m-b-10" />
            </>
          )*/}

          <div className="searchOverview mb-3">
            <input
              className="form-control fuzzy-search"
              type="text"
              name="search"
              placeholder={intl.formatMessage({ id: "search", defaultMessage: "search" })}
              onChange={(e) => {
                const { target: { value } } = e;

                this.setState({ searchText: value });
              }}
            />
          </div>
          <div>
            <div className="form-check custom-checkbox mb-2" onClick={(e) => this._selectIds(null, "all")}>
              <input
                type="checkbox"
                className="form-check-input"
                name="outcome"
                key="check-all-briefs"
                checked={checkAllIds}
                onChange={() => { }}
              />
              <label className="form-check-label text-capitalize" htmlFor="check-all-briefs">
                <FormattedMessage id="placeholder.select_all" defaultMessage="select all" />
              </label>
            </div>
          </div>

          <div className="assign-brief-container">
            <>
              {(criteriaListData).map((l) => {
                const id = (l.id || null);

                return (
                  <div key={`main-dev-${id}`} onClick={(e) => this._selectIds(id)}>
                    <div className="form-check custom-checkbox mb-2">
                      <input
                        key={`checkbox-${id}`}
                        type="checkbox"
                        className="form-check-input"
                        name="outcome"
                        checked={((selectedCriteriaIds || []).includes(id))}
                        onChange={() => { }}
                        value={id}
                      />
                      <label className="form-check-label" htmlFor={`checkbox-${id}`}>{(l.name || "")}</label>
                    </div>
                  </div>
                );
              })}
            </>
          </div>


        </form>

        <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
          <button className="btn btn-secondary" onClick={() => this._closeModal()} >
            <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
          </button>
          <button className="btn btn-primary text-capitalize" onClick={() => this._assignIdsToBriefs()}>
            <FormattedMessage id="campaign_setup.campaign_brief.assign.data_type" defaultMessage="assign {dataType}" values={{ dataType: "job functions" }} />
          </button>
        </div>
      </CustomModal>
    );
  }

  render() {

    return (
      <>

        {this._renderModalContent()}

        <Dropdown.Item className="dropdown-item d-block waves-effect text-capitalize" onClick={() => this._showModal()} >
          <FormattedMessage id="campaign_setup.campaign_brief.assign.data_type" defaultMessage="assign {dataType}" values={{ dataType: "job functions" }} />
        </Dropdown.Item>

      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(null, mapDispatchToProps)(injectIntl(AssignJobFunctionsToBrief));
