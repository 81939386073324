import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { showAlertMessage, Select, ErrorMessage } from "../../../../../resusableComponents/common/formElements";
import { hasAccess } from "../../../../../resusableComponents/hoc/AccessManagement";
import { isDomainNameValid } from "../../../../../helpers/utils"

import { doCreateClient, clearCreateClientResponse } from "../../../../../redux/actions/userMangement"
import { doGetAgencyList } from "../../../../../redux/services/userMangement";

class CreateNewClient extends Component {

  constructor(props) {
    super(props);

    this.defaultState = {
      clientName: "",
      websiteUrl: "",
      selectedAgency: null,
      formFile: ""
    }

    this.state = {
      formErrors: {},
      formData: { ...this.defaultState },
      isFormSubmitted: false,
      agencyListDropDownData: [],
    };
  }

  // handleFileUpload = async (e) => {
  //   let file = _get(e, "target.files[0]", null);

  //   this.setState({
  //     formFile: file
  //   });
  // }

  componentDidMount() {
    this._fetchAgencyList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevState.formData, this.state.formData) && (_get(this.state, "isFormSubmitted", false) === true)) {
      this._handleValidation();
    }

    if (!_isEqual(prevProps.createClientResponse, this.props.createClientResponse) && !_isEmpty(this.props.createClientResponse)) {
      const { createClientResponse, clearCreateClientResponse } = this.props;

      if ((_get(createClientResponse, "flag", null) === true)) {
        showAlertMessage(_get(createClientResponse, "message", "Client created successfully."), "success");

        this.setState({ formData: { ...this.defaultState }, formErrors: {}, isFormSubmitted: false });
      } else if ((_get(createClientResponse, "flag", null) === false)) {
        showAlertMessage(_get(createClientResponse, "message", "Something went wrong while creating client."), "error");
      }

      if (typeof clearCreateClientResponse === "function") { clearCreateClientResponse(); }
    }
  }

  _fetchAgencyList = async () => {

    try {
      const response = await doGetAgencyList({ agency_id: null, search_text: null, dropdown_flag: 1 });

      if ((response.flag || false) === true) {
        this.setState({ agencyListDropDownData: _get(response, "data.responseData.agency_list", []) });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching agency list.")));
        this.setState({ agencyListDropDownData: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching agency list.")));
    }
  };

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { formData: { clientName, websiteUrl, selectedAgency, /* formFile */ } } = this.state;

    let formErrors = {};

    if (_isEmpty(selectedAgency)) {
      formErrors["selectedAgency"] = intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "agency", defaultMessage: "agency" })
      });
    }

    if (_isEmpty(clientName)) {
      formErrors["clientName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.client.client_name", defaultMessage: "client name" })
      });
    }

    if (_isEmpty(websiteUrl)) {
      formErrors["websiteUrl"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.client.website_url", defaultMessage: "website URL" })
      });
    }

    if (!_isEmpty(websiteUrl) && isDomainNameValid(websiteUrl) === false) {
      formErrors["websiteUrl"] = intl.formatMessage({ id: "error.valid", defaultMessage: "Please enter valid {field}." }, {
        field: intl.formatMessage({ id: "user_management.client.website_url", defaultMessage: "website URL" })
      });
    }

    // if (_isEmpty(formFile)) {
    //   formErrors["formFile"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
    //     field: intl.formatMessage({ id: "password", defaultMessage: "password" })
    //   });
    // }

    this.setState({ formErrors });

    if (returnFlag) {
      return (!_isEmpty(formErrors)) ? false : true;
    }
  }

  handleChange = (keyName, keyValue) => {
    this.setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [keyName]: keyValue,
      }
    }));
  }

  _handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { doCreateClient } = this.props;
    const { formErrors, formData: { clientName, websiteUrl, selectedAgency } } = this.state;

    if (!_isEmpty(formErrors)) { return false; }

    this.setState({ isFormSubmitted: true });

    const isValidForm = this._handleValidation(true);

    if (!isValidForm) { return false; }

    if (typeof doCreateClient === "function") {

      let data = new FormData();
      data.append("client_name", (clientName || ""));
      data.append("website", (websiteUrl || ""));
      data.append("agency_id", _get(selectedAgency, "id", null));

      doCreateClient(data);
    }
  }

  _renderTextFiled = ({ label, placeholder, onChange, name, value, isRequired = false }) => {
    const { formErrors } = this.state;

    return (
      <div className="mb-6">
        <label className="form-label text-capitalize">
          {label}
          {(isRequired) && (<span className="text-danger">*</span>)}
        </label>
        <input
          name={name}
          type="text"
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        {_get(formErrors, `${name}`, "") && <ErrorMessage message={_get(formErrors, `${name}`, "")} />}
      </div >
    );
  }

  _renderView = () => {
    const { intl } = this.props;
    const { formErrors, formData: { clientName, websiteUrl, selectedAgency }, agencyListDropDownData } = this.state;

    return (

      <div className="row">

        <div className="col-lg-6">
          {this._renderTextFiled({
            label: intl.formatMessage({ id: "user_management.client.client_name", defaultMessage: "client name" }),
            placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
              field: intl.formatMessage({ id: "user_management.client.client_name", defaultMessage: "client name" })
            }),
            onChange: (event) => this.handleChange("clientName", _get(event, "target.value", "")),
            name: "clientName",
            isRequired: true,
            value: (clientName || "")
          })}
        </div>

        <div className="col-lg-6">
          {this._renderTextFiled({
            label: intl.formatMessage({ id: "user_management.client.website_url", defaultMessage: "website URL" }),
            placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
              field: intl.formatMessage({ id: "user_management.client.website_url", defaultMessage: "website URL", }),
            }),
            onChange: (event) => this.handleChange("websiteUrl", _get(event, "target.value", "")),
            name: "websiteUrl",
            isRequired: true,
            value: (websiteUrl || "")
          })}
        </div>

        <div className="col-lg-6">
          <div className="mb-6">
            <label className="form-label text-capitalize">
              <FormattedMessage id="user_management.client.agency_option" defaultMessage="Select Agency" />
              <span className="text-danger">*</span>
            </label>
            <Select
              className="form-custom-select"
              placeholder={intl.formatMessage({ id: "user_management.client.agency_option", defaultMessage: "Select Agency" })}
              options={(agencyListDropDownData || [])}
              value={(selectedAgency || null)}
              getOptionLabel={(option) => (option.agency_name || "")}
              getOptionValue={(option) => (option.id || null)}
              onChange={(selected) => this.handleChange("selectedAgency", selected)}
              isMulti={false}
            />
            {_get(formErrors, "selectedAgency", "") && <ErrorMessage message={_get(formErrors, "selectedAgency", "")} />}
          </div>
        </div>

        {/* <div className="col-lg-6">
          <div className="mb-6">
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
                <FormattedMessage id="user_management.client.upload_logo" defaultMessage="Upload Logo" />
              </label>
              <input className="form-control" type="file" id="formFile" onChange={(event) => this.handleFileUpload(event)} />
            </div>
          </div>
        </div> */}

        <div className="col-lg-12">
          <div className="text-end">
            <button type="button" className="btn btn-primary text-capitalize" onClick={(e) => this._handleSubmit(e)}>
              <FormattedMessage id="btn.submit" defaultMessage="submit" />
            </button>
          </div>
        </div>
      </div>
    );
  }


  render() {

    return (
      <>
        {hasAccess("admin", "client-management", ["create-client"]) && this._renderView()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  createClientResponse: _get(state, "userManagement.createClient", {}),
});

const mapDispatchToProps = (dispatch) => ({
  doCreateClient: (data) => dispatch(doCreateClient(data)),
  clearCreateClientResponse: () => dispatch(clearCreateClientResponse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateNewClient));
