import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get as _get } from "lodash";

import { showAlertMessage, ConfirmBox } from "./formElements";

import { doMarkAsCompleteCampaign } from "../../redux/services/campaign";
import { updateLoadingState } from "../../redux/actions/application";

class MarkAsButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      updateOperation: ""
    }
  }

  _markAsCompletedConfirmBox = (status) => {
    const { selectedCampaignIds, intl } = this.props;

    if ((selectedCampaignIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "campaign_list.campaign", defaultMessage: "campaign" }) }
      ));
      return false;
    }
    this.setState({ updateOperation: status });
  }

  _onMarkCampaignsAsComplete = async () => {
    const { selectedCampaignIds, updateLoadingState, source = "", setState, intl } = this.props;
    const { updateOperation } = this.state;

    if ((selectedCampaignIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "campaign_list.campaign", defaultMessage: "campaign" }) }
      ));
      return false;
    }

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doMarkAsCompleteCampaign({ campaign_ids: selectedCampaignIds, complete_flag: (updateOperation === "complete") ? true : false });

      if ((response.flag || false) === true) {

        if (updateOperation === "complete") {
          showAlertMessage(_get(response, "message", "Campaign mark as completed successfully."), "success");
        } else if (updateOperation === "live") {
          showAlertMessage(_get(response, "message", "Campaign mark as live successfully."), "success");
        }

        if ((source === "campaign_dashboard") && (typeof setState === "function")) {
          setState({ reloadCampaignDashboard: true });
        } else if ((source === "campaign_list") && (typeof setState === "function")) {
          setState({ reloadCampaignList: true, selectedCampaignIds: [] });
        }
        this.setState({ updateOperation: "" });
      } else {

        if (updateOperation === "complete") {
          showAlertMessage(_get(response, "message", "Something went wrong while marking campaign as completed."));
        } else if (updateOperation === "live") {
          showAlertMessage(_get(response, "message", "Something went wrong while marking campaign as live."));
        }
        this.setState({ updateOperation: "" });
      }
    } catch (err) {

      if (updateOperation === "complete") {
        showAlertMessage(err.message || "Something went wrong while marking campaign as completed.");
      } else if (updateOperation === "live") {
        showAlertMessage(err.message || "Something went wrong while marking campaign as live.");
      }
      this.setState({ updateOperation: "" });
    } finally {

      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  render() {
    const { buttonId, buttonDefultMessage, operationStatus, className = "btn btn-primary", intl } = this.props;
    const { updateOperation } = this.state;

    return (
      <>
        <button className={(className || "")} onClick={() => this._markAsCompletedConfirmBox(operationStatus)}>
          <FormattedMessage id={buttonId} defaultMessage={buttonDefultMessage} />
        </button>

        <ConfirmBox
          isOpen={(updateOperation !== "")}
          content={
            ((updateOperation === "complete"))
              ?
              intl.formatMessage({ id: "confirm.are_you_sure", defaultMessage: "Are you sure want to {field}?" },
                { field: intl.formatMessage({ id: "campaign_list.mark_as_complete_campaign", defaultMessage: "mark this campaign as completed" }) }
              )
              :
              intl.formatMessage({ id: "confirm.are_you_sure", defaultMessage: "Are you sure want to {field}?" },
                { field: intl.formatMessage({ id: "campaign_list.mark_as_live_campaign", defaultMessage: "mark this campaign as live" }) }
              )
          }
          onConfirm={() => this._onMarkCampaignsAsComplete()}
          onClose={() => { this.setState({ updateOperation: "" }); }}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
})

export default connect(null, mapDispatchToProps)(injectIntl(MarkAsButton));
