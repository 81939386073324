import React, { useEffect, useState } from "react";
import { isEmpty as _isEmpty, isEqual as _isEqual, get as _get } from "lodash";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Helmet from "react-helmet";

import { showAlertMessage } from "../../resusableComponents/common/formElements/AlertMessage";
import { hasAccess } from "../../resusableComponents/hoc/AccessManagement";
import { isPasswordValid } from "../../helpers/utils";

import { doPasswordChange, clearUpdateUserPasswordResponse } from "../../redux/actions/user";
import { updateUserAuthenticationData } from "../../redux/actions/authentication";
import { updateLoadingState } from "../../redux/actions/application";

import { doChangeProfileImg } from "../../redux/services/user";

const Settings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const changePasswordResponse = useSelector(({ user }) => (user.changePassword || {}));
  const profilePic = useSelector(({ authentication }) => (authentication.user.profile_pic_url || ""));
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formValues, setFormValues] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: _get(e, "target.value", "")
    })
  };

  useEffect(() => {
    if (formSubmitted) {
      handleValidation();
    }
  }, [formValues, formSubmitted]);

  const handleValidation = (returnFlag = false) => {
    let passwordError = "";
    let confirmPasswordError = "";

    if (_isEmpty(formValues.password)) {
      passwordError = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "password", defaultMessage: "password" })
      });
    }

    if (_isEmpty(formValues.confirmPassword)) {
      confirmPasswordError = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "confirm_password", defaultMessage: "confirm_password" })
      });
    }

    if (!_isEmpty(formValues.password) && !isPasswordValid(formValues.password)) {
      passwordError = intl.formatMessage({ id: "error.password_valid_char", defaultMessage: "Password must be at least 8 characters long and contain an uppercase letter, a number, and a special character." });
    }

    if (!_isEqual(formValues.password, formValues.confirmPassword)) {
      confirmPasswordError = intl.formatMessage({ id: "error.password_not_match", defaultMessage: "Confirm password does not match." });
    }

    setPasswordErrorMessage(passwordError);
    setConfirmPasswordErrorMessage(confirmPasswordError);

    if (returnFlag) {
      return (passwordError || confirmPasswordError) ? false : true;
    }
  };

  useEffect(() => {

    if (_get(changePasswordResponse, "flag", null) === null) { return; }

    const havingError = (_get(changePasswordResponse, "flag", null) === true) ? false : true;
    showAlertMessage(_get(changePasswordResponse, "message", ""), (havingError) ? "error" : "success");

    if (havingError === false) {
      setFormValues({ password: "", confirmPassword: "" });
      setFormSubmitted(false);
    }

    dispatch(clearUpdateUserPasswordResponse());

  }, [changePasswordResponse, dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setFormSubmitted(true);

    if (!_isEmpty(passwordErrorMessage) || !_isEmpty(confirmPasswordErrorMessage)) { return false; }

    const isValidFormData = handleValidation(true);

    if (isValidFormData === false) { return false; }

    const payload = { password: formValues.password }

    try {
      dispatch(doPasswordChange(payload));
    } catch (error) {
      showAlertMessage(error.message || "Something went wrong while changing profile picture.");
    }
  };

  const handleFileUpload = async (e) => {
    let file = _get(e, "target.files[0]", null);

    if (file === null) {
      showAlertMessage("Please select at least one file.", "error");
      return;
    }
    if (!["image/jpg", "image/jpeg", "image/png"].includes((file.type || ""))) {
      showAlertMessage("please select valid image file.");
      return;
    }

    let data = new FormData();
    data.append("formFile", file);

    try {
      dispatch(updateLoadingState(true));

      const response = await doChangeProfileImg(data);

      if (response.flag === true) {
        showAlertMessage(_get(response, "message", "Image Uploaded SuccessFully."), "success");
        dispatch(updateUserAuthenticationData((response.data || {})));
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while updating profile image."));
      }
    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while updating profile image."));
    } finally {
      dispatch(updateLoadingState(false));
    }
  };

  const _renderChangePassword = () => {

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h2 className="heading-04 fw-semibold text-dark-35 mb-8">
                <FormattedMessage id="profile.change_password" defaultMessage="Change Password" />
              </h2>
              <form className="row" onSubmit={(e) => handleSubmit(e)} >
                <div className="col-lg-6">
                  <div className="mb-5">
                    <label className="form-label">
                      <FormattedMessage id="profile.password" defaultMessage="Password" />
                    </label>
                    <input
                      name="password"
                      type="password"
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={_get(formValues, "password", "")}
                    />
                    {(((passwordErrorMessage || "") !== "") &&
                      <div className="invalid-feedback d-block">
                        {(passwordErrorMessage || "")}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-5">
                    <label className="form-label">
                      <FormattedMessage id="profile.confirm_password" defaultMessage="Confirm Password" />
                    </label>
                    <input
                      name="confirmPassword"
                      type="password"
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={formValues.confirmPassword}
                    />
                    {(((confirmPasswordErrorMessage || "") !== "") &&
                      <div className="invalid-feedback d-block">
                        {(confirmPasswordErrorMessage) || ""}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="text-end mt-7">
                    <button type="submit" className="btn btn-primary">
                      <FormattedMessage id="btn.reset" defaultMessage="Reset" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const _renderProfilePicture = () => {
    const profilePicURL = (profilePic) ? profilePic : require("../../assets/images/user.png");

    return (
      <div className="row mt-5">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h2 className="heading-04 fw-semibold text-dark-35 mb-8">
                <FormattedMessage id="profile.profile_image" defaultMessage="Profile Image" />
              </h2>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-12">
                    <div className="profile-image">
                      <img src={(profilePicURL || "")} className="img-fluid" alt="User" height="75" width="75" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-5">
                    <label htmlFor="formFile" className="form-label">
                      <FormattedMessage id="profile.upload_photo" defaultMessage="Upload Photo" />
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      id="formFile"
                      value=""
                      onChange={(e) => handleFileUpload(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title> {intl.formatMessage({ id: "hermes.settings", defaultMessage: "Settings: Hermes" })} </title>
      </Helmet>

      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 px-6 py-6">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="heading-04 fw-semibold text-dark-35 text-uppercase mb-5">
                <FormattedMessage id="profile.title" defaultMessage="settings" />
              </h1>
            </div>
          </div>

          {(hasAccess("settings", "index", ["change-password"]) === true) && _renderChangePassword()}

          {(hasAccess("settings", "index", ["profile-image"]) === true) && _renderProfilePicture()}
        </div>
      </div>
    </>
  )
}

export default injectIntl(Settings);
