import { call, fork, put, takeLatest } from "redux-saga/effects";
import { get as _get } from "lodash";

import {
  FETCH_CAMPAIGN_DASHBOARD_DATA_REQUEST, FETCH_CAMPAIGN_DASHBOARD_DATA_SUCCESS, FETCH_CAMPAIGN_DASHBOARD_DATA_ERROR,
  FETCH_CAMPAIGN_EDIT_BRIEFS_REQUEST, FETCH_CAMPAIGN_EDIT_BRIEFS_SUCCESS, FETCH_CAMPAIGN_EDIT_BRIEFS_ERROR,
  GET_CAMPAIGN_LIST_REQUEST, GET_CAMPAIGN_LIST_SUCCESS, GET_CAMPAIGN_LIST_ERROR,
  FETCH_CAMPAIGN_LEAD_DETAILS_REQUEST, FETCH_CAMPAIGN_LEAD_DETAILS_SUCCESS, FETCH_CAMPAIGN_LEAD_DETAILS_ERROR,
} from "../actions/campaign";
import { UPDATE_APP_LOADING } from "../actions/application";

import { fetchCampaignDashboardData, doGetCampaignList, fetchCampaignLeadDetails } from "../services/campaign";
import { fetchCampaignBriefs } from "../services/campaignSetup";

function* workerCampaignDashboardData(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(fetchCampaignDashboardData, action.payload);

    if ((response.flag || false) === true) {

      yield put({
        type: FETCH_CAMPAIGN_DASHBOARD_DATA_SUCCESS,
        data: {
          flag: true,
          data: _get(response, "data", {}),
          message: _get(response, "message", "Campaign dashboard data fetched successfully."),
        }
      });
    } else {
      yield put({
        type: FETCH_CAMPAIGN_DASHBOARD_DATA_ERROR,
        error: {
          message: _get(response, "message", "Something went wrong while fetching campaign dashboard data."),
        }
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_CAMPAIGN_DASHBOARD_DATA_ERROR,
      error: {
        message: _get(error, "response.message", "Something went wrong while fetching campaign dashboard data."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerDoGetCampaignList(action) {

  try {

    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(doGetCampaignList, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: GET_CAMPAIGN_LIST_SUCCESS,
        data: (response.data || {}),
        message: (response.message || ""),
      });
    } else {
      yield put({
        type: GET_CAMPAIGN_LIST_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          data: {},
          message: _get(response, "message", "Something went wrong while fetching campaign list."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: GET_CAMPAIGN_LIST_ERROR,
      error: {
        data: {},
        message: _get(error, "response.message", "Something went wrong while fetching campaign list."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerFetchCampaignEditBriefs(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(fetchCampaignBriefs, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_CAMPAIGN_EDIT_BRIEFS_SUCCESS,
        data: (response.data || []),
        message: (response.message || ""),
      });
    } else {
      yield put({
        type: FETCH_CAMPAIGN_EDIT_BRIEFS_ERROR,
        error: {
          data: {},
          message: _get(response, "message", "Something went wrong while fetching campaign briefs."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_CAMPAIGN_EDIT_BRIEFS_ERROR,
      error: {
        data: {},
        message: _get(error, "response.message", "Something went wrong while fetching campaign briefs."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* workerfEtchCampaignLeadDetails(action) {
  try {
    yield put({ type: UPDATE_APP_LOADING, loading: true });

    const response = yield call(fetchCampaignLeadDetails, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_CAMPAIGN_LEAD_DETAILS_SUCCESS,
        data: (response.data || []),
        message: (response.message || ""),
      });
    } else {
      yield put({
        type: FETCH_CAMPAIGN_LEAD_DETAILS_ERROR,
        error: {
          data: {},
          message: _get(response, "message", "Something went wrong while fetching campaign leads."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_CAMPAIGN_LEAD_DETAILS_ERROR,
      error: {
        data: {},
        message: _get(error, "response.message", "Something went wrong while fetching campaign leads."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APP_LOADING, loading: false });
  }
}

function* watcherFetchCampaignDashboardData() {
  yield takeLatest(FETCH_CAMPAIGN_DASHBOARD_DATA_REQUEST, workerCampaignDashboardData);
}

function* watcherDoGetCampaignList() {
  yield takeLatest(GET_CAMPAIGN_LIST_REQUEST, workerDoGetCampaignList);
}

function* watcherFetchCampaignEditBriefs() {
  yield takeLatest(FETCH_CAMPAIGN_EDIT_BRIEFS_REQUEST, workerFetchCampaignEditBriefs);
}

function* watcherFetchCampaignLeadDetails() {
  yield takeLatest(FETCH_CAMPAIGN_LEAD_DETAILS_REQUEST, workerfEtchCampaignLeadDetails);
}

const userSaga = [
  fork(watcherFetchCampaignDashboardData),
  fork(watcherDoGetCampaignList),
  fork(watcherFetchCampaignEditBriefs),
  fork(watcherFetchCampaignLeadDetails),
]

export default userSaga;
