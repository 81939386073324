import React, { Component } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { get as _get } from "lodash";
import { connect } from "react-redux";

import { showAlertMessage, Select, ConfirmBox } from "../../../resusableComponents/common/formElements";
import { hasAccess } from "../../../resusableComponents/hoc/AccessManagement";

import { getLeadColumnList, getLeadColumnMasterList, updateLeadColumnValue, deleteSelectedLeads } from "../../../redux/services/campaign";
import { updateLoadingState } from "../../../redux/actions/application";

class UpdateLeadColumn extends Component {

  constructor(props) {
    super(props);

    this.defaultColumnState = { friendly_name: "", id: 0 };
    this.defaultColumnAndValueState = { label: "", value: "" };

    this.state = {
      leadsFilter: {},
      columnList: [],
      columnMasterValueList: [],
      showUpdateDropdown: false,
      showDeleteConfirmBox: false,
      showList: 0,
      selectedColumn: { ...this.defaultColumnState },
      selectedColumnValue: { ...this.defaultColumnAndValueState },
    };
  }

  componentDidMount() {
    this._getFilterValues();
  }

  _getFilterValues = async () => {
    const { updateLoadingState } = this.props;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getLeadColumnList();

      if (_get(response, "flag", false) === true) {

        this.setState({ columnList: _get(response, "data", []), columnMasterValueList: [] });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching filter values.")));
        this.setState({ columnList: [], columnMasterValueList: [] });
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching filter values.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _setSelectedColumnOrFetchList = async (selectedOption) => {
    const { updateLoadingState } = this.props;

    const showList = _get(selectedOption, "show_list", 0);

    if (showList > 0) {
      try {
        if (typeof updateLoadingState === "function") { updateLoadingState(true); }

        const response = await getLeadColumnMasterList(_get(selectedOption, "id", 0));

        if (_get(response, "flag", false) === true) {

          this.setState({
            showList: showList,
            selectedColumn: selectedOption,
            columnMasterValueList: _get(response, "data", []),
            selectedColumnValue: { ...this.defaultColumnAndValueState },
          });
        } else {
          showAlertMessage((_get(response, "message", "Something went wrong while fetching column master list.")));

          this.setState({ showList: showList, selectedColumn: selectedOption, columnMasterValueList: [], selectedColumnValue: { ...this.defaultColumnAndValueState }, });
        }
      } catch (error) {
        showAlertMessage((_get(error, "message", "Something went wrong while fetching column master list.")));
      } finally {
        if (typeof updateLoadingState === "function") { updateLoadingState(false); }
      }
    } else {
      this.setState({ showList: showList, selectedColumn: selectedOption, columnMasterValueList: [], selectedColumnValue: { ...this.defaultColumnAndValueState } });
    }
  }

  _handleClosePopover = (reloadTable = false) => {
    const { onClose } = this.props;

    if (typeof onClose === "function") { onClose(reloadTable); }

    this.setState({
      showList: 0,
      showUpdateDropdown: false,
      showDeleteConfirmBox: false,
      selectedColumn: { ...this.defaultColumnState },
      selectedColumnValue: { ...this.defaultColumnAndValueState },
    });
  }

  _handleUpdateBtn = async () => {
    const { selectedLeadIds, campaignId, updateLoadingState, intl } = this.props;
    const { selectedColumn, selectedColumnValue } = this.state;

    if ((selectedLeadIds || []).length !== 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_single", defaultMessage: "Please select single {field}." }, {
        field: intl.formatMessage({ id: "lead", defaultMessage: "lead" })
      }));
      return false;

    } else if (_get(selectedColumn, "id", 0) === 0) {
      showAlertMessage(intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
        field: intl.formatMessage({ id: "view_leads.update.column", defaultMessage: "column" })
      }));

      return false;
    } else if ((_get(selectedColumnValue, "value", "").trim()) === "") {
      showAlertMessage(intl.formatMessage({ id: "error.valid", defaultMessage: "Please enter valid {field}." }, {
        field: intl.formatMessage({ id: "view_leads.update.value", defaultMessage: "value" })
      }));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await updateLeadColumnValue(_get(selectedLeadIds, "[0]", 0), {
        "campaign_id": campaignId,
        "column_id": _get(selectedColumn, "id", 0),
        "column_value": _get(selectedColumnValue, "value", "").trim()
      });

      if (_get(response, "flag", false) === true) {
        showAlertMessage(_get(response, "message", "Lead updated successfully."), "success");

        this._handleClosePopover(true);
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while updating lead details.")));
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while updating lead details.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleDeleteBtn = async () => {
    const { selectedLeadIds, campaignId, updateLoadingState, intl } = this.props;

    if ((selectedLeadIds || []).length !== 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_single", defaultMessage: "Please select single {field}." }, {
        field: intl.formatMessage({ id: "lead", defaultMessage: "lead" })
      }));

      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await deleteSelectedLeads({ "lead_ids": selectedLeadIds, "campaign_id": campaignId });

      if (_get(response, "flag", false) === true) {
        showAlertMessage(_get(response, "message", "Lead deleted successfully."), "success");

        this._handleClosePopover(true);
      } else {
        this.setState({ showDeleteConfirmBox: false });
        showAlertMessage((_get(response, "message", "Something went wrong while deleting lead.")));
      }
    } catch (error) {
      this.setState({ showDeleteConfirmBox: false });
      showAlertMessage((_get(error, "message", "Something went wrong while deleting lead.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderUpdateDropDown = () => {
    const { selectedLeadIds, intl } = this.props;
    const { columnList, columnMasterValueList, showList, showUpdateDropdown, selectedColumn, selectedColumnValue } = this.state;

    const imgName = (showUpdateDropdown) ? require(`../../../assets/icons/icon-chev-down-primary.svg`) : require(`../../../assets/icons/icon-chev-up-primary.svg`);

    return (
      <Popover className="popover-dropdown popover-dropdown-viewlead">
        <div className="popover-body">
          <ul className="nestedtree list-unstyled mb-0">
            <li className="mb-2">
              <div className="d-flex align-items-center gap-2 mb-2">
                <img src={(imgName).default} onClick={() => this.setState({ showUpdateDropdown: !showUpdateDropdown })} className="img-fluid" alt="img" />
                <label className="form-label heading-04 text-capitalize d-block cursor-pointer mb-0" onClick={() => this.setState({ showUpdateDropdown: !showUpdateDropdown })}>
                  <FormattedMessage id="btn.edit" defaultMessage="Edit" />
                </label>
              </div>

              <ul className={`pl-0 list-unstyled ${(showUpdateDropdown === false && "d-none")}`} >
                <li className="mb-2">
                  <div className="row no-gutters">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label text-capitalize d-block">
                          <FormattedMessage id="view_leads.update.column" defaultMessage="column" />
                        </label>
                        <Select
                          isMulti={false}
                          getOptionLabel={(option) => (option.friendly_name || "")}
                          getOptionValue={(option) => (option.id || "")}
                          value={(selectedColumn || {})}
                          options={(columnList || [])}
                          onChange={(values) => this._setSelectedColumnOrFetchList(values)}
                          className="form-custom-select"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label text-capitalize d-block">
                          <FormattedMessage id="view_leads.update.value" defaultMessage="Value" />
                        </label>
                        {(showList > 0) ? (
                          <Select
                            isMulti={false}
                            className="form-custom-select"
                            getOptionLabel={(option) => (option.label || "")}
                            getOptionValue={(option) => (option.value || "")}
                            value={(selectedColumnValue || {})}
                            options={(columnMasterValueList || [])}
                            onChange={(values) => this.setState({ selectedColumnValue: values })}
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            value={_get(selectedColumnValue, "value", "")}
                            onChange={(e) => {
                              const inputValue = _get(e, "currentTarget.value", "");
                              this.setState({ selectedColumnValue: { label: inputValue, value: inputValue } });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {(hasAccess("campaigns", "view-leads", ["update"]) === true) && (
                    <div className="row no-gutters">
                      <div className="col-lg-12">
                        <button type="button" className="btn btn-success" onClick={(e) => this._handleUpdateBtn(e)}>
                          <FormattedMessage id="btn.update" defaultMessage="Update" />
                        </button>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            </li>
            <li className="border-bottom pt-2 mb-4" />
            <li>
              <div className="d-flex justify-content-between">
                {(hasAccess("campaigns", "view-leads", ["delete"]) === true) && (
                  <span
                    className="btn btn-danger"
                    onClick={(e) => {
                      if ((selectedLeadIds || []).length !== 1) {
                        showAlertMessage(intl.formatMessage({ id: "error.select_single", defaultMessage: "Please select single {field}." }, {
                          field: intl.formatMessage({ id: "lead", defaultMessage: "lead" })
                        }));

                        return false;
                      }

                      this.setState({ showDeleteConfirmBox: true });
                    }}>
                    <FormattedMessage id="btn.delete" defaultMessage="Delete" />
                  </span>
                )}
                <button type="button" className="btn btn-secondary" onClick={() => this._handleClosePopover()}>
                  <FormattedMessage id="btn.close" defaultMessage="Close" />
                </button>
              </div>
            </li>
          </ul>
        </div>
      </Popover>
    )
  }

  render() {
    const { intl } = this.props;
    const { showDeleteConfirmBox } = this.state;

    return (
      <>
        {(hasAccess("campaigns", "view-leads", ["update"]) === true) && (
          <div className="col-lg-3">
            <label className="form-label text-capitalize d-block">
              <FormattedMessage id="view_leads.filter.update" defaultMessage="update" />
            </label>
            <OverlayTrigger trigger="click" rootClose={true} placement="bottom-end" overlay={this._renderUpdateDropDown()}>
              <button className="form-select text-capitalize text-start" type="button">
                <FormattedMessage id="view_leads.filter.update" defaultMessage="update" />
              </button>
            </OverlayTrigger>
          </div>
        )}

        {(hasAccess("campaigns", "view-leads", ["delete"]) === true) && (
          <ConfirmBox
            isOpen={showDeleteConfirmBox}
            content={intl.formatMessage({ id: "confirm.delete_leads", defaultMessage: "Are you sure you want to delete selected leads?" })}
            onConfirm={() => this._handleDeleteBtn()}
            onClose={() => this.setState({ showDeleteConfirmBox: false })}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "dd/MM/yyyy"),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UpdateLeadColumn));
