import { combineReducers } from "redux";
import { get as _get } from "lodash";
import actionTypes from "../actions";

const initialChangePassword = { flag: null, data: {}, message: "" };
const changePassword = (state = initialChangePassword, action) => {
  switch (action.type) {

    case actionTypes.CHANGE_USER_PASSWORD_SUCCESS:
      return {
        flag: _get(action, "data.flag", true),
        data: _get(action, "data.data", {}),
        message: _get(action, "data.message", "")
      };

    case actionTypes.CHANGE_USER_PASSWORD_ERROR:
      return {
        ...initialChangePassword,
        flag: false,
        message: _get(action, "error.message", "")
      };

    case actionTypes.CLEAR_CHANGE_PASSWORD_RESPONSE:
      return { ...initialChangePassword };

    default:
      return state;
  }
};

export default {
  user: combineReducers({
    changePassword,
  }),
};
