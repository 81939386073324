import { get as _get } from "lodash";
import api from "../../helpers/api";

export const doPasswordChange = (variables) => {
  return api().patch("/user/password", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "data.responseMessage", "Something went wrong while changing password."),
      data: {},
    };
  });
};

export const doChangeProfileImg = async (variables) => {
  return api().patch("/user/profile_image", variables).then((res) => {
    return {
      flag: true,
      message: _get(res, "data.responseMessage", ""),
      data: _get(res, "data.responseData", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      message: _get(err, "data.responseMessage", "Something went wrong while uploading profile image."),
      data: {},
    };
  });
}
