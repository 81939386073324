// Update User in redux action
export const UPDATE_USER = "UPDATE_USER";

// Update User in redux action
export const CHANGE_USER_PASSWORD_REQUEST = "CHANGE_USER_PASSWORD_REQUEST";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_ERROR = "CHANGE_USER_PASSWORD_ERROR";

//clear change password error
export const CLEAR_CHANGE_PASSWORD_RESPONSE = "CLEAR_CHANGE_PASSWORD_RESPONSE";

export const doPasswordChange = (data) => {
  return ({
    type: CHANGE_USER_PASSWORD_REQUEST,
    data
  })
}

export const clearUpdateUserPasswordResponse = () => {
  return ({
    type: CLEAR_CHANGE_PASSWORD_RESPONSE
  });
}
