import { combineReducers } from "redux";
import { cloneDeep as _cloneDeep, get as _get } from "lodash";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import configs from "../../helpers/config";

import actionTypes from "../actions";

const persistCampaignConfig = {
  key: `${configs.store_name}__campaign_setup`,
  storage,
};

const initiateSummary = {
  "common": {
    agencyId: null,
    campaignName: "",
    campaignId: null,
    clientId: null,
    currentStep: 1,
    totalStep: 8,
    lastFilledStep: 1
  }
}

//Campaign setup > Summary
const summary = (state = initiateSummary, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_ASSIGNED_SUPPRESSION_LIST_SUCCESS:
    case actionTypes.REMOVE_ASSIGNED_ACCOUNT_LIST_SUCCESS:
    case actionTypes.ASSIGN_EXISTING_ACCOUNTS_SUCCESS:
    case actionTypes.ASSIGN_EXISTING_SUPPRESSION_SUCCESS:
    case actionTypes.REMOVE_ASSIGNED_ASSETS_SUCCESS:
    case actionTypes.SAVE_CAMPAIGN_DETAILS_SUCCESS:
    case actionTypes.UPDATE_CAMPAIGN_SETUP_DETAILS:
    case actionTypes.ASSIGN_ASSET_CAMPAIGN_SUCCESS:
    case actionTypes.SAVE_REQUIRED_FIELDS_SUCCESS:
    case actionTypes.INITIATE_ASSET_CAMPAIGN_SUCCESS:
      const currentState = _cloneDeep(state);
      return Object.assign({}, currentState, action.payload);

    case actionTypes.AUTHENTICATION_LOGIN_SUCCESS:
    case actionTypes.RESET_CAMPAIGN_SETUP:
      return { ...initiateSummary };

    case actionTypes.DELETE_AUTHENTICATION_TOKEN_REQUEST:
      return {};

    default:
      return state;
  }
}

const setupErrors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_ASSIGNED_SUPPRESSION_LIST_ERROR:
    case actionTypes.REMOVE_ASSIGNED_ACCOUNT_LIST_ERROR:
    case actionTypes.ASSIGN_EXISTING_ACCOUNTS_ERROR:
    case actionTypes.ASSIGN_EXISTING_SUPPRESSION_ERROR:
    case actionTypes.REMOVE_ASSIGNED_ASSETS_ERROR:
    case actionTypes.SAVE_CAMPAIGN_DETAILS_ERROR:
    case actionTypes.ASSIGN_ASSET_CAMPAIGN_ERROR:
    case actionTypes.INITIATE_ASSET_CAMPAIGN_ERROR:
    case actionTypes.SAVE_REQUIRED_FIELDS_ERROR:
    case actionTypes.INITIATE_CAMPAIGN_ERROR:
      return (action.error || {});

    case actionTypes.RESET_CAMPAIGN_SETUP:
    case actionTypes.RESET_CAMPAIGN_SETUP_ERROR:
      return {};

    default:
      return state;
  }
}

const setupMetaErrors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_ASSIGNED_SUPPRESSION_LIST_ERROR:
    case actionTypes.REMOVE_ASSIGNED_ACCOUNT_LIST_ERROR:
    case actionTypes.ASSIGN_EXISTING_ACCOUNTS_ERROR:
    case actionTypes.ASSIGN_EXISTING_SUPPRESSION_ERROR:
    case actionTypes.REMOVE_ASSIGNED_ASSETS_ERROR:
    case actionTypes.SAVE_CAMPAIGN_DETAILS_ERROR:
    case actionTypes.ASSIGN_ASSET_CAMPAIGN_ERROR:
    case actionTypes.INITIATE_ASSET_CAMPAIGN_ERROR:
    case actionTypes.INITIATE_CAMPAIGN_ERROR:
      return _get(action, "error.errors", {});

    case actionTypes.RESET_CAMPAIGN_SETUP:
    case actionTypes.RESET_CAMPAIGN_SETUP_ERROR:
      return {};

    default:
      return state;
  }
}

const initialMapping = { showModal: false, data: {}, success: {}, successMessage: "" };
const dataMapping = (state = initialMapping, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_READ_FILE_SUCCESS:
      const showModal = _get(action, "payload.showModel", true)
      return { showModal, data: _get(action, "payload.data", {}), success: {}, successMessage: "" };

    case actionTypes.SAVE_UPLOAD_MAPPING_DATA_SUCCESS:
      return { showModal: false, data: {}, success: _get(action, "payload.data", {}), successMessage: _get(action, "payload.message", {}) };

    case actionTypes.RESET_DATA_MAPPING_CONFIG:
    case actionTypes.RESET_CAMPAIGN_SETUP:
      return { ...initialMapping };

    default:
      return state;
  }
}

const dataMappingErrors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_READ_FILE_ERROR:
      return (action.payload || {});

    case actionTypes.SAVE_UPLOAD_MAPPING_DATA_ERROR:
      return (action.error || {});

    case actionTypes.RESET_DATA_MAPPING_CONFIG:
    case actionTypes.RESET_DATA_MAPPING_ERROR:
    case actionTypes.RESET_CAMPAIGN_SETUP:
      return {};

    default:
      return state;
  }
}

const fileUpload = combineReducers({
  dataMapping,
  errors: dataMappingErrors
});

const initialRemoveUploadState = { status: null, message: "" };
const removeUpload = (state = initialRemoveUploadState, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_UPLOADED_FILE_SUCCESS:
      return { status: true, message: _get(action, "payload.message", "") };

    case actionTypes.REMOVE_UPLOADED_FILE_ERROR:
      return { status: false, message: _get(action, "error.message", "") };

    case actionTypes.RESET_REMOVE_UPLOADED_FILE:
      return initialRemoveUploadState;

    default:
      return state;
  }
}

const initialBriefs = { flag: null, data: [], message: "" };
const briefs = (state = initialBriefs, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGN_BRIEFS_SUCCESS:
      return (action.data || []);

    case actionTypes.FETCH_CAMPAIGN_BRIEFS_ERROR:
      return { ...initialBriefs, flag: false, message: _get(action, "error.message", "") };

    default:
      return state;
  }
}

const setupInitiated = (state = false, action) => {
  switch (action.type) {
    case actionTypes.INITIATE_CAMPAIGN_SUCCESS:
      return true;

    case actionTypes.RESET_CAMPAIGN_SETUP:
      return false;

    default:
      return state;
  }
}

export default {
  campaignSetup: combineReducers({
    summary: persistReducer(persistCampaignConfig, summary),
    setupErrors,
    setupMetaErrors: setupMetaErrors,
    fileUpload,
    removeUpload,
    briefs,
    setupInitiated
  })
}
