import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const CustomPagination = ({ rowsPerPage, rowCount, currentPage, onChangePage }) => {
  const totalPages = Math.ceil(rowCount / rowsPerPage);

  const getPageRange = () => {
    const pageRange = [];
    const maxPageRange = Math.min(totalPages, 5);
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(currentPage + 1, totalPages);

    while (pageRange.length < maxPageRange && startPage <= endPage) {
      pageRange.push(startPage);
      startPage++;
    }

    return pageRange;
  };

  const handlePageChange = (newPage) => {
    onChangePage(newPage);
  }

  return (
    <>
      <div className="row align-items-center g-0 mt-5">
        <div className="col-lg-5">
          <p className="heading-06 fw-semibold text-grey-8e mt-lg-0 mt-3 text-lg-start text-center text-capitalize-first">
            <FormattedMessage id="showing_records_of_total" defaultMessage="Showing {currentPage} out of {totalPages}" values={{ currentPage, totalPages }} />
          </p>
        </div>
        <div className="col-lg-7">
          <ul className="pagination justify-content-lg-end justify-content-center mt-lg-0 mt-5">
            <li className="page-item">
              <button
                className="page-link cursor-pointer"
                disabled={(currentPage === 1) ? true : false}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <img src={require("../../assets/icons/icon-chev-left.svg").default} className="img-fluid" alt="img" />
              </button>
            </li>

            {getPageRange().map((pageNumber) => (
              <li key={pageNumber} className="page-item cursor-pointer">
                <span
                  className={`page-link ${(currentPage === pageNumber) ? "active" : ""}`}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {(pageNumber || 1)}
                </span>
              </li>
            ))}

            <li className="page-item">
              <button
                className="page-link cursor-pointer"
                aria-label="Next" disabled={(currentPage === totalPages) ? true : false}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <img src={require("../../assets/icons/icon-chev-right.svg").default} className="img-fluid" alt="img" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

CustomPagination.propTypes = {
  rowCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
};

export default CustomPagination;
