import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

import { CustomModal, showAlertMessage, DatePicker, ErrorMessage } from "../common/formElements";

import { assignDatesToBrief } from "../../redux/services/campaignSetup";

import { updateLoadingState } from "../../redux/actions/application";

class AssignDatesToBrief extends Component {

  constructor(props) {
    super(props);

    this.defaultState = {
      setStartDate: false,
      setEndDate: false,
      startDate: "",
      endDate: "",
      errors: {},
    };

    this.state = {
      ...this.defaultState,
      showModal: false
    };
  }

  componentDidUpdate(prevProps, prevStates) {
    if (
      !_isEqual(prevStates.setStartDate, this.state.setStartDate) ||
      !_isEqual(prevStates.setEndDate, this.state.setEndDate) ||
      !_isEqual(prevStates.endDate, this.state.endDate) ||
      !_isEqual(prevStates.startDate, this.state.startDate)
    ) {
      this._handleValidation();
    }
  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { setStartDate, setEndDate, startDate, endDate } = this.state;

    let errors = {};

    if (setStartDate === true && startDate === "") {
      errors["startDate"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_brief.modal.date_start_date", defaultMessage: "start date" })
      });
    }

    if (setEndDate === true && endDate === "") {
      errors["endDate"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "campaign_brief.modal.date_end_date", defaultMessage: "end date" })
      });
    }

    this.setState({ errors: errors });
  }

  _showModal = () => {
    const { selectedBriefIds, intl } = this.props;

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    this.setState({ ...this.defaultState, showModal: true });
  }

  _closeModal = () => {
    const { setState } = this.props;

    this.setState({ ...this.defaultState, showModal: false });

    //Set Parent Component States
    if (typeof setState === "function") { setState({ reloadBriefList: true }); }
  }

  async _assignIdsToBriefs() {
    const { selectedBriefIds, updateLoadingState, intl } = this.props;
    const { errors, setStartDate, setEndDate, startDate, endDate } = this.state;

    if (!_isEmpty(errors)) {
      showAlertMessage(intl.formatMessage({ id: "error.please_fill_up_the_proper_details", defaultMessage: "Please fill up the proper details." }));
      return false;
    }

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const payload = {
        "brief_ids": selectedBriefIds,
        "set_startdate": setStartDate,
        "start_date": (startDate || ""),
        "set_enddate": setEndDate,
        "end_date": (endDate || ""),
      };

      const response = await assignDatesToBrief(payload);

      if ((response.flag || false) === true) {

        showAlertMessage(_get(response, "data.message", "Dates assigned successfully."), "success");

        this._closeModal();
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while assigning to briefs."));
      }

    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while assigning to briefs."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderModalContent = () => {
    const { displayDateFormat, intl } = this.props;
    const { showModal, startDate, endDate, setStartDate, setEndDate, errors } = this.state;

    return (
      <CustomModal
        isOpen={showModal}
        className="text-capitalize"
        bodyClassName="overflow-unset"
        modalTitle={(intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.modal.title", defaultMessage: "assign {dataType} to briefs" },
          { dataType: intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.dates", defaultMessage: "dates" }) }))}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
      >
        <>
          <div className="row mb-4">
            <div className="col-md-5">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="set-start-date"
                  className="form-check-input"
                  name="checkbox"
                  defaultChecked={(setStartDate || false)}
                  onClick={(e) => {
                    const checked = _get(e, "currentTarget.checked", false);

                    this.setState({ setStartDate: checked });
                  }}
                />
                <label className="form-check-label" htmlFor="set-start-date" >
                  <FormattedMessage id="campaign_setup.campaign_brief.assign.start_date" defaultMessage="start date" />
                </label>
              </div>
            </div>
            <div className="col-md-7">
              <DatePicker
                className="form-control"
                placeholderText={intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.start_date_placeholder", defaultMessage: "Choose a start date" })}
                selected={(startDate || null)}
                onChange={(d) => { this.setState({ startDate: moment(d).format() }) }}
                dateFormat={displayDateFormat || "dd/MM/yyyy"}
              />
              {(errors.startDate !== "") && (<ErrorMessage message={(errors.startDate || "")} />)}
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-5">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="set-end-date"
                  className="form-check-input"
                  name="checkbox"
                  defaultChecked={(setEndDate || false)}
                  onClick={(e) => {
                    const checked = _get(e, "currentTarget.checked", false);

                    this.setState({ setEndDate: checked });
                  }}
                />
                <label className="form-check-label" htmlFor="set-end-date" >
                  <FormattedMessage id="campaign_setup.campaign_brief.assign.end_date" defaultMessage="end date" />
                </label>
              </div>
            </div>
            <div className="col-md-7">
              <DatePicker
                className="form-control"
                placeholderText={intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.end_date_placeholder", defaultMessage: "Choose an end date" })}
                selected={(endDate || null)}
                onChange={(d) => this.setState({ endDate: moment(d).format() })}
                dateFormat={displayDateFormat || "dd/MM/yyyy"}
                minDate={(startDate || "")}
              />
              {(errors.endDate !== "") && (<ErrorMessage message={(errors.endDate || "")} />)}
            </div>
          </div>

          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button className="btn btn-secondary" onClick={() => this._closeModal()} >
              <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
            </button>
            <button className="btn btn-primary text-capitalize" onClick={() => this._assignIdsToBriefs()}>
              <FormattedMessage id="campaign_setup.campaign_brief.assign.data_type" defaultMessage="assign {dataType}" values={{ dataType: "dates" }} />
            </button>
          </div>
        </>
      </CustomModal>
    );
  }

  render() {

    return (
      <>

        {this._renderModalContent()}

        <Dropdown.Item className="dropdown-item d-block waves-effect text-capitalize" onClick={() => this._showModal()} >
          <FormattedMessage id="campaign_setup.campaign_brief.assign.data_type" defaultMessage="assign {dataType}" values={{ dataType: "dates" }} />
        </Dropdown.Item>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "")
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignDatesToBrief));
