import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from "react-helmet";

import { hasAccess } from "../../resusableComponents/hoc/AccessManagement";

class AgencyDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showAddCredits: false
    }
  }

  _renderAddCredits = () => {

    return (
      <div className="row" id="addCredits">
        <div className="col-lg-5">
          <div className="mt-6">
            <label className="form-label text-capitalize">
              <FormattedMessage id="agency_dashboard.add_credits" defaultMessage="add credits" />
            </label>
            <div className="input-group">
              <span className="input-group-text text-dark-35 border-grey-cc py-1 text-capitalize">
                <FormattedMessage id="agency_dashboard.placeholder.credits" defaultMessage="credits" />
              </span>
              <input type="text" className="form-control" placeholder="Enter Credits" />
              <button className="input-group-text btn btn-primary text-capitalize">
                <FormattedMessage id="btn.submit" defaultMessage="submit" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderHeader = () => {
    const { showAddCredits } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center gap-5">
                <img src={require("../../assets/images/client01.png")} className="img-fluid" alt="logo" />
                <p className="heading-04 text-uppercase">THE MARKETING PRACTICE</p>

                {(hasAccess("dashboard", "agency", ["add-credit"])) && (
                  <button className="ms-auto ps-3 btn btn-outline-primary text-capitalize" onClick={() => this.setState({ showAddCredits: !showAddCredits })}>
                    <img alt="img" src={require("../../assets/icons/icon-plus.svg").default} className="img-fluid me-5" />
                    <FormattedMessage id="agency_dashboard.add_credits" defaultMessage="add credits" />
                  </button>
                )}

              </div>

              {showAddCredits && this._renderAddCredits()}

            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderCreditSection = () => {

    return (
      <div className="row justify-content-center gy-4 mt-1">
        <div className="col-lg-4">
          <div className="pt-2 pb-5 px-4 bg-primary text-white rounded-1">
            <p className="heading-05 fw-semibold mb-3 text-capitalize">
              <FormattedMessage id="agency_dashboard.total_credits" defaultMessage=" total credits" />
            </p>
            <p className="heading-02 fw-semibold">50,000</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="pt-2 pb-5 px-4 bg-primary text-white rounded-1">
            <p className="heading-05 fw-semibold mb-3 text-capitalize">
              <FormattedMessage id="agency_dashboard.used_credits" defaultMessage="used credits" />
            </p>
            <p className="heading-02 fw-semibold">38,000</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="pt-2 pb-5 px-4 bg-primary text-white rounded-1">
            <p className="heading-05 fw-semibold mb-3 text-capitalize">
              <FormattedMessage id="agency_dashboard.available_credits" defaultMessage="available credits" />
            </p>
            <p className="heading-02 fw-semibold">12,000</p>
          </div>
        </div>
      </div>
    )
  }

  _renderClientAgencyInfo = () => {
    return (
      <>
        <div className="row justify-content-center gy-4 mt-1">
          <div className="col-lg-4">
            <div className="pt-2 pb-5 px-4 bg-light border border-primary text-primary rounded-1">
              <p className="heading-05 fw-semibold mb-3 text-capitalize">
                <FormattedMessage id="agency_dashboard.current_clients" defaultMessage="current clients" />
              </p>
              <p className="heading-02 fw-semibold">7</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pt-2 pb-5 px-4 bg-light border border-primary text-primary rounded-1">
              <p className="heading-05 fw-semibold mb-3 text-capitalize">
                <FormattedMessage id="agency_dashboard.live_campaigns" defaultMessage="live campaigns" />
              </p>
              <p className="heading-02 fw-semibold">1694</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center gy-4 mt-1">
          <div className="col-lg-4">
            <div className="pt-2 pb-5 px-4 bg-light border border-primary text-primary rounded-1">
              <p className="heading-05 fw-semibold mb-3 text-capitalize">
                <FormattedMessage id="agency_dashboard.lead_value_delivered" defaultMessage="lead value delivered" />
              </p>
              <p className="heading-02 fw-semibold">$607,805</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pt-2 pb-5 px-4 bg-light border border-primary text-primary rounded-1">
              <p className="heading-05 fw-semibold mb-3 text-capitalize">
                <FormattedMessage id="agency_dashboard.contract_gross_value" defaultMessage="contract gross value" />
              </p>
              <p className="heading-02 fw-semibold">2,750,000</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pt-2 pb-5 px-4 bg-light border border-primary text-primary rounded-1">
              <p className="heading-05 fw-semibold mb-3 text-capitalize">
                <FormattedMessage id="agency_dashboard.value_available" defaultMessage="value available" />
              </p>
              <p className="heading-02 fw-semibold">$2,142,195</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pt-2 pb-5 px-4 bg-white border border-primary text-primary rounded-1">
              <p className="heading-05 fw-semibold text-grey-93 mb-3 text-capitalize">
                <FormattedMessage id="agency_dashboard.traditional_media" defaultMessage="agency profit using traditional media" />
              </p>
              <p className="heading-02 fw-semibold">$1,500,000</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pt-2 pb-5 px-4 bg-white border border-primary text-primary rounded-1">
              <p className="heading-05 fw-semibold text-grey-93 mb-3 text-capitalize">
                <FormattedMessage id="agency_dashboard.rethink_demand" defaultMessage="agency profit using rethink demand" />
              </p>
              <p className="heading-02 fw-semibold">$5,100,000</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pt-2 pb-5 px-4 bg-white border border-primary text-primary rounded-1">
              <p className="heading-05 fw-semibold text-grey-93 mb-3 text-capitalize">
                <FormattedMessage id="agency_dashboard.additional_profit" defaultMessage="additional profit using rethink demand" />
              </p>
              <p className="heading-02 fw-semibold">$3,600,000</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  _renderCharts = () => {

    return (
      <div className="row gy-4 mt-1">
        <div className="col-lg-6">
          <div className="py-4 px-5 bg-white border border-primary text-primary rounded-1">
            <h6 className="heading-04 fw-semibold mb-8">Lead Quality</h6>
            <div className="chart ps-lg-12 pb-8">
              <img alt="img" src={require("../../assets/images/chart01.svg").default} className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="py-4 px-5 bg-white border border-primary text-primary rounded-1">
            <h6 className="heading-04 fw-semibold mb-8">Credit Usage</h6>
            <div className="chart ps-lg-12 pb-8">
              <img alt="img" src={require("../../assets/images/chart02.svg").default} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderView = () => {

    return (
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 px-6 py-6">
          {this._renderHeader()}
          {this._renderCreditSection()}
          {this._renderClientAgencyInfo()}
          {this._renderCharts()}
        </div>
      </div>
    );
  }

  render() {

    return (
      <>
        <Helmet>
          <title> {this.props.intl.formatMessage({ id: "hermes.agency_dashboard", defaultMessage: "Agency Dashboard: Hermes" })} </title>
        </Helmet>
        {(hasAccess("dashboard", "agency", ["page-view"])) && (
          this._renderView()
        )}
      </>
    );
  }

}

export default injectIntl(AgencyDashboard);
