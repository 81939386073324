import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty, debounce as _debounce } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { showAlertMessage, Select } from "../../resusableComponents/common/formElements";
import CustomPagination from "../../resusableComponents/common/CustomPagination";
import { hasAccess } from "../../resusableComponents/hoc/AccessManagement";
import { USER_ROLES } from "../../helpers/constants";

import { doGetAgencyList } from "../../redux/services/userMangement";
import { updateLoadingState } from "../../redux/actions/application";
import { getDashboardData } from "../../redux/services/application";

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.defaultPayload = {
      agency_id: null,
      search_text: "",
      page_no: 1,
      page_size: 10
    }

    this.state = {
      dashboardData: [],
      defaultPayload: { ...this.defaultPayload },
      dashboardDataPagination: {},
      agencyDropDownData: [],
      selectedAgency: null,
    }

    this.searchDebounce = _debounce(this._handleSearchAgency, 1000);
  }

  componentDidMount() {
    const { roleId } = this.props;
    if (hasAccess("dashboard", "index", ["page-view"])) {
      this._fetchAgency();

      if ((![_get(USER_ROLES, "AGENCY.id", "0"), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", "0")].includes(roleId))) {
        this._fetchdashBoardList();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.defaultPayload, this.state.defaultPayload)) {
      this._fetchdashBoardList();
    }
  }

  _fetchAgency = async () => {
    const { updateLoadingState, agencyId, roleId, agencyName } = this.props;

    try {

      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      let selAgencyList = null
      const selAgencyId = [_get(USER_ROLES, "AGENCY.id", "0"), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", "0")].includes(roleId) ? agencyId : null;

      const response = await doGetAgencyList({ dropdown_flag: 1, agency_id: selAgencyId });

      if (([_get(USER_ROLES, "AGENCY.id", "0"), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", "0")].includes(roleId))) {
        selAgencyList = { "id": agencyId, "agency_name": agencyName };
      }

      if ((response.flag || false) === true) {
        const fetchData = _get(response, "data.responseData.agency_list", []);
        let agencyDropDownData = [...fetchData];
        agencyDropDownData.unshift({ id: null, agency_name: "All" });
        this.setState(prevState => ({
          defaultPayload: { ...prevState.defaultPayload, agency_id: selAgencyId },
          agencyDropDownData: agencyDropDownData, selectedAgency: selAgencyList
        }));
      }
      else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching agency.")));
        this.setState(prevState => ({
          defaultPayload: { ...prevState.defaultPayload, agency_id: selAgencyId },
          agencyDropDownData: [], selectedAgency: selAgencyList
        }));
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching agency.")));
      this.setState({ agencyDropDownData: [], selectedAgency: null });
    } finally {

      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _fetchdashBoardList = async () => {
    const { updateLoadingState } = this.props;
    const { defaultPayload } = this.state;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getDashboardData(defaultPayload);

      if (_get(response, "flag", false) === true) {
        this.setState({
          dashboardData: _get(response, "data.dashboard_details", []),
          dashboardDataPagination: _get(response, "data.dashboard_page_info[0]", {}),
        });
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching dashboard data.")));
      }

    } catch (err) {
      showAlertMessage((_get(err, "message", "Something went wrong while fetching dashboard data.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleSearchAgency = (searchText) => {
    const { defaultPayload } = this.state;

    this.setState({ defaultPayload: { ...defaultPayload, search_text: searchText } });
  }

  handlePaginationChange = (pageNo) => {
    const { defaultPayload } = this.state;

    this.setState({ defaultPayload: { ...defaultPayload, page_no: pageNo } });
  }

  _renderHeading = () => {

    return (
      <div className="row">
        <div className="col-lg-12">
          <h1 className="heading-04 fw-semibold text-dark-35 text-uppercase mb-5">
            <FormattedMessage id="dashboard.title" defaultMessage="dashboard" />
          </h1>
        </div>
      </div>
    );
  }

  _renderFilters = () => {
    const { intl, roleId } = this.props;
    const { agencyDropDownData, selectedAgency } = this.state;

    return (
      <div className="row justify-content-between gy-4">

        {(hasAccess("dashboard", "index", ["agency-filter"])) && (
          <div className="col-lg-3">
            <div>
              <label className="form-label text-capitalize" >
                <FormattedMessage id="campaign_list.select.agency.label" defaultMessage="agency" />
              </label>
              <Select
                className="form-custom-select text-capitalize"
                placeholder={intl.formatMessage({ id: "campaign_list.select.agency.placeholder", defaultMessage: "select agency" })}
                options={(agencyDropDownData || [])}
                value={(selectedAgency || null)}
                getOptionLabel={(option) => (option.agency_name || "")}
                getOptionValue={(option) => (option.id || null)}
                onChange={(selected) => this.setState(prevState => ({
                  defaultPayload: {
                    ...prevState.defaultPayload,
                    agency_id: _get(selected, "id", null)
                  },
                  selectedAgency: selected
                }))}
                isDisabled={[_get(USER_ROLES, "AGENCY.id", "0"), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", "0")].includes(roleId) ? true : false}
                isMulti={false}
              />
            </div>
          </div>
        )}

        {(hasAccess("dashboard", "index", ["search"])) && (
          <div className="col-lg-3">
            <div>
              <label className="form-label text-capitalize">
                <FormattedMessage id="placeholder.search" defaultMessage="search" />
              </label>
              <div className="input-group">
                <input type="text"
                  className="form-control"
                  placeholder="Search ..."
                  onChange={(e) => this.searchDebounce(_get(e, "target.value", ""))} />
                <span className="input-group-text bg-primary waves-effect py-1 d-flex align-items-center">
                  <img src={require("../../assets/icons/icon-search-sm.svg").default} alt="Hermes" className="img-fluid" />
                </span>
              </div>
            </div>
          </div>
        )}

      </div>
    );
  }

  _renderDashboardCard = () => {
    const { dashboardData } = this.state;

    return (
      <>
        {(dashboardData || []).map((data, index) => {

          const logoUrl = (!_isEmpty(_get(data, "website", ""))) ? `https://logo.clearbit.com/${_get(data, "website", "")}` : require("../../assets/images/no-img-icon.png");

          return (
            <div className="card-agency-list" key={index}>

              <div className="card-agency-list-logo">
                <p>
                  {_get(data, "name", "")}
                </p>
                <img src={logoUrl} className="img-fluid" alt="" />
              </div>

              <div className="card-agency-list-campaign">
                <p className="text-capitalize">
                  <FormattedMessage id="dashboard.active" defaultMessage="active" />
                  <br />
                  <FormattedMessage id="dashboard.campaigns" defaultMessage="campaigns" />
                </p>
                <h6>{_get(data, "active_campaigns", 0)}</h6>
              </div>

              <div className="card-agency-list-leads">
                <p className="text-capitalize">
                  <FormattedMessage id="dashboard.leads" defaultMessage="leads" />
                  <br />
                  <FormattedMessage id="dashboard.delivered" defaultMessage="delivered" />
                </p>
                <h6>{_get(data, "leads_delivered", 0)}</h6>
              </div>

              <div className="card-agency-list-leadsvalue">
                <p className="text-capitalize">
                  <FormattedMessage id="dashboard.leads_value" defaultMessage="leads value" />
                  <br />
                  <FormattedMessage id="dashboard.delivered" defaultMessage="delivered" />
                </p>
                <h6>${_get(data, "leads_value_delivered", 0)}</h6>
              </div>

              <div className="card-agency-list-contract">
                <p className="text-capitalize">
                  <FormattedMessage id="dashboard.contract" defaultMessage="contract" />
                  <br />
                  <FormattedMessage id="dashboard.value" defaultMessage="value" />
                </p>
                <h6>{_get(data, "contract_value", 0)}</h6>
              </div>

              <div className="card-agency-list-outstanding">
                <p className="text-capitalize">
                  <FormattedMessage id="dashboard.outstanding" defaultMessage="outstanding" />
                  <br />
                  <FormattedMessage id="dashboard.credit_value" defaultMessage="credit value" />
                </p>
                <h6>{_get(data, "outstanding_credit_value", 0)}</h6>
              </div>

              <div className="card-agency-list-more">
                <img src={require("../../assets/icons/icon-three-dot.svg").default} className="img-fluid" alt="img" />
              </div>
            </div>
          )
        })}
      </>
    );
  }

  _renderDashboardData = () => {
    const { dashboardDataPagination } = this.state;

    return (
      <div className="row mt-6">
        <div className="col-lg-12">

          {this._renderDashboardCard()}

          <ul className="pagination justify-content-end mt-9">
            <CustomPagination
              rowsPerPage={10}
              currentPage={_get(dashboardDataPagination, 'current_page', 1)}
              rowCount={_get(dashboardDataPagination, "total_records", 0)}
              onChangePage={(e) => this.handlePaginationChange(e)} />
          </ul>
        </div>
      </div>
    )
  }

  _renderView = () => {

    return (
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 px-6 py-6">
          {this._renderHeading()}
          {this._renderFilters()}
          {this._renderDashboardData()}
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title>
            {intl.formatMessage({ id: "hermes.dashboard", defaultMessage: "Dashboard: Hermes", })}
          </title>
        </Helmet>

        {(hasAccess("dashboard", "index", ["page-view"])) && (
          this._renderView()
        )}

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  roleId: _get(state, "authentication.user.role_id", 0),
  agencyId: _get(state, "authentication.user.agency_id", null),
  agencyName: _get(state, "authentication.user.agency_name", ""),
})

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Dashboard));
