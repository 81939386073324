import React, { Component } from "react";
import { isEqual as _isEqual, get as _get, cloneDeep as _cloneDeep, map as _map, includes as _includes, forEach as _forEach } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";

import { CustomModal, showAlertMessage } from "../common/formElements";

import { updateLoadingState } from "../../redux/actions/application";

import { fetchCampaignRequiredFields, saveRequiredFields } from "../../redux/services/campaignSetup";

class EditRequiredFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRequiredFields: [],
      selectedFieldIds: [],
      selectedFieldColumnValues: [],
      showModal: false,
      defaultSelectedFields: []
    };
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.showModal, this.state.showModal) && _get(this.state, "showModal", false) === true) {

      this._fetchCampaignRequiredFields();
    }

    if (!_isEqual(prevState.selectedRequiredFields, this.state.selectedRequiredFields) || !_isEqual(prevState.defaultSelectedFields, this.state.defaultSelectedFields)) {
      this._setDefaultRequiredFields();
    }
  }

  _fetchCampaignRequiredFields = async () => {
    const { campaignId, updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const reqRes = await fetchCampaignRequiredFields(campaignId);

      if ((reqRes.flag || false) === true) {
        this.setState({ selectedRequiredFields: _get(reqRes, "data.required_fields", []), defaultSelectedFields: _get(reqRes, "data.campaign_required_fields", []) });
      } else {
        showAlertMessage(_get(reqRes, "message", "Something went wrong while fetching default selected fields."));
      }
    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while fetching required field details."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _setDefaultRequiredFields = () => {
    const { selectedFieldIds, selectedFieldColumnValues, defaultSelectedFields } = this.state;

    let tmpSelectedFieldIds = _cloneDeep(selectedFieldIds);
    let tmpSelectedFieldColumnValues = _cloneDeep(selectedFieldColumnValues);

    _forEach((defaultSelectedFields || []), (mf) => {
      const id = _get(mf, "id", 0);
      const colmName = _get(mf, "friendly_name", "");
      let requiredValues = _get(mf, "required_values", "");

      tmpSelectedFieldIds = { ...tmpSelectedFieldIds, [id]: id };
      tmpSelectedFieldColumnValues = { ...tmpSelectedFieldColumnValues, [id]: { "id": id, "name": colmName, "value": (requiredValues || "") } };
    });

    this.setState({ selectedFieldIds: tmpSelectedFieldIds, selectedFieldColumnValues: tmpSelectedFieldColumnValues });
  }

  _handleSubmit = async () => {
    const { updateLoadingState, campaignId } = this.props;
    const { selectedFieldColumnValues } = this.state;

    const colmnValue = _map(selectedFieldColumnValues, (v, k) => ({ "name": (v.name || ""), "value": (v.value || ""), "id": (v.id || "") }));

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const payload = {
        campaignId,
        data: {
          fields: colmnValue,
        },
      }

      const response = await saveRequiredFields(payload);

      if ((response.flag || false) === true) {
        showAlertMessage(_get(response, "message", "Campaign required fields updated successfully."), "success");
        this._closeModal();
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while updating campaign required fields."));
      }

    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while updating campaign required fields."));
    } finally {

      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _showModal = () => {
    const { campaignId, intl } = this.props;

    if ((campaignId || null) === null) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "campaign", defaultMessage: "campaign" }) }
      ));
      return false;
    }

    this.setState({ showModal: true });
  }

  _closeModal = () => {
    const { setState } = this.props;

    this.setState({ selectedRequiredFields: [], selectedFieldIds: [], selectedFieldColumnValues: [], showModal: false, defaultSelectedFields: [] });

    if (typeof setState === "function") { setState({ reloadBriefList: true }); }
  }

  _renderModalContent = () => {
    const { intl } = this.props;
    const { showModal, selectedRequiredFields, selectedFieldIds, selectedFieldColumnValues } = this.state;

    return (
      <CustomModal
        isOpen={showModal}
        className="text-capitalize"
        modalTitle={(intl.formatMessage({ id: "campaign_setup.campaign_brief.edit_required_fields", defaultMessage: "edit required fields" }))}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
        size="lg"
      >
        <>
          <div className="row">
            {_map(selectedRequiredFields, (field, i) => {

              return (
                <div key={i} className="col-lg-6">
                  <div className="mb-5">
                    <div className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        className="form-check-input form-check-input-radio"
                        name="required_fields"
                        id={(field.id || "")}
                        value={(field.id || "")}
                        checked={_includes((selectedFieldIds || []), (field.id || "")) ? true : false}
                        onChange={() => {

                          let tmpSelectedFieldIds = _cloneDeep(selectedFieldIds);
                          let tmpSelectedFieldColumnValues = _cloneDeep(selectedFieldColumnValues);
                          const key = (field.id || null);

                          if (key === null) { return false; }
                          const isExist = (typeof tmpSelectedFieldIds[key] !== "undefined") ? true : false;

                          if (isExist === true) {
                            delete tmpSelectedFieldIds[key];
                            delete tmpSelectedFieldColumnValues[key];

                          } else {
                            tmpSelectedFieldIds = { ...tmpSelectedFieldIds, [key]: key };
                            tmpSelectedFieldColumnValues = { ...tmpSelectedFieldColumnValues, [key]: { "id": key, "name": (field.friendly_name || null), "value": "" } };

                          }

                          this.setState({ selectedFieldIds: tmpSelectedFieldIds, selectedFieldColumnValues: tmpSelectedFieldColumnValues });
                        }}
                      />
                      <label className="form-check-label text-capitalize" htmlFor={(field.id || "")}>
                        {(field.friendly_name || "")}
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
            <button className="btn btn-secondary" onClick={() => this._closeModal()} >
              <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
            </button>
            <button className="btn btn-primary text-capitalize" onClick={() => this._handleSubmit()}>
              <FormattedMessage id="btn.submit" defaultMessage="submit" />
            </button>
          </div>
        </>
      </CustomModal>
    );
  }

  render() {

    return (
      <>

        {this._renderModalContent()}

        <Dropdown.Item className="dropdown-item d-block waves-effect text-capitalize" onClick={() => this._showModal()} >
          <FormattedMessage id="campaign_setup.campaign_brief.edit_required_fields" defaultMessage="edit required fields" />
        </Dropdown.Item>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(null, mapDispatchToProps)(injectIntl(EditRequiredFields));
