import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class RenderTableHeaderValue extends PureComponent {

  render() {
    const { value = "", className = "text-capitalize", textClassName = "", ...restProps } = this.props;

    return (
      <div className={(className || "")} {...restProps}>
        <span className={(textClassName || "")}>{(value || "")}</span>
      </div>
    )
  }
}

RenderTableHeaderValue.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  sortable: PropTypes.bool,
  sortIconPosition: PropTypes.string,
  sortIconClassName: PropTypes.string,
};

export default RenderTableHeaderValue;
