import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { showAlertMessage, Select, ErrorMessage } from "../../../../../resusableComponents/common/formElements";
import { isEmailValid, isPasswordValid } from "../../../../../helpers/utils";
import { USER_ROLES } from "../../../../../helpers/constants";

import { doCreateUser, clearCreateUserResponse } from "../../../../../redux/actions/userMangement";
import { doGetAgencyList, doGetClientList } from "../../../../../redux/services/userMangement";
import { updateLoadingState } from "../../../../../redux/actions/application";
import { hasAccess } from "../../../../../resusableComponents/hoc/AccessManagement";

class CreateNewUser extends Component {

  constructor(props) {
    super(props);

    this.defaultState = {
      confirmPassword: "",
      agencyList: null,
      clientList: null,
      firstName: "",
      lastName: "",
      password: "",
      userType: null,
      email: "",
    }

    this.state = {
      formData: { ...this.defaultState },
      agencyListDropDownData: [],
      formErrors: {},
      clientListDropDownData: [],
      isFormSubmitted: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_isEqual(_get(prevState, "formData.userType", null), _get(this.state, "formData.userType", null)) &&
      ([_get(USER_ROLES, "AGENCY.id", 0), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", 0)].includes(_get(this.state, "formData.userType.id", null)))
    ) {
      this._fetchAgencyList();
    }

    if (!_isEqual(_get(prevState, "formData.agencyList", null), _get(this.state, "formData.agencyList", null))) {
      this._fetchClientList();
    }

    if (!_isEqual(prevState.formData, this.state.formData) && (_get(this.state, "isFormSubmitted", false) === true)) {
      this._handleValidation();
    }

    if (!_isEqual(prevProps.createUserResponse, this.props.createUserResponse) && !_isEmpty(this.props.createUserResponse)) {
      const { createUserResponse, clearCreateUserResponse } = this.props;

      if ((_get(createUserResponse, "flag", null) === true)) {
        showAlertMessage(_get(createUserResponse, "message", "User created successfully."), "success");

        this.setState({ formData: { ...this.defaultState }, formErrors: {}, isFormSubmitted: false });
      } else if ((_get(createUserResponse, "flag", null) === false)) {
        showAlertMessage(_get(createUserResponse, "message", "Something went wrong while creating user."), "error");
      }

      if (typeof clearCreateUserResponse === "function") { clearCreateUserResponse(); }
    }
  }

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { formData: { firstName, password, confirmPassword, email, lastName, agencyList, clientList, userType } } = this.state;
    let formErrors = {};
    const selectedUserType = _get(userType, "id", null);

    if (([_get(USER_ROLES, "AGENCY.id", 0), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", 0)].includes(selectedUserType)) && _isEmpty(agencyList)) {
      formErrors["agencyList"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.agency_placeholder", defaultMessage: "agency list" })
      });
    }

    if (([_get(USER_ROLES, "AGENCY.id", 0), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", 0)].includes(selectedUserType)) && _isEmpty(clientList)) {
      formErrors["clientList"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.client_placeholder", defaultMessage: "client list" })
      });
    }

    if (_isEmpty(userType)) {
      formErrors["userType"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.user.user_type", defaultMessage: "userType" })
      });
    }

    if (_isEmpty(firstName)) {
      formErrors["firstName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.firstname", defaultMessage: "first name" })
      });
    }

    if (_isEmpty(lastName)) {
      formErrors["lastName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.lastname", defaultMessage: "last name" })
      });
    }

    if (!isEmailValid(email)) {
      formErrors["email"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}." }, {
        field: intl.formatMessage({ id: "email", defaultMessage: "email" }),
      });
    }

    if (_isEmpty(password)) {
      formErrors["password"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "password", defaultMessage: "password" })
      });
    }

    if (!_isEmpty(password) && !isPasswordValid(password)) {
      formErrors["password"] = intl.formatMessage({ id: "error.password_valid_char", defaultMessage: "Password must be at least 8 characters long and contain an uppercase letter, a number, and a special character." });
    }

    if (_isEmpty(confirmPassword)) {
      formErrors["confirmPassword"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "confirm_password", defaultMessage: "confirm password" })
      });
    }

    if (!_isEqual(password, confirmPassword)) {
      formErrors["confirmPassword"] = intl.formatMessage({ id: "error.password_not_match", defaultMessage: "Confirm password does not match." });
    }

    this.setState({ formErrors });

    if (returnFlag) {
      return (!_isEmpty(formErrors)) ? false : true;
    }
  }

  handleChange = (keyName, keyValue) => {
    this.setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [keyName]: keyValue,
      }
    }));
  }

  _handleUserSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { doCreateUser } = this.props;
    const { formErrors, formData: { firstName, password, email, lastName, agencyList, clientList, userType } } = this.state;

    if (!_isEmpty(formErrors)) { return false; }

    this.setState({ isFormSubmitted: true });

    const isValidForm = this._handleValidation(true);

    if (!isValidForm) { return false; }

    if (typeof doCreateUser === "function") {
      const clientIds = (!_isEmpty(clientList)) ? (clientList || []).map(obj => obj.id) : null;

      doCreateUser({
        first_name: (firstName || ""),
        last_name: (lastName || ""),
        email: (email || ""),
        role_id: _get(userType, "id", null),
        agency_id: _get(agencyList, "id", null),
        client_ids: clientIds,
        password: (password || "")
      });
    }
  }

  _fetchAgencyList = async () => {
    const { updateLoadingState } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetAgencyList({ dropdown_flag: 1 });

      if ((response.flag || false) === true) {
        this.setState((prevState) => ({ agencyListDropDownData: _get(response, "data.responseData.agency_list", []), formData: { ...prevState.formData, agencyList: null } }));
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching agency list.")));
        this.setState((prevState) => ({ agencyListDropDownData: [], formData: { ...prevState.formData, agencyList: null } }));
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching agency list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _fetchClientList = async () => {
    const { updateLoadingState } = this.props;
    const { formData: { agencyList } } = this.state;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await doGetClientList({ agency_id: _get(agencyList, "id", null), dropdown_flag: 1 });

      if ((response.flag || false) === true) {
        this.setState((prevState) => ({ clientListDropDownData: _get(response, "data.responseData.client_list", []), formData: { ...prevState.formData, clientList: null } }));
      } else {
        showAlertMessage((_get(response, "message", "Something went wrong while fetching client list.")));
        this.setState((prevState) => ({ clientListDropDownData: [], formData: { ...prevState.formData, clientList: null } }));
      }
    } catch (error) {
      showAlertMessage((_get(error, "message", "Something went wrong while fetching client list.")));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  };

  _renderTextFiled = ({ label, placeholder, onChange, name, defaultValue, type = "text", isRequired = false }) => {

    return (
      <>
        <label className="form-label">
          {label}
          {(isRequired) && (<span className="text-danger">*</span>)}
        </label>
        <input
          name={(name || "")}
          type={(type || "text")}
          className="form-control"
          placeholder={(placeholder || "")}
          onChange={onChange}
          value={(defaultValue || "")}
        />
      </>
    );
  }

  _renderView = () => {
    const { userRolesDropDownData, intl } = this.props;
    const {
      agencyListDropDownData, clientListDropDownData, formErrors,
      formData: { confirmPassword, clientList, agencyList, firstName, userType, lastName, password, email },
    } = this.state;

    const selectedUserType = _get(userType, "id", null);

    return (
      <div className="row">

        <div className="col-lg-6">
          <div className="mb-6">
            {this._renderTextFiled({
              label: intl.formatMessage({ id: "user_management.firstname_label", defaultMessage: "First Name" }),
              placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                field: intl.formatMessage({ id: "user_management.firstname", defaultMessage: "first name" }),
              }),
              onChange: (event) => this.handleChange("firstName", _get(event, "target.value", "")),
              name: "firstName",
              isRequired: true,
              defaultValue: (firstName || "")
            })}
            {_get(formErrors, "firstName", "") && <ErrorMessage message={_get(formErrors, "firstName", "")} />}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="mb-6">
            {this._renderTextFiled({
              label: intl.formatMessage({ id: "user_management.lastname_label", defaultMessage: "Last Name" }),
              placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                field: intl.formatMessage({ id: "user_management.lastname", defaultMessage: "last name" }),
              }),
              onChange: (event) => this.handleChange("lastName", _get(event, "target.value", "")),
              name: "lastName",
              isRequired: true,
              defaultValue: (lastName || "")
            })}
            {_get(formErrors, "lastName", "") && <ErrorMessage message={_get(formErrors, "lastName", "")} />}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="mb-6">
            {this._renderTextFiled({
              label: intl.formatMessage({ id: "user_management.email_label", defaultMessage: "Email" }),
              placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                field: intl.formatMessage({ id: "email", defaultMessage: "email" }),
              }),
              onChange: (event) => this.handleChange("email", _get(event, "target.value", "")),
              name: "email",
              isRequired: true,
              defaultValue: (email || "")
            })}
            {_get(formErrors, "email", "") && <ErrorMessage message={_get(formErrors, "email", "")} />}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-6">
            <label className="form-label text-capitalize">
              <FormattedMessage id="user_management.usertype_label" defaultMessage="User Type" />
              <span className="text-danger">*</span>
            </label>
            <Select
              className="form-custom-select"
              placeholder={intl.formatMessage({ id: "user_management.usertype_label", defaultMessage: "User Type" })}
              options={(userRolesDropDownData || [])}
              value={(userType || null)}
              getOptionLabel={(option) => (option.name || "User Type")}
              getOptionValue={(option) => (option.id || null)}
              onChange={(selected) => this.handleChange("userType", selected)}
              isMulti={false}
            />
            {_get(formErrors, "userType", "") && <ErrorMessage message={_get(formErrors, "userType", "")} />}
          </div>
        </div>

        {([_get(USER_ROLES, "AGENCY.id", 0), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", 0)].includes(selectedUserType)) && (
          <div className="col-lg-6">
            <div className="mb-6">
              <label className="form-label text-capitalize">
                <FormattedMessage id="user_management.agency_placeholder" defaultMessage="Agency list" />
                <span className="text-danger">*</span>
              </label>
              <Select
                className="form-custom-select"
                placeholder={intl.formatMessage({ id: "user_management.agency_placeholder", defaultMessage: "Agency list" })}
                options={(agencyListDropDownData || [])}
                value={(agencyList || null)}
                getOptionLabel={(option) => (option.agency_name || "Agency list")}
                getOptionValue={(option) => (option.id || null)}
                onChange={(selected) => this.handleChange("agencyList", selected)}
                isMulti={false}
              />
              {_get(formErrors, "agencyList", "") && <ErrorMessage message={_get(formErrors, "agencyList", "")} />}
            </div>
          </div>
        )}

        {([_get(USER_ROLES, "AGENCY.id", 0), _get(USER_ROLES, "AGENCY_ACCOUNTS.id", 0)].includes(selectedUserType)) && (
          <div className="col-lg-6">
            <div className="mb-6">
              <label className="form-label text-capitalize">
                <FormattedMessage id="user_management.client_placeholder" defaultMessage="Client list" />
              </label>
              <Select
                className="form-custom-select"
                placeholder={intl.formatMessage({ id: "user_management.client_placeholder", defaultMessage: "Client list" })}
                options={(clientListDropDownData || [])}
                value={(clientList || null)}
                getOptionLabel={(option) => (option.client_name || "Client list")}
                getOptionValue={(option) => (option.id || null)}
                onChange={(selected) => this.handleChange("clientList", selected)}
                isMulti={true}
              />
              {_get(formErrors, "clientList", "") && <ErrorMessage message={_get(formErrors, "clientList", "")} />}
            </div>
          </div>
        )}

        <div className="col-lg-6">
          <div className="mb-6">
            {this._renderTextFiled({
              label: intl.formatMessage({ id: "user_management.password_label", defaultMessage: "Password" }),
              placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                field: intl.formatMessage({ id: "password", defaultMessage: "password" }),
              }),
              onChange: (event) => this.handleChange("password", _get(event, "target.value", "")),
              name: "password",
              defaultValue: (password || ""),
              isRequired: true,
              type: "password"
            })}
            {_get(formErrors, "password", "") && <ErrorMessage message={_get(formErrors, "password", "")} />}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="mb-6">
            {this._renderTextFiled({
              label: intl.formatMessage({ id: "user_management.confirmpassword_label", defaultMessage: "Confirm Password" }),
              placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
                field: intl.formatMessage({ id: "confirm_password", defaultMessage: "confirm password" }),
              }),
              onChange: (event) => this.handleChange("confirmPassword", _get(event, "target.value", "")),
              name: "confirmPassword",
              defaultValue: (confirmPassword || ""),
              isRequired: true,
              type: "password"
            })}
            {_get(formErrors, "confirmPassword", "") && <ErrorMessage message={_get(formErrors, "confirmPassword", "")} />}
          </div>
        </div>

        <div className="col-lg-12">
          <div className="text-end">
            <button type="button" className="btn btn-primary text-capitalize" onClick={(e) => this._handleUserSubmit(e)}>
              <FormattedMessage id="btn.submit" defaultMessage="submit" />
            </button>
          </div>
        </div>

      </div>
    );
  }

  render() {

    return (
      <>
        {hasAccess("admin", "user-management", ["create-new-user"]) && this._renderView()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  createUserResponse: _get(state, "userManagement.createUser", {}),
});

const mapDispatchToProps = (dispatch) => ({
  doCreateUser: (data) => dispatch(doCreateUser(data)),
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  clearCreateUserResponse: () => dispatch(clearCreateUserResponse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateNewUser));
