import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { showAlertMessage, ErrorMessage } from "../../../../../resusableComponents/common/formElements";
import { hasAccess } from "../../../../../resusableComponents/hoc/AccessManagement";
import { isEmailValid, isDomainNameValid } from "../../../../../helpers/utils"

import { doCreateAgency, clearCreateAgencyResponse } from "../../../../../redux/actions/userMangement"

class CreateNewAgency extends Component {

  constructor(props) {
    super(props);

    this.defaultState = {
      agencyName: "",
      email: "",
      websiteUrl: "",
      credits: "",
      gross: "",
      net: "",
      formFile: ""
    }

    this.state = {
      formData: { ...this.defaultState },
      isEditButtonClicked: false,
      disableSubmit: false,
      formErrors: {}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevState.formData, this.state.formData) && (_get(this.state, "isFormSubmitted", false) === true)) {
      this._handleValidation();
    }

    if (!_isEqual(prevProps.createAgencyResponse, this.props.createAgencyResponse) && !_isEmpty(this.props.createAgencyResponse)) {
      const { createAgencyResponse, clearCreateAgencyResponse } = this.props;

      if ((_get(createAgencyResponse, "flag", null) === true)) {
        showAlertMessage(_get(createAgencyResponse, "message", "Agency created successfully."), "success");

        this.setState({ formData: { ...this.defaultState }, formErrors: {}, isFormSubmitted: false });
      } else if ((_get(createAgencyResponse, "flag", null) === false)) {
        showAlertMessage(_get(createAgencyResponse, "message", "Something went wrong while creating agency."), "error");
      }

      if (typeof clearCreateAgencyResponse === "function") { clearCreateAgencyResponse(); }
    }
  }

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { formData: { agencyName, email, websiteUrl, /* formFile */ } } = this.state;

    let formErrors = {};

    if (_isEmpty(agencyName)) {
      formErrors["agencyName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.agency.create.agency_name", defaultMessage: "agency name" })
      });
    }

    if (!isEmailValid(email)) {
      formErrors["email"] = intl.formatMessage({ id: "error.valid", defaultMessage: "Please enter valid {field}." }, {
        field: intl.formatMessage({ id: "user_management.agency.create.email_address", defaultMessage: "email address" })
      });
    }

    if (_isEmpty(websiteUrl)) {
      formErrors["websiteUrl"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.agency.create.website_url", defaultMessage: "website URL" })
      });
    }

    if (!_isEmpty(websiteUrl) && isDomainNameValid(websiteUrl) === false) {
      formErrors["websiteUrl"] = intl.formatMessage({ id: "error.valid", defaultMessage: "Please enter valid {field}." }, {
        field: intl.formatMessage({ id: "user_management.agency.create.website_url", defaultMessage: "website URL" })
      });
    }

    // if (_isEmpty(formFile)) {
    //   formErrors["formFile"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
    //     field: intl.formatMessage({ id: "password", defaultMessage: "password" })
    //   });
    // }

    this.setState({ formErrors });

    if (returnFlag) {
      return (!_isEmpty(formErrors)) ? false : true;
    }
  }

  handleChange = (keyName, keyValue) => {
    this.setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [keyName]: keyValue,
      }
    }));
  }

  handleFileUpload = async (e) => {
    let file = _get(e, "target.files[0]", null);
    this.setState({
      formFile: file
    });
  }

  _handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { doCreateAgency } = this.props;
    const { formErrors, formData: { agencyName, email, websiteUrl, credits, gross, net } } = this.state;

    if (!_isEmpty(formErrors)) { return false; }

    this.setState({ isFormSubmitted: true });

    const isValidForm = this._handleValidation(true);

    if (!isValidForm) { return false; }

    if (typeof doCreateAgency === "function") {

      let data = new FormData();
      data.append("agency_name", (agencyName || ""));
      data.append("email", (email || ""));
      data.append("website", (websiteUrl || ""));
      data.append("credits", (credits || 0));
      data.append("gross", (gross || 0));
      data.append("net", (net || 0));

      doCreateAgency(data);
    }
  }

  _renderTextFiled = ({ label, placeholder, onChange, name, defaultValue, isRequired = false, type = "text" }) => {
    const { formErrors } = this.state;

    return (
      <div className="mb-6">
        <label className="form-label text-capitalize">
          {label}
          {(isRequired) && (<span className="text-danger">*</span>)}
        </label>
        <input
          name={name}
          type={(type || "text")}
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
          value={defaultValue}
        />
        {_get(formErrors, `${name}`, "") && <ErrorMessage message={_get(formErrors, `${name}`, "")} />}
      </div>
    );
  }

  _renderView = () => {
    const { intl } = this.props;
    const { formData: { agencyName, email, websiteUrl, credits, gross, net } } = this.state;

    return (

      <div className="row">

        <div className="col-lg-6">
          {this._renderTextFiled({
            label: intl.formatMessage({ id: "user_management.agency_name", defaultMessage: "Agency Name" }),
            placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
              field: intl.formatMessage({ id: "user_management.agency_name", defaultMessage: "agency name", }),
            }),
            onChange: (event) => this.handleChange("agencyName", _get(event, "target.value", "")),
            name: "agencyName",
            isRequired: true,
            defaultValue: (agencyName || "")
          })}
        </div>

        <div className="col-lg-6">
          {this._renderTextFiled({
            label: intl.formatMessage({ id: "user_management.email_address", defaultMessage: "Email Address" }),
            placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
              field: intl.formatMessage({ id: "user_management.email_address", defaultMessage: "email address", }),
            }),
            onChange: (event) => this.handleChange("email", _get(event, "target.value", "")),
            name: "email",
            isRequired: true,
            defaultValue: (email || "")
          })}
        </div>

        <div className="col-lg-6">
          {this._renderTextFiled({
            label: intl.formatMessage({ id: "user_management.website_url", defaultMessage: "Website URL" }),
            placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
              field: intl.formatMessage({ id: "user_management.website_url", defaultMessage: "website url", }),
            }),
            onChange: (event) => this.handleChange("websiteUrl", _get(event, "target.value", "")),
            name: "websiteUrl",
            isRequired: true,
            defaultValue: (websiteUrl || "")
          })}
        </div>

        <div className="col-lg-6">
          {this._renderTextFiled({
            label: intl.formatMessage({ id: "user_management.leads_booked", defaultMessage: "Leads booked" }),
            placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
              field: intl.formatMessage({ id: "user_management.leads_booked", defaultMessage: "Leads booked", }),
            }),
            onChange: (event) => this.handleChange("credits", _get(event, "target.value", "")),
            name: "credits",
            type: "number",
            isRequired: true,
            defaultValue: (credits || "")
          })}
        </div>

        <div className="col-lg-6">
          {this._renderTextFiled({
            label: intl.formatMessage({ id: "user_management.agency.create.gross", defaultMessage: "Gross" }),
            placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
              field: intl.formatMessage({ id: "user_management.agency.create.gross", defaultMessage: "Gross", }),
            }),
            onChange: (event) => this.handleChange("gross", _get(event, "target.value", "")),
            name: "gross",
            type: "number",
            defaultValue: (gross || "")
          })}
        </div>

        <div className="col-lg-6">
          {this._renderTextFiled({
            label: intl.formatMessage({ id: "user_management.agency.create.net", defaultMessage: "Net" }),
            placeholder: intl.formatMessage({ id: "placeholder.enter_field", defaultMessage: "Enter {field}" }, {
              field: intl.formatMessage({ id: "user_management.agency.create.net", defaultMessage: "Net", }),
            }),
            onChange: (event) => this.handleChange("net", _get(event, "target.value", "")),
            name: "net",
            type: "number",
            defaultValue: (net || "")
          })}
        </div>

        {/*<div className="col-lg-6">
          <div className="mb-6">
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
                <FormattedMessage id="user_management.upload_logo" defaultMessage="Upload Logo" />
              </label>
              <input className="form-control" type="file" id="formFile" onChange={(event) => this.handleFileUpload(event)} />
            </div>
          </div>
        </div>*/}

        <div className="col-lg-12">
          <div className="text-end">
            <button type="button" className="btn btn-primary text-capitalize" onClick={(e) => this._handleSubmit(e)}>
              <FormattedMessage id="btn.submit" defaultMessage="submit" />
            </button>
          </div>
        </div>

      </div>
    );
  }

  render() {
    return (
      <>
        {hasAccess("admin", "agency-management", ["create-agency"]) && this._renderView()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  createAgencyResponse: _get(state, "userManagement.createAgency", {}),
});

const mapDispatchToProps = (dispatch) => ({
  doCreateAgency: (data) => dispatch(doCreateAgency(data)),
  clearCreateAgencyResponse: () => dispatch(clearCreateAgencyResponse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateNewAgency));
