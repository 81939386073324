
// Campaign dashboard
export const FETCH_CAMPAIGN_DASHBOARD_DATA_REQUEST = "FETCH_CAMPAIGN_DASHBOARD_DATA_REQUEST";
export const FETCH_CAMPAIGN_DASHBOARD_DATA_SUCCESS = "FETCH_CAMPAIGN_DASHBOARD_DATA_SUCCESS";
export const FETCH_CAMPAIGN_DASHBOARD_DATA_ERROR = "FETCH_CAMPAIGN_DASHBOARD_DATA_ERROR";

export const fetchCampaignDashboardData = (payload) => {
  return ({
    type: FETCH_CAMPAIGN_DASHBOARD_DATA_REQUEST,
    payload
  });
}

export const CLEAR_CAMPAIGN_DASHBOARD_ERROR = "CLEAR_CAMPAIGN_DASHBOARD_ERROR";
export const clearCampaignDashboardError = () => {
  return ({
    type: CLEAR_CAMPAIGN_DASHBOARD_ERROR
  });
}

//Campaign List
export const GET_CAMPAIGN_LIST_REQUEST = "GET_CAMPAIGN_LIST_REQUEST";
export const GET_CAMPAIGN_LIST_SUCCESS = "GET_CAMPAIGN_LIST_SUCCESS";
export const GET_CAMPAIGN_LIST_ERROR = "GET_CAMPAIGN_LIST_ERROR";
export const CLEAR_CAMPAIGN_LIST_RESPONSE = "CLEAR_CAMPAIGN_LIST_RESPONSE";

export const doGetCampaignList = (payload) => {
  return ({
    type: GET_CAMPAIGN_LIST_REQUEST,
    payload
  });
}

export const clearCampaignListError = () => {
  return ({
    type: CLEAR_CAMPAIGN_LIST_RESPONSE
  });
}

// Campaign Edit
export const FETCH_CAMPAIGN_EDIT_BRIEFS_REQUEST = "FETCH_CAMPAIGN_EDIT_BRIEFS_REQUEST";
export const FETCH_CAMPAIGN_EDIT_BRIEFS_SUCCESS = "FETCH_CAMPAIGN_EDIT_BRIEFS_SUCCESS";
export const FETCH_CAMPAIGN_EDIT_BRIEFS_ERROR = "FETCH_CAMPAIGN_EDIT_BRIEFS_ERROR";

export const fetchCampaignEditBriefs = (payload) => {
  return ({
    type: FETCH_CAMPAIGN_EDIT_BRIEFS_REQUEST,
    payload
  });
}

export const CLEAR_CAMPAIGN_EDIT_BRIEFS_ERROR = "CLEAR_CAMPAIGN_EDIT_BRIEFS_ERROR";
export const clearCampaignEditBriefsError = () => {
  return ({
    type: CLEAR_CAMPAIGN_EDIT_BRIEFS_ERROR
  });
}

// View leads
export const FETCH_CAMPAIGN_LEAD_DETAILS_REQUEST = "FETCH_CAMPAIGN_LEAD_DETAILS_REQUEST";
export const FETCH_CAMPAIGN_LEAD_DETAILS_SUCCESS = "FETCH_CAMPAIGN_LEAD_DETAILS_SUCCESS";
export const FETCH_CAMPAIGN_LEAD_DETAILS_ERROR = "FETCH_CAMPAIGN_LEAD_DETAILS_ERROR";
export const fetchCampaignLeadDetails = (payload) => {
  return ({
    type: FETCH_CAMPAIGN_LEAD_DETAILS_REQUEST,
    payload
  });
}

export const RESET_CAMPAIGN_LEAD_DETAILS = "RESET_CAMPAIGN_LEAD_DETAILS";
export const resetCampaignLeadDetails = () => {
  return ({
    type: RESET_CAMPAIGN_LEAD_DETAILS
  });
}
