import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, cloneDeep as _cloneDeep, map as _map } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";

import { CustomModal, showAlertMessage } from "../common/formElements";

import { assignAssetToBriefs, getAssetsData } from "../../redux/services/campaignSetup";

import { updateLoadingState } from "../../redux/actions/application";


class AssignAssets extends Component {

  constructor(props) {
    super(props);
    this.defaultState = {
      searchText: "",
      selectedAssetsIds: [],
      assetsListData: [],
      initialAssetsListData: [],
    };

    this.state = {
      ...this.defaultState,
      checkAllIds: false,
      showModal: false
    };
  }

  componentDidUpdate(prevProps, prevStates) {

    if (!_isEqual(prevStates.showModal, this.state.showModal) && _get(this.state, "showModal", false) === true) {

      this._fetchListData();
    }

    if (!_isEqual(prevStates.searchText, this.state.searchText)) {
      const { initialAssetsListData, searchText } = this.state;

      let tmpListData = _cloneDeep(initialAssetsListData);

      tmpListData = (tmpListData || []).filter(({ asset_name }) => (asset_name || "").toLowerCase().includes((searchText || "").toLowerCase()));

      this.setState({ assetsListData: tmpListData });
    }

  }

  _showModal = () => {
    const { selectedBriefIds, intl } = this.props;

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    this.setState({ ...this.defaultState, showModal: true });
  }

  _closeModal = () => {

    this.setState({ ...this.defaultState, showModal: false });
  }

  _fetchListData = async () => {
    const { selectedBriefIds, updateLoadingState, campaignId, intl } = this.props;
    const { selectedAssetsIds } = this.state;
    let tmpSelectIds = _cloneDeep(selectedAssetsIds || []);

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const payload = {
        "dropdown_flag": 1,
        "brief_ids": (selectedBriefIds || [])
      };

      const response = await getAssetsData(campaignId, payload);

      if ((response.flag || false) === true) {

        _get(response, "data.asset_details", []).forEach(asset => {
          if (_get(asset, "is_assigned_to_brief", 0) === 1) {
            tmpSelectIds.push(_get(asset, "id", 0));
          }
        });

        this.setState({
          selectedAssetsIds: (tmpSelectIds || []),
          assetsListData: _get(response, "data.asset_details", []),
          initialAssetsListData: _get(response, "data.asset_details", []),
        });
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while fetching assets data."));
      }
    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while fetching assets data."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _assignAssetsToBriefs = async () => {
    const { updateLoadingState, selectedBriefIds, campaignId, intl } = this.props;
    const { selectedAssetsIds } = this.state;

    if ((selectedAssetsIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.assets", defaultMessage: "asset" }) }
      ));
      return false;
    }

    if ((selectedBriefIds || []).length < 1) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." },
        { field: intl.formatMessage({ id: "brief", defaultMessage: "brief" }) }
      ));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const payload = {
        "asset_ids": selectedAssetsIds,
        "brief_ids": selectedBriefIds,
        "delete_existing_assets": true
      };

      const response = await assignAssetToBriefs(campaignId, payload);

      if ((response.flag || false) === true) {

        showAlertMessage(_get(response, "data.message", "Assets assigned to brief successfully."), "success");

        this._closeModal();
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while assigning assets."));
      }
    } catch (error) {
      showAlertMessage(_get(error, "message", "Something went wrong while assigning assets."));

    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _selectIds = (id = null, source = "single") => {
    const { selectedAssetsIds, checkAllIds, assetsListData } = this.state;
    let updatedState = null;
    let tmpSelectIds = _cloneDeep(selectedAssetsIds || []);

    switch (source) {
      case "single":
        let tmpCheckAllIds = _cloneDeep(checkAllIds);

        if (tmpSelectIds.includes(id)) {
          tmpSelectIds = (tmpSelectIds).filter(c => (c !== id));
          tmpCheckAllIds = false;
        } else {
          tmpSelectIds.push(id);
        }

        updatedState = { selectedAssetsIds: tmpSelectIds, checkAllIds: tmpCheckAllIds };

        break;

      case "all":
        updatedState = {
          selectedAssetsIds: (checkAllIds === true) ? [] : _map(assetsListData, "id"),
          checkAllIds: !checkAllIds
        };
        break;

      default:
        break;
    }

    this.setState(updatedState);
  }

  _renderModalContent = () => {
    const { intl } = this.props;
    const { showModal, assetsListData, selectedAssetsIds, checkAllIds } = this.state;

    return (
      <CustomModal
        isOpen={showModal}
        className="text-capitalize"
        modalTitle={(intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.modal.title", defaultMessage: "assign {dataType} to briefs" },
          { dataType: intl.formatMessage({ id: "campaign_setup.campaign_brief.assign.assets", defaultMessage: "assets" }) }))}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
      >

        <form className="modalForm assign-brief-modal">

          <div className="searchOverview mb-3">
            <input
              className="form-control fuzzy-search"
              type="text"
              name="search"
              placeholder={intl.formatMessage({ id: "search", defaultMessage: "search" })}
              onChange={(e) => {
                const { target: { value } } = e;

                this.setState({ searchText: value });
              }}
            />
          </div>
          <div>
            <div className="form-check mb-2" onClick={(e) => this._selectIds(null, "all")}>
              <input
                type="checkbox"
                className="form-check-input"
                name="outcome"
                key="check-all-briefs"
                checked={checkAllIds}
                onChange={() => { }}
              />
              <label className="form-check-label text-capitalize" htmlFor="check-all-briefs">
                <FormattedMessage id="placeholder.select_all" defaultMessage="select all" />
              </label>
            </div>
          </div>

          <div className="assign-brief-container">
            <>
              {(assetsListData || []).map((l) => {
                const id = (l.id || null);

                return (
                  <div key={`main-dev-${id}`} onClick={(e) => this._selectIds(id)}>
                    <div className="form-check mb-2">
                      <input
                        key={`checkbox-${id}`}
                        type="checkbox"
                        className="form-check-input"
                        name="outcome"
                        checked={((selectedAssetsIds || []).includes(id))}
                        onChange={() => { }}
                        value={id}
                      />
                      <label className="form-check-label" htmlFor={`checkbox-${id}`}>{(l.asset_name || "")}</label>
                    </div>
                  </div>
                );
              })}
            </>
          </div>
        </form>

        <div className="pt-4 px-4 mx-n4 d-flex gap-3 align-items-center justify-content-end border-top">
          <button className="btn btn-secondary" onClick={() => this._closeModal()} >
            <FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
          </button>
          <button className="btn btn-primary text-capitalize" onClick={() => this._assignAssetsToBriefs()}>
            <FormattedMessage id="campaign_setup.campaign_brief.assign.data_type" defaultMessage="assign {dataType}" values={{ dataType: "assets" }} />
          </button>
        </div>
      </CustomModal>
    );
  }

  render() {
    return (
      <>

        {this._renderModalContent()}

        <Dropdown.Item className="dropdown-item d-block waves-effect text-capitalize" onClick={() => this._showModal()} >
          <FormattedMessage id="campaign_setup.campaign_brief.assign.data_type" defaultMessage="assign {dataType}" values={{ dataType: "assets" }} />
        </Dropdown.Item>

      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default connect(null, mapDispatchToProps)(injectIntl(AssignAssets));
