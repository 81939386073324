import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { get as _get } from "lodash";
import { connect } from "react-redux";

import { withRouter } from "../../../resusableComponents/hoc/withRouter";

import { toggleNavbarMenu } from "../../../redux/actions/application";

const customDropdownToogle = React.forwardRef(({ children, onClick }, ref) => (
  <NavLink
    to={"/"}
    ref={ref}
    className="d-flex"
    onClick={(e) => { e.preventDefault(); onClick(e); }}
  >
    {children}
  </NavLink>
));

class Header extends Component {

  _toggleNavbarMenu = () => {
    const { toggleNavbarMenu, isNavbarVisible } = this.props;

    const changeMenuType = ((isNavbarVisible || false) === false) ? true : false;

    if (typeof toggleNavbarMenu === "function") { toggleNavbarMenu(changeMenuType); }
  };

  render() {
    const { isNavbarVisible, fullName, profilePic } = this.props;

    const profilePicURL = (profilePic) ? profilePic : require("../../../assets/images/user.png");

    return (
      <nav className={`navbar layout-navbar bg-white ${(isNavbarVisible === true) ? "" : "active"}`}>
        <button className="navbar-toggler" type="button" aria-expanded="false" onClick={() => this._toggleNavbarMenu()}>
          <img src={require("../../../assets/icons/icon-menu.svg").default} className="img-fluid" alt="Hermes" />
        </button>

        <a className="navbar-brand ms-xl-9 ms-3" href="/#">
          <img src={require("../../../assets/images/logo-dark.svg").default} className="img-fluid" alt="Hermes" />
        </a>

        <ul className="navbar-nav flex-row align-items-center ms-auto">
          <Dropdown>
            <li className="nav-item dropdown nav-item-user">
              <Dropdown.Toggle as={customDropdownToogle} id="dropdown-basic">
                <span
                  className="nav-link dropdown-toggle fw-normal"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={(profilePicURL || "")} className="img-fluid me-2" alt="Hermes admin team" />
                  {(fullName || "User")}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul" className="dropdown-menu dropdown-menu-end">
                <li>
                  <Dropdown.Item href="/settings" className="dropdown-item waves-effect text-capitalize">
                    <img src={require("../../../assets/icons/icon-setting-sm.svg").default} alt="" />
                    <FormattedMessage id="settings" defaultMessage="settings" />
                  </Dropdown.Item>
                </li>
                <li> <hr className="dropdown-divider" /> </li>
                <li>
                  <Dropdown.Item href="/logout" className="dropdown-item waves-effect text-capitalize">
                    <img src={require("../../../assets/icons/icon-logout.svg").default} alt="" />
                    <FormattedMessage id="logout" defaultMessage="logout" />
                  </Dropdown.Item>
                </li>
              </Dropdown.Menu>
            </li>
          </Dropdown>
        </ul>
      </nav>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleNavbarMenu: (payload) => dispatch(toggleNavbarMenu(payload))
});

const mapStateToProps = (state, props) => {
  const firstName = _get(state, "authentication.user.first_name", "");
  const lastName = _get(state, "authentication.user.last_name", "");

  return ({
    fullName: `${(firstName || "")} ${(lastName || "")}`,
    profilePic: _get(state, "authentication.user.profile_pic_url", ""),
    isNavbarVisible: _get(state, "application.isNavbarVisible", false)
  })
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Header)));
