import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { showAlertMessage } from "../../../resusableComponents/common/formElements";
import { withRouter } from "../../../resusableComponents/hoc/withRouter";
import CampaignSetupProgress from "./CampaignSetupProgress";

import { initiateCampaign, resetCampaignSetupError, resetCampaignSetup } from "../../../redux/actions/campaignSetup";

class InitiateCampaign extends Component {

  componentDidUpdate(prevProps, prevStates) {
    if (!_isEqual(prevProps.setupErrors, this.props.setupErrors) && !_isEmpty(this.props.setupErrors)) {
      showAlertMessage(_get(this.props, "setupErrors.message", ""));

      if (typeof this.props.resetCampaignSetupError === "function") { this.props.resetCampaignSetupError(); }
    }
  }

  _handleNext = () => {
    const { common, common: { lastFilledStep, currentStep, campaignId }, initiateCampaign, intl } = this.props;

    if (!campaignId) {
      showAlertMessage(intl.formatMessage({ id: "error.campaign_id_is_missing", defaultMessage: "Campaign id is missing, Please contact support team." }));
      return false;
    }

    const variable = {
      "common": {
        ...common,
        currentStep: (currentStep + 1),
        lastFilledStep: (lastFilledStep > currentStep) ? lastFilledStep : (currentStep + 1),
      },
      [currentStep - 1]: {
        summaryLabel: intl.formatMessage({ id: "campaign_setup.initiate_campaign.initiate_campaign", defaultMessage: "initiate camapign" }),
      }
    };

    if (typeof initiateCampaign === "function") {
      const payload = Object.assign({}, variable, { "payload": { campaign_id: campaignId } });

      initiateCampaign(payload);
    }
  }

  render() {
    const { common: { currentStep, totalStep }, onPreviousStep } = this.props;

    return (
      <div className="row">
        <div className="col-lg-8">
          <div className="card">
            <div className="card-body py-5 vh-165">
              <h6 className="heading-04 fw-semibold mb-6">{`Step ${currentStep} of ${totalStep} - Initiate Campaign`}</h6>

              <div className="row">
                <div className="col-lg-12">
                  <button className="btn btn-primary" onClick={() => this._handleNext()}>
                    <FormattedMessage id="campaign_setup.initiate_campaign.initiate_campaign" defaultMessage="initiate camapign" />
                  </button>
                </div>
              </div>

              <div className="row mt-auto">
                <div className="col-lg-12">
                  <div className="mt-4">
                    <button className="btn btn-primary text-capitalize" onClick={() => { if (typeof onPreviousStep === "function") { onPreviousStep() } }}>
                      <FormattedMessage id="btn.back" defaultMessage="back" />
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <CampaignSetupProgress />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  common: _get(state, "campaignSetup.summary.common", {}),
  setupErrors: _get(state, "campaignSetup.setupErrors", {}),
});

const mapDispatchToProps = (dispatch) => ({
  initiateCampaign: (data) => dispatch(initiateCampaign(data)),
  resetCampaignSetupError: () => dispatch(resetCampaignSetupError()),
  resetCampaignSetup: () => dispatch(resetCampaignSetup()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(InitiateCampaign)));
